
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const invoiceService = {
    getInvoiceData,
    saveTableHeader,
    invoiceBulkAction,
    getInvoiceDetails,
    entryMasterData,
    submitInvoice,
    getInvoiceByDate
    
 
};

async function  getInvoiceData() {
    return  axios.get(`/invoices/get-invoice-data`, authHeader());
}


async function  getInvoiceByDate(date) {
    return  axios.put(`/invoices/get-invoice-by-date`, date, authHeader());
}

async function  invoiceBulkAction(body ) {
    return  axios.put(`/invoices/invoice-bulk-action`,body, authHeader());
}


async function  saveTableHeader(body) {
    return  axios.post(`/invoices/save-table-header`, body, authHeader());
}

async function  getInvoiceDetails(invoice_id) {
    return  axios.get(`/invoices/get-invoice-details/${invoice_id}`, authHeader());
}
async function  entryMasterData() {
    return  axios.get(`/invoices/entry-master-data`, authHeader());
}

async function  submitInvoice(data) {
    return  axios.post(`/invoices/submit-invoice`,data, authHeader());
}







