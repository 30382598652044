import * as React from "react";
import { useEffect, useState } from "react";

import {DataGrid,GridToolbar} from "@mui/x-data-grid";

import { customerService } from "../../services/customer.service";
import {
  GetCustomerTransListAtom,
  SetCustomerTransListAtom,
} from "../../store/customerTransListAtom";
import CustomerHistorySelect from "./CustomerHistorySelect";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";
import { formatCustomDate } from "../../helpers/formatCustomDate";



const tableHead = [
  {
    field: "Ref_No",
    headerName: "Ref",
    width: 130,
    className: "font-weight-bold",
  },
  { field: "Transaction_Date", headerName: "Date", width: 160 },
  { field: "Transaction_Type_Name", headerName: "Type", width: 150 },
  { field: "Transaction_Group", headerName: "Group ", width: 130 },
  { field: "Amount", headerName: "Amount (₦)", width: 150 },
  { field: "Narration", headerName: "Narration", width: 160 },
];

export default function CustomerTransactionTab({ customer_id }) {
  const [tableList, setTableList] = useState([]);
  const setTransList = SetCustomerTransListAtom();
  const transList = GetCustomerTransListAtom();

console.log('Trans ', tableList);

  useEffect(() => {
    if (!transList.transation_history?.length) {
      getDataFromServer();
    } else {
      setTableList(transList.transation_history);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id, transList.transation_history?.length]);

  const getDataFromServer = () => {
    customerService.getCustomerTransactions(customer_id).then((res) => {
      if (res.data?.transation_history?.length) {
        setTransList(res.data);
        setTableList(res.data.transation_history);
        // let tempList =  res.data?.transation_history.map((item, index)=> { return {...item, id: index}} );
      }
    });
  };

  const onTypeSelect = (selection) => {
    if (selection?.Transaction_Type_Id === 0) {
      return setTableList(transList.transation_history);
    }
    let newList = transList.transation_history.filter(
      (item) => item.transaction_type_id === selection?.Transaction_Type_Id
    );
    setTableList(newList);
  };


  return (
    <>
      <div className="ml-1 @container">
        <div className="md:flex md:flex-row md:items-center md:justify-between">
          <div className="mt-1 text-gray-600 text-xl font-bold">
            transaction History
          </div>
          <div className="mr-1 mt-3 md:mt-0">
            <CustomerHistorySelect
              optionList={transList.transation_type}
              onTypeSelect={onTypeSelect}
            />
          </div>
        </div>


        <DataGrid
          className="mt-4 overflow-x-auto bg-white outline-none border-[1px] border-gray-200 rounded-xl"
          style={{ height: 600, width: "100%" }}
          columns={tableHead}
          rows={tableList?.map((item, index) => {
            return { ...item,Amount: formatCustomNumber(item?.Amount),
               Transaction_Date: formatCustomDate(item?.Transaction_Date) , id: index };
          })}
       
          onCellClick={(event) => {
            event.defaultMuiPrevented = true;
          }}
       
          slots={{ toolbar: GridToolbar }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
        />
      </div>
    </>
  );
}
