

import moment from "moment";


function VoucherDetailContent(props) {
    const {voucherDetails} = props;




    const managePurchaseStatus = ( Voucher_Status_Id, statusName) => {
      let defaultClass = "color1";

      switch (Voucher_Status_Id) {
          case 1:
              defaultClass = "color2";
              break;
          case 2:
              defaultClass = "color3";
              break;
          case 3:
              defaultClass = "color4";
              break;
          case 4:
              defaultClass = "color5";
              break;
          case 5:
              defaultClass = "color6";
              break;
          case 6:
              defaultClass = "color1";
              break;
          case 7:
              defaultClass = "color8";
              break;

          default:
              break;
      }

      return (
          <span className={`text-sm font-bold md:text-sm ${defaultClass} `}>
              {statusName}
          </span>
      );
  };



    return (
        <>

<div className="space-y-4">

{/* Corporate Information Section */}
<div className="bg-white shadow-sm rounded-lg p-4">
  <div className="flex  mb-2">
    <p className="text-md font-medium text-gray-700">Corporate Name:</p>
    <p className="text-lg font-bold text-blue-600 ml-3">{voucherDetails?.Corporate_Name}</p>
  </div>
 
  <div className="flex mb-2">
    <p className="text-md font-medium text-gray-700">Customer Email:</p>
    <p className="text-md text-gray-500 ml-3">{voucherDetails?.Customer_Email_Address || "N/A"}</p>
  </div>
  <div className="flex  mb-2">
    <p className="text-md font-medium text-gray-700">Customer Name:</p>
    <p className="text-md text-gray-500 ml-3">{voucherDetails?.Customer_Full_Name || "N/A"}</p>
  </div>
  <div className="flex  mb-2">
    <p className="text-md font-medium text-gray-700">Customer Mobile:</p>
    <p className="text-md text-gray-500 ml-3">{voucherDetails?.Customer_Mobile_No || "N/A"}</p>
  </div>
  <div className="flex  mb-2">
    <p className="text-md font-medium text-gray-700">Status:</p>
    <p className="text-md text-gray-500 ml-3">
    {managePurchaseStatus(voucherDetails?.Voucher_Status_Id, voucherDetails?.Voucher_Status_Name)}
      
      </p>
  </div>
  <div className="flex mb-2">
    <p className="text-md font-medium text-gray-700">Type:</p>
    <p className="text-md text-gray-500 ml-3">{voucherDetails?.Voucher_Type_Name || "N/A"}</p>
  </div>
  <div className="flex mb-2">
    <p className="text-md font-medium text-gray-700">Created by:</p>
    <p className="text-md text-gray-500 ml-3">{voucherDetails?.Created_By_Name || "N/A"}</p>
  </div>
 
</div>

{/* Voucher Details Section */}
<section className="bg-white shadow-sm rounded-lg p-4">
  <div className="space-y-2">
    <div className="flex ">
      <p className="text-md font-medium text-gray-700">Voucher code:</p>
      <p className="text-lg font-bold text-blue-600 ml-3">{voucherDetails?.Voucher_Number }</p>
    </div>
    <div className="flex ">
      <p className="text-md font-medium text-gray-700">Amount/Unit:</p>
      <p className="text-md text-gray-500 ml-3">₦{voucherDetails?.Voucher_Amount?.toLocaleString()}</p>
    </div>
   
    <div className="flex ">
      <p className="text-md font-medium text-gray-700">Recipient Mobile:</p>
      <p className="text-md text-gray-500 ml-3">{voucherDetails?.Reciepient_Mobile_Number?.toLocaleString()}</p>
    </div>
    <div className="flex ">
      <p className="text-md font-medium text-gray-700">Recipient Name:</p>
      <p className="text-md text-gray-500 ml-3">{voucherDetails?.Reciepient_Name?.toLocaleString()}</p>
    </div>
    <div className="flex ">
      <p className="text-md font-medium text-gray-700">Redeem  by:</p>
      <p className="text-md text-gray-500 ml-3">{ voucherDetails?.Redeem_By_Name?.toLocaleString() ||  "N/A"}</p>
    </div>
    <div className="flex ">
      <p className="text-md font-medium text-gray-700"> Date:</p>
      <p className="text-md text-gray-500 ml-3">{moment(voucherDetails?.Transaction_Date).format('llll')}</p>
    </div>
  </div>
</section>

</div>

        
        </>
      );
}

export default VoucherDetailContent;