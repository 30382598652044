import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import CurrencyFormat from "react-currency-format";
import { invoiceService } from "../../services/invoice.service";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../Context/LogicContext";
import { GetManualJournalAtom, SetManualJournalAtom, SetManualJournalEditAtom } from "../../store/manualJounalAtom";

import { ImAttachment } from "react-icons/im";
import { FaFilePdf } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";
import { creditNoteService } from "../../services/creditNoteService";
import { useReactToPrint } from 'react-to-print';
import CreditNoteOptions from "../credit_note/CreditNoteOptions";
import ManualJournalDetailsContent from "./ManualJournalDetailsContent";
import CreditNoteScroll from "../credit_note/CreditNoteScroll";
import { accountService } from "../../services/accountService";
import ManualJournalScroll from "./ManualJournalScroll";
// import CreditNoteFilter from "./CreditNoteFilter";



function ManualJournalDetailsPage() {
  const myPromiseModal = createModal(GeneralModal);
  const setJournalEdit = SetManualJournalEditAtom();
  const getManualJournalAtom = GetManualJournalAtom();
  const setManualJournalAtom = SetManualJournalAtom();
  
  const logicContext = useContext(LogicContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const componentRef = useRef();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];
    const [details, setDetails] = useState({});
    const [detailsList, setDetailsList] = useState([]);



    useEffect(()=> {
      loadDetails(id);
        
    },[id]);


    useEffect(() => {
      document.title = 'Manual Journal details';
      if (!getManualJournalAtom.account_info?.length) {
        loadTableList();
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getManualJournalAtom.account_info]);


    const loadTableList = () => {
      
      accountService.manualJournal().then(res => {
        if (res.data) {
          // setManualJournalAtom(res.data);
        }
      });
    }

    const loadDetails = async(accountId)=> {
        try {
        const response =  await accountService.manualJournalDetails(accountId);
        if(response.data?.account_info?.length){
          const journal = response.data?.account_info[0];
            setDetails(journal);
            setDetailsList(response.data?.account_info);
          //  const newList = getManualJournalAtom.account_info?.map((item)=> {
          //   if(item.Ref_No === creditNote.Ref_No){
          //     return creditNote;
          //   }else{
          //     return item;
          //   }
          //  });
          //  setCreditNoteListAtom({credit_note: newList,status_info: getCreditNoteListAtom.status_info});

         
        }
            
        } catch (error) {
            console.log('Error ', error);
            
        }
    }


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });



  
  const addNewRecord = ()=> {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
  }

  
  const viewCustomerDetails = (id)=> {
    navigate(`/${currModuleId}/${currModuleName}/customers/customer-details/${id}`);
  }


      const editJournal = async () => {
        const response = await myPromiseModal({
          title: "Modify Journal",
          body: " Do you want to modify this record?",
          showInput: false,
          placeHolder: "",
        });
    
        if (response.decision === true) {
          setJournalEdit(detailsList);
          navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
        }
      };



  const onDeleteJournal = async ()=> {
    const response = await myPromiseModal({
      title: "Delete Journal",
      body: " Record will be deleted?",
      showInput: false,
      placeHolder: "",
    });

    if (response.decision === true) {
   const response =  await  accountService.deleteManualJournal(details?.account_ref_no);
   const data =  logicContext.httpResponseManager(response.data[0]);
   const newAccList = getManualJournalAtom.account_info.filter((acc)=>  acc.account_ref_no !== details?.account_ref_no );
   setManualJournalAtom({...getManualJournalAtom,account_info:newAccList });
   navigate(-1);
    }

  }


  const actionPerform = async (type, option)=> {
    const data = {account_ref_no: id,option: option }

    const response = await myPromiseModal({  title: type,   body: `You want to ${type}`,
      showInput: false, placeHolder: "" });

    if (response.decision === false) {
      return;
    }

    switch (type) {
      case 'DELETE':

     const resp1 = await accountService.journalAction(data);
     const data1 =  logicContext.httpResponseManager(resp1.data?.result_status[0]);
     logicContext.showToast(data1?.message_text, 'success');
     const newAccList = getManualJournalAtom.account_info.filter((acc)=>  acc.account_ref_no !== details?.account_ref_no );
     setManualJournalAtom({...getManualJournalAtom,account_info:newAccList });
     navigate(-1);
        
        break; 
      case 'PUBLISH':
        const resp2 = await accountService.journalAction(data);
     const data2 =  logicContext.httpResponseManager(resp2.data?.result_status[0]);
     logicContext.showToast(data2?.message_text, 'success');
     loadDetails(id);
        
        
        break;
        case 'REVERSE':
          const resp3 = await accountService.journalAction(data);
          const data3 =  logicContext.httpResponseManager(resp3.data?.result_status[0]);
          logicContext.showToast(data3?.message_text, 'success');
          loadDetails(id);
        
        break;
    
      default:
        break;
    }

    
   


  }
    

    return ( 
        <>
          <section className="">
        <section className="flex flex-wrap justify-between">
          <div className="mt-3">
            {/* {selectedList.length > 0 && (
              <Dropdown>
                <Dropdown.Toggle
                  className="border-[1px] border-gray-200"
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#45444B",
                    fontSize: "12px",
                    height: "40px",
                  }}
                  id="dropdown-basic"
                >
                  Bulk Actions
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-32 px-2 text-sm shadow-lg">
                  {getBulkActions?.map((action, i) => {
                    return (
                      <Dropdown.Item
                        className="px-0 text-gray-600"
                        key={i}
                        onClick={() => bulkActions(action)}
                      >
                        {action.Action_Name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )} */}
          </div>

          <div className="flex flex-row flex-wrap items-center">
            <div className="mt-3 flex flex-row items-center mr-6">
          <button onClick={addNewRecord} className="btn btn-primary m-4" >Add New</button>
              
            {/* Drop down */}
            {/* Drop down end */
            <Dropdown style={{ width: "150px", marginRight:"30px", marginTop: "2px" }}>
                          <Dropdown.Toggle
                            className="pr-10"
                            id="dropdown-basic"
                            style={{
                              backgroundColor: "#476EF8",
                              // fontSize: "10px",
                              // height: "43px",
                            
                            
                            }}
                          >
                             ACTION
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {(details?.status_id === 1 )  &&
                            <>
                              <Dropdown.Item onClick={()=> actionPerform('PUBLISH',5)} className="border" style={{ fontSize: "15px" }}>
                              PUBLISH
                            </Dropdown.Item>
                         
                            </>
                            
                            }

                              <Dropdown.Item onClick={()=> actionPerform('REVERSE',6)} className="border" style={{ fontSize: "15px" }}>
                              REVERSE
                            </Dropdown.Item>          
                          
            
                            <Dropdown.Item onClick={()=> actionPerform('DELETE',3)} className="border" style={{ fontSize: "15px" }}>
                              DELETE
                            </Dropdown.Item>
                           
                          </Dropdown.Menu>
                        </Dropdown>
                   }
            </div>
            <div className="mt-3 flex fle-row items-center space-x-1 ">
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <ImAttachment className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <FaFilePdf className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <IoPrintSharp  onClick={handlePrint} className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <MdEdit onClick={editJournal} className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div onClick={()=>onDeleteJournal()} className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <MdDelete className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
            </div>
          </div>
        </section>
        <section className="mt-4 lg:flex space-x-1">
          <div className="lg:w-[210px]">
            <ManualJournalScroll
            ></ManualJournalScroll>
          </div>
          <section className="w-full overflow-x-hidden mt-4 lg:mt-0 lg:pl-4 ">
          
          {/* Paste here */}
          
            <ManualJournalDetailsContent viewCustomerDetails={viewCustomerDetails}
             ref={componentRef} detailsList={detailsList}  id={id} details={details} />
          {/* Paste end */}
          </section>
        </section>
      </section>
        
        </>
     );
}

export default ManualJournalDetailsPage;