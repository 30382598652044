import { useEffect, useRef, useState } from "react";
import { GetSalesOrderList } from "../../store/salesOrderAtom";
// import {GetPurchaseOrderMasterData} from '../store/purchaseOrderAtom';
import { Grid } from "@mui/material";
import CurrencyFormat from "react-currency-format";
import { useParams } from "react-router-dom";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { twMerge } from "tailwind-merge";

function SalesScroll(props) {
  const GetSalesOrderMasterData = GetSalesOrderList();
  const mountRef = useRef(true);
  const [tableData, setTableData] = useState([]);
  const [checkedAll, setCheckAll] = useState(false);
  const { sales_id } = useParams();

  useEffect(() => {
    mountRef.current = true;

    if (GetSalesOrderMasterData["Sales_Order_Info"]?.length > 0) {
      setTableData(
        GetSalesOrderMasterData["Sales_Order_Info"].map((ele) => {
          return { ...ele, checked: false };
        })
      );
    }

    return () => {
      mountRef.current = false;
    };
  }, [GetSalesOrderMasterData]);

  const onActionFilterPerform = (action) => {
    const option = action.target.value;
    if (Number(option) === 0) {
      return setTableData(GetSalesOrderMasterData["Sales_Order_Info"]);
    }
    var newList = GetSalesOrderMasterData["Sales_Order_Info"].filter(
      (item) => item.Sales_Order_Status_Id === Number(option)
    );
    setTableData(newList);
  };

  const onCheckAllElement = async (selection) => {
    setCheckAll((prev) => {
      return !prev;
    });

    let newList = tableData?.map((item, i) => {
      return { ...item, checked: !selection };
    });

    await setTableData((prev) => {
      return [...newList];
    });
    props?.onSelection(newList);
  };

  const onCheckboxClick = async (e, index) => {
    let element = tableData[index];
    tableData[index] = { ...element, checked: e.target.checked };
    await setTableData((prev) => {
      return [...tableData];
    });
    props?.onSelection(tableData);
  };

  const managePurchaseStatus = (status) => {
    let defaultClass = "color1";

    switch (status) {
      case "Draft":
        defaultClass = "color2";
        break;
      case "Approved":
        defaultClass = "color3";
        break;
      case "Pending for Approval":
        defaultClass = "color4";
        break;
      case "Fulfilled":
        defaultClass = "color5";
        break;
      case "Rejected":
        defaultClass = "color6";
        break;
      case "Confirmed":
        defaultClass = "color1";
        break;
      case "Partially Billed":
        defaultClass = "color8";
        break;

      default:
        break;
    }

    return (
      <span className={`text-sm font-medium ${defaultClass} `}>{status}</span>
    );
  };

  return (
    <>
      <section className="border-[1px] rounded-lg bg-white border-gray-200 flex flex-col overflow-y-scroll h-[300px] lg:h-[1000px] scrollbar-thumb-gray-500 scrollbar-thin scrollbar-track-gray-100 pb-[100px]">
        <div className="border-b-[1px] border-b-gray-300">
          <ActionPaneComponent
            bulkActionData={GetSalesOrderMasterData["Bulk_Action"]}
            onSelectBulkAction={onActionFilterPerform}
          />
        </div>
        <div
          className={twMerge(
            `p-2 lg:p-1 grid grid-cols-2 lg:grid-cols-none  border-b-[1px] cursor-pointer border-gray-200`
          )}
        >
          <label className="flex flex-row items-center">
            <input
              onChange={(e) => onCheckAllElement(checkedAll)}
              type="checkbox"
              className="w-4 h-4 border-[1px] border-gray-300 rounded-sm cursor-pointer"
              checked={checkedAll ? checkedAll : ""}
            />
            <div className="ml-2 text-sm font-bold ">Select all</div>
          </label>
        </div>
        <div>
          {tableData?.map((sales, i) => {
            return (
              <div
                key={i}
                className={twMerge(
                  `p-2 lg:p-1 grid grid-cols-2 lg:grid-cols-none  border-b-[1px] cursor-pointer border-gray-200 ${
                    Number(sales_id) === sales?.Sales_Order_Ref_No
                      ? "bg-gray-200"
                      : "hover:bg-gray-100"
                  }`
                )}
              >
                <div className="flex flex-row">
                  <div className="">
                    <input
                      onChange={(e) => onCheckboxClick(e, i)}
                      checked={sales?.checked ? sales?.checked : ""}
                      type="checkbox"
                      className="w-4 h-4 border-[1px] border-gray-300 rounded-sm cursor-pointer"
                    />
                  </div>

                  <div
                    onClick={() => props.viewDetails(sales?.Sales_Order_Ref_No)}
                    className="ml-1"
                  >
                    <div
                      onClick={() =>
                        props.viewDetails(sales?.Sales_Order_Ref_No)
                      }
                    >
                      <span className="text-sm font-bold text-gray-700">
                        {sales?.Customer_Full_Name}
                      </span>{" "}
                      <br></br>
                      <span className="text-xs text-gray-400 font-semibold italic">
                        {sales?.Sales_Order_No}
                      </span>
                      <br></br>
                      <span className="text-sm lg:text-xs font-medium text-gray-600">
                        <CurrencyFormat
                          value={parseFloat(sales?.Sales_Order_Amount)?.toFixed(
                            2
                          )}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="lg:mt-2 lg:ml-5 ">
                  <div className={twMerge("text-sm font-normal")}>
                    {managePurchaseStatus(sales?.Sales_Order_Status_Name)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default SalesScroll;
