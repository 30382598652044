import React, { useEffect } from "react";
import chat from "../assets/chat.png";
import customeravater from "../assets/avaer2.png";
import edit from "../assets/edit.svg";
import canccel from "../assets/x-octagon.svg";
import "../pages/Image.css";
import chart from "../assets/chart.svg";
import "./ItemOverviewPage.css";
import "./ItemOverview.css";

import Text from "antd/lib/typography/Text";
import { useParams } from "react-router-dom";
import ItemStockInformation from "./ItemStockInformation";
import AssociateSection from "./AssociateSection";
import { Grid } from "@mui/material";
import ItemImageDisplay from "./itemImageDisplay";
import { formatCustomNumber } from "../helpers/formatCustomNumber";

function ItemOverviewPage(props) {
  const { item_id } = useParams();
  





  return (
    <>
      <section className=" mt-1  border-2   md:  " style={{}}>
        <section className="mx-4">
          {/* BEANS SECTION */}
          <section className="profile_section ">
            <div className="my-3">
              <span className="font-bold">{props.itemDetails.Item_Name} </span> <br></br>
              <span>
                {" "}
                {/* <i>2 items</i>{" "} */}
              </span>
            </div>
            <div className="  ">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <section className="mt-3 ">
                    <div className="flex my-2 justify-between">
                      <div className="text-sm">Item Id</div>
                      <span className="text-xs font-bold  mt-[0.5vh] w-60 ">
                        {props.itemDetails.Item_Id}
                      </span>
                    </div>
                    <div className="flex my-2 justify-between">
                      <div className="text-sm">Type</div>
                      <span className="text-xs font-bold  mt-[0.5vh] w-60 ">
                        {props.itemDetails.Item_Type_Name}
                      </span>
                    </div>
                    <div className="flex my-2 justify-between">
                      <span className="text-sm">Category</span>
                      <span className="text-xs font-bold mt-[0.5vh] w-60 ">
                        {props.itemDetails.Item_Category_Name}
                      </span>
                    </div>
                    <div className="flex my-2 justify-between">
                      <span className="text-sm">Unit</span>
                      <span className="text-xs font-bold w-60   mt-[0.5vh]">
                        {props.itemDetails.Item_Unit_Name}
                      </span>
                    </div>
                    <div className="flex my-2 justify-between">
                      <span className="text-sm">Dimension</span>
                      <span className="text-xs font-bold w-60   mt-[0.5vh] ">
                        {props.itemDetails.Item_Dimension_Unit_Name}
                      </span>
                    </div>
                    <div className="flex my-2 justify-between">
                      <span className="text-sm">SKU</span>
                      <span className="text-xs font-bold w-60   mt-[0.5vh] ">
                        {props.itemDetails.Item_Sku}
                      </span>
                    </div>
                    <div className="flex my-2 justify-between">
                      <span className="text-sm">is Returnable</span>
                      <span className="text-xs font-bold w-60  mt-[0.5vh] ">
                        {props.itemDetails.Item_Returnable_Name}
                      </span>
                    </div>
                    <div className="flex my-2 justify-between">
                      <span className="text-sm">Type</span>
                      <span className="text-xs font-bold w-60  mt-[0.5vh] ">
                        {props.itemDetails.Item_Variety_Name}
                      </span>
                    </div> 
                    <div className="flex my-2 justify-between">
                      <span className="text-sm">Type</span>
                      <span className="text-xs font-bold w-60   mt-[0.5vh] ">
                      {(props.itemDetails?.Product_Sharing_Status_Id === 1) ? " GroPool Item " : "Not GroPool"}
                      </span>
                    </div>
                    <div className="flex my-2 justify-between">
                      <span className="text-sm"> Stock</span>
                      {/* <span className="text-xs font-bold w-30  mt-[0.5vh] "> */}
                      <span className={`text-xs font-bold w-60  mt-[0.5vh] p-2 ${!props.itemDetails?.Item_Stock_Out ? 'bg-green-200' : 'bg-red-200'}`}>
                        {props.itemDetails.Item_Stock_Out ? 'Out of Stock':'In Stock'}
                      </span>
                    </div>
                  </section>

                </Grid>
                <Grid item  xs={8}>
                  <section >
                    <ItemImageDisplay key={props.imageList?.length}  imageList={props.imageList} />
                  </section>
                </Grid>



              </ Grid>

            </div>
           
          </section>
        </section>
      </section>

      {/* PURCHASE SECTION  */}
      <section className=" mt-2 right-1 border  md:  " style={{}}>
        <section className="mx-4">
          {/* PURCHASE SECTION */}

          <section className="flex purchase   justify-between ">
            <section className="w-50 mr-3">
              <span className="text-xs font-bold">Purchase Information</span>

              <div className="flex mt-2 justify-between ">
                <span className="text-xs">Cost Price</span>
                <span className="text-xs font-bold w-30">
                  {" "}
                  <Text>&#8358;</Text> {formatCustomNumber(props.itemDetails.Cost_Rate)}
                </span>
              </div>
              <div className="flex mt-2 justify-between ">
                <span className="text-xs">Market Price</span>
                <span className="text-xs font-bold w-30">
                  {" "}
                  <Text>&#8358;</Text> {formatCustomNumber(props.itemDetails.Market_Rate)}
                </span>
              </div>
              <div className="flex my-3 justify-between ">
                <span className="text-xs">Account </span>
                <span className="text-xs w-30 font-bold">{props.itemDetails.Sales_Accounts_Name}</span>
              </div>
              <div className="flex my-3 justify-between ">
                <span className="text-xs">Description</span>
                <span className="text-xs w-30 font-bold">{props.itemDetails.Purchase_Description} </span>
              </div>
              <div className="flex justify-between ">
                <span className="text-xs">Preferred Vendor</span>
                <span className="text-xs w-30 font-bold">{props.itemDetails.Preferred_Vendor_Name} </span>
              </div>
            </section>
            <section className="w-50 mx-3">
              <span className="text-xs mt-2 font-bold">Sales Information</span>
              <div className="flex mt-2 justify-between ">
                <span className="text-xs"> Grocedy Price </span>
                <span className="text-xs w-30 font-bold">
                  {" "}
                  <Text>&#8358;</Text> {formatCustomNumber(props.itemDetails.Sales_Rate)}
                </span>
              </div>
              <div className="flex my-3 justify-between ">
                <span className="text-xs">Account </span>
                <span className="text-xs w-30 font-bold">{props.itemDetails.Sales_Accounts_Name} </span>
              </div>
              <div className="flex my-3 justify-between ">
                <span className="text-xs">Description</span>
                <span className="text-xs font-bold w-30">{props.itemDetails.Sales_Description} </span>
              </div>
              <div className="flex mb-2 justify-between ">
                <span className="text-xs">Re order level</span>
                <span className="text-xs font-bold w-30">{props.itemDetails.Reorder_Quantity} </span>
              </div>
            </section>
          </section>

          {/* AASOCIATE  */}
          {props?.itemDetails?.Item_Variety_Id == 2 ?
            <AssociateSection Item_Id={item_id}></AssociateSection>
            : null}
        </section>
      </section>

      <section className="  ">
        <ItemStockInformation Item_Id={props.itemDetails.Item_Id}></ItemStockInformation>
      </section>

      {/* SALES ORDER SUMMARY  */}
      <section className=" mt-2 right-1 border-2 chart  md:  " style={{}}>
        {/* STOCK INFORMATION  */}
        <section className="mx-4 mt-4">
          <span className="text-xs font-bold">
            Sales Order Summary (in NGN)
          </span>

          <img src={chart} alt="" />
        </section>
      </section>

      {/* opening stock */}
      <section
        className=" mt-2 right-1 open_stock border chart  md:  "
        style={{}}
      >
        {/* Opening Stock  */}
        <section className="mx-4 mt-4">
          <span> Opening Stock : ₦2,200.000</span>

          <section className="flex purchase open_stock_info justify-between ">
            <section className="w-50 mr-3">
              <h5 className="text-xs my-3 font-bold">Accounting Stock</h5>
              <div className="flex mt-3 justify-between ">
                <span className="text-xs  ">Stock on Hand</span>
                <span className="text-xs text font-bold w-30">
                  {" "}
                  <Text>&#8358;</Text>16.00
                </span>
              </div>
              <div className="flex my-3 justify-between ">
                <span className="text-xs ">Commited Stock </span>
                <span className="text-xs text font-bold w-30">
                  <Text>&#8358;</Text> 16.00
                </span>
              </div>
              <div className="flex my-3 justify-between ">
                <span className="text-xs ">Available for stock</span>
                <span className="text-xs text font-bold w-30">
                  {" "}
                  <Text>&#8358;</Text> 16.00
                </span>
              </div>
            </section>
            <section className="w-50 mx-3">
              <h5 className="text-xs my-3 font-bold">Sales Information</h5>
              <div className="flex mt-2 justify-between ">
                <span className="text-xs font-bold ">Stock on Hand</span>
                <span className="text-xs text w-30">
                  {" "}
                  <Text>&#8358;</Text> 16.00
                </span>
              </div>
              <div className="flex my-3 justify-between ">
                <span className="text-xs ">Commited Stock </span>
                <span className="text-xs text font-bold w-30">
                  <Text>&#8358;</Text>16.00
                </span>
              </div>
              <div className="flex my-3 justify-between ">
                <span className="text-xs ">Available for stock</span>
                <span className="text-xs text font-bold w-30">
                  {" "}
                  <Text>&#8358;</Text> 16.00
                </span>
              </div>
            </section>

            <section></section>
          </section>
          <section className="flex py-10 justify-between">
            <div className="box_image border-1">
              <span className="">To be Shipped</span>
              <span className=" zero">
                0 <span className="qty">Qty</span>
              </span>
            </div>
            <div className="box_image border-1">
              <span className="">To be Received</span>
              <span className="zero">
                0 <span className="qty">Qty</span>
              </span>
            </div>
            <div className="box_image border-1">
              <span className="">To be Invoiced</span>
              <span className=" zero">
                0 <span className="qty">Qty</span>
              </span>
            </div>
            <div className="box_image border-1">
              <span className="">To be Billed</span>
              <span className=" zero">
                0 <span className="qty">Qty</span>
              </span>
            </div>
          </section>
        </section>

        {/* REORDER POINT  */}
        <section className=" right-1  chart  md:  " style={{}}>
          {/* STOCK INFORMATION  */}
          <section className="mx-4 reorder-point stock mb-3">
            <h5 className="mx-3 text-sm">Reorder Point</h5>
            <div className="mx-3 text-order flex justify-between">
              <span className="font-bold">1300</span>
              <span style={{ color: "#476EF8" }}>Edit</span>
            </div>
          </section>
        </section>
      </section>

      <section></section>
    </>
  );
}

export default ItemOverviewPage;
