
import { useEffect, useState } from "react";
import { transactionService } from "../../services/transaction.service";
import underscore from "underscore";
import { useRecoilValue } from "recoil";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { transactionmasterDataAtom } from "../../store/transAtom";
import { IoSearchOutline } from "react-icons/io5";
import { Table, Input } from 'antd';
import { formatCustomNumber } from "../../helpers/formatCustomNumber";
const { Search } = Input;

const columns = [
  {
    title: ' Date',
    key: 'Sales_Order_Date_String',
    dataIndex: 'Sales_Order_Date_String',
    sorter: (a, b) => a.Sales_Order_Date_String.localeCompare(b.Sales_Order_Date_String)
  //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
  },

  {
    title: "Sales Order No",
    dataIndex: "Sales_Order_No",
    sorter: (a, b) => a.Sales_Order_No.localeCompare(b.Sales_Order_No)
  },

  {
    title: " Order Ref",
    dataIndex: "Sales_Order_Ref_No",
    className:"text-primary",
    sorter: (a, b) => a.Sales_Order_Ref_No.localeCompare(b.Sales_Order_Ref_No)
  },
  {
    title: "Amount",
    dataIndex: "Sales_Order_Status_Name",
    sorter: (a, b) => a.Sales_Order_Status_Name.localeCompare(b.Sales_Order_Status_Name)
  },
  {
    title: "Subscription Ref No",
    dataIndex: "Subscription_Ref_No",
    sorter: (a, b) => a.Subscription_Ref_No.localeCompare(b.Subscription_Ref_No)
  },
  {
    title: "Sales Order Amount",
    dataIndex: "Sales_Order_Amount",
    sorter: (a, b) => a.Sales_Order_Amount.localeCompare(b.Sales_Order_Amount)
  },

];


function SalesOrderContent(props) {
  const [temp, setTemp] = useState([]);
  const [salesOrderList, setSalesOrderList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');

  const masterData = useRecoilValue(transactionmasterDataAtom);
  var masterDataGroup = underscore.groupBy(masterData, "Master_Type");
  const statusList = masterDataGroup[1];
  var masterDataGroup = underscore.groupBy(masterData, "Master_Type");
  const invoiceStatus = masterDataGroup[3];
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  useEffect(() => {
    transactionService.getSalesOrder(props.customer_id).then((res) => {
      console.log("Cust sales ord er", res.data);
      let tempList = [];
      if (res?.data !== undefined) {
        tempList = res?.data?.map((salesOrder, index) => {
          return {
            ...salesOrder,
            id: index,
            Sales_Order_Amount: formatCustomNumber(salesOrder?.Sales_Order_Amount),
          };
        });
      }
      setSalesOrderList(tempList);
      setFilteredData(tempList);
    });
  }, [props.customer_id]);


  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = salesOrderList.filter((record) =>
      Object.values(record).some((text) =>
        String(text).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };
  

  function selectHandler(value) {
    if (value === "All") {
      return setTemp(salesOrderList);
    } else {
      var tempList = salesOrderList?.filter(
        (item) => item.Sales_Order_Status_Name === value
      );
      setTemp(tempList);
    }
  }



  const paginationOptions = {
    pageSize: 10,
    showSizeChanger: true,
    total: filteredData.length,
    // onChange: (page, pageSize) => {
    //   console.log(page, pageSize);
    //   const newList = tableDataList.slice(0, pageSize);
    //   console.log('check ', newList);
    //   setTableList(newList);
    // },
  };




  const handleRowClick = (record) => {
    navigate(`/${currModuleId}/${currModuleName}/sales-order/details/${record?.Sales_Order_Ref_No}`);

  };

  return (
    <>
      <div className="ml-1 @containe">
        <div className="mt-1 text-gray-600 text-xl font-bold">Sales Order</div>
        <div className="mt-2 space-y-3 @lg:flex @lg:flex-row @lg:items-center @lg:justify-between @lg:space-y-0 @lg:space-x-2">
          <div className="relative w-full max-w-[250px]">
          
          <Search
        placeholder="Search..."
        onSearch={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        value={searchText}
        style={{ marginBottom: 10,width: 250 }}
      />
            <div className="absolute top-2.5 left-1.5 pl-2">
              <IoSearchOutline className="h-[20px] w-[20px] text-gray-600" />
            </div>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <div className="flex flex-row items-center space-x-2">
              <div className="text-sm text-gray-600">Status:</div>
              <select
                onChange={(ev) => selectHandler(ev.target.value)}
                className="rounded-md w-[130px]"
              >
                <optgroup className="bg-white rounded-md">
                  {" "}
                  <option>select</option>
                  {statusList?.reverse().map((status, i) => {
                    return (
                      <option key={i} value={status.Name}>
                        {status.Name}{" "}
                      </option>
                    );
                  })}
                </optgroup>
              </select>
              <button className="text-sm font-medium text-[#476EF8] hover:underline hover:underline-offset-2">
                Add New
              </button>
            </div>
          </div>
        </div>

        {/* TABLE */}
  

        <Table key={filteredData?.length} columns={columns} dataSource={filteredData}
                      pagination={paginationOptions}
                      rowClassName="show-cursor"
                      style={{ background: '#f0f2f5', borderRadius: '8px' }}
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            handleRowClick(record);
                          },
                        };
                      }}
                        />
      </div>
    </>
  );
}

export default SalesOrderContent;
