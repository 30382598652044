import { Grid } from "@mui/material";
import React from "react";

import SalesOrderDetailsTable from "./SalesOrderDetailsTable";
import CurrencyFormat from "react-currency-format";
import { useNavigate } from "react-router-dom";
import { formatCustomDate } from "../../helpers/formatCustomDate";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";

function SalesOrderOverview(props) {
  const { salesData } = props;
  const navigate = useNavigate();

  //  calculate  sub total
  const calculateSubTotal = (list) => {
    let result = 0;

    list?.forEach((element) => {
      result += element.Total_Amount;
    });
    const finalVal =  formatCustomNumber(result);
    return finalVal;
  };

  //  calculate base quantity with discount, adjustment and shipping
  const calculateBaseTotal = (list) => {
//     (+) Shipping Fee : 
// (-) Discount : 
// (+/-) Adjustment :
    let result = 0;
    list?.forEach((element) => {
      result += element.Item_Sales_Quantity * element.Item_Sales_Rate;
    });

    const discountValue = salesData?.Discount_Amount;
    const discountType = salesData?.Discount_Type_Id;
    // const ShippingAmount = salesData?.Shipping_Amount;
    // const adjustmentAmount = salesData?.Adjustment_Amount;

    // if (ShippingAmount) {
    //   result += ShippingAmount;
    // }
    // if (adjustmentAmount) {
    //   result += adjustmentAmount;
    // }

    if (discountValue && (discountType === 1 || discountType === 0)) {
      result = result - discountValue;
    }

    if (discountValue && discountType === 2) {
      let discount = (discountValue / 100) * result;
      result = Number(result) - Number(discount);
    }
    const finalResult =  formatCustomNumber(result);   
    return finalResult;
  };

  //  dynamically manage different status
  const manageSalesStatus = (status) => {
    let defaultClass = "status_color1 text-white";

    switch (status) {
      case "Draft":
        defaultClass = "text-light status_color2";
        break;
      case "Approved":
        defaultClass = "text-light status_color3";
        break;
      case "Pending for Approval":
        defaultClass = "text-dark status_color4";
        break;
      case "Fulfilled":
        defaultClass = "text-light status_color5";
        break;
      case "Rejected":
        defaultClass = "text-light status_color6";
        break;
      case "Confirmed":
        defaultClass = "text-light status_color1";
        break;
      case "Partially Billed":
        defaultClass = "text-light status_color8";
        break;

      default:
        break;
    }

    return (
      <span className={`px-4 py-2.5 rounded font-semibold ${defaultClass}`}>
        {status}
      </span>
    );
  };

  // view subscription by going to subscription module
  function viewSubscription(subId) {
    navigate(`/${2}/${"Subscription"}/${"subscriptions"}/details/${subId}`);
  }

  return (
    <section className="mt-2 w-full text-gray-700">

     


      <div className="border-[1px] border-gray-200 rounded-lg p-3 lg:p-4 bg-white ">
         {/* new view */}
      <div class="grid grid-cols-2 gap-4">
        {/* Left */}
        <div> 
          <p className=" font-bold text-gray-700"> SALES ORDER NUMBER: <span className=" font-bold text-[#AAA96A] italic">{salesData?.Sales_Order_No}</span> </p> 
          <p> Approvals: <span className=" font-bold text-[#AAA96A] italic">View Approval List</span> </p>
          <p> Customer Name: <span className="font-bold" >{salesData?.Customer_Full_Name} </span></p>
          <p> Customer Email: <span className="font-bold">{salesData?.Customer_Email_Address} </span></p>
          <p> Customer Mobile: <span className="font-bold">{salesData?.Customer_Mobile_No} </span></p>
          <p>  Status: <span className="font-bold">{salesData?.Gift_Status_Name} </span></p>
          {(salesData?.Gift_Status_Id === 1)  &&  
          <>
           <p className=" font-bold text-gray-700"> GIFT PERSON INFO </p> 
          <p> Recipient Name: <span className="font-bold">{salesData?.Recipient_Full_Name} </span></p>
          <p> Recipient Email: <span className="font-bold">{salesData?.Recipient_Email_Address} </span></p>
          <p> Recipient Mobile: <span className="font-bold">{salesData?.Recipient_Phone_Number} </span></p>
          </>
          
          }
      
        </div>

        {/* Right */}
        <div>
        <div className="py-2.5 rounded px-4 text-lg text-right mb-3">
            {manageSalesStatus(salesData?.Sales_Order_Status_Name)}
          </div>
        <p className="text-gray-600 text-base font-bold"> Billing Address  </p>
                <span className="subheading">
                  {salesData?.Billing_Address1},
                </span>
                <span className="subheading">
                  {salesData?.Billing_Area_Name},
                </span>
                <span className="subheading">
                  {" "}
                  {salesData?.Billing_City_Name},
                </span>
                <span className="subheading">
                  {" "}
                  {salesData?.Billing_City_Name},
                </span>
                <span className="subheading">
                  {" "}
                  {salesData?.Billing_Country_Name}
                </span>

                {(salesData?.Gift_Status_Id === 2)  &&  
                <>
                  <p className="mt-3 text-gray-600 text-base font-bold" >Shipping Address</p>
                <span className="subheading">
                  {salesData?.Shipping_Address1},
                </span>
                <span className="subheading">
                  {salesData?.Shipping_City_Name},
                </span>
                <span className="subheading">
                  {" "}
                  {salesData?.Shipping_Area_Name},
                </span>
                <span className="subheading">
                  {" "}
                  {salesData?.Shipping_Country_Name}
                </span>
                
                </>
                }
              

                {(salesData?.Gift_Status_Id === 1)  &&  
                <>
                 <p className="mt-3 text-gray-600 text-base font-bold" >GIFT PERSON ADDRESS</p>
                <span className="subheading">
                  {salesData?.Recipient_Full_Address_Shipping},
                </span>
                <span className="subheading">
                  {salesData?.Recipient_Shipping_Area_Name},
                </span>
                <span className="subheading">
                  {" "}
                  {salesData?.Recipient_Shipping_City_Name},
                </span>
                <span className="subheading">
                  {" "}
                  {salesData?.Recipient_Shipping_Country_Name}
                </span>
                
                </>
                }
               

        </div>

    </div>
      {/* new view end  */}

      
      </div>

      <div className="mt-4 border-[1px] border-gray-200 rounded-lg p-3 lg:p-4 bg-white ">
        <p className="font-bold text-base text-gray-600">SALES ORDER</p>

        <div class="grid grid-cols-2 gap-4">
          {/* Left */}
            <div>
              <p>Reference Number: <span className="font-bold" >{salesData?.Sales_Order_No} </span> </p>
              <p>Order Date: <span className="font-bold" >{salesData?.Sales_Order_Date_String} </span> </p>
              <p>Payment Terms: <span className="font-bold" >{salesData?.Payment_Term_Name} </span> </p>
              <p>Subscription ID: <span className="font-bold"  onClick={() => viewSubscription(salesData?.Subscription_Ref_No)} >{salesData?.Subscription_Ref_No} </span> </p>
              <p>Subscription Invoice Number: <span className="font-bold" > </span> </p>
              <p>Order Remark: <span className="font-bold" >{salesData?.Order_Remark} </span> </p>
              <p>Customer Remark: <span className="font-bold" >{salesData?.Customer_Notes} </span> </p>
              <p>Created Date: <span className="font-bold" >{salesData?.Created_Date_String} </span> </p>
              <p>Created By: <span className="font-bold" >{salesData?.Package_Encoded_By} </span> </p>
            </div>

            {/* Right */}
            <div>
              <p className="p-2 font-bold" >Package & Shipment Information</p>
              <p>Package Number: <b className="font-bold" >{salesData?.Package_Slip_Number} </b> </p>
              <p>Package Date: <b className="font-bold" > {formatCustomDate(salesData?.Package_Date) || ''} </b> </p>
              <p>Shipment Date: <b className="font-bold" > {formatCustomDate(salesData?.Shipment_Date) || ''} </b> </p>
              <p>Shipment delivery Date: <b className="font-bold" > {formatCustomDate(salesData?.Shipment_Delivered_Date) || ''} </b> </p>
              <p>Pickup Date: <span className="font-bold" >{formatCustomDate(salesData?.Pickup_Date) || ''} </span> </p>

            </div>
      </div>


      
      </div>

      <div className="mt-4 border-[1px] border-gray-200 rounded-lg py-3 lg:py-4 bg-white">
        <h1 className="text-center text-gray-700 text-xl font-bold">
          Sales Details List{" "}
        </h1>
        <div className=" overflow-y-scroll h-[250px] scrollbar-thumb-gray-500 scrollbar-thin scrollbar-track-gray-100 pb-[10px]">
          <SalesOrderDetailsTable
            salesData={salesData}
            tableData={salesData?.Sales_Order_details}
          ></SalesOrderDetailsTable>
        </div>
        <div className=" px-3 lg:px-4">
          <div className="mt-3  px-3 lg:px-4 border-[1px] border-gray-200 rounded-lg p-3 flex items-center justify-end">
            <div className="">
            <div className="flex flex-row">
                <div className="text-gray-600 font-bold mr-2">Sub Total:</div>{" "}
                <div className="w-[170px] flex-shrink-0">
                  <div className="text-gray-600 font-bold">
                    <CurrencyFormat
                      value={
                        calculateSubTotal(salesData?.Sales_Order_details)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="flex flex-row">
                <div className="text-sm text-gray-600 font-bold mr-2">
                  Shipping Fee
                </div>
                <div className="w-[170px] flex-shrink-0">
                  <div className="text-sm text-gray-600">
                    <CurrencyFormat
                      value={formatCustomNumber(salesData?.Shipping_Amount)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </div>
                </div>
              </div> */}
              <div className="flex flex-row">
                <div className="text-sm font-bold mr-2">Discount:</div>{" "}
                <div className="w-[170px] flex-shrink-0">
                  <div className="text-sm">
                    {salesData?.Discount_Type_Id === 1 && (
                      <CurrencyFormat
                        value={formatCustomNumber(salesData?.Discount_Amount)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    )}

                    {salesData?.Discount_Type_Id === 2 && (
                      <CurrencyFormat
                        value={formatCustomNumber(salesData?.Discount_Amount)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"%"}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="flex flex-row">
                <div className="text-sm font-bold mr-2">Adjustment:</div>
                <div className="w-[170px] flex-shrink-0">
                  <div className="text-sm">
                    <CurrencyFormat
                      value={formatCustomNumber(salesData?.Adjustment_Amount)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </div>
                </div>
              </div> */}
            
              <div className="flex flex-row ">
                <div className="text-2xl font-bold mr-2">Total:</div>{" "}
                <div className="w-[170px] flex-shrink-0">
                  <div className="text-2xl  font-bold">
                    <CurrencyFormat
                      value={ calculateBaseTotal(salesData?.Sales_Order_details) }
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                    />
                  </div>
                </div>
              </div>
              {/* Sales_Order_Attachement_URL */}
    
            </div>
          </div>
        </div>


     
        {/* <PurchaseReceiveTable tableData={purchaseData?.Purchase_Receive_details} /> */}
      </div>

      {/*file  */}
      <div className="text-center" >
        {(salesData?.Sales_Order_Attachement_URL?.length > 3) && 
     <a key={salesData?.Sales_Order_Attachement_URL} href={`${salesData?.Sales_Order_Attachement_URL}`} className='btn btn-primary'  target="_blank" rel="noopener noreferrer">
     Open File
   </a>}

        </div>
    </section>
  );
}

export default SalesOrderOverview;
