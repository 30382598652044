import { useEffect, useRef, useState } from "react";
import {GetPurchaseOrderMasterData} from '../../store/purchaseOrderAtom';
import { Grid } from "@mui/material";
import CurrencyFormat from "react-currency-format";
import { useParams } from "react-router-dom";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";




function PurchaseScroll(props) {
  const purchaseMasterData = GetPurchaseOrderMasterData();
  const mountRef = useRef(true)
  const [tableData, setTableData] = useState([]);
  const [checkedAll, setCheckAll] = useState(false);
  const { purchase_id } = useParams();



  useEffect(() => {
    // What is the purpose of mount ref?
    mountRef.current = true;
    if(purchaseMasterData['Purchase_Order_Info']?.length > 0){
      setTableData( purchaseMasterData['Purchase_Order_Info'].map((ele) => {return { ...ele, checked: false } }));
    }
    return () => {
      mountRef.current = false;
    }
  }, [purchaseMasterData]);


  const onActionFilterPerform = (action)=> {
    const option = action.target.value;
    if(Number(option) === 0){
        return setTableData(purchaseMasterData['Purchase_Order_Info']);
    }
    var newList = purchaseMasterData['Purchase_Order_Info'].filter((item)=> item.Purchase_Order_Status_Id === Number(option) );
    setTableData(newList);
  }


  const onCheckAllElement = async (selection)=> {

    setCheckAll(prev => { return (!prev) });

    let newList = tableData?.map((item, i)=> {
      return {...item,checked: !selection }
    });

    await setTableData((prev) => {
      return [...newList];
    });
    props?.onSelection(newList);
  }



  const onCheckboxClick = async (e, index) => {
    let element = tableData[index];
    // why are you targetig the index
    tableData[index] = { ...element, checked: e.target.checked };
    // why is the before the setTable data
    await setTableData((prev) => {
      return [...tableData];
    });
// exlian this line 
    props?.onSelection(tableData);
  };


  const managePurchaseStatus = (status) => {
    let defaultClass = "text-primary";

    switch (status) {
      case "Draft":
        defaultClass = "text-danger";
        break;
      case "Approved":
        defaultClass = "text-primary";
        break;
      case "Pending Approval":
        defaultClass = "text-warning";
        break;
      case "Billed":
        defaultClass = "text-info";
        break;
      case "Issued":
        defaultClass = "text-success";
        break;
      case "Partially Billed":
        defaultClass = "text-dark";
        break;

      default:
        break;
    }

    return (
      <span className={`text-sm font-bold md:text-sm ${defaultClass} `}>
        {status}
      </span>
    );
  };



  return (
    <>
     
     <section className=" w-full  z-0 mt-3 main	 border-2 b">
  
        <ActionPaneComponent bulkActionData={purchaseMasterData['Filtering_Criteria']}
         onSelectBulkAction={onActionFilterPerform}/>

         <div>
         <label className=" mt-3 mb-3 ml-2 ">
                    <input onChange={(e)=> onCheckAllElement(checkedAll)} 
                     type="checkbox" className="w-[2vh] h-[2vh]" checked={checkedAll ? checkedAll : '' }   />

                     <span className="ml-2 font-bold " >Select all</span>
                  </label>
         </div>
               

 {/* What are you trying to achieve here  */}
          {tableData?.map((purchase, i)=> {
            return (
              <div key={i}
              className={"flex  hover:bg-filterColor border  " 

              +(Number(purchase_id) === purchase?.Purchase_Order_Ref_No ?"app_bg_color1 mb-2 cursor" : "mb-2 cursor") 
            
            
            
            }
               >
              <Grid
               container spacing={2} >
                <Grid item xs={1} className=" "  >
                  <label className="ml-2 ">
                    <input   onChange={(e)=> onCheckboxClick(e, i)}  checked={purchase?.checked ? purchase?.checked : '' } 
                     type="checkbox" className="w-[2vh] h-[2vh]" />
                  </label>
                </Grid>
                <Grid item xs={6} className=" ml-2 "    >
                  <label onClick={() => props.viewDetails(purchase?.Purchase_Order_Ref_No )}  className="" >
                    <span className="text-sm font-bold md:text-sm">
                    {purchase?.Vendor_Name}
                    </span> <br></br>

                    <span className="text-sm font-bold md:text-sm">
                     <i> {purchase?.Purchase_Order_Number} </i>
                    </span>

                    <br></br>
                    <span className="text-xs md:text-sm font-bold"> 
                    <CurrencyFormat value={parseFloat(purchase.Purchase_Order_Amount)?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                    </span>
                  </label>
                </Grid>
                <Grid clas item xs={4}  onClick={() => props.viewDetails(purchase?.Purchase_Order_Ref_No )} >
           
                <section className="flex mr-3 mt-1 justify-end">
                <div className="flex justify-start w-full"><label className=" subheading" >
                    { managePurchaseStatus(purchase?.Purchase_Order_Status_Name)}   
                    </label></div>
           
                </section>
                </Grid>
              </Grid>
            </div>
            )
          })}
          
          
  
      </section>
    </>
  );
}

export default PurchaseScroll;
