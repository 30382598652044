
import { DataGrid ,  gridPageCountSelector,
    gridPageSelector, GridToolbarContainer, GridToolbarExport ,
    useGridApiContext,GridToolbar,
    useGridSelector} from "@mui/x-data-grid";
    import Pagination from '@mui/material/Pagination';
  import PaginationItem from '@mui/material/PaginationItem';
//   import { GetRecoilCorporateList, SetRecoilCorporateList } from "../store/corporateAtom";
  import { useEffect, useState } from "react";
  import moment from "moment/moment";
  import {  useLocation, useNavigate } from "react-router-dom";
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  
  
  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  
    return (
        <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            // @ts-expect-error
            rowsPerPageOptions={[6]}
            checkboxSelection={true}
              components={{
                Toolbar: CustomToolbar, Pagination: CustomPagination}}
            renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
           
        />
    );
  }


  const tableHead = [
    { field: 'Transaction_Type_Name', headerName:'Name' , width: 450 , headerClassName: "tableHeader "},
    { field: 'Total_Rating', headerName:'Total' , width: 220 , headerClassName: "tableHeader "},
    { field: 'Rating_Value', headerName:'Rating' , width: 220 , headerClassName: "tableHeader "}
  
    ]
  
  
  
  function RatingMainTable(props) {

  const [tableList, setTableList] = useState([]);
          const navigate = useNavigate();
          const { pathname } = useLocation();  
          var url = pathname.split('/')
          const currModuleId = url[1];
          const currModuleName = url[2];
          const currUserMenu = url[3];
  
 
  
    useEffect(()=> {
      let isMount = true;
    //   if(corporateData.Display_Column?.length){
    //     let newTableHead = corporateData.Display_Column?.filter((item) => item.Display_Id === 1).map((item) => {
    //       return {
    //         ...item,field: item.Column_Value.trim(),headerName: item.Column_Name, width: 150,
    //         Due_Date_String: moment(item.Due_Date_String).format('DD/MM/YYYY') };
    //     }).sort((a, b) => {
    //       return a.Print_Sequence - b.Print_Sequence;
    //     });
    //    if(isMount)  {
    //     setTableHeadHead(newTableHead)
    //    };
    
    //   }
  
      // set table list
      var list = props?.tableList?.map((item, i) => {
        return { ...item, id: i } });
           setTableList(list)
      
    
      return ()=> {
        isMount = false;
      }
  
    },[props.tableList]);
  
  
  
    function OnItemCellClick(event){
      const id = event.row['Transaction_type_Id'];
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`)
    }
    
    
    function onSelectChange(selectedList) {
      // var selection = selectedList?.map((index) => {
      //   return props.tableData[index];
      // });
      // props.selectedTableItems(selection);
    }
  
  return (
  
    <>
  <div className="mt-5">
  
  <div  style={{ height: 450, width: '100%' }}>
  <DataGrid
    columns={tableHead}
    rows={tableList}
    components={{
      Pagination: CustomPagination,
    }}
    onRowSelectionModelChange={onSelectChange}
      onRowClick={(event)=> {  OnItemCellClick(event) }}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      sx={{
         borderColor: 'primary.light',
        '& .MuiDataGrid-cell:hover': {   color: 'primary.main', cursor: 'pointer' }  }} 
            
  />
  </div>
  </div>
    
    </>
  );
  }
  
  export default RatingMainTable;