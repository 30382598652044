import React, { useEffect } from 'react'
import Switch from '@mui/material/Switch';




function CustomerApproveToggle(props) {
    // const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
    const [checked, setChecked] = React.useState(false);

    useEffect(()=>{
      if(props?.val == 1){
        setChecked(true)
      }else{
        setChecked(false)
      }
    },[props?.val]);

    const handleChange = (event) => {
      setChecked(event.target.checked);
      props.toggleItemAdminRole(event.target.checked);
    };
  

  return (
     <>
      <span className="font-bold" > ADMIN ROLE :  { props?.val === 1 ? 'True' : 'False'} </span>
     <Switch 
     checked={checked}
     onChange={handleChange}
      />
     </>
   
  )
}

export default CustomerApproveToggle;