/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation, useNavigate } from "react-router-dom";
// import CustomerTable from "./CustomerTable";


import { useCallback, useEffect, useState } from "react";

import RestartAlt from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { voucherService } from "../../services/voucherService";
import { SetGeneralVoucherListAtom, GetGeneralVoucherListAtom} from "../../store/voucherAtom";
import Tooltip from "@mui/material/Tooltip";
import { Table, Input } from 'antd';

const { Search } = Input;




function SalesReturnMainPage() {
  const { pathname } = useLocation();
  const getGeneralVoucherList = GetGeneralVoucherListAtom();
  const setGeneralVoucherList = SetGeneralVoucherListAtom();
  const navigate = useNavigate();
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');


  useEffect(() => {
    document.title = 'Sales return page';
    // if (getGeneralVoucherList.length < 1) {
    //     loadVoucherData();
    // }else{
    //     setFilteredData(getGeneralVoucherList);

    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const reloadData = () => {
    loadVoucherData();
  }


  const loadVoucherData = () => {
    voucherService.getVoucherList().then(res => {
      if (res.data?.length) {
        setGeneralVoucherList(res.data);
        setFilteredData(res.data);
      }
    });
  }

  function addNew() {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)
  }

  const columns = [
    {
      title: 'Ref',
      key: 'Ref_No',
      dataIndex: 'Ref_No',
      className:"text-primary",
      sorter: (a, b) => a.Ref_No.localeCompare(b.Ref_No)
    //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
    {
      title: 'customer Name',
      dataIndex: 'Customer_Full_Name',
      sorter: (a, b) => a.Customer_Full_Name.localeCompare(b.Customer_Full_Name)
    //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
    {
      title: 'customer No',
      dataIndex: 'Customer_Mobile_No',
      sorter: (a, b) => a.Customer_Mobile_No.localeCompare(b.Customer_Mobile_No)
    //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
 
    {
      title: "Status",
      dataIndex: "Voucher_Status_Name",
      sorter: (a, b) => a.Voucher_Status_Name.localeCompare(b.Voucher_Status_Name)
    },
  
    {
      title: "Voucher No By",
      dataIndex: "Voucher_Number",
      sorter: (a, b) => a.Voucher_Number.localeCompare(b.Voucher_Number)
    },
    {
      title: "Voucher Amount",
      dataIndex: "Voucher_Amount",
      sorter: (a, b) => a.Voucher_Amount.localeCompare(b.Voucher_Amount)
    },
    {
      title: "Type",
      dataIndex: "Voucher_Type_Name",
      sorter: (a, b) => a.Voucher_Type_Name.localeCompare(b.Voucher_Type_Name)
    },
    {
      title: "Recipient",
      dataIndex: "Reciepient_Name",
      sorter: (a, b) => a.Reciepient_Name.localeCompare(b.Reciepient_Name)
    },
    {
      title: "Recipient No",
      dataIndex: "Reciepient_Mobile_Number",
      sorter: (a, b) => a.Reciepient_Mobile_Number.localeCompare(b.Reciepient_Mobile_Number)
    },
  ];


  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = getGeneralVoucherList.filter((record) =>
      Object.values(record).some((text) =>
        String(text).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };


  const paginationOptions = {
    pageSize: 10,
    showSizeChanger: true,
    total: filteredData.length,
    // onChange: (page, pageSize) => {
    //   const newList = tableDataList.slice(0, pageSize);
    //   setTableList(newList);
    // },
  };



  const handleRowClick = (record) => {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${record?.Ref_No}`);
  };




  return (

    <>
      <Grid container >
        <Grid item xs={12}>
          {/* grid start */}
          <div className="row   mt-5 mb-2" >
            <div className="col" >
              <Tooltip title="Reload">
                <RestartAlt
                  className="cursor ml-2 mt-2 mr-2"
                  onClick={() => reloadData()}
                  color="primary"
                  fontSize="large"
                />
              </Tooltip>
            </div>



            <div className="col offset-3" >

              <div className="flex  justify-end">
                {/* <button
                  style={{ backgroundColor: "#476EF8" }}
                  onClick={() => addNew()}
                  className=" mr-3  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                >
                  EXPORT
                </button> */}
                <button
                  style={{ backgroundColor: "#476EF8" }}
                  onClick={() => addNew()}
                  className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                >
                  ADD NEW
                </button>

              </div>
            </div>

          </div>
          {/* End of Grid */}


        </Grid>
      </Grid>


      <section className="mt-5   w-full">
      <div className="">
        <Search
        placeholder="Search..."
        onSearch={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        value={searchText}
        style={{ marginBottom: 10, width: 250 }}
      />

      <p className="text-danger" > Replace page content when Mr Rahman is back </p>

        <Table key={filteredData?.length} columns={columns} dataSource={filteredData}
                      pagination={paginationOptions}
                      rowClassName="show-cursor"
                      style={{ background: '#f0f2f5', borderRadius: '8px' }}
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            handleRowClick(record);
                          },
                        };
                      }}
                        />

        </div>
      </section>
    </>

  );
}

export default SalesReturnMainPage;
