import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import underscore from "underscore";
import { transactionService } from "../../services/transaction.service";
import { useRecoilValue } from "recoil";
import { transactionmasterDataAtom } from "../../store/transAtom";

import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { twMerge } from "tailwind-merge";

const tableHead = [
  { field: "Credit_Date", headerName: "Date", width: 130 },
  { field: "Credit_Ref_No", headerName: "Reference", width: 130 },
  { field: "Credit_Status", headerName: "Status", width: 130 },
  { field: "Amount", headerName: "Amount", width: 130 },
  { field: "Balance", headerName: "Balance", width: 130 },
  { field: "Status", headerName: "Status", width: 130 },
];

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}


export default function CreditNoteTab(props) {
  const [creditList, setCreditList] = useState([]);
  const [temp, setTemp] = useState([]);
  const masterData = useRecoilValue(transactionmasterDataAtom);
  var masterDataGroup = underscore.groupBy(masterData, "Master_Type");
  const statusList = masterDataGroup[1];
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const [searchList, setSearchList] = useState([]);
  const currUserMenu = url[3];

  useEffect(() => {
    transactionService.getCreditNote(props.customer_id).then(res => {
      if(res?.data?.length){
        setCreditList(res?.data);
        setTemp(res?.data);
        setSearchList(res?.data);

      }else{
        setCreditList([]);
        setTemp([]);
        setSearchList([]);
      }
     
    }).catch((err)=> {
    })
  }, [props.customer_id]);

  function search(search) {
    if (search.length < 3) {
      setTemp(creditList);
    } else {
      var tempResult = creditList.filter((item) => {
        return (
          item?.Expense_Date?.toLowerCase().indexOf(search.toLowerCase()) >=
            0 ||
          item?.Expense_Category?.toLowerCase().indexOf(search.toLowerCase()) >=
            0 ||
          item?.Invoice_Numbner?.toLowerCase().indexOf(search.toLowerCase()) >=
            0 ||
          item?.Amount?.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
          item?.Expense_Status.toString()
            ?.toLowerCase()
            .indexOf(search.toLowerCase()) >= 0
        );
      });

      setTemp(tempResult);
    }
  }

  function selectHandler(value) {
    if (value === "All") {
      return setTemp(creditList);
    } else {
      var tempList = temp?.filter((item) => item.Invoice_Status === value);
      setTemp(tempList);
    }
  }


  function OnItemCellClick(event) {
    const id = event.row["Invoice_Ref_No"];
    navigate(`/${currModuleId}/${currModuleName}/invoices/details/${id}`);
  }


  return (
    <>
      <div className="ml-1 @container">
        <div className="mt-1 text-gray-600 text-xl font-bold">Credit Note</div>
        <div className="mt-2 space-y-3 @lg:flex @lg:flex-row @lg:items-center @lg:justify-between @lg:space-y-0 @lg:space-x-2">
          <div className="relative w-full max-w-[250px]">
            <input
              onChange={(ev) => search(ev.target.value)}
              placeholder="Search Estimate"
              className={twMerge(
                "w-full pl-11 h-[40px] bg-white text-gray-700 placeholder:text-gray-400 placeholder:text-sm outline-none focus:outline-none border-[1px] border-gray-200 rounded-md ring-0 focus:ring-1 focus:ring-blue-500"
              )}
            />
            <div className="absolute top-2.5 left-1.5 pl-2">
              <IoSearchOutline className="h-[20px] w-[20px] text-gray-600" />
            </div>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <div className="flex flex-row items-center space-x-2">
              <div className="text-sm text-gray-600">Status:</div>
              <select
                onChange={(ev) => selectHandler(ev.target.value)}
                className="rounded-md w-[130px]"
              >
                <optgroup className="bg-white rounded-md">
                  {" "}
                  {statusList?.reverse().map((status, i) => {
                    return (
                      <option key={i} value={status.Name}>
                        {status.Name}{" "}
                      </option>
                    );
                  })}
                </optgroup>
              </select>
              <button className="text-sm font-medium text-[#476EF8] hover:underline hover:underline-offset-2">
                Add New
              </button>
            </div>
          </div>
        </div>

        <DataGrid
          className="mt-4 overflow-x-auto bg-white outline-none border-[1px] border-gray-200 rounded-xl"
          style={{ height: 600, width: "100%" }}
          columns={tableHead}
          rows={searchList?.map((item, id)=> { return {...item, id:id} } )}
          pageSize={10}
          rowsPerPageOptions={[6]}
          onRowClick={(event) => {
            OnItemCellClick(event);
          }}
          components={{
            Pagination: CustomPagination,
          }}
          onCellClick={(event) => {
            event.defaultMuiPrevented = true;
          }}
    
        />
      </div>
    </>
  );
}
