import React, { useEffect, useState, useContext } from "react";
import "./AddOn.css";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import AddonAddFeatures from "../../components/AddonAddFeatures";
import { useParams } from "react-router-dom";
import underscore from "underscore";
import LogicContext from "../../Context/LogicContext";
import { productService } from "../../services/product.service";
import { SetAddonMasterData, GetAddonMasterData } from "../../store/productAtom";
import { useForm } from "react-hook-form";
import AddOnUnitSelect from "../AddonUnitSelect";
import AddonCurrSelect from "../item-page/AddItemFormProps/AddonCurrSelect";
import AddOnIntervalSelect from "../AddonIntervalSelect";
import AddonAccountSelect from "../AddonAccountSelect";
import AddonTaxSelect from "../AddonTaxSelect";
import AddonDepartmentSelect from "../AddonDepartmentSelect";
import AddonDeliverySelect from "../AddonDeliverySelect";
import AddonVehicleSelect from "../AddonVehicleSelect";
import AddonBranchSelect from "../AddonBranchSelect";
import AddonPlatformSelect from "../AddonPlatformSelect";
import AddonSubscribersSelect from "../AddonSubscribersSelect";
import AddonLocationSelect from "../AddonLocationSelect";
import AddonProductSelect from "../AddonProductLine";
import AddonAssociates from "../../components/AddonAssociates";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { useNavigate } from "react-router-dom";
import { GetAddonEdit, ResetAddonEdit } from "../../store/productAtom";
import { SetAssoTable, GetAssoTable, ResetAssoTable, SetAssoPlanTable,ResetAssoPlanTable} from "../../store/productAtom";
import AddonTypeSelect from "../AddonTypeSelect";
import AddonWidget from "../Addonwidget";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function AddAddOnPage() {
  const setAddonData = SetAddonMasterData();
  const logicContext = useContext(LogicContext);
  const getAddonData = GetAddonMasterData();
  const setAssoPlanTable = SetAssoPlanTable();
  const getAddonToEdit = GetAddonEdit();
  const resetAssoTable = ResetAssoTable();
  const setTableData = SetAssoTable();
  const tableData = GetAssoTable();
  const resetAssoTableData =  ResetAssoPlanTable();

  const resetAddonEdit = ResetAddonEdit();
  const [selectedFeatureList, setSelectedFeatureList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);

  const { register, setValue, getValues, handleSubmit, reset, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const { product_id } = useParams();
  const { product_id_edit } = useParams();
  const { addon_id_edit } = useParams();


  useEffect(() => {
    let isMounted = true;


   if (isMounted){
    setValue("Product_Id", product_id);
    setValue("Addon_Type_Id", 1);
   
    if (getAddonToEdit?.Product_Id) {
      setValue("Addon_Id", getAddonToEdit?.Addon_Id);
      setValue("Product_Id", getAddonToEdit?.Product_Id);
      setValue("Addon_Type_Id", getAddonToEdit?.Addon_Type_Id);
      setValue("Addon_Name", getAddonToEdit?.Addon_Name);
      setValue("Addon_Price", getAddonToEdit?.Addon_Price);
      setValue("Addon_Description", getAddonToEdit?.Addon_Description);
      setValue("Unit_Id", getAddonToEdit?.Unit_Id);
      setValue("Addon_Price_Schema_Id", getAddonToEdit?.Addon_Price_Schema_Id);
      setValue("Addon_Interval_Id", getAddonToEdit?.Addon_Interval_Id);
      setValue("Addon_Account_Id", getAddonToEdit?.Addon_Account_Id);
      setValue("Addon_Tax_Id", getAddonToEdit?.Addon_Tax_Id);
      setValue("Department_Id", getAddonToEdit?.Department_Id);
      setValue("Vehicle_Id", getAddonToEdit?.Vehicle_Id);
      setValue("Branch_Id", getAddonToEdit?.Branch_Id);
      setValue("Platform_Id", getAddonToEdit?.Platform_Id);
      setValue("Subscriber_Id", getAddonToEdit?.Subscriber_Id);
      setValue("unit_id", getAddonToEdit?.unit_id);
      setValue("Addon_Price_Schema_Id", getAddonToEdit?.Addon_Price_Schema_Id);
      setValue("Addon_Account_Id", getAddonToEdit?.Addon_Account_Id);
      setValue("Addon_Tax_Id", getAddonToEdit?.Addon_Tax_Id);
      setValue("Department_Id", getAddonToEdit?.Department_Id);
      setValue("Vehicle_Id", getAddonToEdit?.Vehicle_Id);
      setValue("Branch_Id", getAddonToEdit?.Branch_Id);
      setValue("Platform_Id", getAddonToEdit?.Platform_Id);
      setValue("Product_Line_Id", getAddonToEdit?.Product_Line_Id);
      setValue("Addon_Widget_Id", getAddonToEdit?.Addon_Widget_Id);
      setValue("Addon_Allow_Customer_Portal_Id", getAddonToEdit?.Addon_Allow_Customer_Portal_Id);
      setValue("Delivery_Id", getAddonToEdit?.Delivery_Id);
      productService.getSingleAddon(addon_id_edit, product_id_edit).then((res) => {
        if(res.data !== undefined){
          const newGroup =  underscore.groupBy(res.data, "Item_Plan_Type_Id");
              setTableData(newGroup[1]);
              setAssoPlanTable(newGroup[2]);
        }
      });
    }
   }

    return () => {
      resetAddonEdit();
      resetAssoTable();
      resetAssoTableData();
      isMounted = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAddonToEdit]);


  useEffect(()=> {
    productService.getProductAddonMasterData(product_id).then((res) => {
      if (res?.data !== undefined) {
        const groupedMasterData = underscore.groupBy(res.data, "Master_Type");
        setAddonData(groupedMasterData);
      }
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[product_id])



  function onSelectedAssociatesItems(data) {
    setSelectedFeatureList(data);
  }


  function onUnitSelect(val) {
    setValue("Unit_Id", val.Code);
  }


  function onSchemeSelect(val) {
    setValue("Addon_Price_Schema_Id", val.Code);
  }

  function onIntervalSelect(val) {
    setValue("Addon_Interval_Id", val.Code);
  }


  function onAccountSelect(val) {
    setValue("Addon_Account_Id", val.Code);
  }


  function onAddonTypeSelect(val) {
    setValue("Addon_Type_Id", val);
  }


  function onTaxSelect(val) {
    setValue("Addon_Tax_Id", val.Code);
  }


  function onDepartmentSelect(val) {
    setValue("Department_Id", val.Code);
  }


  function onVehicleSelect(val) {
    setValue("Vehicle_Id", val.Code);
  }

  function onBranchSelect(val) {
    setValue("Branch_Id", val.Code);
  }


  function onPlatformSelect(val) {
    setValue("Platform_Id", val.Code);
  }

  function onSubscribersSelect(val) {
    setValue("Subscriber_Id", val.Code);
  }

  function onLocationSelect(val) {
    setValue("unit_id", val.Code);
  }


  function onProductLineSelect(val) {
    setValue("Product_Line_Id", val.Code);
  }


  function onWidgetCheck(val) {
    setValue("Addon_Widget_Id", val);
  }

  function onAllowAssoSelect(val) {
    setValue("Addon_Allow_Customer_Portal_Id", val);
  }


  function onDeliverySelect(val) {
    setValue("Delivery_Id", val.Code);
  }

  const onSubmit = async () => {

    let xml_item = "";
    let temp = "";
    tableData?.map((item, i) => {
      temp += `<a><item_id>${item.Code} </item_id> </a>`;
    });
    xml_item = `<root> ${temp} </root>`;

    let xml_plan = "";
    let temp2 = "";
    selectedFeatureList?.map((item, i) => {
      temp2 += `<a><plan_id>${item.Item_Id} </plan_id> </a>`;
    });
    xml_plan = `<root> ${temp2} </root>`;


    await myPromiseModal({ title: "Confirmation", body: " Do you want to save this record?",
    showInput:false, placeHolder:'' }).then((res) => {
      if (res.decision === true) {
        const newData = getValues();
        let option = 1;
        if (getAddonToEdit?.Addon_Id) {
          option = 2;
        }
        productService.submitProductAddon({ option: option, ...newData,
          xml_item: xml_item, xml_plan: xml_plan
        }).then((res) => {
            if (res?.data !== undefined) {
              const { message_text, message_id } = res.data[0];
              if(message_id === 1){
                logicContext.showToast(message_text, "success");
              navigate(-1);

              }else{
               return logicContext.showToast(message_text, "error");

              }
            }
          });
      }
    });
  };

  function onErrors(error) {
    console.error(errors);
    logicContext.showToast("Form error", "error");
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <section className="container max-auto  max-w-6xl ">
          <div className="mb-1 ">
            <h6 className="font-weight-bold  mb-3">Add Addon</h6>
            <p>
              Required{" "}
              <span className="font-weight-bold text-danger ml-2">*</span>{" "}
            </p>
          </div>

          <section className="mt-4">
            <section className="plan">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
              
                        {" "}
                        <div className=" ">
                          <span className="text-sm text-danger">
                            Addon Name
                          </span>
                          <div className="plain_input">
                            <input
                              className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                              type="text"
                              {...register("Addon_Name", {
                                required: "Addon name is required",
                              })}
                            />
                          </div>
                          <small className="text-danger">
                            {errors?.Addon_Name && errors.Addon_Name.message}
                          </small>
                        </div>

                        <div className=" mt-3 ">
                      <span className="text-sm text-danger">
                        Unit Name

                      </span>
                      <div
                        {...register("Unit_Id", {
                          required: "Unit  is required!",
                        })}
                        className="name"
                      >
                        <AddOnUnitSelect
                          defaultVal={{
                            label: getAddonToEdit?.Addon_Unit_Name,
                          }}
                          onUnitSelect={onUnitSelect}
                        ></AddOnUnitSelect>
                      </div>
                      <i>
                        A name of your choice to refer to one unit of the plan.
                      </i>
                    </div>
                    <small className="text-danger">
                      {" "}
                      {errors?.Unit_Id && errors.Unit_Id.message}
                    </small>
                  
                    </Grid>
       
                  </Grid>
                </Grid>
                <Grid item xs={6}>
          
                    <div>
                      <span className="text-sm">Add Description</span>
                      <div className="name  ">
                        <textarea
                          {...register("Addon_Description", {})}
                          rows="5"
                          cols="45"
                          type="text"
                          className="bg-filterInput bottom-0 outline-none border-transparent 
               focus:border-transparent focus:ring-0"
                        ></textarea>
                      </div>
                    </div>
         
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
          
                  <div className="my-3">
                      <span className="text-sm">Account</span>
                      <div className="name">
                        <AddonAccountSelect
                          defaultVal={{
                            label: getAddonToEdit?.addon_interval_Name,
                          }}
                          onAccountSelect={onAccountSelect}
                        />
                      </div>
                      <p>
                        <i>
                          Revenue made from this addon will be associated with
                          this account
                        </i>
                      </p>
                    </div>
           
                </Grid>
                <Grid item xs={6}>
              
                    <div className="mt-[2.5vh]">
                      <span className="text-sm text-danger">
                        Price
                      </span>
                      <div className="plain_input_P">
                        <div className="">
                          <label className="relative">
                            <span className="other_dimension_input">
                              <input
                                className="bg-filterInput pl-[6vw]  placeHolder_input z-50 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                type="text"
                                placeholder=""
                                {...register("Addon_Price", {
                                  required: "Price is required!",
                                })}
                              />
                              <span className="dimenSelect_input ">
                                <AddonCurrSelect />
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <small className="text-danger">
                        {" "}
                        {errors?.Addon_Price && errors.Addon_Price.message}
                      </small>
                    </div>

                   
             
                </Grid>

              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                 <div  {...register("Unit_Id", {
                      required: "Unit  is required!",
                    })}>
                   
                  
                    <AddonAddFeatures></AddonAddFeatures>
                    <div className="">
                      <span className="text-sm">Pricing Interval</span>
                      <div className="name">
                        <AddOnIntervalSelect
                          defaultVal={{
                            label: getAddonToEdit?.addon_interval_Name,
                          }}
                          onIntervalSelect={onIntervalSelect}
                        />
                      </div>
                    </div>

                   
                    </div>


                    <div className=" mt-4">
                      <span className="text-sm">Tax</span>
                      <div className="name">
                        <AddonTaxSelect
                          defaultVal={{ label: getAddonToEdit?.Addon_Tax_Name }}
                          onTaxSelect={onTaxSelect}
                        />
                      </div>
                    </div>

               
                </Grid>

                <Grid item xs={6}>

                <Grid container spacing={1}>
                <Grid item xs={4}>
                <div className="mt-3">
                      <span className="text-sm"> Addon Type</span>
                    {/* put type here */}
                    <AddonTypeSelect val={getAddonToEdit?.Addon_Type_Id} onAddonTypeSelect={onAddonTypeSelect} />
                    </div>
                </Grid>

                  <Grid item xs={8}>
                    <Item>
                    {/* incldue widget */}
                    <AddonWidget onWidgetCheck={onWidgetCheck}
                    allowPortal={getAddonToEdit?.Addon_Allow_Customer_Portal_Id}
                    widget={getAddonToEdit?.Addon_Widget_Id}
                     onAllowAssoSelect={onAllowAssoSelect} />
                   </Item>
               
                  
                </Grid>
              </Grid>

                
              
                 
                  
                    {/* <AddOnTable></AddOnTable> */}
                    <AddonAssociates
                      onSelectedAssociatesItems={onSelectedAssociatesItems}
                    />
               
                  
                </Grid>
              </Grid>

        


            </section>
          </section>

          <section className=" mt-5 ">
            <span className="text-md font-bold mb-5 mt-5 ">
              Reporting Tags
            </span>
            <Grid container spacing={2}>
              <Grid item xs={4}>
          
                  <div className="">
                    <span className="text-sm">Department</span>
                    <div className="name">
                      <AddonDepartmentSelect
                        defaultVal={{ label: getAddonToEdit?.Deapartment_Name }}
                        onDepartmentSelect={onDepartmentSelect}
                      />
                    </div>
                  </div>
         
              </Grid>
              <Grid item xs={4}>
           
                  <div className="">
                    <span className="text-sm ">Delivery</span>
                    <div className="name">
                      <AddonDeliverySelect
                        defaultVal={{ label: getAddonToEdit?.Delivery_Name }}
                        onDeliverySelect={onDeliverySelect}
                      />
                    </div>
                  </div>
             
              </Grid>
              <Grid item xs={4}>
            
                  <div className="">
                    <span className="text-sm">Vehicle</span>
                    <div className="name">
                      <AddonVehicleSelect
                        defaultVal={{ label: getAddonToEdit?.Vehicle_Name }}
                        onVehicleSelect={onVehicleSelect}
                      />
                    </div>
                  </div>
            
              </Grid>
            </Grid>

          <section className="my-3">
            
          <Grid container spacing={2}>
              <Grid item xs={4}>
             
                  <div className="">
                    <span className="text-sm">Branch</span>
                    <div className="name">
                      <AddonBranchSelect
                        defaultVal={{ label: getAddonToEdit?.Branch_Name }}
                        onBranchSelect={onBranchSelect}
                      />
                    </div>
                  </div>
          
              </Grid>
              <Grid item xs={4}>
           
                  <div className="">
                    <span className="text-sm">Platforms</span>
                    <div className="name">
                      <AddonPlatformSelect
                        defaultVal={{ label: getAddonToEdit?.Platform_Name }}
                        onPlatformSelect={onPlatformSelect}
                      />
                    </div>
                  </div>
         
              </Grid>
              <Grid item xs={4}>
          
                  <div className="">
                    <span className="text-sm">Subscribers</span>
                    <div className="name">
                      <AddonSubscribersSelect
                        defaultVal={{ label: getAddonToEdit?.Subscriber_Name }}
                        onSubscribersSelect={onSubscribersSelect}
                      />
                    </div>
                  </div>
          
              </Grid>
            </Grid>
          </section>

            <Grid container spacing={2}>

              <Grid item xs={4}>
         
                  <div className="">
                    <span className="text-sm">Location Unit</span>
                    <div className="name">
                      <AddonLocationSelect
                        defaultVal={{ label: getAddonToEdit?.Unit_Name }}
                        onLocationSelect={onLocationSelect}
                      />
                    </div>
                  </div>
          
              </Grid>

              <Grid item xs={4}>
      
                  <div className="">
                    <span className="text-sm">Product Line</span>
                    <div className="name">
                      <AddonProductSelect
                        defaultVal={{label: getAddonToEdit?.Product_Line_Name}}
                        onProductLineSelect={onProductLineSelect}
                      />
                    </div>
                  </div>
           
              </Grid>
            </Grid>
            
          </section>

          {/* <section className="mt-4 plan">
          <span className="text-md font-bold mb-5">Custom Field</span>

          <div className="flex justify-between">
            <div className="">
              <span className="text-sm">Minimum Payment</span>
              <div className="plain_input">
                <div className="">
                  <label className="relative">
                    <span className="other_dimension_input">
                      <input
                        className=" placeHolder_input z-50 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        placeholder="me"
                      />
                      <span className="dimenSelect_input ">
                       
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </section> */}

          <section>
            <div className="mb-5 gird grid-cols-2 flex justify-between mt-4 gap-4 w-full">
              <div
              onClick={()=> navigate(-1)}
                style={{ color: "#F17E7E", backgroundColor: "white" }}
                className="cursor text-sm rounded"
              >
                CANCEL
              </div>
              <button
                type="submit"
                style={{ backgroundColor: "#476EF8" }}
                className="text-sm text-white px-3 rounded py-2"
              >
                SAVE
              </button>
            </div>
          </section>
        </section>
      </form>
    </>
  );
}

export default AddAddOnPage;
