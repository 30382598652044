/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { accountService } from "../../services/accountService";
import Select from "react-select";
import iconImage from "../../assets/Icon.svg";
import CurrencyFormat from "react-currency-format";
import LogicContext from "../../Context/LogicContext";
import {
 GetPaymentReceiveEdit, ResetReceiveEdit} from "../../store/paymentReceivedAtom";
import { useForm } from "react-hook-form";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";


import AddPaymentCustomer from "./AddPaymentCustomer";
import AddPaymentMode from "./AddPaymentMode";
import AddDepositTo from "./AddDepositTo";
import AddCreated from "./AddCreated";
import AddPaymentTable from "./AddPaymentTable";
import PaymentReceiveTaxCheck from "./PaymentReceiveTaxCheck";
import { useSearchParams } from 'react-router-dom';


function AddPaymentReceived() {
const editablePayment = GetPaymentReceiveEdit();
const resetPayment = ResetReceiveEdit();

  const navigate = useNavigate();
  const myPromiseModal = createModal(GeneralModal);
  const { register,setValue, getValues, handleSubmit,control, reset,formState: { errors },
  } = useForm();
  const logicContext = useContext(LogicContext);
  const [invoiceList, setInvoiceList] = useState([]);

  const [attachedFile, setAttachedFile] = useState(0);
  const [paymentReceive, setPaymentReceive] = useState(editablePayment?.Payment_Amount ? editablePayment?.Payment_Amount: 0);
  const [totalDues, setTotalDues] = useState(0);
  const [amountRefund, setAmountRefund] = useState(0);
  const [amountUsedPayment, setAmountUsedPayment] = useState(0);
  const [excessAmount, setExcessAmount] = useState(0);

  const paymentReceiveRef = useRef(null);
  const mountRef = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const Customer_Id = searchParams.get('Customer_Id');
  const Customer_Full_Name = searchParams.get('Customer_Full_Name');
  const Invoice_Ref_No = searchParams.get('Invoice_Ref_No');




  const calCulateGeneralCallBack = useCallback((list)=> {
    let tempDuesTotal = 0;
    let payAmountUsed = 0;

    list?.forEach((invoice, i)=> {
      tempDuesTotal += invoice.Due_Amount;
      payAmountUsed += invoice.Adjustment_Amount;
    
    });
    if(payAmountUsed > paymentReceiveRef.current.value){
      return logicContext.showToast('Amount used cannot be greater than payment received!','error');
    }
    setAmountUsedPayment(payAmountUsed);
    setTotalDues(tempDuesTotal);
    setAmountRefund(Number(paymentReceiveRef.current?.value) -  payAmountUsed);

    let diffExcess =  payAmountUsed - tempDuesTotal;

    if( diffExcess > 0 ){
      setExcessAmount( diffExcess);
    }else{
      setExcessAmount( 0);
    }

  },[logicContext]);

 

  useEffect(() => {
    mountRef.current = true;
    if(editablePayment?.Payment_Ref_No){
      setValue('Payment_Ref_No', editablePayment?.Payment_Ref_No);
      setValue('Customer_Id', editablePayment?.Customer_Id);
  
      setValue('Payment_Received_User_Id', editablePayment?.Payment_Received_User_Id);
      setValue('Deposit_Reference', editablePayment?.Deposit_Reference);
      setValue('Payment_Amount', editablePayment?.Payment_Amount);
      setValue('Bank_Charges', editablePayment?.Bank_Charges);
      setValue('Tax_Deducted_Id', editablePayment?.Tax_Deducted_Id);
      setValue('Customer_Notes', editablePayment?.Customer_Notes);
      setValue('Payment_Mode_Id', editablePayment?.Payment_Mode_Id);
      setValue('Deposit_Id', editablePayment?.Deposit_Id);
      setValue('Deposit_Reference', editablePayment?.Deposit_Reference);
      setValue('User_Id', editablePayment?.Created_User_Id);
      setValue('Tax_Deducted_Id', editablePayment?.Tax_Deducted_Id);
      setValue('Attached_File_Format', editablePayment?.Attached_File_Format);
      setValue('Attached_File_Id', editablePayment?.Attached_File_Id);
      if(editablePayment?.payment_details.length > 0){
        setInvoiceList(editablePayment?.payment_details);
       
        let tempDuesTotal = 0;
        let payAmountUsed = 0;
    
        editablePayment?.payment_details?.forEach((invoice, i)=> {
          tempDuesTotal += invoice.Due_Amount;
          payAmountUsed += invoice.Adjustment_Amount;
        
        });
        if(payAmountUsed > paymentReceiveRef.current.value){
          return logicContext.showToast('Amount used cannot be greater than payment received!','error')
        }
    
        setAmountUsedPayment(payAmountUsed);
        setTotalDues(tempDuesTotal);
        setAmountRefund(Number(paymentReceiveRef.current?.value) -  payAmountUsed)
        let diffExcess = Number(paymentReceiveRef.current?.value) - (tempDuesTotal + payAmountUsed)
    
        if( diffExcess > 0 ){
          setExcessAmount( diffExcess);
        }
      }
    }
    
    return () => {
      mountRef.current = false;
      resetPayment();
     
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editablePayment?.Payment_Ref_No]);


  const getCustomerInvoice = async (id)=> {
     const response = await accountService.getPaymentEntryData(id);
     if(response && mountRef.current){
      setInvoiceList([...response.data]);
      setTimeout(() => {
        calCulateGeneralCallBack(response.data);
      }, 1000);
     }
  }


 const  onChangeAmountReceive = (event)=> {
  setPaymentReceive(Number(paymentReceiveRef.current?.value))
  setValue('Payment_Amount', Number(paymentReceiveRef.current?.value));
 setTimeout(() => {
  calCulateGeneralCallBack(invoiceList);
 }, 2000);
 } 


 const  onPayAmountChange = (newAmount, index) => {

 const newInvoiceList = invoiceList?.map((invoice, i)=> {
    if(i === index){
      if( Number(newAmount) > Number(invoice?.Due_Amount)){
          logicContext.showToast('Payment amount must not be greater than Due Amount','error');
          return invoice;
      }
      return {...invoice, Adjustment_Amount: Number(newAmount)}
    }else{
      return invoice;
    }
  });
  setInvoiceList(newInvoiceList);
  //  calculate sum
  setTimeout(() => {
    calCulateGeneralCallBack(newInvoiceList);
  }, 1000);
 }


 useEffect(()=> {

  if(Customer_Id){
    setValue("Customer_Id", Customer_Id);
    setValue("Deposit_Reference", Invoice_Ref_No);
    getCustomerInvoice(Customer_Id);
  }
},[Customer_Id])



  function onTaxCheckSelect(val) {
    setValue("Tax_Deducted_Id", val);
  }


  function onCustomerSelect(val) {
    setValue("Customer_Id", val.Customer_Id);
    getCustomerInvoice(val.Customer_Id);
  }

  function onPaymentModeSelect(val) {
    setValue("Payment_Mode_Id", val.Payment_Mode_Id);
  }


  function onSelectCreateOfficer(val) {
    setValue("User_Id", val.Officer_Id);
  }

  function onSelectReceivePerson(val) {
    setValue("Payment_Received_User_Id", val.Officer_Id);
  }
 
  function onDepositToSelect(val) {
    setValue("Deposit_Id", val.Account_Id);
  }



  const onSubmit = async (option) => {
    const paymentData = getValues();

    //  validate
    if(!paymentData?.Customer_Id){
      return logicContext.showToast('Please select a customer','error');
    }
    //  validate
    if(!paymentData?.Payment_Amount ||  paymentData?.Payment_Amount < 100){
      return logicContext.showToast('Please enter a valid amount receive','error');
    }
    if(!paymentData?.Payment_Mode_Id ){
      return logicContext.showToast('Please select payment Mode','error');
    }

    if(!paymentData?.Deposit_Id ){
      return logicContext.showToast('Please select Deposit account','error');
    }

    var tempStringList = invoiceList?.map((item) => {
      let temp = `<a> <invoice_refno>${item.Invoice_Ref_No}  </invoice_refno>
        <adjustment_amount>${item?.Adjustment_Amount} </adjustment_amount>
        <due_amount>${item?.Due_Amount} </due_amount>
          </a>`;
      return temp;
    });

    await myPromiseModal({
      title: "Confirmation",
      body: " Do you want to save this record?",showInput:false, placeHolder:''
    }).then((res) => {
        if (res.decision === true) {
          const newData = getValues();
          if (editablePayment?.Payment_Ref_No) {
            option = 3;
          }
          // attachedFile
          const formData = new FormData();
          for (const keyData in newData) {
            formData.set(keyData, newData[keyData] ? newData[keyData] : 0);
          }
          formData.set("option", option);
          formData.set("xml_string_invoice",`<root> ${tempStringList} </root>`);
          if (attachedFile) {
            formData.set("attachment", attachedFile);
            formData.set("Attached_File_Id", 1);
            var fileExt = attachedFile.name.split(".").pop();
            formData.set("Attached_File_Format", fileExt);
          }

          accountService.submitPaymentReceived(formData).then((res) => {
            if (res?.data !== undefined) {
              const { message_text, message_id } = res.data[0];
              if (message_id === 1) {
                logicContext.showToast(message_text, "success");
                navigate(-1);
              } else {
                return logicContext.showToast(message_text, "error");
              }
            }
          });
        }
      })
      .catch((err) => {
        logicContext.showToast("error submitting record", "error");
      }).catch((err)=> {
        logicContext.showToast('error submitting form, please try again', 'error')
      })
  };


  function onErrors(error) {
    console.error("Errorrrrr ", error);
    logicContext.showToast("Form error", "error");
  }

  const selectFile = () => {
    document.getElementById("attached-file").click();
  };

  function attachmentHandler(e) {
    const file = e.target.files[0];
    setAttachedFile(file);
  }


  function paymentDateFunction() {
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
    if (editablePayment?.Payment_Date !== undefined) {
      defaultDate = moment(editablePayment?.Payment_Date).format("YYYY-MM-DD");
    }

    return (
      <div className="">
      <input
      defaultValue={defaultDate}
      key={defaultDate}
        className="bg-filterInput  pl-3  rounded max-h-full w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
        type="date"
        {...register("Payment_Date", {
          required: "Due date is required",
        })}
      />
      <small className="text-danger">
                      {errors?.Payment_Date && errors.Payment_Date.message}
                    </small>
    </div>

    );
  }

 


  return (
    <>
          <form onSubmit={handleSubmit(onSubmit, onErrors)}>
    
            <section className="container max-auto ">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <span className="text-sm  ">Customer Name</span>
                  <span className="text-danger font-bold" >*</span> 
                  <Grid container>
                    <Grid item xs={12}>
                      <AddPaymentCustomer {...register("Customer_Id", { required: "customer is required",  })}
                       defaultVal={{label: editablePayment?.Customer_Full_Name ? editablePayment?.Customer_Full_Name : Customer_Full_Name}} 
                        onCustomerSelect={onCustomerSelect} />
                        <small className="text-danger">
                      {errors?.Customer_Id && errors.Customer_Id.message}
                    </small>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <div className="">
                    <span className="text-sm ">Payment Date</span>
                    <span className="text-danger font-bold" >*</span> 
                    {paymentDateFunction()}
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="">
                    <span className="text-sm ">Amount Received</span>
                    <span className="text-danger font-bold" >*</span> 
                    <div className="">
                      <input
                        className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        placeholder=""
                        ref={paymentReceiveRef}
                        key={paymentReceive} 
                        defaultValue={paymentReceive}
                        onChange={onChangeAmountReceive}
                        autoFocus="autoFocus"
                        type="number"
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="">
                    <span className="text-sm ">
                      Payment Number (Auto Generated )
                    </span>
                    <div className="">
                      <input
                      readOnly={true}
                      value={editablePayment?.Payment_Ref_No}
                        className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        placeholder=""
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="">
                    <span className="text-sm ">Bank Charges if Any</span>
                    <div className="">
                      <input
                        className="bg-filterInput  w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="number"
                        {...register("Bank_Charges", {
                        })}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="">
                    <span className="text-sm  ">Payment Mode</span>
                    <span className="text-danger font-bold" >*</span> 
                    <div className="">
                      <AddPaymentMode {...register("Payment_Mode_Id", { required: "Payment mode is required",  })}
                      defaultVal={{label: editablePayment?.Payment_Mode_Name}} onPaymentModeSelect={onPaymentModeSelect}></AddPaymentMode>
                        <small className="text-danger">
                      {errors?.Payment_Mode_Id && errors.Payment_Mode_Id.message}
                    </small>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="">
                    <span className="text-sm  ">Deposit to</span>
                    <span className="text-danger font-bold" >*</span> 
                    <div className="">
                      <AddDepositTo {...register("Deposit_Id", { required: "Deposit Account is required",  })}
                       defaultVal={{label: editablePayment?.Deposit_Name}}  onDepositToSelect={onDepositToSelect}></AddDepositTo>
                        <small className="text-danger">
                      {errors?.Deposit_Id && errors.Deposit_Id.message}
                    </small>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <span className="text-sm ">Created By</span>
                  <span className="text-danger font-bold" >*</span> 
                  <AddCreated  defaultVal={{label: editablePayment?.Created_User_Full_Name}}  onSelectCreateOfficer={onSelectCreateOfficer} />
                </Grid>

                <Grid item xs={6}>
                  <span className="text-sm ">Payment Received By</span>
                  <AddCreated  defaultVal={{label: editablePayment?.Payment_Received_User_Name}} onSelectCreateOfficer={onSelectReceivePerson} />
                </Grid>
                <Grid item xs={6}>
                  <div className="">
                    <span className="text-sm ">Invoice Reference Number</span>
                    <div className="">
                      <input
                        className="bg-filterInput  pl-3  rounded w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        {...register("Deposit_Reference", {
                        })}
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <span className="#476ef8">Tax Deducted</span>
               <PaymentReceiveTaxCheck defaultVal={editablePayment?.Tax_Deducted_Id} onTaxCheckSelect={onTaxCheckSelect} />
                </Grid>

                <section className="border-2 z-0 mx-1 w-full  ">
                  <span className="#476ef8">Unpaid Invoice</span>
                  {invoiceList.length && 
                      <AddPaymentTable onPayAmountChange={onPayAmountChange} invoiceList={invoiceList} ></AddPaymentTable>
                   
                  }
                <Grid className="px-2 py-2" item xs={12}>
               </Grid>

                <Grid item xs={12}>
                 <div className="flex w-ful py-2 justify-end" >
                 <div className="w-50">
                 <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <span className="#476ef8 font-bold">Total Dues Amount </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="#476ef8 font-bold">
                      <CurrencyFormat  value={parseFloat(totalDues )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="#476ef8 font-bold">Payment Received </span>
                      
                    </Grid>
                    <Grid item xs={6}>
                     
                    <CurrencyFormat className="font-bold"
                     value={parseFloat(paymentReceive )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                     
                    </Grid>
                    <Grid item xs={6}>
                      <span className="#476ef8 font-bold">Amount Used for Payment </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="#476ef8 font-bold">
                      <CurrencyFormat value={parseFloat(amountUsedPayment )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                         </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="#476ef8 font-bold">Wallet Balance </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="#476ef8 font-bold">
                      <CurrencyFormat className="font-bold"
                      value={parseFloat(amountRefund )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                      </span>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="#476ef8 font-bold">Amount in Excess </span>
                    </Grid>
                    <Grid item xs={6}>
                    
                      <CurrencyFormat  className={" font-bold " +(excessAmount > 0? 'text-success': 'text-danger' ) }
                       value={parseFloat(excessAmount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                    
                    </Grid>
                 
                  </Grid>
                 </div>
                 </div>
                </Grid>
                </section>
                

                <Grid item xs={12}>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <div className="">
                          <span className="text-sm ">Customer Notes</span>
                          <div className="">
                            <input
                              className="bg-filterInput pl-3 rounded  h-[15vh] w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                              type="text"
                              {...register("Customer_Notes", {})}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <div className="">
                      <span className="text-sm ">Attach File</span>
                      <div className="relative w-full">
                        <input
                          id="attached-file"
                          className="bg-filterInput h-[6vh] w-50 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                          onChange={attachmentHandler}
                          type="file"
                          name="name"
                        />

                        <div
                          onClick={selectFile}
                          className="flex justify-between w-full absolute  top-0 left-0"
                        >
                          <img className="ml-2 mt-3" src={iconImage} alt="" />

                          {/* <InvoiceImage></InvoiceImage> */}
                        </div>
                      </div>
                      <i> maximum file size 5MB </i>
                    </div>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <section>
                    <div className="gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
                      <span
                        type="cancel"
                        onClick={()=> navigate(-1)}
                        style={{ color: "#F17E7E", backgroundColor: "white" }}
                        className=""
                      >
                        CANCEL
                      </span>
                      <div>
                        <span
                        onClick={()=> onSubmit(2)}
                          type="submit"
                          style={{ color: "#476EF8" }}
                          className="text-sm  px-3 rounded mr-10 py-2"
                        >
                          SAVE AS DRAFT
                        </span>
                        <span
                          type="submit"
                          onClick={()=> onSubmit(1)}
                          style={{ backgroundColor: "#476EF8" }}
                          className="text-sm text-white px-3 rounded py-2"
                        >
                          SAVE
                        </span>
                      </div>
                    </div>
                  </section>
                </Grid>
              </Grid>
            </section>
          </form>
    </>
  );
}

export default AddPaymentReceived;
