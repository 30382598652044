import { useEffect, useState } from "react";

function ItemReturnable(props) {
    const [isChecked, setIsChecked] = useState(false); 
    

    useEffect(()=> {
        if(props.defaultVal === 1){
            setIsChecked(true)
        }
      },[props?.defaultVal])
    


        const handleOnChange = () => {
            setIsChecked(!isChecked);
            if(isChecked){
                props.addItemReturnable(2)
            }else{
                props.addItemReturnable(1)
            }
            
          };




    return ( 
        <>
    <div className="  mt-4 ">
      <input  checked={isChecked}  onChange={handleOnChange} type="checkbox" className="w-[0.8vw] h-[1.6vh]" />
      <span className="text-sm ml-2">Returnable Item</span>
    </div>
        </>
     );
}

export default ItemReturnable;