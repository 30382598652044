/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown } from "react-bootstrap";
import { IoMdRefresh } from "react-icons/io";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { customerService } from "../../services/customer.service";
import LogicContext from "../../Context/LogicContext";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { editCustomerAtom } from "../../store/customerAtom";
import { useResetRecoilState, useRecoilState } from "recoil";
import CustomerContext from "../../Context/CustomerContext";
import CsvDownload from "react-json-to-csv";
import Tooltip from "@mui/material/Tooltip";
import {
  SetCustomerListAtom,
  GetCustomerListAtom,
  SetCustomerListBackUpAtom,
} from "../../store/customerAtom";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";

import { Table, Input } from 'antd';

const { Search } = Input;


function CustomersPage() {
     const { register, setValue, getValues, handleSubmit, reset, formState: { errors } } = useForm();
    
  const logicContext = useContext(LogicContext);
  const customerContext = useContext(CustomerContext);
  const [customerSelect, setCustomerSelect] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const setCustomerListAtom = SetCustomerListAtom();
  const customerAtomList = GetCustomerListAtom();
  const setCustomerAtomListBackUp = SetCustomerListBackUpAtom();


  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const resetCustomerEditAtom = useResetRecoilState(editCustomerAtom);

  const reloadCustomerData = async () => {
    customerContext.getCustomerTableHeader();
    const response = await customerService.getCustomerListNoCache();
    if (response.data?.customers?.length) {
      setCustomerListAtom(response?.data);
      setCustomerAtomListBackUp(response?.data);
    }
  };

  useEffect(() => {
    document.title = "Customer page"
    resetCustomerEditAtom();
  }, []);

useEffect(()=> {
  
if(customerAtomList.customers?.length){
  setFilteredData(customerAtomList.customers);
}
},[customerAtomList]);


  function selectCustomer(selectionList) {
    setCustomerSelect(selectionList);
  }


  function exportCustomers() {
    customerService.getAllCustomers().then((res) => {
        setFilteredData(res?.data);
      }).catch((err) => {
        logicContext.showToast("error getting customers data ", "warning");
      });
  }

  const loadMoreData = async (start, end) => {
    if (end > customerAtomList.total) {
      end = customerAtomList.total;
      start = start - 50;
    }
    const response = await customerService.getCustomerListNoCache(start, end);
    if (response.data?.customers?.length) {
      setCustomerListAtom(response?.data);
      setCustomerAtomListBackUp(response?.data);
    }
  };

  function pdfExportHandler() {}


  const handleRowClick = (record) => {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/customer-details/${record?.Customer_Id}`);

  };


     const searchByDate = async (event) => {
        const formData = getValues();
       const response =  await  customerService.getCustomerByDate(formData);
       console.log('response?.data ',response?.data);
       if(response?.data?.length){
        setFilteredData(response?.data);
      }else{
        logicContext.showToast("No result found", "");
        setFilteredData([]);
       }
       
      }


  const paginationOptions = {
    pageSize: 10,
    showSizeChanger: true,
    total: filteredData.length,
    // onChange: (page, pageSize) => {
    //   console.log(page, pageSize);
    //   const newList = tableDataList.slice(0, pageSize);
    //   console.log('check ', newList);
    //   setTableList(newList);
    // },
  };



const columns = [
    {
      title: 'ID',
      key: 'Customer_Id',
      dataIndex: 'Customer_Id',
      sorter: (a, b) => a?.Customer_Id - b?.Customer_Id
    //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
 
    {
      title: 'Name',
      // key: 'id',
      dataIndex: 'Name',
      sorter: (a, b) => a.Name.localeCompare(b.Name)
    //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
      
    {
      title: "Email",
      dataIndex: "Email_Address",
      sorter: (a, b) => a.Email_Address.localeCompare(b.Email_Address)
    },
 
    // {
    //   title: "Company",
    //   dataIndex: "Company_Name",
    //   sorter: (a, b) => a.Company_Name.localeCompare(b.Company_Name)
    // },
  
 
    {
      title: "Mobile",
      dataIndex: "Mobile",
      sorter: (a, b) => a.Mobile.localeCompare(b.Mobile)
    },
    {
      title: "Type",
      dataIndex: "Customer_Type_Name",
      sorter: (a, b) => a.Customer_Type_Name.localeCompare(b.Customer_Type_Name)
    },
    {
      title: "Branch",
      dataIndex: "Branch_Name",
      // sorter: (a, b) => a.Branch_Name.localeCompare(b.Branch_Name)
    },
  ];


  

  return (
    <section className="@container">
      <div className="">
        <section className=" w-full ">
          <section className="">
            <div className="mt-2 flex flex-col @2xl:flex-row @2xl:items-center @2xl:justify-between space-y-3 @2xl:space-y-0">
              <div className="flex flex-row items-center">
                <div className="flex flex-row items-center space-x-1">
                 
                  <Tooltip title="Reload">
                    <div
                      onClick={() => reloadCustomerData()}
                      className="p-2 rounded-full cursor-pointer hover:bg-gray-200 active:bg-gray-300 "
                    >
                      <IoMdRefresh className="h-[18px] w-[18px] text-gray-600 " />
                    </div>
                  </Tooltip>
                  <Tooltip title="Total" className="">
                    <div className="card font-weight-bold text-primary p-2 text-sm">
                      Total: {customerAtomList?.total}
                    </div>
                  </Tooltip>
                </div>
              
              </div>

               {/* date search */}
                      <div class="grid grid-cols-3 gap-2 ml-3">
                        <div>
                        <span className="text-sm ">From Date:</span>
                             <input
                                defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                                {...register("fr_date")}
                                className="bg-filterInput  w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                type="date"
                              />
                        </div>
                        
                        <div>
                        <span className="text-sm ">To Date:</span>
                             <input
                              {...register("to_date")}
                              defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
                              className="bg-filterInput  w-full pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                              type="date"
                            />
                        </div>
                        <div>
                        <button
                              onClick={() => searchByDate()}
                              type="button"
                              style={{ backgroundColor: "#476EF8" }}
                              className="text-sm text-white px-3 mt-4 rounded py-2.5" >
                              Search
                            </button>
                        </div>
                      </div>
                      {/* date search end */}
              

              <div className="flex flex-row items-center space-x-2 @2xl:space-x-1 ">
                <button className=" bg-[#476EF8] px-3 py-2 text-white text-sm rounded hover:bg-blue-500">
                  IMPORT
                </button>
                <div className="">
                  <button
                    onClick={() => exportCustomers()}
                    className="bg-[#476EF8] text-white text-sm rounded px-3 py-2 hover:bg-blue-500"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    EXPORT
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        {" "}
                        <CsvDownload data={filteredData}>
                          CSV <small>{filteredData.length} item(s)</small>{" "}
                        </CsvDownload>{" "}
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Excel <small>{filteredData.length} item(s)</small>
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => pdfExportHandler()}
                        className="dropdown-item"
                        href="#"
                      >
                        PDF
                      </a>
                    </li>
                  </ul>
                </div>

                <Link
                  to={`/${currModuleId}/${currModuleName}/${currUserMenu}/add_customer`}
                >
                  <button className="bg-buttonColor text-sm px-3 py-2 rounded text-white hover:bg-blue-500">
                    ADD NEW
                  </button>
                </Link>
              </div>
            </div>
            <div>
              {customerSelect.length > 0 ? (
                <section className="mt-4 sm:mt-6 w-full sm:mx-auto max-w-fit">
                  <div className="flex flex-row items-center space-x-2 sm:space-x-4 ">
                    <button className=" bg-filterColor text-gray-700 text-xs font-semibold font-[Poppins] px-3 sm:px-4 py-2 cursor-pointer hover:opacity-80 rounded">
                      ACTIVE
                    </button>
                    <button className=" bg-filterColor text-gray-700 text-xs font-semibold font-[Poppins] px-3 sm:px-4 py-2 cursor-pointer hover:opacity-80 rounded">
                      IN ACTIVE
                    </button>

                    <button className="bg-filterColorRed text-gray-700 text-xs font-semibold font-[Poppins] px-3 sm:px-4 py-2 cursor-pointer hover:opacity-80 rounded">
                      DELETE
                    </button>
                  </div>
                </section>
              ) : null}
            </div>
          </section>

          <section className="mt-4">
            {/* Table */}
            {/* <CustomerTable
              customerList={customerAtomList.customers}
              selectCustomer={selectCustomer}
            ></CustomerTable> */}

{/* <Search
        placeholder="Search in Table"
        onSearch={handleSearch} 
        onChange={(e) => handleSearch(e.target.value)}
        value={searchText}
        style={{ marginBottom: 10, width: 250 }}
      /> */}

        <Table key={filteredData?.length} columns={columns} dataSource={filteredData}
                      pagination={paginationOptions}
                       rowClassName="show-cursor"
                      style={{ background: '#f0f2f5', borderRadius: '8px' }}
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            handleRowClick(record);
                          },
                        };
                      }}
                        />


          </section>
        </section>
      </div>
    </section>
  );
}

export default CustomersPage;
