import { Grid,  } from "@material-ui/core";
import { useEffect, useState, useContext } from "react";
import { voucherService } from "../../services/voucherService";
import LogicContext from "../../Context/LogicContext";
import moment from "moment";
import { createModal } from "react-modal-promise";
import GeneralModal from "../../helpers/GeneralModal";
import CurrencyFormat from 'react-currency-format';







const OfflineVoucherRedeem = () => {
    const [searchInput, setSearchInput] = useState('');
    const [otpInput, setOtpInput] = useState('');
    const [redeemInput, setRedeemInput] = useState('');
    const [otpVerified, setOtpVerified] = useState(false);
    const [otpCounter, setOtpCounter] = useState(0);
    const [voucherDetails, setVoucherDetails] = useState({});
    const logicContext = useContext(LogicContext);
    const myPromiseModal = createModal(GeneralModal);

 


    useEffect(()=> {

    },[voucherDetails?.voucher_status_id]);

    const searchForVoucherById = (event)=> {
        event.preventDefault();
        if(!searchInput){
            return  logicContext.showToast('Voucher not found!', 'error');
        }
        getVoucherById(searchInput);
    }




    const getVoucherById = (voucher)=> {
        voucherService.searchVoucherById(voucher).then( res => {
            if(res?.data?.length){
                setVoucherDetails(res.data[0]);
            }else{
                return  logicContext.showToast('Voucher not found!', 'error');
            }
        }).catch( err => {
            return  logicContext.showToast('Error getting voucher!', 'error');
        });
    }


    const startOTPCounter = ()=> {
        setOtpCounter(30);
      const intervalId =   setInterval(() => {

            setOtpCounter( prev =>  { 
                    if(prev < 1){
                        clearInterval(intervalId);
                        return 0;
                    }else{
                        return prev - 1
                    }
                } );
        }, 1000);
    }



    const verifyOtp = (event)=> {
        event.preventDefault();
        if(voucherDetails?.Voucher_Status_Id !== 1){
            return logicContext.showToast('Only OPEN voucher can be processed!', 'error');
        }
        voucherService.verifyOtp({voucher_number: voucherDetails?.Voucher_Number, otp_number: otpInput}).then( res => {
            // getVoucherById();
            if(res?.data?.length){
                const data =  res?.data[0];
                if(data?.message_id === 1){
                    setOtpVerified(true);
                    logicContext.showToast( data?.message_text, 'success');
                }else{
                    logicContext.showToast(data?.message_text, 'error');
                }

            }
        }).catch( err => {
            console.log('search_eer ', err);
        });
    }


    const sendVoucherOtp = ()=> {
        if(voucherDetails?.Voucher_Status_Id !== 1){
            return logicContext.showToast('Only OPEN voucher can be processed!', 'error');

        }

        voucherService.sendVoucherOtp(voucherDetails?.Voucher_Number).then( res => {
            if(res?.data?.voucher?.length){
                startOTPCounter();
                logicContext.showToast('Voucher OTP has been sent!', 'success');
            }
        }).catch( err => {
            console.log('search_eer ', err);
        });
    }

    const redeemActionClick = async ()=> {
        if(voucherDetails?.Voucher_Status_Id !== 1){
            return logicContext.showToast('Only OPEN voucher can be processed!', 'error');
        }

        await myPromiseModal({ title: `Confirm voucher - ${voucherDetails?.Voucher_Number}`, body:  `Voucher will be redeemed to the owner of ${voucherDetails?.Reciepient_Mobile_Number}!`,
        showInput:false, placeHolder:''}).then((res) => {
          if (res.decision === true) {
            const data = {customer_id :  voucherDetails?.Customer_Id, voucher_number: voucherDetails?.Voucher_Number,
                mobile_number: voucherDetails?.Reciepient_Mobile_Number, otp_number: otpInput, redeem_purpose: redeemInput};
    
            voucherService.voucherRedeemAction(data).then( res => {
                if(res?.data?.result_status?.length){
                    const tempData =  res.data?.result_status[0];
                    if(tempData?.message_id === 1){
                        logicContext.showToast( tempData?.message_text, 'success');
                        getVoucherById(voucherDetails?.Voucher_Number);
                    }else{
                        logicContext.showToast( tempData?.message_text, 'error');
                    }
                }else{
                    logicContext.showToast('Something went wrong, please contact Admin!', 'error');
                }
              
            }).catch( err => {
            });

          }});

      
    }



    const manageVoucherStatus = (status) =>{
        let defaultClass = 'bg-primary text-white';
  
            switch (status) {
              case 'Unassigned':
              defaultClass = 'text-light bg-danger';
                break;
              case '"Redeemed"':
              defaultClass = 'text-light bg-success';
                break;
              case 'Open':
              defaultClass = 'text-light bg-warning';
                break;
            
              default:
                break;
            }
  
  
            return (
              <span className={` rounded-pill font-weight-bold py-2 px-4 h5 ${defaultClass} rounded`}>
              {status}
            </span>
            )
  
        
          
      }
  


    return (
        <>
                <Grid item xs={12} className="mx-2  border-2 p-2" container>
                    <Grid item xs={12}>
                        <div className="flex justify-between">
                            <div className="">
                                <span className="font-bold my-2 ">Voucher Redeem</span>
                            </div>
                        </div>
                    </Grid>

                    <Grid item className=" border-2 bg-light my-2 p-2" xs={12}>
                        <div className="row" >
                            <div className="col-8" >
                            <div className="flex  mt-4">
                                <div className="mt-2 font-bold">Voucher No :</div>
                                <form className=" ml-2 ">
                                    <div className="pt-2 mb-2 relative mx-auto text-gray-600  w-100">
                                        <input className="border-2 border-gray-300 bg-white h-10 px-6 pr-16 rounded-lg text-sm focus:outline-none"
                                            onChange={(e) => setSearchInput(e.target.value)}
                                           type="search" name="search" placeholder="Enter voucher number" />
                                        <button disabled={!searchInput} onClick={(event)=> searchForVoucherById(event)}
                                         className="btn btn-primary ml-2" >Search</button>

                                    </div>
                                </form>
                            </div>
                            </div>

                            <div className="col-4 mt-4" >
                            {voucherDetails?.Voucher_Status_Name  &&  
                           <>  {manageVoucherStatus(voucherDetails?.Voucher_Status_Name)} </>
    
                            }
                            </div>
                        </div>
                    </Grid>

                    {voucherDetails?.Ref_No  &&  
                    <>
                        <Grid item container className=" border-2 bg-light my-2 p-2" xs={12}>
                        <Grid className=" " item xs={6}>
                            <div className="">
                                <div>
                                    <span className="#476ef8 text-sm ">
                                      <span className="font-bold"> Recipient Name</span>   : <span className="text-sm"> {voucherDetails?.Reciepient_Name} </span>
                                    </span>{" "}
                                    <br></br>
                                    <span className="">
                                        <span className=" text-sm "> <span className="font-bold">Email Address </span>: {voucherDetails?.Reciepient_Email_Address} </span>{" "}
                                
                                        <br />
                                        <span className="text-sm  text-capitalize">
                                            {" "}
                                           <span className="font-bold"> Created Date :</span>
                                            
                                             :  { moment(voucherDetails?.Transaction_Date).format(" DD-MM-YYYY:HH:mm:ss")}
                                        </span>
                                        <br />
                                        <span className="  text-sm text-capitalize">
                                            {" "}
                                            <span className="font-bold">  Created By : </span>
                                            
                                            <span>{voucherDetails?.Created_By_Name} </span>
                                        </span>
                                        <div className="  text-sm text-capitalize">
                                            <span className="font-bold">  Redeem By : </span>
                                            <span>{voucherDetails?.Redeem_By_Name} </span>
                                        </div>
                                        <div className="  text-sm text-capitalize">
                                            <span className="font-bold">  Redeem Remark : </span>
                                            <span>{voucherDetails?.Redeem_Remark} </span>
                                        </div>
                                        {voucherDetails?.Redeem_Date &&  
                                         <div className="  text-sm text-capitalize">
                                         <span className="font-bold">  Redeem Date : </span>
                                         <span>{ moment(voucherDetails?.Redeem_Date).format(" DD-MM-YYYY:HH:mm:ss")} </span>
                                     </div>
                                        }
                                       
                                        <br />
                                 
                                    </span>
                                </div>
                            </div>

                        </Grid>
                        <Grid className="flex justify-start " item xs={6}>
                            <div className="">
                                <div>

                                    <span className="">
                                        <span className=" text-sm "> 
                                       
                                        <span className="font-bold">   Mobile Number : </span>
                                       {voucherDetails?.Reciepient_Mobile_Number} </span>
                                        <button disabled={(otpCounter > 0 )? true: false} onClick={()=> sendVoucherOtp()} className="btn btn-primary">Send OTP</button>
                                        {(otpCounter > 0 )  &&  
                                       <i className="ml-2">
                                       Resend OTP in <i className=" text-danger p-1 font-bold" > {otpCounter} </i> second(s)
                                       </i>
                                     }
                                     
                                   
                                  
                                        <br />
                                        <span className="text-sm  text-capitalize">
                                            {" "}
                                              <span className="">
                                              <span className="font-bold"> Amount : </span>
                                                 </span> <span className="text-xl">
                                                 <CurrencyFormat value={ ''+ parseFloat(voucherDetails?.Voucher_Amount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />  </span>
                                        </span>
                                        <br />
                                        <span className="  text-sm text-capitalize">
                                            {" "}
                                          
                                            <span className="font-bold">Voucher Type: </span>
                                            
                                             : <span className="text-primary" >{voucherDetails?.Voucher_Type_Name} </span>
                                        </span>
                                        <br /> 


                                        {(voucherDetails?.Voucher_Type_Name === 'Bulk')  && 
                                        
                                        <span className=" text-sm  text-capitalize">
                                        <span className="font-bold">   Corporate Name: </span>
                                        
                                         : {voucherDetails?.Corporate_Name}
                                    </span>
                                        }
                                   



                                    </span>
                                </div>
                            </div>


                        </Grid>

                        <span className=" font-bold text-sm text-capitalize">
                            {" "}
                            Voucher Note : <span>{voucherDetails?.Purchase_Zoho_Send_Remark} </span>
                        </span>
                    </Grid>
                  
                   
                <Grid item container className=" border-2 bg-light my-2 p-2" xs={12}>
                        <Grid item xs={5}>
                            <div className="flex  mt-5">
                                <div className="mt-2 font-bold">Enter OTP :</div>
                                <form className=" ml-2 w-50">
                                    <div class="pt-2 mb-2 relative mx-auto text-gray-600  ">
                                        <input  onChange={(e) => setOtpInput(e.target.value)}
                                         class="border-2 border-gray-300 bg-white h-10 px-6 pr-1 w-100 rounded-lg text-sm focus:outline-none"
                                            type="search" name="enter otp" placeholder="Enter otp" />
                                    </div>
                                    <button className="bg-blue-500 text-sm p-2 text-white rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-5 "
                                    disabled={!searchInput} onClick={(event)=> verifyOtp(event)}>Verify OTP</button>
                                </form>
                            </div>

                        </Grid>

                        {otpVerified  && 

                          <Grid item className="flex justify-center" xs={6}>
                            <div className="  mt-5">
                                <div className="mt-2 font-bold">Redeem Purpose :</div>
                                <form className=" ml-2 w-50">
                                    <div class="pt-2 mb-2  mx-auto text-gray-600  ">
                                        <input class="border-2 border-gray-300 bg-white h-[20vh] px-6 pr-1 w-[30vw] rounded-lg text-sm focus:outline-none"
                                             onChange={(e) => setRedeemInput(e.target.value)} type="search" name="enter otp" placeholder="Enter purpose" />
                                    </div>
                                </form>
                                <div className="flex justify-end w-full">
                                    <button onClick={(event)=> redeemActionClick(event)}XZbd268mc className="bg-blue-500 text-sm p-2 text-white rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-5 ">Redeem</button>
                                </div>
                            </div>
                        </Grid>
                        
                        }
                    </Grid>
                    

                    </>
                    
                    
                    }

             



                </Grid>

         
        </>

    );
};

export default OfflineVoucherRedeem;
