import React, { useEffect, useState, useContext } from "react";
// import "../pages/Plan.css";
import { productService } from "../../services/product.service";

import { useParams } from "react-router-dom";
import underscore from "underscore";
import LogicContext from "../../Context/LogicContext";
import {
  SetCouponMasterData,GetCouponMasterData} from "../../store/productAtom";
import { useForm } from "react-hook-form";
import  moment from 'moment';

import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import AdCouponTable from "../../components/AdCouponTable1";
import AdCouponTable2 from "../../components/AdCouponTable2";
import CouponDiscountType from "../CouponDiscounType";
import RedemptionTypeSelect from "./RedemtionTypeSelect";
import { GetCouponEdit, ResetCouponEdit, SetCouponTableOne, SetCouponTableTwo
, ResetCouponTableOne, ResetCouponTableTwo} from '../../store/productAtom';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function AddCouponPage() {
  const setMasterData = SetCouponMasterData();
  const masterData = GetCouponMasterData();
  const setCouponTableOne = SetCouponTableOne();
  const setCouponTableTwo = SetCouponTableTwo();
  const resetTableOne = ResetCouponTableOne();
  const resetTableTwo = ResetCouponTableTwo();
  const navigate = useNavigate();
  const myPromiseModal = createModal(GeneralModal);
  const { register, setValue,  getValues, handleSubmit,  reset, formState: { errors } } = useForm();
  const logicContext = useContext(LogicContext);
  const [associateAddons, setAssociateAddons] =  useState([]);
  const [associatePlans, setAssociatePlans] =  useState([]);
  const couponEdit = GetCouponEdit();
  const resetCouponEdit = ResetCouponEdit();

  const { product_id } = useParams();


  useEffect(() => {
    let isMounted = true;
    setValue("Redemption_Type_Id", 1);
    setValue("Product_Id", product_id);
    setValue("Discount_Type_Id", 1);
    if (couponEdit?.Product_Id) {
      // reset({ ...couponEdit });
      setValue("Redemption_Type_Id", couponEdit?.couponEdit);
      setValue("Product_Id",couponEdit?.Product_Id);
      setValue("Coupon_Id",couponEdit?.Coupon_Id);
      setValue("Discount_Type_Id", couponEdit?.Discount_Type_Id);
      setValue("Discount_Type_Id", couponEdit?.Discount_Type_Id);
      setValue("Discount_Type_Id", couponEdit?.Discount_Type_Id);
      setValue("Coupon_Name", couponEdit?.Coupon_Name);
      setValue("Coupon_Discount", couponEdit?.Coupon_Discount);
      setValue("Coupon_Code", couponEdit?.Coupon_Code);
      setValue("Coupon_Valid_UpTo", couponEdit?.Coupon_Valid_UpTo);
      setValue("Maximum_Redemption", couponEdit?.Maximum_Redemption);

      productService.getCouponDetails(couponEdit?.Coupon_Id,couponEdit?.Product_Id).then(res => {
        if(res?.data !== undefined){
          const newGroup =  underscore.groupBy(res.data, "Item_Plan_Type_Id");
          if(isMounted){
            setCouponTableOne(newGroup[1]);
          setCouponTableTwo(newGroup[2]);
          }
        }
      }).catch((err)=> {
      })
    }

    return ()=> {
      resetCouponEdit();
      resetTableOne();
      resetTableTwo();
      isMounted = false;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponEdit?.Product_Id]);

  useEffect(() => {
    if (masterData[0] === undefined) {
      productService.getCouponMasterData(couponEdit?.Product_Id).then((res) => {
        if (res?.data !== undefined) {
          const groupedMasterData = underscore.groupBy(res.data, "Master_Type");
          setMasterData(groupedMasterData);
          if(!couponEdit?.Product_Id){
            setCouponTableOne(groupedMasterData[4])
            setCouponTableTwo(groupedMasterData[5])
          }
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponEdit?.Product_Id]);


  function onSelectAssociatePlan(val){
    setAssociatePlans(val)
  }

  function onSelectAssociatesAddons(val){
    setAssociateAddons(val)
  }


  function onDiscountTypeSelect(val) {
    setValue("Discount_Type_Id", val);
  }



  function onRedemptionSelect(val) {
    setValue("Redemption_Type_Id", val);
  }




  const onSubmit = async () => {
    let xml_addon = "";
    let temp = "";
    associateAddons.map((item, i) => {
      temp += `<a><addon_id>${item.Code} </addon_id> </a>`;
    });
    xml_addon = `<root> ${temp} </root>`;

    let xml_plan = "";
    let temp2 = "";
    associatePlans.map((item, i) => {
      temp2 += `<a><plan_id>${item?.Code} </plan_id> </a>`;
    });
    xml_plan = `<root> ${temp2} </root>`;
    await myPromiseModal({ title: "Confirmation", body: " Do you want to save this record?",
    showInput:false, placeHolder:''}).then((res) => {
      if (res.decision === true) {

        const newData = getValues();
        let option = 1;
        if (couponEdit?.Product_Id) {
          option = 2;
        }
        productService.submitProductCoupon({ option: option, ...newData , xml_addon: xml_addon, xml_plan: xml_plan})
        .then((res) => {
            if (res?.data !== undefined) {
              const { message_text, message_id } = res.data[0];
              if(message_id === 1){
                logicContext.showToast(message_text, "success");
              navigate(-1);

              }else{
               return logicContext.showToast(message_text, "error");

              }
            }
          });
      }
    });
  };

  

  function onErrors(error) {
    console.error(errors);
    logicContext.showToast("Form error", "error");
  }


  return (
    <>
        
      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <section className="container max-auto   ">

          <Grid container spacing={2}>
          <Grid item xs={6}>
                <Item>
                <div className=" ">
                    <span className="text-sm text-danger">Coupon Name</span>
                    <div className="plain_input">
                      <input
                        className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        {...register("Coupon_Name", {
                          required: " Coupon Name is required!",
                        })}
                      />
                    </div>
                    <small className="text-danger"> {errors?.Coupon_Name && errors.Coupon_Name.message}
                        </small>
         
                  </div>  
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={2}>
            <Grid item xs={12}>
              <Item> 
              <div className="">
                        <span className="text-sm text-danger">Discount(NGN)</span>
                        <div className=" name_input">
                          <div className="">
                            <label className="relative">
                              <span className="other_dimension_input plain_input">
                                <input
                                  className="bg-filterInput  placeHolder_input z-50 bottom-0 pl-[7vw]
                        outline-none border-transparent focus:border-transparent focus:ring-0"
                                  type="number"
                                  placeholder=""
                                  {...register("Coupon_Discount", {
                                    required: "Discount is required!",
                                  })}
                                />
                                <span className="dimenSelect_input ">
                                  <CouponDiscountType onDiscountTypeSelect={onDiscountTypeSelect} />
                              

                                </span>
                              </span>
                            </label>
                          </div>
                          <small className="text-danger"> {errors?.Coupon_Discount && errors.Coupon_Discount.message}
                        </small>
                        </div>
                      </div> 
         
              </Item>
            </Grid>
            
     
        
          
          </Grid>
              </Grid>
              <Grid item xs={6}>
                <Item>
                    <span className="text-sm text-danger">Coupon Code</span>
                    <div className="plain_input">
                      <input
                        className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        {...register("Coupon_Code", {
                          required: "Coupon Code is required!",
                        })}
                      />
                    </div>
                    <small className="text-danger"> {errors?.Coupon_Code && errors.Coupon_Code.message}
                        </small>
         
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
            <Grid item xs={6}>
              <Item> 
      
                 <div className=" ">
                    <span className="text-sm text-danger">Valid Up to</span>
                    <div className="plain_input">
                      <input type="date"
                      defaultValue={getValues('Coupon_Valid_UpTo')}
                      // defaultValue={moment("10-07-2022").format("YYYY-MM-DD")}
                      // defaultValue={"10-07-2022"}
                        {...register("Coupon_Valid_UpTo", {
                          required: "Date  is required!",
                        })}
                        className="bg-filterInput bottom-0 outline-noneCoupon_Valid_Upto border-transparent focus:border-transparent focus:ring-0"
                      />
                    </div>
                    <small className="text-danger"> {errors?.Coupon_Valid_Upto && errors.Coupon_Valid_Upto.message}
                        </small>
         
                  </div>  
            
              </Item>
            </Grid>
            
     
        
          
          </Grid>
              </Grid>
              <Grid item xs={6}>
                <Item>
              
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
           
                </Item>
              </Grid>

              <Grid item xs={6}>
              <Item> 
                  
              <div>
                  <span className="text-sm text-center "> Redemption type</span>
                <RedemptionTypeSelect val={couponEdit?.Redemption_Type_Id}  onRedemptionSelect={onRedemptionSelect} />
              </div></Item>
            </Grid>
            <Grid item xs={6}>
                <Item>
                <div className=" ">
                    <span className="text-sm text-center text-danger">Maximum No of Redemptions</span>
                    <div className="plain_input">
                      <input
                        className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="text"
                        {...register("Maximum_Redemption", {
                          required: "Field is required!",
                        })}
                      />
                    </div>
                    <small className="text-danger"> {errors?.Maximum_Redemption && errors.Maximum_Redemption.message}
                        </small>
         
                  </div>  
                </Item>
              </Grid>


            </Grid>

            <section>
            <Grid container spacing={2}>
            <Grid item xs={6}>
              <Item> 
              <div className="">
              <span className="text-sm">Associated Plans</span>
              <div className="name">
              <AdCouponTable onSelectAssociatePlan={onSelectAssociatePlan}></AdCouponTable>
              </div>
           
            </div>
              </Item>
            </Grid>

            <Grid item xs={6}>
              <Item> 
              <div className="">
              <span className="text-sm">Associated Addons</span>
              <div className="name">
              <AdCouponTable2 onSelectAssociatesAddons={onSelectAssociatesAddons}></AdCouponTable2>
              </div>
           
            </div>
              </Item>
            </Grid>
         
          
          </Grid>
           
            </section>


         

          <section>
            <div className="focus cursor gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
              <div onClick={()=> navigate(-1)}
                style={{ color: "#F17E7E", backgroundColor: "white" }}
                className="text-sm rounded"
              >
                CANCEL
              </div>

              <button
                type="submit"
                style={{ backgroundColor: "#476EF8" }}
                className="text-sm text-white px-3 rounded py-2"
              >
                SAVE
              </button>
            </div>
          </section>
        </section>
      </form>
  

    </>
  );
}

export default AddCouponPage;
