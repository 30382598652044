import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Table } from "react-bootstrap";
import * as React from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CurrencyFormat from "react-currency-format";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SalesOrderDetailsTable(props) {
  return (
    <>
      <div className="">
        <TableContainer className="mt-3 w-full bg-white">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <colgroup>
              <col style={{ width: "40%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <TableHead style={{ backgroundColor: "#F1F1F1" }}>
              <TableRow>
                <TableCell className="subheading">Items Details</TableCell>

                {/* <TableCell className="subheading">Minimum Pay</TableCell> */}
                <TableCell className="subheading">Quantity</TableCell>
                <TableCell className="subheading">weight</TableCell>
                <TableCell className="subheading"> Rate </TableCell>
                <TableCell className="subheading"> Tax </TableCell>

                <TableCell className="subheading"> Amount </TableCell>
                {/* <TableCell  align="center">Rate</TableCell>
                  <TableCell  align="right">Amount</TableCell> */}
                {/* <TableCell></TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody className=" mb-3">
              {props?.tableData?.map((item, i) => {
                return (
                  <TableRow
                    className="border-1"
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className="subheading" align="left">
                      {item.Item_Name}{" "}
                    </TableCell>
                    <TableCell className="subheading" align="left">
                      {formatCustomNumber(item.Item_Sales_Quantity)} 
                    </TableCell>
                    <TableCell className="subheading" align="left">
                      {item.Sales_Order_Item_Weight_Description} 
                    </TableCell>
                    <TableCell className="subheading" align="left">
                    {/*  */}
                      <CurrencyFormat
                        value={formatCustomNumber(item.Item_Sales_Rate)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </TableCell>

                    <TableCell className="subheading" align="center">
                      {item?.Item_Tax_Name}{" "}
                    </TableCell>

                    <TableCell className="subheading" align="left">
                      <CurrencyFormat
                        value={formatCustomNumber(item.Item_Sales_Rate * item.Item_Sales_Quantity)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default SalesOrderDetailsTable;
