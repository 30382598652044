import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { transactionService } from "../../services/transaction.service";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import underscore from "underscore";
import up from "../../assets/icons/Caret_up.png";
import down from "../../assets/icons/Caret_down.png";
import { useRecoilValue } from "recoil";
import { transactionmasterDataAtom } from "../../store/transAtom";
import { customerService } from "../../services/customer.service";
import { twMerge } from "tailwind-merge";
import { IoSearchOutline } from "react-icons/io5";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";

const tableHead = [
  { field: "Bill_Date_String", headerName: "Date", width: 130 },
  { field: "Bill_Number", headerName: "Bill No", width: 130 },
  { field: "Bill_Ref_No", headerName: "Bill Ref", width: 130 },
  { field: "Amount", headerName: "Amount", width: 130 },
  { field: "Invoice_Status", headerName: "Status", width: 130 },
];

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function BillContent(props) {
  const [billList, setBillList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const masterData = useRecoilValue(transactionmasterDataAtom);
  var masterDataGroup = underscore.groupBy(masterData, "Master_Type");
  const invoiceStatus = masterDataGroup[3];
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  useEffect(() => {
    customerService.customerDetailBills(props.customer_id).then((res) => {
      let listInvoice = [];
      if (res?.data !== undefined) {
        listInvoice = res?.data?.map((invoice, index) => {
          return { ...invoice, id: index, Amount: formatCustomNumber( invoice?.Amount) };
        });
        setBillList(listInvoice);
        setSearchList(listInvoice);
      }else{
        setBillList([]);
        setSearchList([]);
      }
    
    });
  }, [props.customer_id]);


  function statusSelectHandler(value) {
    if (value === "All") {
      return setSearchList(billList);
    } else {
      var tempList = billList?.filter((item) => item.Invoice_Status === value);
      setSearchList(tempList);
    }
  }

  function billSearch(search) {
    if (search.length < 3) {
      setSearchList(billList);
    } else {
      var tempResult = billList.filter((bill) => {
        return (
          bill?.Amount?.toString()?.indexOf(search.toLowerCase()) >= 0 ||
          bill?.Bill_Date_String?.toLowerCase().indexOf(search.toLowerCase()) >=
            0 ||
          bill?.Bill_Ref_No?.toString().indexOf(search.toLowerCase()) >= 0 ||
          bill?.Bill_Number?.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
          bill?.Customer_Id.toString()
            ?.toLowerCase()
            .indexOf(search.toLowerCase()) >= 0
        );
      });

      setSearchList(tempResult);
    }
  }

  function OnItemCellClick(event) {
    const id = event.row["Bill_Ref_No"];
    navigate(`/${currModuleId}/${currModuleName}/bills/details/${id}`);
  }

  return (
    <>
      <div className="ml-1 @container">
        <div className="mt-1 text-gray-600 text-xl font-bold">Invoice</div>
        <div className="mt-2 space-y-3 @lg:flex @lg:flex-row @lg:items-center @lg:justify-between @lg:space-y-0 @lg:space-x-2">
          <div className="relative w-full max-w-[250px]">
            <input
              onChange={(ev) => billSearch(ev.target.value)}
              placeholder="Search Bills"
              className={twMerge(
                "w-full pl-11 h-[40px] bg-white text-gray-700 placeholder:text-gray-400 placeholder:text-sm outline-none focus:outline-none border-[1px] border-gray-200 rounded-md ring-0 focus:ring-1 focus:ring-blue-500"
              )}
            />
            <div className="absolute top-2.5 left-1.5 pl-2">
              <IoSearchOutline className="h-[20px] w-[20px] text-gray-600" />
            </div>
          </div>
          <div className="flex flex-row items-center space-x-2">
            <div className="flex flex-row items-center space-x-2">
              <div className="text-sm text-gray-600">Status:</div>
              <select
                onChange={(ev) => statusSelectHandler(ev.target.value)}
                className="rounded-md w-[130px]"
              >
                <optgroup className="bg-white rounded-md">
                  {invoiceStatus?.reverse().map((status, i) => {
                    return <option value={status.Name}> {status.Name} </option>;
                  })}
                </optgroup>
              </select>
              <button className="text-sm font-medium text-[#476EF8] hover:underline hover:underline-offset-2">
                Add New
              </button>
            </div>
          </div>
        </div>

        <DataGrid
          className="mt-4 overflow-x-auto bg-white outline-none border-[1px] border-gray-200 rounded-xl"
          style={{ height: 600, width: "100%" }}
          columns={tableHead}
          rows={searchList}
          pageSize={10}
          rowsPerPageOptions={[6]}
          onRowClick={(event) => {
            OnItemCellClick(event);
          }}
          components={{
            Pagination: CustomPagination,
          }}
          onCellClick={(event) => {
            event.defaultMuiPrevented = true;
          }}
       
        />
      </div>
    </>
  );
}

export default BillContent;
