import { useState, useEffect, useRef, useContext } from "react";
import CreditNoteScroll from "./CreditNoteScroll";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import CurrencyFormat from "react-currency-format";
import { invoiceService } from "../../services/invoice.service";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../Context/LogicContext";
import {  SetEditCreditNoteAtom, GetCreditNoteListAtom, SetCreditNoteListAtom } 
from "../../store/CreditNoteStore";
import { ImAttachment } from "react-icons/im";
import { FaFilePdf } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";
import { creditNoteService } from "../../services/creditNoteService";
import CreditNoteDetailsContent from "./CreditNoteDetailsContent";
import { useReactToPrint } from 'react-to-print';
import CreditNoteFilter from "./CreditNoteFilter";
import CreditNoteOptions from "./CreditNoteOptions";


function CreditNoteDetailsPage() {
  const myPromiseModal = createModal(GeneralModal);
  const setCreditNoteEdit = SetEditCreditNoteAtom();
  const getCreditNoteListAtom = GetCreditNoteListAtom();
  const setCreditNoteListAtom = SetCreditNoteListAtom();
  
  const logicContext = useContext(LogicContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const componentRef = useRef();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];
    const [creditNoteDetails, setCreditNoteDetails] = useState({});



    useEffect(()=> {
        loadDetails(id);
        
    },[id]);


    useEffect(() => {
      document.title = 'Credit note page';
      if (!getCreditNoteListAtom.credit_note?.length) {
        loadTableList();
      } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCreditNoteListAtom.credit_note]);


    const loadTableList = () => {
      creditNoteService.creditNoteList().then(res => {
        if (res.data) {
          setCreditNoteListAtom(res.data);
        }
      });
    }

    const loadDetails = async(noteId)=> {
        try {
        const response =  await creditNoteService.creditNoteDetails(noteId);
        if(response.data?.length){
          const creditNote = response.data[0];
            setCreditNoteDetails(creditNote);
           const newList = getCreditNoteListAtom?.credit_note.map((item)=> {
            if(item.Ref_No === creditNote.Ref_No){
              return creditNote;
            }else{
              return item;
            }
           });
           setCreditNoteListAtom({credit_note: newList,status_info: getCreditNoteListAtom.status_info});

         
        }
            
        } catch (error) {
            console.log('Error ', error);
            
        }
    }


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });



  
  const addNewRecord = ()=> {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
  }

  
  const viewCustomerDetails = (id)=> {
    navigate(`/${currModuleId}/${currModuleName}/customers/customer-details/${id}`);
  }


      const editCreditNoteOrder = async () => {
        const response = await myPromiseModal({
          title: "Modify Credit Note",
          body: " Do you want to modify this record?",
          showInput: false,
          placeHolder: "",
        });
    
        if (response.decision === true) {
          setCreditNoteEdit(creditNoteDetails);
          navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
        }
      };


  const onStatusChange =  async(selection) => {
    // return ;
    const formData = {ref_no: id, option: selection?.Credit_Note_Status_Id};
    const response = await myPromiseModal({ title: `Change status to ${selection?.Credit_Note_Status_Name}`,
      body: " Do you want to modify this record?",  showInput: false, placeHolder: "",
    });
    if(response?.decision === true){
      creditNoteService.creditNoteStatus(formData).then((res)=> {
        loadDetails(id);
        const responseData = res?.data[0];
        if(responseData?.message_id == 1){
          logicContext.showToast(responseData?.message_text, "success");
        }
        
      }).catch((err)=> {
      });

    }

 
  };
    
    

    return ( 
        <>
          <section className="">
        <section className="flex flex-wrap justify-between">
          <div className="mt-3">
            {/* {selectedList.length > 0 && (
              <Dropdown>
                <Dropdown.Toggle
                  className="border-[1px] border-gray-200"
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#45444B",
                    fontSize: "12px",
                    height: "40px",
                  }}
                  id="dropdown-basic"
                >
                  Bulk Actions
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-32 px-2 text-sm shadow-lg">
                  {getBulkActions?.map((action, i) => {
                    return (
                      <Dropdown.Item
                        className="px-0 text-gray-600"
                        key={i}
                        onClick={() => bulkActions(action)}
                      >
                        {action.Action_Name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )} */}
          </div>

          <div className="flex flex-row flex-wrap items-center">
            <div className="mt-3 flex flex-row items-center mr-6">
          <button onClick={addNewRecord} className="btn btn-primary m-4" >Add New</button>

            <CreditNoteOptions bulkActionData={getCreditNoteListAtom?.status_info}
            onFilterSelect={onStatusChange}
             />

            </div>
            <div className="mt-3 flex fle-row items-center space-x-1 ">
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <ImAttachment className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <FaFilePdf className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <IoPrintSharp  onClick={handlePrint} className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <MdEdit onClick={editCreditNoteOrder} className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              {/* <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <MdDelete className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div> */}
            </div>
          </div>
        </section>
        <section className="mt-4 lg:flex space-x-1">
          <div className="lg:w-[210px]">
            <CreditNoteScroll
            ></CreditNoteScroll>
          </div>
          <section className="w-full overflow-x-hidden mt-4 lg:mt-0 lg:pl-4 ">
          
          {/* Paste here */}
          
            <CreditNoteDetailsContent viewCustomerDetails={viewCustomerDetails}
             ref={componentRef}  id={id} creditNoteDetails={creditNoteDetails} />
          {/* Paste end */}
          </section>
        </section>
      </section>
        
        </>
     );
}

export default CreditNoteDetailsPage;