import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import underscore from "underscore";


function TypeSelect(props) {
    const [goodChecked, setGoodsChecked] = useState(true); 
    const [serviceChecked, setServiceChecked] = useState(false); 
    

    useEffect(()=> {
        if(props.defaultVal === 1){
            setGoodsChecked(true);
            setServiceChecked(false);
        }else if(props.defaultVal === 2){
            setServiceChecked(true);
            setGoodsChecked(false);
        }
      },[props?.defaultVal])
    
    
        const handleGoodsChange = () => {
            setGoodsChecked(true);
            setServiceChecked(false);
            props.addItemType(1);
          };
    
    
        const handleServiceChange = () => {
            setGoodsChecked(false);
            setServiceChecked(true);
            props.addItemType(2);
          };



    return ( 
        <>
          <div>
      <span className="text-sm">Type</span>
      <div className="flex text-sm">
        <div className="ml-2 flex">
          <div>
            {" "}
            <input type="radio" className="w-[0.8vw] h-[1.6vh]" value="1"
            checked={goodChecked}  onChange={handleGoodsChange} />
          </div>
          <div className="mt-[0.3vh]">
            {" "}
            <span className="text-xs ml-2">Goods</span>
          </div>
        </div>
        <div className="ml-2 flex">
          <div>
            {" "}
            <input type="radio" className="w-[0.8vw] h-[1.6vh]" value="2"
            checked={serviceChecked}  onChange={handleServiceChange} />
          </div>
          <div className="mt-[0.3vh]">
            {" "}
            <span className="text-xs ml-2">Services</span>
          </div>
        </div>
      </div>
    </div>
        </>
     );
}

export default TypeSelect;