
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const productSharingService = {
    productSharingList, productSharingDetails
    
};







async function  productSharingList() {
    return  axios.get(`/inventory/product-sharing`, authHeader());
}



async function  productSharingDetails(id) {
    return  axios.get(`/inventory/product-sharing-details/${id}`, authHeader());
}


// async function  deletePackage(id) {
//     return  axios.delete(`/inventory/delete-package/${id}`, authHeader());
// }











