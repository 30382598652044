import { Route, Routes } from "react-router-dom";
import Userlayout from "../layout/Userlayout";
import NotFoundPage from "../pages/NotFoundPage";
import { PrivateRoute } from "./PrivateRoutes";


import SettingsDashBoard from "../pages/home_page_feature/SettingsDashboard";
import HomePageFeaturePage from "../pages/home_page_feature/HomePageFeaturePage";
import HomePageFeatureDetails from "../pages/home_page_feature/HomePageFeatureDetails";
import AddHomePageFeature from "../pages/home_page_feature/AddHomePageFeature";
import FeatureActionUrlMainPage from "../pages/feature_action_url/FeatureActionUrlMainPage";
import FeatureAddActionUrlPage from "../pages/feature_action_url/FeatureAddActionUrlPage";
import FeatureActionUrlDetailsPage from "../pages/feature_action_url/FeatureActionUrlDetails";
import AddsSettingMainPage from "../pages/ads-settings/AddsSettingMainPage";
import AddsSettingDetails from "../pages/ads-settings/AddsSettingDetailsPage";
import NewAdsPage from "../pages/ads-settings/NewAdsPage";
import ProductAddActionUrlPage from "../pages/product_action-url/ProductAddActionUrlPage";
import ProductionActionUrlMainPage from "../pages/product_action-url/ProductActionUrlMainPage";
import ProductActionUrlDetailsPage from "../pages/product_action-url/ProductActionUrlDetails";
import ProductFeaturePage from "../pages/product_feature/ProductFeaturePage";
import AddProductFeature from "../pages/product_feature/AddProductFeature";
import ProductFeatureDetail from "../pages/product_feature/ProductFeatureDetail";
import CompositeItemGroupPage from "../pages/CompositeItemGroupPage";
import CompositeItemGroupAddNew from "../pages/item-page/AddItemFormProps/CompositeitemGroupAddNew";
import CompositeGroupDetailsPage from "../pages/CompositeGroupDetailsPage";
import ItemGroupPage from "../pages/item_group/ItemGroupPage";
import AddItemGroup from "../pages/item_group/AddItemGroup";
import ItemGroupDetailsPage from "../pages/item_group/ItemGroupDetailsPage";
import ItemPage from "../pages/item-page/ItemPage";
import AddItemPage from "../pages/item-page/AddItemPage";
import ItemDetailsPage from "../pages/item-page/ItemDetailsPage";
import PriceListPage from "../pages/price_list/PriceListPage";
import PriceListDetailsPage from "../pages/price_list/PriceListDetailsPage";
import PaymentGateWayPage from "../pages/payment_gateway/PaymentGatewayPage";
import WalletCheckPage from "../pages/WalletVariance/WalletCheckPage";
import WalletCheckDetails from "../pages/WalletVariance/WalletCheckDetails";
import SalesOrderPage from "../pages/sales_order/SalesOrderPage";
import AddSalesOrderPage from "../pages/sales_order/AddSalesOrderPage";
import SalesOrderDetailsPage from "../pages/sales_order/SalesOrderDetailsPage";
import InvoicePage from "../pages/invoice_page/InvoicePage";
import AddInvoicePage from "../pages/invoice_page/AddInvoicePage";
import InvoiceDetailsPage from "../pages/invoice_page/InvoiceDetailsPage";
import PaymentReceivedPage from "../pages/payment_receive/PaymentReceivedPage";
import AddPaymentReceived from "../pages/payment_receive/AddPaymentReceived";
import PaymentReceivedDetailsPage from "../pages/payment_receive/PaymentReceivedDetailsPage";
import CreditNoteMainPage from "../pages/credit_note/CreditNoteMainPage";
import CreditNoteDetailsPage from "../pages/credit_note/CreditNoteDetailsPage";
import CreditNoteAddNew from "../pages/credit_note/CreditNoteAddNew";
import VendorPage from "../pages/vendor/VendorPage";
import AddVendorPage from "../pages/vendor/AddVendorPage";
import VendorDetailsPage from "../pages/vendor/VendorDetailsPage";
import PurchaseReceivePage from "../pages/purchase_receive/PurchaseReceivePage";
import AddPurchaseReceivePage from "../pages/AddPurchaseReceivePage";
import PurchaseReceiveDetailsPage from "../pages/purchase_receive/PurchaseReceiveDetailsPage";
import BillPage from "../pages/bill/BillPage";
import ImageUploaderPage from "../pages/image_upload/ImageUploaderPage";
import AddBillPage from "../pages/bill/AddBillPage";
import BillsDetailsPage from "../pages/bill/BillsDetailsPage";
import PurchaseOrderPage from "../pages/purchase_order/PurchaseOrderPage";
import PurchaseOrderDetailsPage from "../pages/purchase_order/PurchaseOrderDetailsPage";
import AddPurchaseOrderPage from "../pages/purchase_order/AddPurchaseOrderPage";
import ManualJournalMainPage from "../pages/manual_journals/ManualJournalMainPage";
import ManualJournalDetailsPage from "../pages/manual_journals/ManualJournalDetailsPage";
import NewManualJournal from "../pages/manual_journals/NewManualJournal";
import ChartOfAccountPage from "../pages/chart_of_account/ChartOfAccountPage";
import AddNewChartOfAccountPage from "../pages/chart_of_account/AddNewChartOfAccountPage";
import ChartOfAccountDetailsPage from "../pages/chart_of_account/ChartOfAccountDetailsPage";
import CustomersPage from "../pages/customer-page/CustomersPage";
import CustomerDetailsPage from "../pages/customer-page/CustomerDetailsPage";
import AddCustomerPage from "../pages/AddCustomerPage";
import CustomerApprovalPage from "../pages/customer_approval/customerApprovalPage";
import CustomerApproveDetails from "../pages/customer_approval/customerApprovedetails";
import RewardCenterSetup from "../pages/reward_center/RewardCenterSetup";
import RewardHistoryPage from "../pages/reward_history/RewardHistoryPage";
import RewardHistoryDetails from "../pages/reward_history/RewardHistorydetails";
import RewardRedeemPage from "../pages/reward_redeeem/RewardRedeemPage";
import CarrierBagPage from "../pages/carreer_bag/CarrierBagPage";
import CarrierDetailsPage from "../pages/carreer_bag/CarrierPageDetails";
import CarrierBagAddNewPage from "../pages/carreer_bag/CarrierBadAddNewPage";

function SettingsRoutes() {
    return ( 
        <>
      <Userlayout>
      <Routes>
      
          <Route exact  path='/dashboard' element={
                <PrivateRoute>  <SettingsDashBoard /> </PrivateRoute>}>
          </Route>
        
            {/* Home page features */}
            <Route  path='/home-page-feature' element={
                  <PrivateRoute>  <HomePageFeaturePage /> </PrivateRoute>}>
            </Route>
            <Route  path='/home-page-feature/add-new' element={
                  <PrivateRoute>  <AddHomePageFeature /> </PrivateRoute>}>
            </Route>
            <Route  path='/home-page-feature/details/:id' element={
                  <PrivateRoute>  <HomePageFeatureDetails /> </PrivateRoute>}>
            </Route>
            {/* Home page feature end */}

            {/* Grocedy Action url */}
            <Route  path='/feature-action-url'  element={
                  <PrivateRoute>  <FeatureActionUrlMainPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/feature-action-url/add-new' element={
                  <PrivateRoute>  <FeatureAddActionUrlPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/feature-action-url/details/:id' element={
                  <PrivateRoute>  <FeatureActionUrlDetailsPage />  </PrivateRoute>}>
            </Route>
            {/* Grocedy Action url end */}


            {/* Setting page */}
            <Route  path='/ad-settings'  element={
                  <PrivateRoute>  <AddsSettingMainPage /> </PrivateRoute>}>
            </Route>
            <Route  path='/ad-settings/details/:id'  element={
                  <PrivateRoute>  <AddsSettingDetails /> </PrivateRoute>}>
            </Route>
            <Route  path='/ad-settings/add-new'  element={
                  <PrivateRoute>  <NewAdsPage /> </PrivateRoute>}>
            </Route>
            {/* End setting routes */}

      
            {/* Product Action Url */}
            <Route  path='/product-feature-action-url' element={
                  <PrivateRoute>  <ProductionActionUrlMainPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/product-feature-action-url/add-new'  element={
                  <PrivateRoute>  <ProductAddActionUrlPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/product-feature-action-url/details/:id'  element={
                  <PrivateRoute>  <ProductActionUrlDetailsPage /> </PrivateRoute>}>
            </Route>
            {/* Product Action Url End */}


            {/* Product feature */}
            <Route  path='/product-category-feature' element={
                  <PrivateRoute>  <ProductFeaturePage /> </PrivateRoute>}>
            </Route>
            <Route  path='/product-category-feature/add-new'  element={
                  <PrivateRoute>  <AddProductFeature /> </PrivateRoute>}>
            </Route>
            <Route  path='/product-category-feature/details/:id'  element={
                  <PrivateRoute>  <ProductFeatureDetail /> </PrivateRoute>}>
            </Route>

               {/* composite item group */}
          <Route exact  path='/composite-item-group' element={
                <PrivateRoute> <CompositeItemGroupPage />  </PrivateRoute>}>
          </Route>
          <Route exact  path='/composite-item-group/add-new' element={
                <PrivateRoute> <CompositeItemGroupAddNew />  </PrivateRoute>}>
          </Route>
          <Route exact  path='/composite-item-group/details/:group_id' element={
                <PrivateRoute> <CompositeGroupDetailsPage />  </PrivateRoute>}>
          </Route>
        
          <Route exact  path='/item-groups' element={
                <PrivateRoute> <ItemGroupPage /> </PrivateRoute>}>
          </Route>

            <Route  path='/item-groups/add-item-group' element={
            <PrivateRoute><AddItemGroup /> </PrivateRoute>}>
            </Route>

            <Route  path='/item-groups/item-group-details/:item_group_id' element={
                  <PrivateRoute> <ItemGroupDetailsPage />  </PrivateRoute>}>
            </Route>
        
        {/* items */}
          <Route exact  path='/items' element={
                <PrivateRoute> <ItemPage />  </PrivateRoute>}>
          </Route>

            <Route  path='/items/add-item/:option' element={
                  <PrivateRoute>  <AddItemPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/items/item-details/:item_id' element={
                  <PrivateRoute>  <ItemDetailsPage /> </PrivateRoute>}>
            </Route>

                  {/* price-list route */}
                  <Route  path='/price-list' element={
                  <PrivateRoute>  <PriceListPage />  </PrivateRoute>}>
            </Route>
            
          
            <Route  path='/price-list/details/:id' element={
                  <PrivateRoute> <PriceListDetailsPage/> </PrivateRoute>}>
            </Route>


            <Route path='/payment-gateway' element={
                  <PrivateRoute>  <PaymentGateWayPage /> </PrivateRoute>}>
            </Route>

                {/* Wallet Variant */}
      <Route path='/wallet-check' element={
                        <PrivateRoute>  <WalletCheckPage/></PrivateRoute>}>
                  </Route>
                  <Route path='/wallet-check/details/:id' element={
                        <PrivateRoute>  <WalletCheckDetails/> </PrivateRoute>}>
                  </Route>

                  <Route  path='/sales-order' element={
                  <PrivateRoute>  <SalesOrderPage/> </PrivateRoute>}>
            </Route>

            <Route  path='/sales-order/add-new' element={
                  <PrivateRoute>  <AddSalesOrderPage/> </PrivateRoute>}>
            </Route>

            <Route  path='/sales-order/details/:sales_id' element={
                  <PrivateRoute>  <SalesOrderDetailsPage/> </PrivateRoute>}>
            </Route>

               {/*  */}
          <Route exact  path='/invoices' element={
                <PrivateRoute> <InvoicePage/> </PrivateRoute>}>
          </Route>  

          <Route exact  path='/invoices/add-invoice' element={
                <PrivateRoute> <AddInvoicePage/> </PrivateRoute>}>
          </Route> 

                  
          <Route exact  path='/invoices/details/:invoice_id' element={
                <PrivateRoute> <InvoiceDetailsPage/> </PrivateRoute>}>
          </Route> 

          <Route exact  path='/payment-received' element={
                <PrivateRoute> <PaymentReceivedPage/> </PrivateRoute>}>
          </Route>
          <Route exact  path='/payment-received/add-payment-received' element={
                <PrivateRoute> <AddPaymentReceived/> </PrivateRoute>}>
          </Route>


          <Route exact  path='/payment-received/details/:payment_ref_id' element={
                <PrivateRoute> <PaymentReceivedDetailsPage/> </PrivateRoute>}>
          </Route> 


            {/* Credit Notes */}
            <Route  path='/credit-notes' element={
                  <PrivateRoute>  < CreditNoteMainPage/></PrivateRoute>}>
            </Route>

            <Route  path='/credit-notes/details/:id' element={
                  <PrivateRoute>  < CreditNoteDetailsPage/></PrivateRoute>}>
            </Route>

            <Route  path='/credit-notes/add-new' element={
                  <PrivateRoute>  < CreditNoteAddNew/></PrivateRoute>}>
            </Route>


            <Route  path='/vendors' element={
                  <PrivateRoute>  <VendorPage/> </PrivateRoute>}>
            </Route>
            <Route  path='/vendors/add-new' element={
                  <PrivateRoute>  <AddVendorPage/></PrivateRoute>}>
            </Route>
            <Route  path='/vendors/details/:vendor_id' element={
                  <PrivateRoute>  <VendorDetailsPage/> </PrivateRoute>}>
            </Route>

            <Route  path='/purchase-receives' element={
                  <PrivateRoute>  <PurchaseReceivePage/> </PrivateRoute>}>
            </Route>
            <Route  path='/purchase-receives/add-new' element={
                  <PrivateRoute>  <AddPurchaseReceivePage/> </PrivateRoute>}>
            </Route>
            
            <Route  path='/purchase-receives/details/:purchase_id' element={
                  <PrivateRoute>  <PurchaseReceiveDetailsPage/> </PrivateRoute>}>
            </Route>


            <Route  path='/bills' element={
                  <PrivateRoute>  <BillPage/> </PrivateRoute>}>
            </Route>

            <Route  path='/image-upload' element={
                  <PrivateRoute>  <ImageUploaderPage/> </PrivateRoute>}>
            </Route>
            <Route  path='/bills/add-new' element={
                  <PrivateRoute>  <AddBillPage/> </PrivateRoute>}>
            </Route>
            <Route  path='/bills/details/:bill_id' element={
                  <PrivateRoute>  <BillsDetailsPage/> </PrivateRoute>}>
            </Route>

            <Route  path='/purchase-order' element={
                  <PrivateRoute>  <PurchaseOrderPage/> </PrivateRoute>}>
            </Route>


            <Route  path='/purchase-order/details/:purchase_id' element={
                  <PrivateRoute>  <PurchaseOrderDetailsPage/> </PrivateRoute>}>
            </Route>

            <Route  path='/purchase-order/add-new' element={
                  <PrivateRoute>  <AddPurchaseOrderPage/> </PrivateRoute>}>
            </Route>


            <Route  path='/manual-journels' element={
                  <PrivateRoute>  < ManualJournalMainPage/></PrivateRoute>}>
            </Route>    

            <Route  path='/manual-journels/details/:id' element={
                  <PrivateRoute>  < ManualJournalDetailsPage/> </PrivateRoute>}>
            </Route>

            <Route  path='/manual-journels/add-new' element={
                  <PrivateRoute>  < NewManualJournal/> </PrivateRoute>}>
            </Route>

            <Route exact path='/chart-of-accounts' element={
                  <PrivateRoute> <ChartOfAccountPage/> </PrivateRoute>}>
            </Route>
            <Route exact path='/chart-of-accounts/add-new' element={
                  <PrivateRoute>
                        <AddNewChartOfAccountPage/>
                  </PrivateRoute>}>
            </Route>
            <Route exact path='/chart-of-accounts/details/:chart_id' element={
                  <PrivateRoute> <ChartOfAccountDetailsPage/> </PrivateRoute>}>
            </Route>

          
       <Route  path='customers' element={
                <PrivateRoute> <CustomersPage /></PrivateRoute>}>
          </Route>

            {/* customer page children routes */}
            <Route exact  path='/customers/customer-details/:customer_id' element={
                  <PrivateRoute> <CustomerDetailsPage /> </PrivateRoute>}>
            </Route>

            <Route  path='/customers/add_customer' element={
                  <PrivateRoute> <AddCustomerPage /> </PrivateRoute>}>
            </Route>


          <Route exact  path='/customer-approval' element={
                <PrivateRoute> <CustomerApprovalPage /> </PrivateRoute>}>
          </Route> 

          <Route exact  path='/customer-approval/details/:customer_id' element={
                <PrivateRoute> <CustomerApproveDetails /> </PrivateRoute>}>
          </Route> 

                 {/* Voucher Setup */}
          <Route  path='/Setup' element={
                  <PrivateRoute>  < RewardCenterSetup/> </PrivateRoute>}>
            </Route>

            <Route  path='/Setup' element={
                  <PrivateRoute>  < RewardCenterSetup/> </PrivateRoute>}>
            </Route>

            <Route  path='/reward' element={
                        <PrivateRoute>  < RewardHistoryPage/> </PrivateRoute>}>
                  </Route>

            <Route  path='/reward/details/:id' element={
                        <PrivateRoute>  < RewardHistoryDetails/> </PrivateRoute>}>
                  </Route>

            {/* Reward redeem */}
            <Route  path='/reward-redeem' element={
                        <PrivateRoute>  < RewardRedeemPage/> </PrivateRoute>}>
                  </Route>


            
            <Route  path='/carrier-bag-return' element={
                  <PrivateRoute>  < CarrierBagPage/> </PrivateRoute>}>
            </Route>

           <Route  path='/carrier-bag-return/details/:id' element={
                  <PrivateRoute>  < CarrierDetailsPage/> </PrivateRoute>}>
            </Route>


           <Route  path='/carrier-bag-return/add-new' element={
                  <PrivateRoute>  < CarrierBagAddNewPage/> </PrivateRoute>}>
            </Route>




        <Route path="/*" element={<NotFoundPage />}></Route>
        </Routes>
        </Userlayout>

        </>
     );
}

export default SettingsRoutes;