
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const creditNoteService = {
    creditNoteList, submitCreditNote,
      creditNoteDetails, creditNoteStatus
  
    
};





async function  creditNoteList() {
    return  axios.get(`/inventory/credit-note`, authHeader());
}


async function  creditNoteDetails(id) {
    return  axios.get(`/inventory/credit-note-details/${id}`, authHeader());
}


async function  submitCreditNote(form) {
    return  axios.post(`/inventory/submit-credit-note`, form, authHeader());
}

async function  creditNoteStatus(form) {
    return  axios.put(`/inventory/credit-note-status`, form, authHeader());
}











