import React, { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {  message, Upload } from 'antd';


const beforeUpload = (file) => {
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    // if (!isJpgOrPng) {
    //   message.error('You can only upload JPG/PNG file!');
    // }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return   isLt2M;
  };



function GeneralFileUploadPreview(props) {
    const [fileUrl, setFileUrl] = useState();


  useEffect(()=> {
    if(props?.defaultFileUrl){
      setFileUrl(props.defaultFileUrl);
    }
  },[props?.defaultFileUrl]);


  
    const handleFileChange = (info) => {
        const { file } = info;
        // const myFile = file.originFileObj;
        // props.onFileSelected(myFile);
        // const fileURL = URL.createObjectURL(file);
        const myFile =  file.originFileObj;
        props.onFileSelected(myFile);
        if (myFile instanceof Blob) {
            // Create a URL for the file
            const fileURL = URL.createObjectURL(myFile);
        
            setFileUrl(fileURL);
      
          } else {
            console.error("No file selected or the selected item is not a valid file.");
          }
       
  
    };
  

  const uploadButton = (
    <button  style={{  border: 0,  background: 'none'  }}type="button" >
      <div style={{  marginTop: 8,width: 40  }}  >
        Upload
      </div>
    </button>
  );

    return ( 
        <>
           <Upload
        name="uploaded_file"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        
        beforeUpload={beforeUpload}
        onChange={handleFileChange}
        multiple={false}
      >
        {fileUrl ? (
          <>
          <img   src={fileUrl}  alt="uploaded file"   style={{   width: '100%',  }}  />
          </>
          
        ) : (    uploadButton  )}
      </Upload>


     {fileUrl?.length > 2 && 
     <a key={fileUrl} href={`${fileUrl}`} className='btn btn-primary'  target="_blank" rel="noopener noreferrer">
     Open File
   </a>

     
     } 
      
        </>
     );
}

export default GeneralFileUploadPreview;