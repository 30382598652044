

import moment from "moment";
import { formatCustomDate } from "../../helpers/formatCustomDate";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";


function SharingDetailsContent(props) {
    const { details, tableList, id, ref, ViewCustomerDetails} = props;




    const managePurchaseStatus = (statusName,  Voucher_Status_Id) => {
      let defaultClass = "color1";

      switch (Voucher_Status_Id) {
          case 1:
              defaultClass = "color2";
              break;
          case 2:
              defaultClass = "color3";
              break;
          case 3:
              defaultClass = "color4";
              break;
          case 4:
              defaultClass = "color5";
              break;
          case 5:
              defaultClass = "color6";
              break;
          case 6:
              defaultClass = "color1";
              break;
          case 7:
              defaultClass = "color8";
              break;

          default:
              break;
      }

      return (
          <span className={`font-bold  ${defaultClass} `}>
              {statusName}
          </span>
      );
  };


  const calculatePurchasedTotal = (tableList)=> {
    let total = 0;

    tableList.forEach((share)=> {
        let temp = share.Slot_Price * share.Purchase_Slot;
        total += temp;
    });
    return total;
  }

  const calculateCharges = (tableList)=> {
    let total = 0;

    tableList.forEach((share)=> {
        let temp = share.Delivery_Charge * share.Purchase_Slot;
        total += temp;
    });
    return total;
  }
//   const expectedTotal = (tableList)=> {
//     let total = 0;

//     tableList.forEach((share)=> {
//         let temp = share.Delivery_Charge * share.Purchase_Slot;
//         total += temp;
//     });
//     return total;
//   }



    return (
        <>


<div   ref={ref} className="bg-white shadow-lg rounded-lg p-8  mx-auto">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-8">
        <div className="text-left">
          {/* <h2 className="text-2xl font-bold"> {manageInvoiceStatus(creditNoteDetails?.Status_Name)} </h2> */}
          <p className=" text-3xl font-bold text-gray-600">{details?.Sharing_Name}</p>
          <img key={id} src={details?.Item_Image_URL} alt="product_img"  width="300" />
        </div>
        <div className="text-right">
          <h3 className="text-4xl font-bold">{managePurchaseStatus(details?.Product_Sharing_Status_Name, details.Product_Sharing_Status_Id)}</h3>
          {/* <p className="text-gray-600"># {creditNoteDetails?.Credit_Note_Number} </p> */}
          <p className="text-gray-600">  Slot: {details.Total_Purchased_Slot}/{details.Total_Purchased_Slot + details.Total_Remaining_Slot} </p>
        </div>
      </div>

      {/* Credit Info */}
      <div className="flex justify-between mb-6">
        <div className="text-left">
          <p  className=" text-dark show-cursor">Ref  <span className="font-bold" >{ details.Ref_No} </span> </p>
          <p  className=" text-dark show-cursor">Product  <span className="font-bold" >{ details.Item_Name} </span> </p>
          <p  className=" text-dark show-cursor">SKU  <span className="font-bold" >{ details.Item_SKU} </span> </p>
          <p  className=" text-dark show-cursor">Type  <span className="font-bold" >{ details.Item_Type_Name} </span> </p>
          <p  className=" text-dark show-cursor">Slot Price  <span className="font-bold" >(₦) {formatCustomNumber( details.Slot_Price)} </span> </p>
          <p  className=" text-dark show-cursor">Total  <span className="font-bold" > {details.Total_Purchased_Slot + details.Total_Remaining_Slot} slot(s) </span>  </p>
          <p className=" text-dark show-cursor">Purchased  <span className="font-bold" >{details.Total_Purchased_Slot} slot(s) </span>  </p>
          <p  className=" text-dark show-cursor">Remaining <span className="font-bold" >{details.Total_Remaining_Slot} slot(s)</span> </p>
          <p  className=" text-dark show-cursor">Created By <span className="font-bold" >{details.Created_User_Name}</span> </p>
        </div>
     
        <div className="text-right">
          <p className="text-gray-600">Created Date: <span className="font-bold" >{formatCustomDate(details?.Created_Date)}</span> </p>
          <p className="text-gray-600">Expire Date: <span className="font-bold" >{formatCustomDate(details?.Gropool_Date)} </span></p>
          <p className="text-gray-600">Completed Date: <span className="font-bold" >{formatCustomDate(details?.Completed_Date)}</span> </p>
          <p className="text-gray-600">Ref #: {id} </p>
        </div>
      </div>

      {/* Item Description Table */}
      <div className="border-t border-b mb-6">
        <table className="w-full text-left table-auto">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2">#</th>
              <th className="py-2">Customer Name</th>
              <th className="py-2">Type</th>
              <th className="py-2">Phone</th>
              <th className="py-2">Slot</th>
              <th className="py-2">Price (₦)</th>
              <th className="py-2">Purchased (₦)</th>
              <th className="py-2">D Charge (₦)</th>
            </tr>
          </thead>
          <tbody>
            
                {tableList?.map((share, index)=> {

                    return (
                        <tr>
                         <td className="py-2">{index+1} </td>
                        <td className="py-2">
                            <strong className="text-primary show-cursor" onClick={()=> ViewCustomerDetails(share.Customer_Id)} >{share?.Customer_Full_Name} </strong>
                            {/* <br />
                            <span className="text-gray-500 text-sm">Sample collection offlinePayment text</span> */}
                        </td>
                        <td className="py-2">{share.Gift_Status_Name} </td>
                        <td className="py-2">{share.Customer_Mobile_No} </td>
                        <td className="py-2">{share?.Purchase_Slot} </td>
                        <td className="py-2">{formatCustomNumber(share?.Slot_Price)} </td>
                        <td className="py-2">{formatCustomNumber(share?.Slot_Price *  share.Purchase_Slot)} </td>
                       
                        <td className="py-2">{formatCustomNumber(share?.Delivery_Charge)} </td>
                        </tr>

                    )
                })}
             
            
          </tbody>
        </table>
      </div>

      {/* Subtotal and Total */}
      <div className="text-right mb-6">
      
        <p className="text-lg ">Purchased Amount:
             <span className="font-bold text-gray-600">
             ₦{ formatCustomNumber(calculatePurchasedTotal(tableList)) }
          </span>
          </p>
        <p className="text-lg ">Delivery charges:
             <span className="font-bold text-gray-600">
             ₦{formatCustomNumber(calculateCharges(tableList))}
          </span>
          </p>
        <p className="text-lg ">Expected Total Delivery Charges:
             <span className="font-bold text-gray-600">
             ₦{formatCustomNumber(details?.Slot_Number * details.Delivery_Charge)}
          </span>
          </p>
           <p className="text-lg ">Expected Total Amount:
             <span className="font-bold text-gray-600">
             ₦{formatCustomNumber(details?.Slot_Number * details.Slot_Price)}
          </span>
          </p>
      </div>

      {/* Credits Remaining */}
      {/* <p className='p-3' >Remark: {creditNoteDetails?.Remark}  </p> */}
     
    </div>

        
        </>
      );
}

export default SharingDetailsContent;