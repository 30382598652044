import React, { useEffect, useRef, useState, useContext } from "react";
import { ImAttachment } from "react-icons/im";
import { FaFilePdf } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";

import { Grid } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { voucherService } from "../../services/voucherService";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../Context/LogicContext";
// import VoucherDetailContent from "./VoucherDetailsContent";
// import VoucherScroll from "./VoucherScroller";
import { GetProductSharingAtom, SetProductSharingAtom } from "../../store/productSharingAtom";
import { productSharingService } from "../../services/productSharing.service";
import SharingDetailsContent from "./SharingDetailsContent";
import { useReactToPrint } from 'react-to-print';
import ProductSharingScroll from "./ProductSharingScroll";




const ProductSharingDetailsPage = () => {
    const { id } = useParams();
    const [details, setDetails] = useState({});
    const [tableList, setTableList] = useState([]);
    const myPromiseModal = createModal(GeneralModal);
    const componentRef = useRef();

    const logicContext = useContext(LogicContext);
    const productSharingAtom = GetProductSharingAtom();
    const setProductSharingAtom = SetProductSharingAtom();

    const navigate = useNavigate();
    const { pathname } = useLocation();
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];


    useEffect(() => {
        document.title = "Voucher Details Page";
        loadVoucherDetails(id);

        return () => {
            //code here
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);



    const loadVoucherDetails = (id) => {
        productSharingService.productSharingDetails(id).then(res => {
            if (res?.data?.product_sharing_info?.length) {
                setDetails(res.data.product_sharing_info[0]);
                setTableList(res.data.customer_info);

            }
        });
    }


    const createNewVoucher = () => {
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
    }


    const addNewRecord = ()=> {
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
      }


      const ViewCustomerDetails = (id)=> {
        navigate(`/${currModuleId}/${currModuleName}/customers/customer-details/${id}`);
      }

    const shareActionButtonAll = async (selectedIdList) => {
        if (selectedIdList.length < 1) {
            return logicContext.showToast(`No selection was made. Please select one or more vouchers`, "error");
        }
        // await myPromiseModal({
        //     title: "Confirm Share action?", body: `A total of  ${selectedIdList?.length} Voucher(s)  will be allocated`,
        //     showInput: false, placeHolder: '',
        // }).then((res) => {
        //     if (res.decision === true) {

        //         let xml_voucher = "";
        //         let temp = "";
        //         let cop_ref = '';

        //         let i = 0;
        //         let j = 0;
        //         let found = false;
        //         while (i < selectedIdList.length && j < getVoucherAtomData.newVoucherList.length) {
        //             let voucherId = selectedIdList[i];
        //             let voucher = getVoucherAtomData.newVoucherList[j];
        //             if (voucher?.Ref_No === voucherId) {
        //                 cop_ref = voucher?.Corporate_Ref_No;
        //                 found = true;
        //                 temp += `<a>
        //                 <voucher_no>${voucher.Voucher_Number} </voucher_no>
        //                 <name>${voucher?.Reciepient_Name ? voucher?.Reciepient_Name : ''} </name>
        //                 <mobile>${voucher?.Reciepient_Mobile_Number ? voucher?.Reciepient_Mobile_Number : ''} </mobile>
        //                 <email>${voucher?.Reciepient_Email_Address ? voucher?.Reciepient_Email_Address : ''} </email>
        //                  </a>`;
        //                 i += 1;
        //             }
        //             j += 1;
        //         }

        //         if (!found) {
        //             return logicContext.showToast(`Selected Voucher(s) does not exist in table List`, "");
        //         }

        //         xml_voucher = `<root> ${temp} </root>`;
        //         voucherService.shareVouchers({ ref_no: cop_ref, xml_voucher: xml_voucher.replace(/^\s+|\s+$/gm, '') }).then((res) => {

        //             voucherService.voucherDetails(cop_ref).then(res => {
        //                 loadVoucherDetails(id);
        //             });
        //         });
        //     }
        // }).catch((err) => {
        //     logicContext.showToast("error submitting record", "error");
        // });

    }

    const editCreditNoteOrder = async () => {
        const response = await myPromiseModal({
          title: "Modify Product Sharing",
          body: " Do you want to modify this record?",
          showInput: false,
          placeHolder: "",
        });
    
        // if (response.decision === true) {
        //   setCreditNoteEdit(creditNoteDetails);
        //   navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
        // }
      };


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
      });


    return ( 
        <>
               <section className="">
        <section className="flex flex-wrap justify-between">
          <div className="mt-3">
            {/* {selectedList.length > 0 && (
              <Dropdown>
                <Dropdown.Toggle
                  className="border-[1px] border-gray-200"
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: "#45444B",
                    fontSize: "12px",
                    height: "40px",
                  }}
                  id="dropdown-basic"
                >
                  Bulk Actions
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-32 px-2 text-sm shadow-lg">
                  {getBulkActions?.map((action, i) => {
                    return (
                      <Dropdown.Item
                        className="px-0 text-gray-600"
                        key={i}
                        onClick={() => bulkActions(action)}
                      >
                        {action.Action_Name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )} */}
          </div>

          <div className="flex flex-row flex-wrap items-center">
            <div className="mt-3 flex flex-row items-center mr-6">
          <button onClick={addNewRecord} className="btn btn-primary m-4" >Add New</button>

            {/* <CreditNoteOptions bulkActionData={getCreditNoteListAtom?.status_info}
            onFilterSelect={onStatusChange}
             /> */}

            </div>
            <div className="mt-3 flex fle-row items-center space-x-1 ">
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <ImAttachment className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <FaFilePdf className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
              </div>

              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <IoPrintSharp  onClick={handlePrint} className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <MdEdit onClick={editCreditNoteOrder} className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div>
              {/* <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                <MdDelete className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
              </div> */}
            </div>
          </div>
        </section>
        <section className="mt-4 lg:flex space-x-1">
          <div className="lg:w-[210px]">
            <ProductSharingScroll
            ></ProductSharingScroll>
          </div>
          <section className="w-full overflow-x-hidden mt-4 lg:mt-0 lg:pl-4 ">
          
          {/* Paste here */}
          
            <SharingDetailsContent ViewCustomerDetails={ViewCustomerDetails} details={details}
             ref={componentRef}  id={id} tableList={tableList} />
          {/* Paste end */}
          </section>
        </section>
      </section>
        

        </>
    );
};

export default ProductSharingDetailsPage;
