import { useEffect, useRef, useState } from "react";
import {GetPurchaseReceiveListData, SetPurchaseReceiveListData} from '../../store/purchaseReceiveAtom';
import { Grid } from "@mui/material";
import CurrencyFormat from "react-currency-format";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { useParams } from "react-router-dom";




function PurchaseReceiveScroll(props) {
const purchaseMasterData = GetPurchaseReceiveListData(); 
  const mountRef = useRef(true)
  const [tableData, setTableData] = useState([]);
  const { purchase_id } = useParams();


  useEffect(() => {
    mountRef.current = true;
    if(purchaseMasterData['Purchase_Receive_Info']?.length > 0){
      setTableData( purchaseMasterData['Purchase_Receive_Info'].map((ele) => {return { ...ele, checked: false } }));
    }

    return () => {
      mountRef.current = false;
    }
  }, [purchaseMasterData]);


  const onActionFilterPerform = (action)=> {
    const option = action.target.value;
    if(Number(option) === 0){
        return setTableData(purchaseMasterData['Purchase_Receive_Info']);
    }
    var newList = purchaseMasterData['Purchase_Receive_Info'].filter((item)=> item.Purchase_Receive_Status_Id === Number(option) );
    setTableData(newList);
  }



  const onCheckboxClick = async (e, index) => {
    let element = tableData[index];
    tableData[index] = { ...element, checked: e.target.checked };
    await setTableData((prev) => {
      return [...tableData];
    });
    props?.onSelection(tableData);
  };


  const managePurchaseStatus = (status) => {
    let defaultClass = "text-primary";

    switch (status) {
      case "Draft":
        defaultClass = "text-danger";
        break;
      case "Approved":
        defaultClass = "text-primary";
        break;
      case "Pending Approval":
        defaultClass = "text-warning";
        break;
      case "Billed":
        defaultClass = "text-info";
        break;
      case "Received":
        defaultClass = "text-success";
        break;
      case "Partial Received":
        defaultClass = "text-warning";
        break;

      default:
        break;
    }

    return (
      <span className={`text-sm font-bold md:text-sm ${defaultClass} `}>
        {status}
      </span>
    );
  };



  return (
    <>
     
     <section className=" w-full  z-0 mt-3 main3	 border-2 b">
  
        <ActionPaneComponent bulkActionData={purchaseMasterData['Filtering_Criteria']}
         onSelectBulkAction={onActionFilterPerform}/>

{
          tableData?.map((bill, index)=> {

            return (

      <div key={index}
      className={"flex hover:bg-filterColor border  " 
      +(Number(purchase_id) === bill?.Purchase_Receive_Ref_No ?"app_bg_color1 mb-2 cursor" : "mb-2 cursor") }
      >
      <Grid  container spacing={1}>
        {/* <Grid item xs={1}>
          <label className="ml-1">
            <input  onChange={(e)=> onCheckboxClick(e, index)} checked={bill?.checked ? bill?.checked : '' }  
             type="checkbox" className="w-[2vh] h-[2vh]" />
          </label>
        </Grid> */}
        <Grid onClick={() => props.viewDetails(bill?.Purchase_Receive_Ref_No )} 
         item xs={6}>
          <label className="ml-2 ">
            <span className="subheading font-bold md:text-sm">{bill?.Vendor_Name} </span>{" "}
            <br></br>
            <span className="subheading font-bold md:text-sm">
              <i>{bill?.Purchase_Receive_Ref_No} </i>
            </span>
            <br></br> 
            <CurrencyFormat value={parseFloat(bill.Bill_Amount)?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={'₦'} />
          </label>
        </Grid>
        <Grid item xs={5}>

          <div>    <label className="ml-2" >
                    { managePurchaseStatus(bill?.Purchase_Receive_Status_Name)}   </label></div>
    
        </Grid>
      </Grid>
    </div>

            )
          })

        }
          
          
  
      </section>
    </>
  );
}

export default PurchaseReceiveScroll;