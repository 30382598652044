import React, { useContext } from "react";

import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
// import "./CustomerDetails.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";


import { accountService } from "../../services/accountService";
import LogicContext from "../../Context/LogicContext";
import NotificationContext from "../../Context/NotificationContext";

import { Dropdown } from "react-bootstrap";
import Tooltip from '@mui/material/Tooltip';
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import ChartAccountScroll from "./ChartAccountScroll";
import CurrencyFormat from 'react-currency-format';
import ForeignCurrencyTable from "../../components/ForeignCurrencyTable";
import BaseCurrencyTable from "../../components/BaseCurrencyTable";
import ChartOfAccountCurrency from "./ChartOfAccountCurrency";
import { useSearchParams } from "react-router-dom";

import {SetChartList,SetChartEdit, GetChartList, SetChartTableFilter, GetChartTableFilter,
  SetChartTableHead, GetChartTableHead} from '../../store/chartOfAccountAtom';
import AddIcon from '@mui/icons-material/Add';


function ChartOfAccountDetailsPage() {


  const logicContext = useContext(LogicContext);
  const notificationContext = useContext(NotificationContext);
  const myPromiseModal = createModal(GeneralModal);
   const getChartList = GetChartList();
  const setEditDetails = SetChartEdit();
  const subChartList = SetChartList();
  const setChartTableHead = SetChartTableHead();
  const [searchParams] = useSearchParams();
  const needsReload = searchParams.get('reload');


  const [chartDetails, setChartDetails] = useState({});
  const [bcyList, setBcyList] = useState([]);
  const [showBaseCurr, setShowBaseCurr] = useState(true);
  const [defaultCurrencyId, setDefaultCurrencyId] = useState(0);
  const [selectedList, setSelectedList] = useState([]); 
  const setChartList = SetChartList();
  const setChartFilter = SetChartTableFilter();
  const setChartTablehead = SetChartTableHead();



  const { chart_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


  useEffect(()=> {
    getSubDetailFromServer(chart_id);
  },[chart_id]);


  useEffect(() => {
    if (sessionStorage.getItem('reloadPage') === 'true') {
      sessionStorage.removeItem('reloadPage');
      loadTableData();
    }
  
    if(getChartList?.length < 1){
      loadTableData();
    }
  }, []);


   const loadTableData = () => {
    accountService.getChartOfAccount().then(res => {
      if(res?.data !== undefined){
        setChartList(res.data['Chart of Accounts']);
        setChartFilter(res.data['Filtering Criteria']);
        setChartTablehead(res.data['Display Column']);

      }
  });
  
    }


  function viewItemDetails(id) {
    navigate( `/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}` );
    getSubDetailFromServer(id);
  }



  function onSelection(list){
    let tempList = []
    list.forEach((parentAcc)=> {
      if(parentAcc.checked === true) {
        tempList.push(parentAcc)
      };
      if(parentAcc?.children?.length){
        parentAcc?.children.forEach((child)=> {
          if(child.checked) tempList.push(child);
          if(child?.children?.length){
            child.children.forEach((grandChild)=> {
              if(grandChild.checked) tempList.push(grandChild);
            })
          }
        })
      }
    })
    setSelectedList(tempList)
  }


  function getSubDetailFromServer(id) {
    accountService.getChartOfAccountDetails(id).then(response => {
      if(response?.data !== undefined){
        setChartDetails(response.data['Chart of Accounts'][0]);
        setBcyList(response.data['BCY']);
      }
    });
  }


  const modifychartAccount = async () => {
    await myPromiseModal({ title: "Modify Account!", body: " Do you want to modify selected Account?",
    showInput:false, placeHolder:''}).then((res) => {
      if(res.decision === true){
        setEditDetails(chartDetails);
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)
      }
    });
  };


  const addNewchartAccount = ()=> {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)
  }


  function  onCurrencySelect(val){
    setDefaultCurrencyId(val)
  }


 async function  onChangeStatus(status){
    if(status === 'I'){
      let xml_string = `<root><a> <account_id> ${chart_id} </account_id> </a> </root>`;
      const option = 4;
      await myPromiseModal({ title: "Mark as InActive?",
      body: " Please confirm your action.",showInput:false, placeHolder:''
     }).then(result => {
      if (result.decision === true){
        accountService.updateAccountStatus({xml_string, option}).then(res => {
          if(res?.data !== undefined){
            const {message_text} = res.data[0]
            logicContext.showToast(message_text, 'success');
           return getSubDetailFromServer(chart_id);
          }
        }).catch((err)=> {
          logicContext.showToast('Error', 'error');
        });
      }
     });

    }else if(status === 'A'){
      let xml_string = `<root><a> <account_id> ${chart_id} </account_id> </a> </root>`;
      const option = 5;
      await myPromiseModal({ title: "Mark as InActive?",
      body: " Please confirm your action.",showInput:false, placeHolder:''
     }).then(result => {
      if (result.decision === true){
        accountService.updateAccountStatus({xml_string, option}).then(res => {
            if(res?.data !== undefined){
              const {message_text} = res.data[0]
              logicContext.showToast(message_text, 'success');
             return getSubDetailFromServer(chart_id);
            }
        }).catch((err)=> {
          logicContext.showToast('Error', 'error');
        });
      }
     });

    }else if(status === 'DELETE'){
      let xml_string = `<root><a> <account_id> ${chart_id} </account_id> </a> </root>`;
      const option = 3;
      await myPromiseModal({ title: `Do you want to delete  ${chartDetails.Account_Name}?`,
      body: " Deleted records cannot be recovered, do you want to continue?",
      showInput:false, placeHolder:''}).then(result => {
      if (result.decision === true){
        if(bcyList.length > 0){
          return logicContext.showToast('this account already have a dependable transaction!', 'error')
        }

        accountService.updateAccountStatus({xml_string, option}).then(res => {
            if(res?.data !== undefined){
              const {message_text} = res.data[0]
              logicContext.showToast(message_text, 'info');
              notificationContext.reloadChartList();
             return navigate(-1);
            }
        }).catch((err)=> {
          logicContext.showToast('Error', 'error');
        });
      }
     });
      
    }
  }


  async function bulkActions(message, option) {
    await myPromiseModal({
      title: message + '?',
      body: `Do you want to  ${message}?`,showInput:false, placeHolder:''
    }).then((result) => {
     
      if(result.decision === true){
        let temp = '';
        selectedList.map(item => {
          temp += `<a> <account_id> ${item.Account_Id} </account_id> </a>`
        });
  
        let xml_string = `<root> ${temp} </root>`;
        accountService.updateAccountStatus({xml_string, option}).then(res => {
          if(res?.data !== undefined){
            const {message_text} = res.data[0];
            logicContext.showToast(message_text, 'success');
           return  loadTableData();
        
          }
      }).catch((err)=> {
        logicContext.showToast('Error', 'error');
      });
      }
  
    });

  }




 
  return (
    <>
      <section className="   mt-2">

        <section className=" max-auto max-w-6xl ">
          <h1 className="font-bold h3">{chartDetails?.Account_Name}</h1>

              {/*  start */}
              <div className="row">
        <div className="col col-md-4">
          {(selectedList?.length > 0)   && 
             <Dropdown>
             <Dropdown.Toggle
               style={{
                 backgroundColor: "#E5E5E5",
                 border: "none",
                 color: "#45444B",
                 fontSize: "12px",
                 height: "45px",
               }}
               id="dropdown-basic"
             >
               Bulk Actions
             </Dropdown.Toggle>

             <Dropdown.Menu>
            
                   <Dropdown.Item  onClick={() => bulkActions('Mark selection(s) as Active', 4)}>
                     Mark as Active
                   </Dropdown.Item>
            
                   <Dropdown.Item  onClick={() => bulkActions('Mark selection(s) as InActive', 5)}>
                     Mark as InActive
                   </Dropdown.Item>
                   <Dropdown.Item  onClick={() => bulkActions('Delete selection(s) ', 3)}>
                     Delete
                   </Dropdown.Item>
                
             </Dropdown.Menu>
           </Dropdown>
          
          }
    
        </div>
      </div>
         {/* end */}

          <div className="flex   flex-wrap justify-end">

         
            <div className="flex">
              <div className="flex  justify-between">
                {(chartDetails?.Account_Status_Id === 'A') && 
                 <button onClick={()=>  onChangeStatus('A')}
                 style={{ borderRadius: "5px" }}
                 className=" mr-2  px-4 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1  "
               >
                 MARK AS INACTIVE
               </button>
                }

                {(chartDetails?.Account_Status_Id === 'I') && 
                 <button onClick={()=>  onChangeStatus('I')}
                 style={{ borderRadius: "5px" }}
                 className=" mr-2  px-4 h-[6vh] text-light bg-primary lg:pt-1  "
               >
                 MARK AS ACTIVE
               </button>
                }
               

                {/* <button
                  style={{ borderRadius: "5px" }}
                  className="  mx-2 px-4 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1  "
                >
                  <img src={paper} alt="" />
                </button>  */}
              
                <Tooltip title="add new account" >
                    <button onClick={addNewchartAccount}
                  style={{ borderRadius: "5px" }}
                  className="  mx-2 px-4 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1  "
                >
                  <AddIcon />
                </button>
                </Tooltip>

                <Tooltip title="delete account" >
                <button onClick={()=>  onChangeStatus('DELETE')}
                  style={{ borderRadius: "5px" }}
                  className="  mx-2  px-4 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1  "
                >
                  <img src={trash} alt="" />
                </button>
                </Tooltip>

                <button
                  style={{ borderRadius: "5px" }}
                  onClick={modifychartAccount}
                  className=" mx-2 px-4  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1  "
                >
                  <img src={pencil} alt="" />
                </button>
              </div>
            </div>
          </div>
        </section>

        <section className="flex relative  justify-between">
          <ChartAccountScroll
            viewItemDetails={viewItemDetails}
            onSelection={onSelection}
          ></ChartAccountScroll>

          <section className="w-full z-50 ">
            <section className="w-full mx-2 mt-2 flex justify-center  ">
              <section className=" w-full border-1 ">
                <div className="mt-10">
                  <div>
                    <span>Closing Balance</span>
                  </div>
                  <div className="my-3">
                    <span className="font-bold text-xl">
                  
                      <CurrencyFormat value={parseFloat(chartDetails?.Closing_Amount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} prefix={chartDetails?.Currency_Name_Short} />
                       </span>
                  </div>
                  <div>
                    {" "}
                    <span>
                      <span>Account Type :</span> 
                      {chartDetails?.Account_Type_Name}
                    </span>
                  </div>
                  <div>
                    {" "}
                    <span>
                      <span>Description :</span> 
                      {chartDetails?.Account_Description}
                    </span>
                  </div>
                </div>

                <div>
                    <span className="font-bold">Recent Transactions</span>
                  </div>

                <div className="flex mt-5  justify-between">
                
                <div className="ml-2 mt-1">
                {showBaseCurr && 
                  <span className="font-bold">Currency</span>
                }
                  
                  </div>
                {showBaseCurr && 
                <ChartOfAccountCurrency  onCurrencySelect={onCurrencySelect}  />
                  }
                  

                  <section className="flex">
                    <div 
                     className={"py-3 px-3 cursor" + (showBaseCurr ?
                       ' bg-primary text-white' : 'content_div accountBorder cursor')}
                    onClick={()=> setShowBaseCurr(true)}>
                      FCY
                    </div>

                    <div 
                     className={"py-3 px-3 cursor" + (!showBaseCurr ?
                      ' bg-primary text-white' : 'content_div accountBorder cursor')}
                    onClick={()=> setShowBaseCurr(false)} >
                      BCY
                    </div>
                  </section>

                </div>
                <div>
                  {showBaseCurr && 
                   <ForeignCurrencyTable  bcyList={bcyList} defaultCurrId={defaultCurrencyId}/>
                  }
                  {!showBaseCurr && 
                   <BaseCurrencyTable bcyList={bcyList}  />
                  }
               
                </div>

                <div><span className="mt-2" style={{color:"#476EF8"}}>See  more details</span></div>
              </section>
            </section>

        
          </section>
        </section>
      </section>
    </>
  );
}

export default ChartOfAccountDetailsPage;
