/* eslint-disable jsx-a11y/anchor-is-valid */
import {useLocation, useNavigate } from "react-router-dom";
// import CustomerTable from "./CustomerTable";


import { useCallback, useEffect } from "react";

import underscore from "underscore";
import CsvDownload from 'react-json-to-csv';
import RestartAlt from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import NotificationTable from "./NotificationTable";
import {notificationService} from "../../services/notification.service";
import { GetRecoilNotify, SetRecoilNotify} from "../../store/notificationMainAtom";




function NotificationSendPage() {
  const { pathname } = useLocation();  
  const setNotifyList = SetRecoilNotify();
  const getNotifyList = GetRecoilNotify();
  const navigate = useNavigate();
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


  useEffect(()=> {
    
if(!getNotifyList.notification?.length){
  notificationService.getNotifyList().then(res => {
    console.log('Noti ', res.data);
    if(res.data?.notification?.length){
      setNotifyList({notification: res?.data?.notification || [],
         notification_template: res.data.notification_template || [],
         notification_type: res.data?.notification_type || [], 
         notification_filter: res?.data?.notification_filter || [] });
    }
  });
}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[getNotifyList]);


  function sendNewNotification(){
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)
  }
  

  return (

      <>
        <Grid container >
          <Grid item xs={12}>
            <section className="w-full justify-end">
            <div className="flex  justify-end">
              <div className="flex ">
                <button
                  style={{ color: "#476ef8" }}
                  className="  rounded  text-md font-[Poppins] py-2 mt-1  px-3"
                >
                  Import
                </button>

                <button
                  style={{ color: "#476ef8" }}
                  className=" mx-2 rounded  text-md font-[Poppins] mt-1 py-[1.8vh]  px-3"
                >
                  Export
                </button>
              </div>

             
                <button
                  style={{ backgroundColor: "#476EF8" }}
                  onClick={()=> sendNewNotification()}
                  className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                >
                  SEND NEW
                </button>
           
            </div>
            </section>
            
          </Grid>
        </Grid>
    

      <section className="mt-5   w-full">
       <NotificationTable list={getNotifyList.notification} ></NotificationTable>
      </section>
    </>
   
  );
}

export default NotificationSendPage;
