import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";



export const productSharingAtom = atom({key:'product_sharing_atom',
    default:{product_sharing_group:[], product_sharing_info :[]}});

export  const SetProductSharingAtom = () => useSetRecoilState(productSharingAtom);
export  const GetProductSharingAtom = () => useRecoilValue(productSharingAtom);





