import * as React from "react";
import { useEffect, useState } from "react";

import {
  DataGrid,
  gridPageCountSelector,
  GridToolbar,
  gridPageSelector,
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

import { customerService } from "../../services/customer.service";
import {
  GetCustomerVoucherAtom,
  SetCustomerVoucherAtom,
} from "../../store/CustomerVoucherAtom";
import { formatCustomDate } from "../../helpers/formatCustomDate";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";



const tableHead = [
  {
    field: "Ref_No",
    headerName: "Ref",
    width: 130,
    className: "font-weight-bold",
  },
  { field: "Transaction_Date", headerName: "Date", width: 160 },
  { field: "Voucher_Number", headerName: "Voucher No", width: 150 },
  { field: "Voucher_Amount", headerName: "Amount (₦)", width: 130 },
  { field: "Reciepient_Name", headerName: "Recipient Name", width: 150 },
  {
    field: "Reciepient_Mobile_Number",
    headerName: "Recipient Mobile",
    width: 160,
  },
  { field: "Voucher_Status_Name", headerName: "Status", width: 130 },
];

export default function VoucherTab({ customer_id }) {
  const [tableList, setTableList] = useState([]);
  const setVoucherList = SetCustomerVoucherAtom();
  const voucherList = GetCustomerVoucherAtom();





  useEffect(()=> {
    getDataFromServer();

  },[customer_id]);

  const getDataFromServer = () => {
    customerService.getCustomerVouchers(customer_id).then((res) => {
      if (res.data?.length) {
        let tempList = res.data.map((item) => {
          return { ...item, id: item?.Ref_No, Transaction_Date: formatCustomDate(item?.Transaction_Date), 
             Voucher_Amount: formatCustomNumber(item?.Voucher_Amount)} }
        );
        setVoucherList(tempList);
        setTableList(tempList);
      }else{
        setVoucherList([]);
        setTableList([]);
      }
    });
  };

  // function OnItemCellClick(event) {
  //   const id = event.row['Invoice_Ref_No'];
  //   navigate(`/${currModuleId}/${currModuleName}/invoices/details/${id}`);
  // }

  return (
    <>
      <div className="ml-1 @container">
        <div className="mt-1 text-gray-600 text-xl font-bold">Voucher List</div>

        <DataGrid
          className="mt-4 overflow-x-auto bg-white outline-none border-[1px] border-gray-200 rounded-xl"
          style={{ height: 600, width: "100%" }}
          columns={tableHead}
          rows={tableList}
          // onRowClick={(event) => {
          //   OnItemCellClick(event);
          // }}

          // components={{
          //   Pagination: CustomPagination,
          // }}
          onCellClick={(event) => {
            event.defaultMuiPrevented = true;
          }}
          // sx={{
          //   borderColor: "primary.light",
          //   "& .MuiDataGrid-cell:hover": {
          //     color: "primary.main",
          //     cursor: "pointer",
          //   },
          // }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{ toolbar: { showQuickFilter: true } }}
        />
      </div>
    </>
  );
}
