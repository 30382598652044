import React, { useRef } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
// import "./CustomerDetails.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useContext } from "react";
// import "./CustomerDetails.css";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { accountService } from "../../services/accountService";
import { Grid } from "@mui/material";
import Item from "antd/lib/list/Item";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { GetPurchaseOrderMasterData, SetPurchaseOrderMasterData, SetPurChaseOrderEdit } from "../../store/purchaseOrderAtom";
import { SetAddPurchaseReceive } from "../../store/purchaseReceiveAtom";
import LogicContext from "../../Context/LogicContext";
import PurchaseScroll from "./PurchaseScroll";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PurchaseOrderBill from "./PurchaseOrderBill";
import PurchaseOrderReceiveTab from "./PurchaseOrderReceiveTab";


import PurchasePendingTab from "./PurchasePendingTab";
import PurchaseOrderHistory from "./PurchaseOrderHistory";
import PurchaseDetailsTable from "./PurchaseDetailsTable";
import DocumentViewer from "../../helpers/DocumentViewer";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";

const PurchaseOrderDetailsPage = () => {
  const [tabValue, setTabValue] = React.useState('1');
  const [selectedList, setSelectedList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);
  const purchaseMasterData = GetPurchaseOrderMasterData();
  const setPurchaseEdit = SetPurChaseOrderEdit();
  const setMasterData = SetPurchaseOrderMasterData();
  const setPendingReceive = SetAddPurchaseReceive();

  const { purchase_id } = useParams();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const [purchaseData, setPurchaseData] = useState({});
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const mountRef = useRef(false);



  useEffect(() => {
    document.title = 'Purchase Order Details';
    mountRef.current = true;
    accountService.getPurchaseDetails(purchase_id).then(response => {
      if (mountRef.current && response.data !== undefined) {
        if (response?.data) {
          setPurchaseData(response.data)
        setMasterData((prev)=> {
         let newList =  prev['Purchase_Order_Info']?.map((item, index)=> {
            if (item?.Purchase_Order_Ref_No === response.data.Purchase_Order_Ref_No){
              return response.data
            }else{
              return item
            }
          })
          return ({...prev, Purchase_Order_Info: newList })
        });

        } else {
          setPurchaseData({})
        }

      }
    })

    return () => {
      mountRef.current = false;

    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchase_id]);

  function reloadPurchaseOrder(){
    accountService.getPurchaseDetails(purchase_id).then(response => {
      if (mountRef.current && response.data !== undefined) {
        if (response?.data) {
          setPurchaseData(response.data)
          setMasterData((prev)=> {
            let newList =  prev['Purchase_Order_Info'].map((item, index)=> {
               if (item?.Purchase_Order_Ref_No === response.data.Purchase_Order_Ref_No){
                 return response.data
               }else{
                 return item
               }
             })
             return ({...prev, Purchase_Order_Info: newList })
           });

        } else {
          setPurchaseData({})
        }

      }
    })
  }


  const reloadGeneralList = async () => {
    accountService.getPurchaseOrderList().then(response => {
      if (response?.data !== undefined) {
        setMasterData(response.data);
      }
    });
  }


  const editPurchaseOrder = async () => {
    const response = await myPromiseModal({
      title: "Modify Purchase Order", body: " Do you want to modify this record?"
   ,showInput:false, placeHolder:'' });
    if (response.decision === true) {
      setPurchaseEdit(purchaseData);
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
    }
  };


  const viewDetails = (id) => {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`)
  }


  function onSelection(list) {
    let tempList = list?.filter(item => item.checked === true);
    setSelectedList(tempList)
  }


  const deletePurchaseOrder = async () => {
    await myPromiseModal({
      title: "confirm your Delete!",
      body: `Do you want to delete ${purchaseData?.Purchase_Order_Number} ?`,
      showInput:false, placeHolder:''}).then((response) => {
      if (response.decision === true) {
        let option = 8;
        let temp = `<a> <refno> ${purchaseData?.Purchase_Order_Ref_No} </refno> </a>`;
        let xml_string_refno = `<root> ${temp} </root>`;
        accountService.setPurchaseBulkAction({ xml_string_refno, option }).then(res => {
          if (res?.data !== undefined) {
            const { message_text } = res.data[0];
            logicContext.showToast(message_text, 'success');
            navigate(-1)
            reloadGeneralList();
          }
        }).catch((err) => {
          console.log('EERR ', err)
          // logicContext.showToast('Error', 'error');
        });
      }
    });
  };


  async function bulkActions(action) {
    await myPromiseModal({
      title: 'confirm your action!',
      body: `Do you want to ${action.Action_Name} ?`,showInput:false, placeHolder:''
    }).then((result) => {

      if (result.decision === true) {
        let temp = '';
        const option = action.Procedure_Option;
        selectedList.map(item => {
          temp += `<a> <refno> ${item.Purchase_Order_Ref_No} </refno> </a>`
        });

        let xml_string_refno = `<root> ${temp} </root>`;
        accountService.setPurchaseBulkAction({ xml_string_refno, option }).then(res => {
          if (res?.data !== undefined) {
            const { message_text } = res.data[0];
            logicContext.showToast(message_text, 'success');
            if(option === 8){
               return reloadGeneralList();
            }else{
              return reloadPurchaseOrder();
            }
          }
        }).catch((err) => {
          logicContext.showToast('Error', 'error');
        });
      }

    });
  }


  async function singleActionPerform(action) {
    await myPromiseModal({ title: 'confirm your action!', body: `Do you want to ${action.Action_Name?.replace('Bulk','')} ?`,
    showInput:false, placeHolder:''}).then((result) => {

      if (result.decision === true) {
        const option = action.Procedure_Option;
        let xml_string_refno = `<root> <a> <refno> ${purchase_id} </refno> </a> </root>`;
        accountService.setPurchaseBulkAction({ xml_string_refno, option }).then(res => {
          if (res?.data !== undefined) {
            const { message_text } = res.data[0];
            logicContext.showToast(message_text, 'success');
            if(option === 8){
              return reloadGeneralList();
           }else{
             return reloadPurchaseOrder();
           }
          }
        }).catch((err) => {
          logicContext.showToast('Error', 'error');
        });
      }

    });
  }


  async function customActionsSingle(actionNumber) {
   var  action = {}
    if (actionNumber === 1){
      action.Action_Name = 'Approved';
      action.Action_Id = 9;
      action.Procedure_Option = 11;

    } 
    if (actionNumber === 2){
      action.Action_Name = 'Submit for Approval';
      action.Action_Id = 1;
      action.Procedure_Option = 4;

    } 
    if (actionNumber === 3){
      action.Action_Name = 'Convert to Bill';
      action.Action_Id = 6;
      action.Procedure_Option = 9;

    } 
    
    if (actionNumber === 4){
      action.Action_Name = 'Mark as Issued';
      action.Action_Id = 4;
      action.Procedure_Option = 5;

    } 
    
    await myPromiseModal({ title: 'confirm your action!', body: `Do you want to ${action.Action_Name} ?`,
    showInput:false, placeHolder:''}).then((result) => {

      if (result.decision === true) {
        const option = action.Procedure_Option;
        let xml_string_refno = `<root> <a> <refno> ${purchase_id} </refno> </a> </root>`;
        accountService.setPurchaseBulkAction({ xml_string_refno, option }).then(res => {
          if (res?.data !== undefined) {
            const { message_text } = res.data[0];
            logicContext.showToast(message_text, 'success');
            return reloadPurchaseOrder();
          }
        }).catch((err) => {
          logicContext.showToast('Error', 'error');
        });
      }

    });
  }


  const calculateSubTotal = (list) => {
    let result = 0
    list?.forEach(element => {
      result += element.Purchase_Amount
    });
    return result;

  }


  const calculateTotalQuantity = (list) => {
    let result = 0;
    let unitName = '';
    list?.forEach(element => {
      result += element.Item_Quantity
      unitName = element.Item_Unit_Name
    });
    return result + ` ${unitName}`

  }


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const customTabChange = (id) => {
    setTabValue(id);
  }


  const calculateTotal = (list) => {
    let result = 0;
    list?.forEach((element) => {
      result += element.Item_Quantity * element.Item_Rate;
    });
    let discountValue = purchaseData?.Discount_Value;
    let discountType = purchaseData?.Discount_Type_Id;
    if (discountValue && (discountType === 1 || discountType === 0)) {

      result = result - discountValue;
    }
    if (discountValue && discountType === 2) {

      let discount = (discountValue / 100) * result;
      result = Number(result) - Number(discount);
    }
    return result?.toFixed(2);
  };

  function viewVendor(vendor_id) {
    navigate(`/${currModuleId}/${currModuleName}/${"vendors"}/details/${vendor_id}`
    );
  }


  function viewBillDetails(bill_id) {
    navigate(`/${currModuleId}/${currModuleName}/${"bills"}/details/${bill_id}`
    );
  }


  const manageInvoiceStatus = (status) => {
    let defaultClass = 'bg-primary text-white';

    switch (status) {
      case 'Draft':
        defaultClass = 'text-light bg-danger';
        break;
      case 'Approved':
        defaultClass = 'text-light bg-primary';
        break;
      case 'Pending Approval':
        defaultClass = 'text-dark bg-warning';
        break;
      case 'Billed':
        defaultClass = 'text-light bg-info';
        break;
      case 'Issued':
        defaultClass = 'text-light bg-success';
        break;
      case 'Partially Billed':
        defaultClass = 'text-light bg-dark';
        break;

      default:
        break;
    }


    return (
      <span className={`py-2 pl-4 pr-4 pt-2 pb-2 h5 ${defaultClass} rounded`}>
        {status}
      </span>
    )

  }


  const magicButtons = (status) => {
    switch (status) {
      case 'Draft':
        return (
          <button onClick={()=> customActionsSingle(2)} style={{ borderRadius: "5px" }}
          className=" px-3 app_blue_color text-white h-[6vh] text-uppercase  subheading mr-3" >
         submit for approval
        </button>
        )
     

      case 'Partially Received':
        return (
          <button onClick={()=> markReceiveHandler(purchaseData?.Pending_Receive, 1)} style={{ borderRadius: "5px"
          ,  backgroundColor: "#476EF8", }}
          className=" px-3 app_blue_color text-white h-[6vh] text-uppercase subheading  mr-3"  >
         Create Transit
        </button>
        )
     

      case 'Pending Approval':
        return (
          <button onClick={()=> customActionsSingle(1)} style={{ borderRadius: "5px"
          ,  backgroundColor: "#476EF8", }}
          className=" px-3 app_blue_color text-white h-[6vh] text-uppercase  subheading mr-3" >
         APPROVE
        </button>
        )
     
     

      case 'Approved':
        return (
          <button onClick={()=> customActionsSingle(3)} style={{ borderRadius: "5px" }}
          className=" px-3  h-[6vh] text-uppercase app_blue_color text-white  subheading  mr-3">
         Convert to Bill
        </button>
        )

      case 'Received & Not Billed':
        return (
          <button onClick={()=> customActionsSingle(3)} style={{ borderRadius: "5px" }}
          className=" px-3  app_blue_color text-white h-[6vh] text-uppercase  subheading  mr-3">
         Convert to Bill
        </button>
        )

      case 'Billed':
        return (
          <button onClick={()=> markReceiveHandler(purchaseData?.Pending_Receive, 2)} 
          className="btn btn-primary subheading h-[6vh] mr-3"
            style={{   backgroundColor: "#476EF8" }}>  RECEIVE </button>
        )

      case 'Issued':
        return (
          <button onClick={()=> markReceiveHandler(purchaseData?.Pending_Receive, 2)} 
          className="btn btn-primary subheading h-[6vh] mr-3"
            style={{   backgroundColor: "#476EF8" }}>  RECEIVE </button>
        )
     
      default:
        return null
    }

  }


  const markReceiveHandler  = (list, option)=> {
    try {
      let newList = []
      list.forEach((element, i)=> {
      if(element.Remaining_Quantity > 0){
        newList.push(element)
      }
      });
      if(newList.length > 0){
        setPendingReceive({Purchase_Order_Ref_No:purchaseData?.Purchase_Order_Ref_No ,list:newList})
        navigate(`/${currModuleId}/${currModuleName}/${"purchase-receives"}/add-new?option=${option}`);
  
      }else{
        logicContext.showToast('No item(s) to receive!', 'info')
      }
    } catch (error) {
      logicContext.showToast('Cannot process receive items', 'info')
    }
  }



  return (
    <>
      <section className="  mt-2 ">
        <Grid container className="" spacing={2}>
          <Grid item xs={12}>
            <section className="max-auto max-w-6xl ">
              <div className="flex flex-wrap justify-between md: md:">
                <div>
                  {selectedList.length > 0 && (
                    <div className="row">
                      <div className="col col-md-4">
                        <Dropdown>
                          <Dropdown.Toggle
                            style={{
                              backgroundColor: "#E5E5E5",
                              border: "none",
                              color: "#45444B",
                              fontSize: "12px",
                              height: "45px",
                            }}
                            id="dropdown-basic" >
                            Bulk Actions
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {purchaseMasterData['Bulk_Action']?.map((action, i) => {
                              return (
                                <Dropdown.Item key={i} onClick={() => bulkActions(action)}>
                                  {action.Action_Name}
                                </Dropdown.Item>
                              )

                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  )}
                </div>

                {/*  old plave */}
              </div>
            </section>
          </Grid>

          <Grid item xs={12}>
            <section className="">
              <Grid container className="" spacing={0}>
                <Grid className="" item xs={2.8}>
                  <PurchaseScroll viewDetails={viewDetails} onSelection={onSelection} />
                </Grid>
                <Grid item xs={9.2}>
                  <section className="w-full b z-50 ">
                    <div className="flex   justify-end">

                   {magicButtons(purchaseData?.Purchase_Order_Status_Name)}
                   
                      <Dropdown  className=" mr-3"style={{ width: ""}} >
                        <Dropdown.Toggle
                          className="h-[6vh] subheading btn btn-primary " id="dropdown-basic"
                          style={{  backgroundColor: "#476EF8" }}>
                          ACTION
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {purchaseMasterData['Bulk_Action']?.map((option, i) => {
                            return (
                              <Dropdown.Item className="border-1" key={i}
                                onClick={() => singleActionPerform(option)} style={{ fontSize: "15px" }}>
                                <div className="font-bold subheading "> {option.Action_Name.replace('Bulk','')}</div>
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>

                      <div className="flex  ">
                        {purchaseData?.Purchase_Order_Status_Name === 'Approved'  && 
                             <button onClick={()=> customActionsSingle(4)} style={{ borderRadius: "5px" }}
                             className=" px-3  bg-warning  h-[6vh] text-uppercase  subheading  mr-3">
                            Mark as Issued
                           </button>
                        
                        }
                        
                        <button
                          style={{ borderRadius: "5px" }}
                          className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3">
                          <img src={paper} alt="" className="" />
                        </button>
                        <button
                          style={{ borderRadius: "5px" }}
                          className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                        >
                          <img src={pdf} alt="" className="" />
                        </button>
                        <button
                          style={{ borderRadius: "5px" }}
                          className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                        >
                          <img src={printer} alt="" className="" />
                        </button>

                        <button
                          style={{ borderRadius: "5px" }} onClick={editPurchaseOrder}
                          className="px-3 mr-2 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ">
                          <img src={pencil} alt="" />
                        </button>

                        <button
                          onClick={deletePurchaseOrder} style={{ borderRadius: "5px" }}
                          className="  px-3 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ml-2">
                          <img src={trash} alt="" />
                        </button>
                      </div>

                    </div>

                    <section className="border-2 mx-2   mt-3">
                      {/* start tab */}
                      <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={tabValue}>
                          <Box className=" ml-4 mt-2" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                              <Tab label="Details" value="1" />
                              {purchaseData?.Count_Information?.length > 0 &&
                                <Tab label={`History (` + purchaseData?.Count_Information[0].History_Count + ')'} value="2" />
                              }

                              {purchaseData?.Count_Information?.length > 0 &&
                                <Tab label={`Bills (` + purchaseData?.Count_Information[0].Bill_Count + ')'} value="3" />
                              }
                             

                              {purchaseData?.Received?.length > 0 &&
                                <Tab label={`Receives (` + purchaseData?.Received.length + ')'} value="4" />
                              }

                              {purchaseData?.Pending_Receive?.length > 0 &&
                                <Tab label={`Receive Pending (` + purchaseData?.Pending_Receive.length + ')'} value="5" />
                              }
                            </TabList>
                          </Box>
                          <TabPanel value="1">

                            <section className="mt-2  text-filterTextColor">
                              <Grid className="mt-2   " container spacing={2}>
                                <Grid className=" border-2 ml-3" container spacing={2}>
                                  <Grid item xs={6}>

                                    <div>
                                      <div>
                                        <span className="#476ef8 font-bold ">
                                          {purchaseData?.Vendor_Name}
                                        </span>
                                        <br></br>
                                      
                                          <i className="subheading font-bold" >Purchase Order Number:
                                            <i className="ml-2 text-primary subheading font-bold" >{purchaseData?.Purchase_Order_Number}</i>  </i>
                                       
                                        <br></br>
                                        
                                          <i className="subheading font-bold " >Reference Number:
                                            <i className="ml-2 text-primary subheading font-bold" >{purchaseData?.Purchase_Order_Ref_No}</i>  </i>
                                       
                                        <br></br>
                                      </div>
                                    </div>

                                  </Grid>
                                  <Grid item xs={6}>

                                    <div className="flex justify-end pr-2">
                                      {manageInvoiceStatus(purchaseData?.Purchase_Order_Status_Name)}

                                    </div>

                                  </Grid>

                                  <Grid item xs={6}>
                                    <div>
                                      <div className="flex justify-between ">
                                        <div>
                                          <span className="subheading">Vendor Address:</span>
                                        </div>
                                        <div className="w-50">
                                          <span style={{ color: "#4999F6" }}>
                                            <p className="text-primary subheading" > {purchaseData?.Vendor_Address}</p>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="flex  justify-between">
                                        <div>
                                          <span className="subheading">Vendor Name:</span>
                                        </div>
                                        <div className=" w-50">
                                          <span onClick={() => viewVendor(purchaseData?.Vendor_Id)} style={{ color: "#4999F6" }}>
                                            <p className="text-primary subheading" > {purchaseData?.Vendor_Name}</p>
                                          </span>
                                        </div>
                                      </div>
                                      <div className="flex  justify-between">
                                        <div>
                                          <span className="subheading">Vendor Phone Number:</span>
                                        </div>
                                        <div className=" w-50">
                                          <p className="text-primary subheading"> {purchaseData?.Vendor_Mobile_Number}</p>
                                        </div>
                                      </div>

                                      <div className="flex  justify-between">
                                        <div>
                                          <span className="subheading">Order Dates:</span>
                                        </div>
                                        <div className=" w-50">
                                          <span className=" subheading" >{purchaseData?.Purchase_Order_Date_String} </span>
                                        </div>
                                      </div>

                                      <div className="flex  justify-between">

                                        <div>
                                          <span className="subheading ">
                                            Delivery Address:
                                          </span>
                                        </div>


                                        <div className=" w-50">
                                          <span className=" subheading" >   {purchaseData?.Delivery_To_Address} </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>

                              </Grid>


                            </section>

                          </TabPanel>
                          <TabPanel value="2">
                            <PurchaseOrderHistory />

                          </TabPanel>

                          <TabPanel value="3">

                            <PurchaseOrderBill reloadPurchaseOrder={reloadPurchaseOrder}
                             viewBillDetails={viewBillDetails} customTabChange={customTabChange} />

                          </TabPanel>

                          <TabPanel value="4">

                            <PurchaseOrderReceiveTab reloadPurchaseOrder={reloadPurchaseOrder}
                             viewVendor={viewVendor} customTabChange={customTabChange} tableData={purchaseData?.Received} />
                          </TabPanel>

                          <TabPanel value="5">
                             <PurchasePendingTab markAsReceive={markReceiveHandler} tableData={purchaseData?.Pending_Receive}  />
                          </TabPanel>
                        </TabContext>
                      </Box>
                      {/* end tab */}
                    </section>

                    <Grid item xs={12} className=" border-2  mx-2 mt-2 mr-[5vh] p-3  ">
                      <PurchaseDetailsTable tableData={purchaseData?.Purchase_Order_details} ></PurchaseDetailsTable>

                      <div className="my-2 border-2  py-4 ">
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={4}></Grid>

                            <Grid item xs={4}>
                              <div>
                                <div>
                                  <span className="#476ef8 font-bold">
                                    Sub Total:

                                  </span>{" "}
                                  <br></br>

                                  <span className="text-sm">Total Quantity</span>{" "}
                                  <br></br>
                                  <span className="text-sm font-bold">Discount</span>{" "}
                                  <br></br>
                                  <span className="text-sm font-bold">Adjustment</span>{" "}
                                  <br></br>
                                  <span className="text-2xl font-bold">Total</span>{" "}
                                  <br></br>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div>
                                <div>
                                  <span className="#476ef8 font-bold ">
                                    <CurrencyFormat
                                      value={parseFloat(calculateSubTotal(purchaseData?.Purchase_Order_details))?.toFixed(2)}
                                      displayType={'text'} thousandSeparator={true} prefix={'₦'} />

                                    { }{" "}
                                  </span>
                                  <br></br>
                                  <span className="text-sm">
                                    {calculateTotalQuantity(purchaseData?.Purchase_Order_details)}

                                    { }{" "}
                                  </span>
                                  <br></br>
                                  <span className="text-sm">
                                    {purchaseData?.Discount_Type_Id === 1 &&
                                      <CurrencyFormat value={formatCustomNumber(purchaseData?.Discount_Value)}
                                        displayType={"text"}
                                        thousandSeparator={true} prefix={"₦"} />
                                    }

                                    {purchaseData?.Discount_Type_Id === 2 &&
                                      <CurrencyFormat value={formatCustomNumber(purchaseData?.Discount_Value)}
                                        displayType={"text"}
                                        thousandSeparator={true} prefix={"%"} />
                                    }

                                  </span>
                                  <br></br>
                                  <span className="text-sm">
                                    <CurrencyFormat
                                      value={formatCustomNumber(purchaseData?.Adjustment_Amount)}
                                      displayType={'text'} thousandSeparator={true} prefix={'₦'} />

                                    { }{" "}
                                  </span>
                                  <br></br>
                                  <span className="text-2xl font-bold">
                                    <CurrencyFormat
                                      value={parseFloat(calculateTotal(purchaseData?.Purchase_Order_details))?.toFixed(2)}
                                      displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                                    { }{" "}
                                  </span>
                                </div>
                              </div>

                            </Grid>
                          </Grid>
                        </Grid>
                      </div>

                    </Grid>
                    <DocumentViewer fileList={purchaseData?.Attachments} />

                  </section>
                </Grid>
              </Grid>

              {/* w-[56.3vw] */}



            </section>
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default PurchaseOrderDetailsPage;
