import React, { useRef } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
// import "./CustomerDetails.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
// import "./CustomerDetails.css";
import { purchaseService } from "../../services/purchase.service";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { SetCustomReceive, SetPurchaseReceiveListData} from "../../store/purchaseReceiveAtom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import HistoryContent from "./PurchaseReceiveHistory";
import { useReactToPrint } from 'react-to-print';


import { PurchaseReceiveWrapperClass } from "./PurchaseReceiveWrapperClass";
import LogicContext from "../../Context/LogicContext";
import { useContext } from "react";
import PurchaseReceiveScroll from "./PurchaseReceiveScroll";


const PurchaseReceiveDetailsPage = () => {
  const [tabValue, setTabValue] = React.useState('1');
  const [selectedList, setSelectedList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);
  const setCustomReceive = SetCustomReceive();
const setPurchaseReceiveData = SetPurchaseReceiveListData();

  const logicContext = useContext(LogicContext);  



  const { purchase_id } = useParams();
  const navigate = useNavigate();
  const [purchaseData, setPurchaseData] = useState({});
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const mountRef = useRef(false);
  const componentRef = useRef();




  useEffect(() => {
    document.title = 'Purchase Receive Details';
    mountRef.current = true;
    purchaseService.getPurchaseReceiveDetails(purchase_id).then(response => {
      if(mountRef.current && response.data !== undefined){
        if(response?.data){
        setPurchaseData(response.data)
        }else{
          setPurchaseData({})
        }

      }
    })
    
    return () => {
      mountRef.current = false;
     
    };
  }, [purchase_id]);
 

  const editPurchaseOrder = async () => {
    const response = await myPromiseModal({
      title: "Modify Purchase Receive",body: " Do you want to modify this record?", showInput:false, placeHolder:''});
    if (response.decision === true) {
      setCustomReceive(purchaseData)
      navigate(`/${currModuleId}/${currModuleName}/${"purchase-receives"}/add-new?purchase_order_ref=${purchaseData?.Purchase_Order_Ref_No}`);

    }
  };


  const viewDetails = (id)=> {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`)
  }


  function onSelection(list){
    let tempList = list?.filter(item => item.checked === true);
    setSelectedList(tempList)
  }


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });



function getPurchaseReceiveListApi(){
  purchaseService.getPurchaseReceiveList().then( response => {
      if(response?.data !== undefined){
        setPurchaseReceiveData(response.data);
        }

        });
  

}

  const deletePurchaseReceive = async () => {
    await myPromiseModal({
      title: "confirm your Delete!",
      body: `Do you want to delete ${purchaseData?.Purchase_Receive_Number} ?`,
      showInput:false, placeHolder:''}).then((response) => {
      if (response.decision === true) {
        let temp = '';
        purchaseData.Purchase_Receive_details?.map((item, i)=> {
           temp += `<a> <item_id> ${item?.Item_Id} </item_id> </a>`;
        });
        
        let option = 4;
        let xml_string_refno = `<root> ${temp} </root>`;
        purchaseService.submitPurchaseReceive(
          {Purchase_Receive_Ref_No:purchaseData?.Purchase_Receive_Ref_No,
            Purchase_Order_Ref_No: purchaseData?.Purchase_Order_Ref_No ,xml_string_refno, option}).then(res => {
          if(res?.data !== undefined){
            const {message_text} = res.data[0];
            logicContext.showToast(message_text, 'success');
            navigate(-1)
           return  getPurchaseReceiveListApi();
          }
      }).catch((err)=> {
        logicContext.showToast('Error', 'error');
      });
      }
    });
  };




     const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };


    const customTabChange = (id)=> {
      setTabValue(id);
    }



     function viewBillDetails(bill_id) {
      navigate(`/${currModuleId}/${currModuleName}/${"bills"}/details/${bill_id}`
      ); 
    }



  return (
      <>
   
     <section className="  mt-2 ">
          <Grid container className="" spacing={2}>
            <Grid item xs={12}>
              <section className="max-auto max-w-6xl ">
                <div className="flex flex-wrap justify-between md: md:">
            
                </div>
              </section>
            </Grid>

            <Grid item xs={12}>
              <section className=" ">
              <Grid container className="" spacing={0}>
                <Grid className="" item xs={2.8}>
                <PurchaseReceiveScroll viewDetails={viewDetails} onSelection={onSelection} />
                  </Grid>
                  <Grid item xs={9.2}>
                  <section className="w-full b z-50 ">
                <div className="flex   justify-end">
                    {/* <Dropdown
                      className=" mr-3"
                      style={{ width: "", marginTop: "2px" }}
                    >
                      <Dropdown.Toggle
                        className="h-[5.8vh]"
                        id="dropdown-basic"
                        style={{
                          backgroundColor: "#476EF8",
                          fontSize: "10px",
                          height: "100%",
                        }}
                      >
                        ACTION
                      </Dropdown.Toggle>
                      
                      <Dropdown.Menu>
                        {purchaseMasterData['Bulk_Action']?.map((option,i)=> {
                          return(
                            <Dropdown.Item className="border-1" key={i} 
                            onClick={() => bulkActionsSingle(option)} style={{ fontSize: "15px" }}>
                           <div className="font-bold subheading "> {option.Action_Name}</div>
                          </Dropdown.Item>
                          )

                        })}
                       

                      </Dropdown.Menu>
                    </Dropdown> */}

                    <div className="flex ">
                      <button
                        style={{ borderRadius: "5px" }}
                        className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                      >
                        <img src={paper} alt="" className="" />
                      </button>
                      <button
                      onClick={()=> handlePrint()}
                        style={{ borderRadius: "5px" }}
                        className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                      >
                        <img src={pdf} alt="" className="" />
                      </button>
                      <button
                      onClick={()=> handlePrint()}
                        style={{ borderRadius: "5px" }}
                        className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
                      >
                        <img src={printer} alt="" className="" />
                      </button>

                      <button
                        style={{ borderRadius: "5px" }} onClick={editPurchaseOrder}
                        className="px-3 mr-2 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ">
                        <img src={pencil} alt="" />
                      </button>

                      <button
                        onClick={deletePurchaseReceive} style={{ borderRadius: "5px" }}
                        className="  px-3 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 ml-2">
                        <img src={trash} alt="" />
                      </button>
                    </div>

                  </div>

                   <section className="border-2 mx-2   mt-3">
                        {/* start tab */}
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tabValue}>
                      <Box className=" ml-4 mt-2"  sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                          <Tab label="Details" value="1"/>
                        
                    {purchaseData?.Count_Information?.length > 0 &&
                     <Tab label={`Comments & History (` + purchaseData?.Count_Information[0]?.History_Count + ')' } value="2" />
                    }    

                    {purchaseData?.Count_Information?.length > 0 &&
                     <Tab label={`Payment (` + purchaseData?.Count_Information[0]?.Payment_Count + ')' } value="3" />
                    }
              

                      </TabList>
                      </Box>
                      <TabPanel value="1">
                       <PurchaseReceiveWrapperClass ref={componentRef} purchaseData={purchaseData}
                        viewBillDetails={viewBillDetails} customTabChange={customTabChange}  />
                      </TabPanel>

                      <TabPanel value="2">
                       <HistoryContent />
                      </TabPanel>

                      <TabPanel value="3">
                      </TabPanel>

                    </TabContext>
                  </Box>

                    {/* end tab */}
                   </section>
                </section>
                  </Grid>
                </Grid>
               
                {/* w-[56.3vw] */}
              

                
              </section>
            </Grid>
          </Grid>
        </section>
      </>
  );
};

export default PurchaseReceiveDetailsPage;
