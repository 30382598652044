import PrimaryImageUpload from "./primaryImageUpload";



const AddItemImageUpload = (props) => {

  

  return (

    <>
   
    <div className="flex flex-col items-center justify-center">
      {/* Top image */}
      <div className="mb-4 ">
      <PrimaryImageUpload defaultImage={props?.getListToEdit[0]?.Image_URL} Item_Id={props?.Item_Id}  imageId={props?.getListToEdit[0]?.Image_Id} />
      </div>

      {/* Bottom four images */}
      <div className="grid grid-cols-4 gap-4">
      <PrimaryImageUpload defaultImage={props?.getListToEdit[1]?.Image_URL} imageId={props?.getListToEdit[1]?.Image_Id} Item_Id={props?.Item_Id} />
      <PrimaryImageUpload defaultImage={props?.getListToEdit[2]?.Image_URL} Item_Id={props?.Item_Id} imageId={props?.getListToEdit[2]?.Image_Id} />
      <PrimaryImageUpload defaultImage={props?.getListToEdit[3]?.Image_URL} Item_Id={props?.Item_Id}  imageId={props?.getListToEdit[3]?.Image_Id} />
      <PrimaryImageUpload defaultImage={props?.getListToEdit[4]?.Image_URL} Item_Id={props?.Item_Id}  imageId={props?.getListToEdit[4]?.Image_Id} />
      </div>
      <small>NB - You can replace an image by clicking on it</small>
    </div>
    </>
   

  );
};
export default AddItemImageUpload;