/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { accountService } from "../../services/accountService";
import { corporateService } from "../../services/corporation.service";
import Select from "react-select";
import iconImage from "../../assets/Icon.svg";
import CurrencyFormat from "react-currency-format";
import LogicContext from "../../Context/LogicContext";
import {
  GetPaymentReceiveEdit,
  ResetReceiveEdit,
} from "../../store/paymentReceivedAtom";
import { GetRecoilCorporateList, SetRecoilCorporateList, GetCorporateEdit, SetCorporateEdit } from "../../store/corporateAtom";
import { useForm } from "react-hook-form";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import Grid from "@mui/material/Grid";
import { useSearchParams } from "react-router-dom";
import EmployeeSelect from "./EmployeeSelect";
import CountrySelect from "./CountrySelect";
import CorporateType from "./CorporateType";
import AreaSelect from "./AreaSelect";
import CitySelect from "./CitySelect";
import TrailPeriodSelect from "./TrailPeriodTypeSelect";
import BankSelect from "./BankSelect";
import SubscriptionTypeSelect from "./SubscriptionTypeSelect";




function AddCorporateList() {
  const corporateRecoilList = GetRecoilCorporateList();
  const corporateEdit = GetCorporateEdit();
  const setTCorporateEdit = SetCorporateEdit();

  const navigate = useNavigate();
  const myPromiseModal = createModal(GeneralModal);
  const { register, setValue,getValues,handleSubmit,control,reset,formState: { errors }} = useForm();
  const logicContext = useContext(LogicContext);
  const [currentAreaList, setCurrentAreaList] = useState([]);
  const [currentCityList, setCurrentCityList] = useState([]);

  const [sampleDocFile, setSampleDocFile] = useState(null);
  const [aGreementDocFile, setAgreementDocFile] = useState(null);



  const mountRef = useRef(true);


  useEffect(() => {
    document.title = 'Add Corporate user';
    mountRef.current = true;
    if(corporateEdit?.Corporate_Id){
      updatedDataForUpdate(corporateEdit);
    }


    return () => {
      mountRef.current = false;
      if(corporateEdit?.Corporate_Id){
        setTCorporateEdit({});
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corporateEdit]);


  const updatedDataForUpdate = (data) => {
    setValue('Number_Of_Officer_Id', data?.Number_Of_Officer_Id);
    setValue('agreement_date', data?.Agreement_Date); 
    setValue('Coroporate_Area_Id', data?.Area_Id);
    setValue('Corporate_Type_Id', data?.Corporate_Type_Id);
    setValue('Corporate_Short_Code', data?.Corporate_Short_Code);
    setValue('Corporate_Name', data?.Corporate_Name);
    setValue('Country_Id', data?.Country_Id);
    setValue('City_Id', data?.City_Id);
    setValue('Area_Id', data?.Area_Id);
    setValue('Corporate_Contact_Person', data?.Corporate_Contact_Person);
    setValue('Corporate_Id', data?.Corporate_Id);
    setValue('Corporate_Email_Address', data?.Corporate_Email_Address);
    setValue('Corporate_Adddress', data?.Corporate_Adddress);
    setValue('sample_document_url', data?.sample_document_url);
    setValue('agreement_document_url', data?.agreement_document_url);
    setValue('subscription_activation_date', data?.subscription_activation_date);
    setValue('subscription_expiry_date', data?.subscription_expiry_date);
    setValue('subscription_fees', data?.subscription_fees);
    setValue('trail_period_type_id', data?.trail_period_type_id);
    setValue('trail_period', data?.trail_period);
    setValue('maximum_discount_percentage', data?.maximum_discount_percentage);
    setValue('Corporate_Contact_Person_Mobile', data?.Corporate_Contact_Person_Mobile);
    setValue('bank_id', data?.Bank_Id);
    


  }


  const onSubmit = async (option) => {
    let decision = undefined;
    await myPromiseModal({ title: "Confirmation", body: " Do you want to save this record?",
    showInput:false, placeHolder:'',showInput:false, placeHolder:''}).then((res) => {
      if(res.decision === true){
        decision = res.decision;
      }
    });


    if(!decision){
      return;
    }

    // if(!sampleDocFile  || !corporateEdit?.Sample_Document_URL){
    if(!sampleDocFile ){
      return logicContext.showToast('Sample document is required!');
    }
    // if(!aGreementDocFile || !corporateEdit?.Agreement_Document_URL){
    if(!aGreementDocFile){
      return logicContext.showToast('Agreement file is required!');
    }
    const newData = getValues();
    if(!newData?.Coroporate_Area_Id){
      logicContext.showToast("Please select country, city and area", "error");
    
    }else if(!newData?.Number_Of_Officer_Id){
      return logicContext.showToast("Please select Number of employee", "error");
    }else if(!newData?.Corporate_Type_Id){
      return logicContext.showToast("Please select Corporate type", "error");
    }else if(!newData?.agreement_date){
      return logicContext.showToast("Please select Activation date", "error");
    }
//     sample_document_url  
// ,agreement_document_url
     // attachedFile

     const formData = new FormData();
     const sampleForm = new FormData();
     sampleForm.append('file', sampleDocFile);
     sampleForm.append('id', Date.now());



    //  upload sample file
     let sampleResponse =  await corporateService.uploadFile(sampleForm);
     if(sampleResponse?.data?.Location){
      formData.append('sample_document_url', sampleResponse?.data?.Location);
     }else{
      return logicContext.showToast('Error uploading Sample document, Please try again!.','error');
     }

    //  upload Agreement file
     const agreementForm = new FormData();
     agreementForm.append('file', aGreementDocFile);
     agreementForm.append('id', Date.now());
     let aggResponse =  await corporateService.uploadFile(agreementForm);
     if(sampleResponse?.data?.Location){
      formData.append('agreement_document_url', aggResponse?.data?.Location);
     }else{
      return logicContext.showToast('Error uploading Agreement document, Please try again!.','error');
     }

     for( const keyData in newData){
         formData.append(keyData, newData[keyData]);
     }



     formData.append('option', option);
       formData.append('sample_doc', sampleDocFile);
       formData.append('agreement_file', aGreementDocFile);

    corporateService.createCorporation(formData).then(res => {
      const filteredData = logicContext.httpResponseManager(res?.data[0]);
      if(filteredData?.message_id == 1 || filteredData?.message_id == 1){
        logicContext.showToast(filteredData?.message_text || filteredData?.message_text, 'success');
        corporateService.getCorporateListNoCache().then(res => {
          navigate(-1);
        });
      }
    });

   
  };

  function onErrors(error) {
    console.error("Errorrrrr ", error);
    return  logicContext.showToast("form error", "error");
   
  }



  const selectFileClickAction = () => {
    document.getElementById("attached-sample-file").click();
  };

  
  const selectAgreementClickAction = () => {
    document.getElementById("attached-agreement-file").click();
  };

  function sampleDocumentHandler(e) {
    const file = e.target.files[0];
    // sample_document_url  
    setSampleDocFile(file);
  }

  function agreementFileHandler(e) {
    const file = e.target.files[0];
    // sample_document_url  
    setAgreementDocFile(file);
  }

  function onDateChange(date){
    setValue('agreement_date', date);
  }

  function onSubActivationDateChange(date){
    setValue('subscription_activation_date', date);
  }
  
  
  function onEmployeeSelect(data){
    setValue('Number_Of_Officer_Id', data?.Number_Of_Officer_Id);
    
  }
  
function onCountrySelect(data){
    setCurrentAreaList([])
    const newList = corporateRecoilList?.City?.filter((city) => city?.Country_ID === data?.Country_ID);
    setCurrentCityList(newList);
  }

  function onCitySelect(data){
    const newList = corporateRecoilList?.Area?.filter((area) => area?.Country_Id === data?.Country_ID);
    setCurrentAreaList(newList);
    // setValue('Number_Of_Officer_Id', data?.Number_Of_Officer_Id);
  }
  
  

  function onAreaSelect(data){
    setValue('Coroporate_Area_Id', data?.Area_ID);
  }

  function onBankSelect(data){
    setValue('bank_id', data?.Bank_Id);
  }
  

  function onSubscriptionSelect(data){
    setValue('subscription_id', data?.Subscription_Id);
  }
  

  function onCorporateTypeSelect(data){
    setValue('Corporate_Type_Id', data?.Corporate_Type_Id);
  }

  function onTrailPeriodSelect(data){
    setValue('trail_period_type_id', data?.Period_Id);
  }


  return (
    <>
        <form className="mt-5 " onSubmit={handleSubmit(onSubmit, onErrors)}>
          <section className="container max-auto ">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <span className="text-sm  ">Corporate Code </span>
                <span className="text-danger font-bold">*</span>
                <Grid container>
                  <Grid item xs={12}>
                    <div className="">
                      <input
                        className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        placeholder=""
                        autoFocus="autoFocus"
                        type="text"
                        {...register("Corporate_Short_Code", {
                          required: "Corporate code is required!",
                        })}
                      />
                     <small className="text-danger"> {errors?.Corporate_Short_Code?.message}</small>

                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Contact Number</span>
                  <span className="text-danger font-bold">*</span>
                  <div className="">
                    <input
                      className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter contact number"
                      autoFocus="autoFocus"
                      type="number"
                      {...register("Corporate_Contact_Person_Mobile", {
                        required: "contact is required!",
                      })}
                    />
                     <small className="text-danger"> {errors?.Corporate_Contact_Person_Mobile?.message}</small>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Corporate Name</span>
                  <span className="text-danger font-bold">*</span>
                  <div className="">
                    <input
                      className="bg-filterInput pl-3 rounded   w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="text"
                      placeholder="enter name"
                      {...register("Corporate_Name", {
                        required: "Corporate name is required!",
                      })}
                    />
                     <small className="text-danger"> {errors?.Corporate_Name?.message}</small>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Contact Person Name</span>
                  <span className="text-danger font-bold">*</span>
                  <div className="">
                    <input
                      className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter contact prson details"
                      autoFocus="autoFocus"
                      type="text"
                      {...register("Corporate_Contact_Person", {
                        required: "Discount is required!",
                      })}
                    />
                     <small className="text-danger"> {errors?.Corporate_Contact_Person?.message}</small>
                  </div>
                </div>
              </Grid>

           

              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Email Address</span>
                  <span className="text-danger font-bold">*</span>
                  <div className="">
                    <input
                      className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter email address"
                      autoFocus="autoFocus"
                      type="text"
                      {...register("Corporate_Email_Address", {
                        required: "Email is required!",
                      })}
                    />
                     <small className="text-danger"> {errors?.Corporate_Email_Address?.message}</small>

                  </div>
                </div>
                <div className="">
                  <span className="text-sm ">Number of Employees</span>
                  <span className="text-danger font-bold">*</span>
                  <EmployeeSelect {...register("Number_Of_Officer_Id", {
                        required: "Number of employee required!"})}
                   defaultVal={{ label: corporateEdit?.Number_Of_Officer_Name }} customList={corporateRecoilList?.Corporate_officer_type} onEmployeeSelect={onEmployeeSelect}></EmployeeSelect>
                   <small className="text-danger"> {errors?.Number_Of_Officer_Id?.message}</small>
                </div>

                <div className="">
                  <span className="text-sm ">Subscription fee</span>
                  <span className="text-danger font-bold">*</span>
                  <div className="">
                    <input
                      className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter subscription fee"
                      autoFocus="autoFocus"
                      type="number"
                      {...register("subscription_fees", {
                        required: "Subscription fee is required!",
                      })}
                    />
                     <small className="text-danger"> {errors?.subscription_fees?.message}</small>

                  </div>
                </div>
                {/* sub ac date */}
                
                  <span className="text-sm ">Subscription Activation Date</span>
                 
                  <div className="">
                    <input
                      className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter activation date(optional)"
                      // autoFocus="autoFocus"
                      type="date"
                      defaultValue={ corporateEdit?.Agreement_Date ? moment(corporateEdit?.subscription_activation_date).format( "YYYY-MM-DD"): null}
                      onChange={(e)=> onSubActivationDateChange(e.target.value)}
                    />
                  </div>
                
                {/* sub ac date end */}
                
                <div className="row mt-1 mb-1">
                  <div className="col" >
                  <span className="text-sm ">Trial Period</span>
                  <span className="text-danger font-bold">*</span>
                  <div className="">
                    <input
                      className="bg-filterInput pl-3 rounded   w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="number"
                      placeholder="enter value, e.g: 25"
                      {...register("trail_period", {
                        required: "Trail period is required!",
                      })}
                    />
                     <small className="text-danger"> {errors?.trail_period?.message}</small>

                  </div>
                  </div>

                  <div className="col" >
                  <div className="">
                  <span className="text-sm ">Trial Period Type</span>
                  <span className="text-danger font-bold">*</span>
                  
                  <TrailPeriodSelect   {...register("trail_period_type_id", {
                        required: "Trail period type is required!"})}
                       defaultVal={{ label: corporateEdit?.trail_period_type_name }} customList={corporateRecoilList?.trail_period}  onTrailPeriodSelect={onTrailPeriodSelect} ></TrailPeriodSelect>
                </div>

                  </div>
               
                </div>

                <span className="text-sm ">Maximum discount percentage %</span>
                  <span className="text-danger font-bold">*</span>
                  <div className="">
                    <input
                      className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter subscription fee"
                      autoFocus="autoFocus"
                      type="number"
                      {...register("maximum_discount_percentage", {
                        required: "maximum discount % is required!",
                      })}
                    />
                     <small className="text-danger"> {errors?.subscription_fees?.message}</small>

                  </div>


                  <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Corporate Type</span>
                  <span className="text-danger font-bold">*</span>
                  <CorporateType defaultVal={{ label: corporateEdit?.Corporate_Type_Name }} customList={corporateRecoilList?.Corporate_Type}  onCorporateSelect={onCorporateTypeSelect} ></CorporateType>
                </div>
              </Grid>
              </Grid>
             
            

         

              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Country</span>
                  <span className="text-danger font-bold">*</span>
                  <CountrySelect
                   defaultVal={{ label: corporateEdit?.Country_Name }} customList={corporateRecoilList?.Country}  onCountrySelect={onCountrySelect} ></CountrySelect> 
                </div>

                <div className="">
                  <span className="text-sm ">City</span>
                  <span className="text-danger font-bold">*</span>
                  <CitySelect defaultVal={{ label: corporateEdit?.City_Name }} customList={currentCityList}  onCitySelect={onCitySelect} ></CitySelect> 
                </div>

                <div className="">
                  <span className="text-sm ">Area</span>
                  <span className="text-danger font-bold">*</span>
                  <AreaSelect defaultVal={{ label: corporateEdit?.Area_Name }} customList={currentAreaList}  onCountrySelect={onAreaSelect}></AreaSelect>


                  <span className="text-sm ">Default Bank</span>
                  <span className="text-danger font-bold">*</span>
                  <BankSelect {...register("bank_id", {
                        required: "Default bank required!"})}
                  defaultVal={{ label: corporateEdit?.Default_Bank_Transaction_Name }} customList={corporateRecoilList.bank_info} 
                   onCountrySelect={onBankSelect}></BankSelect>
                     <small className="text-danger"> {errors?.bank_id?.message}</small>



                  <span className="text-sm ">Subscription Type</span>
                  <span className="text-danger font-bold">*</span>
                  <SubscriptionTypeSelect defaultVal={{ label: corporateEdit?.Subscription_Name }} customList={corporateRecoilList.subscription_type_info} 
                   onCountrySelect={onSubscriptionSelect}></SubscriptionTypeSelect>


                  <div className="">
                  <span className="text-sm ">Address</span>
                  <span className="text-danger font-bold">*</span>
                  <div className="">
                    <input
                      className="bg-filterInput pl-3 rounded  h-[15vh] w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="text"
                      placeholder="write reason"
                      {...register("Corporate_Adddress", {
                        required: "Address is required!",
                      })}
                    />
                     <small className="text-danger"> {errors?.Corporate_Adddress?.message}</small>

                  </div>
                </div>
             
                <div className="">
                  <span className="text-sm ">Activation Date</span>
                  <span className="text-danger font-bold">*</span>
                  <div className="">
                    <input
                      className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="enter activation date"
                      // autoFocus="autoFocus"
                      type="date"
                      defaultValue={ corporateEdit?.Agreement_Date ? moment(corporateEdit?.Agreement_Date).format( "YYYY-MM-DD"): null}
                      onChange={(e)=> onDateChange(e.target.value)}
                    />
                  </div>
                </div>
             
                </div>


                
                <div className="">
                  <span className="text-sm ">Remark</span>
      
                  <div className="">
                    <input
                      className="bg-filterInput pl-3 rounded  h-[15vh] w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="text"
                      placeholder="enter remark"
                   
                    />
                   
                  </div>
                </div>
              </Grid>
           

          
          

              <Grid item xs={12}>
                <Grid item xs={6}>
                  <div className="">
                    <span className="text-sm ">Attach Sample Corporate ID</span>
                    <div className="relative w-full">
                      <input
                        id="attached-sample-file"
                        className="bg-filterInput h-[6vh] w-50 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        onChange={sampleDocumentHandler}
                        type="file"
                        name="name"
                      />
                      <div className="justify-end" >
                        {corporateEdit?.Sample_Document_URL}
                      </div>

                      <div
                        onClick={selectFileClickAction}
                        className="flex justify-between w-full absolute  top-0 left-0"
                      >
                        <img className="ml-2 mt-3" src={iconImage} alt="" />

                      </div>
                    </div>
                    <i> maximum file size 5MB </i>
                  </div>
                </Grid>
                {/* Agreement file */}
                <Grid item xs={6}>
                  <div className="">
                    <span className="text-sm ">Attach Agreement File</span>
                    <div className="relative w-full">
                    
                      <input
                        id="attached-agreement-file"
                        className="bg-filterInput h-[6vh] w-50 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        onChange={agreementFileHandler}
                        type="file"
                        name="name"
                      />

                      <div
                        onClick={selectAgreementClickAction}
                        className="flex justify-between w-full absolute  top-0 left-0"
                      >
                        <img className="ml-2 mt-3" src={iconImage} alt="" />

                      </div>
                    </div>
                    <i> maximum file size 5MB </i>
                  </div>
                </Grid>
        
              </Grid>

              <Grid item xs={12}>
                <section>
                  <div className="gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
                    <span
                      type="cancel"
                      onClick={() => navigate(-1)}
                      style={{ color: "#F17E7E", backgroundColor: "white" }}
                      className="cursor" >
                      CANCEL
                    </span>
                    <div>
                      <button
                        // onClick={() => onSubmit(2)}
                        type="submit"
                        style={{ color: "#476EF8" }}
                        className="text-sm  px-3 rounded mr-10 py-2"
                      >
                        SAVE AS DRAFT
                      </button>
                      <button
                        type="submit"
                        // onClick={() => onSubmit(1)}
                        style={{ backgroundColor: "#476EF8" }}
                        className="text-sm text-white px-3 rounded py-2"
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                </section>
              </Grid>
            </Grid>
          </section>
        </form>
   
    </>
  );
}

export default AddCorporateList;
