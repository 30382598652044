
import "../CustomerInventoryItems/Payment.css";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import PaginationItem from "@mui/material/PaginationItem";
import { useEffect, useState } from "react";
import { transactionService } from "../../services/transaction.service";
import underscore from "underscore";
import up from "../../assets/icons/Caret_up.png";
import down from "../../assets/icons/Caret_down.png";
import { useRecoilValue } from "recoil";
import { transactionmasterDataAtom } from "../../store/transAtom";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { IoSearchOutline } from "react-icons/io5";
import { Table, Input } from 'antd';
import { formatCustomNumber } from "../../helpers/formatCustomNumber";
const { Search } = Input;


const columns = [
  {
    title: 'Invoice Date',
    key: 'Invoice_Date',
    dataIndex: 'Payment_Date',
    sorter: (a, b) => a.Payment_Date.localeCompare(b.Payment_Date)
  //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
  },

  {
    title: "Payment Number",
    dataIndex: "Payment_No",
    sorter: (a, b) => a.Payment_No.localeCompare(b.Payment_No)
  },

  {
    title: "Ref Number",
    dataIndex: "Reference_No",
    className:"text-primary",
    sorter: (a, b) => a.Reference_No.localeCompare(b.Reference_No)
  },
  {
    title: "Amount",
    dataIndex: "Collection_Amount",
    sorter: (a, b) => a.Collection_Amount.localeCompare(b.Collection_Amount)
  },
  {
    title: "Unused Amount",
    dataIndex: "Unused_Amount",
    sorter: (a, b) => a.Unused_Amount.localeCompare(b.Unused_Amount)
  },

];






function PaymentContent(props) {
  const [paymentList, setPaymentList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  const masterData = useRecoilValue(transactionmasterDataAtom);
  var masterDataGroup = underscore.groupBy(masterData, "Master_Type");
  const paymentStatus = masterDataGroup[1];

  useEffect(() => {
    transactionService.getTransactionPayment(props.customer_id).then((res) => {
      setPaymentList(res?.data);
      setFilteredData(res?.data);
    });
  }, [props.customer_id]);





  const paginationOptions = {
    pageSize: 10,
    showSizeChanger: true,
    total: filteredData?.length,
    // onChange: (page, pageSize) => {
    //   console.log(page, pageSize);
    //   const newList = tableDataList.slice(0, pageSize);
    //   console.log('check ', newList);
    //   setTableList(newList);
    // },
  };



  const handleRowClick = (record) => {
    navigate(`/${currModuleId}/${currModuleName}/invoices/details/${record?.Reference_No}`);

  };
  



  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = paymentList.filter((record) =>
      Object.values(record).some((text) =>
        String(text).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };
  
  


  return (
    <>
      <div className="ml-1 @container">
        <div className="mt-1 text-gray-600 text-xl font-bold">Payment</div>
        <div className="mt-2 space-y-3 @lg:flex @lg:flex-row @lg:items-center @lg:justify-between @lg:space-y-0 @lg:space-x-2">
        
        </div>

        <Search
        placeholder="Search..."
        onSearch={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        value={searchText}
        style={{ marginBottom: 10,width: 250 }}
      />

        <Table key={filteredData?.length} columns={columns} dataSource={filteredData?.map((item)=> {
           return {...item, Collection_Amount: formatCustomNumber(item.Collection_Amount)} } )}
                      pagination={paginationOptions}
                      rowClassName="show-cursor"
                      style={{ background: '#f0f2f5', borderRadius: '8px' }}
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            handleRowClick(record);
                          },
                        };
                      }}
                        />
      </div>
    </>
  );
}

export default PaymentContent;
