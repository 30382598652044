
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useForm } from "react-hook-form";
import { Container } from '@mui/material';
import EarnContentTable from './EarnContentTable';
import EarnContentSelect from './EarnContentSelect';
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { useContext, useEffect, useState } from 'react';
import LogicContext from "../../Context/LogicContext";
import { rewardService } from '../../services/Reward.service';
import EarnContentModal from './EarnContentModal';
import { GetRewardCenterList, SetRewardCenterList } from '../../store/rewardCenterAtom';



function EarnContent(props) {
  const myPromiseModal = createModal(GeneralModal);
  const logicContext = useContext(LogicContext);
  const getRewardList = GetRewardCenterList();
  const setRewardList = SetRewardCenterList();
  const [tableList, setTableList] = useState([]);



  const onErrors = ()=> {
    
  }

  useEffect(()=> {
    if(getRewardList.earn_info.length){
      setTableList(getRewardList.earn_info);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[getRewardList.earn_info.length, getRewardList]);


  const onStatusSelectValue = (val)=> {
    if(val === 0){
      return setTableList(getRewardList.earn_info);
    }else{
      let newList = getRewardList.earn_info.filter((item)=> item.Earn_Status_Id === val);
      setTableList(newList);
    }
  }



  const changeEarnStatus = (decision, data) => {

    const newStatus = (decision === true) ? 1: 2;
    const newUpdate = {ref_no: data.Ref_No,title: data?.Earn_Title,description: data?.Earn_Description,
      activation_date: data?.Offer_Activation_Date, status_id: newStatus};
    rewardService.updateEarnContent(newUpdate).then((res)=> {
      const filteredData = logicContext.httpResponseManager(res?.data[0]);
      if(filteredData?.message_id == 1 || filteredData?.message_id == 1){
        logicContext.showToast(filteredData?.message_text || filteredData?.message_text, 'success');
        reloadMainData();
      }
    });
  }




  function reloadMainData(){
    rewardService.getEarnContent().then((response)=> {
      if(response.data.earn_info?.length){
        setRewardList(response.data);
      }
    });
  }


  const syncTableSequence = async (tableData)=> {
    await myPromiseModal({ title: `Confirm Action`, body:'The following action will sync table sequence',
    showInput:false, placeHolder:''}).then((res) => {
      if (res.decision === true) {
        let xml = '';
        let index  = 1;
        tableData.forEach(element => {
                xml +=  `<a><ref_no>${element?.Ref_No} </ref_no><dis_seq>${index} </dis_seq></a>`;
                index += 1;
        });

        rewardService.updateRewardTableSequence({xml_reward: `<root>${xml}</root>`, option: 8 }).then((res)=> {
          const filteredData = logicContext.httpResponseManager(res?.data[0]);
          if(filteredData?.message_id == 1 || filteredData?.message_id == 1){
            logicContext.showToast(filteredData?.message_text || filteredData?.message_text, 'success');
              reloadMainData();
          }
        });
 

      }});

  }


    return (
        <>
      <EarnContentModal submitEarnContent={(formData)=>  props.submitEarnContent(formData)} />

        <Container className='mt-12'>
        <div class="d-flex flex-row-reverse bd-highlight">
          <EarnContentSelect onSelectValue={onStatusSelectValue} />
      </div>
            <EarnContentTable  syncTableSequence={syncTableSequence} changeEarnStatus={changeEarnStatus}  tableDataList={tableList} />
        </Container>
   
        </>


    );
}

export default EarnContent;


