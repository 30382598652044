import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import pimage from "../../assets/plansImage.svg";
import {productService} from '../../services/product.service';
import Card from '@mui/material/Card';
import GeneralModal from "../../../src/helpers/GeneralModal";
import { createModal , } from 'react-modal-promise';
import {SetPlanEdit} from '../../store/productAtom';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import LogicContext from "../../Context/LogicContext";
import {imageService}  from '../../services/image.service';
import CurrencyFormat from 'react-currency-format';





function ProductImagePlans(props) {
  const myPromiseModal = createModal(GeneralModal);
  const [temKeep, setTemKeep] = useState([]);
  const [tableData, setTableData] = useState([]);

  // const logicContext = useContext(LogicContext); 

  // const setPlanEdit = SetPlanEdit();
  // const navigate = useNavigate();
  // const { pathname } = useLocation();
  // var url = pathname.split("/");
  // const currModuleId = url[1];
  // const currModuleName = url[2];
  // const currUserMenu = url[3];


  useEffect(()=> {
    if(props?.Product_Id !== undefined){
      productService.getProductByPlan(props?.Product_Id).then(res => {
        if(res.data !== undefined){
          setTableData(res.data);
          setTemKeep(res.data);
        }
      });
    }
  },[props?.Product_Id])


  function showInformation(){
    if(props?.Product_Id !== undefined){
      productService.getProductByPlan(props?.Product_Id).then(res => {
        if(res.data !== undefined){
          setTableData(res.data);
        }
      });
    }
  }



  function openPanel(){
    if(tableData?.length < 1){
      showInformation()
    }
  }


  function searchProduct (search) {
    if (search.length <  3) {
      setTableData(temKeep);
    }else{
      var tempResult = temKeep.filter((item)=>{
     
        return item?.Product_Name?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
        ||  item?.Plan_Name?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
        ||  item?.Item_Sku?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
        ||  item?.Plan_Price.toString()?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
        ||  item?.Plan_Features?.toLowerCase().indexOf(search.toLowerCase()) >= 0 
      });

      setTableData(tempResult);
    }
}


function onSelectPlan (plan){
  props?.onSelectPlan(plan);
}




  return (
    <>


   <section className="my-2  ">
   <Accordion expanded={true}>
        <AccordionSummary onClick={openPanel} style={{ backgroundColor: "#E7ECFF" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header" >
          <Typography className="font-weight-bold" >  Plans 
          </Typography>
                   {/* SEARCH BAR */}
           <div className="ml-5 border-3">
           <input
              onChange={(ev)=> searchProduct(ev.target.value)}
                className="px-4 text-sm bg-inputColor  py-2 h-[5vh] w-100"
                placeholder="Search product" />
           </div>

        </AccordionSummary>

        <AccordionDetails>
          {tableData?.map((item, i)=> {
            return(
              <Card  key={i} className="mb-3 bg-light ">
                <div onClick={()=> onSelectPlan(item)} className="container">
              <div className="row">
                <div className="col-6">
                <img src={imageService.PRODUCT_PLAN_IMAGE_URL + item.Image_Reference_No + '.'+ item.Image_Format} alt="" />
                </div>
                <div className="col-6">
                <div>
                 <span className="font-bold text-md" style={{color:"#45444B"}}>{item?.Plan_Name} </span> 
                 </div>
                 <div>
                 <span  style={{color:"#45444B"}}> <i style={{fontSize:"15px"}} >Plan Code: {item?.Plan_Id} </i> </span>
                 </div>
                 <div>
                  <span style={{color:"#45444B"}} className="text-md">Associated Addons:  <span className="font-bold"> {item?.Plan_Features} </span>  </span>
                 </div>
                </div>
          

       
              </div>
            </div>
      
              </Card>
            )
         
          })}
        
        </AccordionDetails>
      </Accordion>
 
   </section>
    


    {/* {!showTable ?
    <button className="btn btn-sm btn-primary ml-4 mt-5" onClick={showInformation}> View Associate Items</button>
  : null  } */}
       
       
    
   
    </>
  );
}

export default ProductImagePlans;
