
import { useEffect } from 'react';
import Select from 'react-select';
 
  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      backgroundColor: '#F1F1F1',
      zIndex: 0
      // width: 512,
    }),
  
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
    menuPortal:styles => ({ ...styles, zIndex: 0 })
  }
  
function GeneralDropDown(props) {


  const customOption = props?.dropDown?.map((item)=> {
    return ({...item, label:`${item?.Name}`})
  });
  // {
  //   props.manage == true ? 
  //   <li>
  //   <div className="d-grid gap-2">
  //      <button onClick={()=> props.manageList()} className="btn btn-sm btn-primary btn-block" type="button"> Manage list</button>
  //      </div>
  //      </li> 
  //   :  null
  // }


  useEffect(()=> {

  },[props?.customList, props?.defaultVal]);



    return ( 
        <>
         <Select 
         defaultValue={{label: props?.defaultVal }}
         key={props?.defaultVal}
         onChange={(e)=> props.optionSelect(e)} options={customOption}    styles={customStyles} />
         {props.manage && 
          <small className='text-center' >
          <button type="button" onClick={()=> props.manageList()} className='btn btn-sm  text-primary' >Add Option</button>
         </small>
         
         }
        
        </>
     );
}

export default GeneralDropDown;