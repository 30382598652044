import React, {useEffect,useState,useContext,useRef} from "react";
  // import "../../pages/Plan.css";
  import { salesService } from "../../services/sales.service";
  import iconImage from "../../assets/Icon.svg";
  import CurrencyFormat from "react-currency-format";

  import LogicContext from "../../Context/LogicContext";
  import {imageService} from '../../services/image.service'; 
  
  import {
    GetEntryData,SetEntryData, SetSalesOrderList,GetEditSalesOrder,
    GetSalesOrderList, ResetEditSalesOrder} from "../../store/salesOrderAtom";
  
  import { useForm } from "react-hook-form";
  import moment from "moment";
  
  import { useNavigate } from "react-router-dom";
  import GeneralModal from "../../helpers/GeneralModal";
  import { createModal } from "react-modal-promise";
  import Grid from "@mui/material/Grid";
  

  import { useSearchParams } from 'react-router-dom';
import SalesOrderDiscountType from "./SalesOrderDiscountType";
import AddSalesOrderCustomer from "./AddSalesOrderCustomer";
import AddSalesOrderTable from "./AddSalesOrderTable";
import SalesOrderRate from "./SalesOrderRate";
import SalesOrderPaymentTerms from "./SalesOrderPaymentTerms";
import SalesOrderDeliveryMethod from "./SalesOrderDeliveryMethod";
import SalesOrderWarehouseName from "./SalesOrderWareHouseName";
import SalesOrderSalesPerson from "./SalesOrderSalesPerson";
import AddSalesOrderInvoiceTable from "./AddSalesOrderInvoiceTable";
import GeneralFileUploadPreview from "../../components/GeneralFileUploadPreview";
  
  
  
  function AddSalesOrderPage() {
    const setEntryData = SetEntryData();
    const entryMasterData = GetEntryData();
    const editableSalesOrder = GetEditSalesOrder();
    const resetEdit = ResetEditSalesOrder();
  
    const navigate = useNavigate();
    const myPromiseModal = createModal(GeneralModal);
    const {register,setValue,getValues,handleSubmit,
      control, reset, formState: { errors } } = useForm();
    const logicContext = useContext(LogicContext);
    const [selectedSalesList, setPurchaseList] = useState([]);
    const [selectedInvoiceList, setInvoiceList] = useState([]);
    const [attachmentFile, setAttachmentFile] = useState(null);
  

    const [discountValue, setDiscountValue] = useState(0);
    const [shippingFee, setShippingFee] = useState(0);
    const [adjustmentValue, setAdjustmentValue] = useState(0);
    const [disCountTypeID, setDiscountTypeID] = useState(1);


  

    const mountRef = useRef(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const customVendorId = searchParams.get('vendor_id');
    const customVendorName = searchParams.get('vendor_name');
  


  
    useEffect(() => {
      mountRef.current = true;
      if (entryMasterData["Discount_Type"] === undefined) {
        salesService.getEntrySalesOrderData().then(res => {
          if(res?.data !== undefined){
            setEntryData(res.data);
          }
        });
      }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
  
    useEffect(()=> {
      if(customVendorId !== null){
        setValue('Vendor_Id', customVendorId);
        setValue("Discount_Type_Id", 1);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[customVendorId]);
  
  
    useEffect(()=> {
      document.title = 'New Sales Order';
  
      if (editableSalesOrder?.Sales_Order_Ref_No) {
        document.title = 'Modify Sales Order';
        setValue("Customer_Id", editableSalesOrder?.Customer_Id);
        setValue("Delivery_Method_Id", editableSalesOrder?.Delivery_Method_Id);
        setValue("Customer_Notes", editableSalesOrder?.Customer_Notes);
        setValue("Sales_Order_Ref_No", editableSalesOrder?.Sales_Order_Ref_No);
        setValue("Subscription_Ref_No", editableSalesOrder?.Subscription_Ref_No);
        setValue("Payment_Term_Id", editableSalesOrder?.Payment_Term_Id);
        setValue("Discount_Type_Id", editableSalesOrder?.Discount_Type_Id);
        setValue("discount_value", editableSalesOrder?.Discount_Amount);
        setValue("Sales_Person_Id", editableSalesOrder?.Sales_Person_Id);
        setValue("Warehouse_Id", editableSalesOrder?.Warehouse_Id);
        setValue("attachment_url", editableSalesOrder?.Sales_Order_Attachement_URL);
        setValue("Order_Remark", editableSalesOrder?.Order_Remark);

        setDiscountValue(editableSalesOrder?.Discount_Amount);
        setShippingFee(editableSalesOrder?.Shipping_Amount);
        setAdjustmentValue(editableSalesOrder?.Adjustment_Amount);
        setDiscountTypeID(editableSalesOrder?.Discount_Type_Id)
        // if(editableSalesOrder?.Attachments?.length > 0){
        //   reMapImageUri(editableSalesOrder?.Attachments)
        // }
        // set item list
     
      }
  
      return () => {
        mountRef.current = false;
        resetEdit();
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[editableSalesOrder])
  
  
  
  
  
    const onCustomerSelect = (customer) => {
      setValue("Customer_Id", customer.Customer_Id);
    };
  

  
  
    function onPaymentTermsSelect(val) {
      setValue("Payment_Term_Id", val.Payment_Term_Id);
    }


  
    function onDeliveryMethodSelect(val) {
      setValue("Delivery_Method_Id", val.Delivery_Method_Id);
    }
  
    function onWareHouseSelect(val) {
      setValue("Warehouse_Id", val.Warehouse_Id);
    }

  
    function onSalesPersonSelect(val) {
      setValue("Sales_Person_Id", val.User_Id);
    }



    const onSubmit = async (option) => {
      const salesForm = getValues();

      //  validate
      if (!salesForm?.Customer_Id) {
        return logicContext.showToast("Please select a customer", "info");
      }
      //  validate
      if (!salesForm?.Delivery_Method_Id) {
        return logicContext.showToast("Please select delivery method", "info");
      }
      //  validate
    
  
      if (selectedSalesList.length === 0) {
        return logicContext.showToast("at least one or more item must be selected!", "error");
      }
  
  
  
      var tempStringList = selectedSalesList?.map((item) => {
  
        let temp = `<a> <item_id>${item.Item_Id}  </item_id>
          <item_quantity>${item?.Item_Sales_Quantity} </item_quantity>
          <item_rate>${item?.Item_Sales_Rate} </item_rate>
          <tax_id>${item?.Item_Tax_Id ? item?.Item_Tax_Id : 0} </tax_id>
          <account_id>${item.Account_Id ? item.Account_Id : 1} </account_id>
            </a>`;
        return temp;
      });
  
  
      var tempInvoiceList = selectedInvoiceList?.map((invoice) => {
        let temp = `<a> <invoice_ref_no>${invoice.Invoice_Ref_No}  </invoice_ref_no>
            </a>`;
        return temp;
      });
      
      await myPromiseModal({title: "Confirmation", body: " Do you want to save this record?",
      showInput:false, placeHolder:''}).then((res) => {
          if (res.decision === true) {
            let option = 2;
            const newData = getValues();
            if (editableSalesOrder?.Sales_Order_Ref_No) {
              option = 3;
            }
            // attachedFile
            const formData = new FormData();
            for (const keyData in newData) {
              formData.set(keyData, newData[keyData] ? newData[keyData] : 0);
            }
            formData.set("option", option);
            formData.set("xml_string_item", `<root> ${tempStringList} </root>`);
            formData.set("xml_string_invoice", `<root> ${tempInvoiceList} </root>`);
            formData.set("discount_value", discountValue);
            formData.set("shipping_charge", shippingFee);
            formData.set("adjustment_amount", adjustmentValue);
            if(attachmentFile){
              formData.set('file', attachmentFile)
            }

  
  
            salesService.submitSalesOrder(formData).then((res) => {
              if (res?.data !== undefined) {
                const { message_text, message_id } = res.data[0];
                if (message_id === 1) {
                  logicContext.showToast(message_text, "success");
                  sessionStorage.setItem('reloadPage', 'true');
                  navigate(-1);
                } else {
                  return logicContext.showToast(message_text, "error");
                }
              }
            });
          }
        }).catch((err) => {
          logicContext.showToast("error submitting record", "error",);
        })
        .catch((err) => {
          logicContext.showToast(
            "error submitting form, please try again",
            "error"
          );
        });
    };
  

    function onErrors(error) {
      logicContext.showToast("Fill in the required information!",   "error" );

    }
  
 
  
    function onFileSelected(file) {
      setAttachmentFile(file);
      
    }
  
  
 
  
    function OrderDate() {
      let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
      if (editableSalesOrder?.Order_Date !== undefined) {
        defaultDate = moment(editableSalesOrder?.Order_Date).format("YYYY-MM-DD");
      }
  
      return (
     
        <div className="">
          <input
            defaultValue={defaultDate}
            key={defaultDate}
            className="bg-filterInput  pl-3  rounded max-h-full w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
            type="date"
            {...register("Order_Date", {
              required: "expected delivery date is required",
            })}
          />
            <small className="text-danger">
                      {errors?.Order_Date && errors.Order_Date.message}
                    </small>
        </div>
        
      );
    }
  
    function pickupDate() {
      let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
      if (editableSalesOrder?.Pickup_Date !== undefined) {
        defaultDate = moment(editableSalesOrder?.Pickup_Date).format("YYYY-MM-DD");
      }
  
      return (
        <div >
          <input
            defaultValue={defaultDate}
            key={defaultDate}
            className="bg-filterInput  pl-3  rounded max-h-full w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
            type="date"
            {...register("Pickup_Date", {
              required: "pick up date date is required",
            })}
          />
            <small className="text-danger">
                      {errors?.Pickup_Date && errors.Pickup_Date.message}
                    </small>
        </div>
      );
    }
  
    function salesOrderDate() {
      let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
      if (editableSalesOrder?.Sales_Order_Date !== undefined) {
        defaultDate = moment(editableSalesOrder?.Sales_Order_Date).format("YYYY-MM-DD");
      }
  
      return (
        <div className="">
          <input
            defaultValue={defaultDate}
            key={defaultDate}
            className="bg-filterInput  pl-3  rounded max-h-full w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
            type="date"
            {...register("Sales_Order_Date", {
              required: "sales order date is required",
            })}
          />
            <small className="text-danger">
                      {errors?.Sales_Order_Date && errors.Sales_Order_Date.message}
                    </small>
        </div>
      );
    }
  
 
  
    const onSelectionList = (tableList) => {
      setPurchaseList(tableList);
    };

  
    const onInvoiceSelectionList = (tableList) => {
      setInvoiceList(tableList);
    };


    const calculateSubTotal = (list) => {
      let result = 0;
      list?.forEach((element) => {
        result += element.Item_Sales_Quantity * element.Item_Sales_Rate;
      });
      return result;
    };
  
  
    const onDiscountTypeIDSelect = (typeId) => {
      setValue('Discount_Type_Id',typeId )
      setDiscountTypeID(Number(typeId));
    };
  
  
    const calculateGrandTotal = (list) => {
      let result = 0;
      list?.forEach((element) => {
        result += element.Item_Sales_Quantity * element.Item_Sales_Rate;
      });
  
      if (disCountTypeID === 2) {
        let discount = (discountValue / 100) * result;
        result = result - discount;
      }
  
      result = result + shippingFee;
      result += adjustmentValue;
      if (disCountTypeID === 1) {
        result = result - discountValue;
      }
  
      return result;
    };
  

  
  
    return (
      <>
      <div className="row ">
        <div className="col-4 ml-[1vw] ">
      <h1 className="p-3 float-left font-bold app_bg_color1 mt-3">New Sales Order</h1>

        </div>
      </div>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <section className="container   max-auto  ">
            <Grid container spacing={2}>
       
           <Grid item xs={6}>
              <span className="text-sm ">Select Customer</span>
              <span className="text-danger font-bold">*</span>
              <AddSalesOrderCustomer  {...register("Customer_Id", { required: "customer is required",  })}
                          defaultVal={{ label: editableSalesOrder?.Customer_Full_Name }}
                          onCustomerSelect={onCustomerSelect} />

                    <small className="text-danger">
                      {errors?.Customer_Id && errors.Customer_Id.message}
                    </small>
            
              </Grid>
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Sales Order Date</span>
                  {/*  put date here */}
                  {salesOrderDate()}
                </div>
              </Grid>
  
              <Grid item xs={6}>
                <div className="">
                  <span className="text-sm ">Sales Order Number</span>
                  <div className="">
                    <input
                      type="number"
                      readOnly={true}
                      value={editableSalesOrder?.Sales_Order_No}
                      className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="Sales order number"  />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
              <span className="text-sm "> Order Date</span>
                  {OrderDate()}
              </Grid>
  
              <Grid item xs={6}>
              <span className="text-sm ">Reference Number</span>
                    <input
                    readOnly={true}
                    value={editableSalesOrder?.Sales_Order_Ref_No}
                      type="number"
                      className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder="" />
              </Grid>
              <Grid item xs={6}>
                <span className="text-sm ">Payment Terms</span>
                <span className="text-danger font-bold">*</span>
                <SalesOrderPaymentTerms
                  defaultVal={{ label: editableSalesOrder?.Payment_Term_Name }}
                  onPaymentTermsSelect={onPaymentTermsSelect}
                />
              </Grid>
         
  
              <Grid item xs={6}>
                <span className="text-sm ">Delivery Method</span>
                <span className="text-danger font-bold">*</span>
                <SalesOrderDeliveryMethod {...register("Delivery_Method_Id", { required: "Delivery method required",  })}
                  defaultVal={{ label: editableSalesOrder?.Delivery_Method_Name }}
                  onDeliveryMethodSelect={onDeliveryMethodSelect}
                />
                  <small className="text-danger">
                      {errors?.Delivery_Method_Id && errors.Delivery_Method_Id.message}
                    </small>
              </Grid>
  
              <Grid item xs={6}>
                <div>
                <span className="text-sm">Subscription Id</span>
                </div>
                <input
                      type="number"
                      className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      placeholder=""
                      {...register("Subscription_Ref_No", {
                        // required: "Subscription Id date is required",
                      })}
                    />
              </Grid>
  
              <Grid item xs={6}>
                <span className="text-sm ">Warehouse Name</span>
                <span className="text-danger font-bold">*</span>
                <SalesOrderWarehouseName
                  defaultVal={{ label: editableSalesOrder?.Warehouse_Name }}
                  onWareHouseSelect={onWareHouseSelect}
                />
              </Grid>
  
              <Grid item xs={6}>
              <div>
              <span className="text-sm ">Sales Person</span>
                <span className="text-danger font-bold">*</span>
              </div>
                <SalesOrderSalesPerson
                  defaultVal={{ label: editableSalesOrder?.Sales_Person_Name }}
                  onSalesPersonSelect={onSalesPersonSelect}
                />
              </Grid>
  
              <Grid item xs={6}>
                <span className="text-sm ">Pickup Date</span>
                <span className="text-danger font-bold">*</span>
                {pickupDate()}
              </Grid>
  
              {/* <Grid item xs={6}>
              <span className="text-sm ">Pickup Address</span>
                <span className="text-danger font-bold">*</span>
                <input
                      type="text"
                      className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      {...register("Pickup_Address", {
                        required: "Pickup address is required",
                      })}
                    />
              </Grid> */}
         
  
              <Grid item xs={12} >
                <AddSalesOrderInvoiceTable
                  defaultList={editableSalesOrder?.Invoice_details}
                  onInvoiceSelectionList={onInvoiceSelectionList}
                />
                <AddSalesOrderTable
                  defaultList={editableSalesOrder?.Sales_Order_details}
                  onSelectionList={onSelectionList}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="flex justify-center ">
                  <Grid className="my-2 border-2 bg-light px-2 py-2   " item xs={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <span className="#476ef8 text-md font-bold">Sub Total</span>
                    </Grid>
                    <Grid item xs={4}>
                      <span className="#476ef8"></span>
                    </Grid>
                    <Grid item xs={4} className="flex justify-center">
                      <span className="#476ef8 text-xl right-0">
                        <CurrencyFormat
                          value={parseFloat(
                            calculateSubTotal(selectedSalesList))?.toFixed(2)}
                          displayType={"text"}  thousandSeparator={true}
                          prefix={"₦"}  />
                      </span>
                    </Grid>
  
                    <Grid item xs={4}>
                      <span className="#476ef8 ">
                        <span className="text-md font-bold ">Discount</span>
                      </span>
                    </Grid>
                    <Grid item xs={4} className="flex justify-center">
                      <span className="text-sm">
                        <div className="">
                          <div className=" ">
                            <div className="">
                              <label className="">
                                <span className=" relative">
                          
                                  <input
                                    className="bg-filterInput rounded pl-3 h-[7vh] w-full z-50 bottom-0 
                          outline-none border-transparent focus:border-transparent focus:ring-0"
                                    type="number"
                                    placeholder=""
                                    name="discount_type"
                                    key={discountValue}
                                    autoFocus="autoFocus"
                                    onChange={(e) =>
                                      setDiscountValue(Number(e.target.value))
                                    }
                                    defaultValue={discountValue}
                                  />
                                  <span className=" border-2  absolute -top-4 right-0 ">
                                    <SalesOrderDiscountType 
                                    defaultVal={editableSalesOrder?.Discount_Type_Id}
                                      onDiscountTypeSelect={onDiscountTypeIDSelect} />
                                  </span>
                                </span>
                              </label>
                            </div>
                            <small className="text-danger"></small>
                          </div>
                        </div>
                      </span>
                    </Grid>
                    <Grid item xs={4} className="flex justify-center">
                      <CurrencyFormat value={parseFloat(discountValue)?.toFixed(2)}
                          displayType={"text"}  thousandSeparator={true}
                          prefix={""}  />
                          {/* ₦ */}
                    </Grid>
                    <Grid item xs={4}>
                      <span className="text-md font-bold">
                        {" "}
                        Shipping Charges <br></br>
                        <i style={{ color: "#476EF8" }}>
                          Apply Tax on shipping Charge
                        </i>
                      </span>{" "}
                    </Grid>
  
                    <Grid item xs={4} className="flex justify-center">
                      <span className="">
                        <input
                          className="bg-filterInput mt-2 h-[7vh] pl-3 rounded  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="number"
                          name="shipping_amount"
                          defaultValue={shippingFee}
                          autoFocus="autoFocus"
                          onChange={(e) => setShippingFee(Number(e.target.value))}
                          key={shippingFee}
                        />
                      </span>
                    </Grid>
  
                    <Grid item xs={4} className="flex justify-center">
                      <span className="#476ef8 ">
                        <CurrencyFormat
                          className={"text-xl"}
                          value={parseFloat(shippingFee)?.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      </span>
                    </Grid>
  
                    <Grid item xs={4}>
                      <div className="">
                        <span className="text-md font-bold">Adjustment</span>
                        {/* <input
                            className="bg-filterInput h-[7vh] w-full rounded bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                            type="text"
                            placeholder="Adjustment"
                          /> */}
                      </div>
                    </Grid>
                    <Grid item xs={4} className="flex justify-center">
                      <span className="">
                        <input
                          className="bg-filterInput   h-[7vh] w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="number"
                          step="any"
                          defaultValue={adjustmentValue}
                          autoFocus="autoFocus"
                          onChange={(e) =>
                            setAdjustmentValue(Number(e.target.value))
                          }
                          key={adjustmentValue}
                          name="adjustment_value"
                        />
                      </span>
                    </Grid>
  
                    <Grid item xs={4} className="flex justify-center">
                      <CurrencyFormat
                        className={
                          "text-xl " +
                          (adjustmentValue < 0 ? "text-danger" : "text-success")
                        }
                        value={parseFloat(adjustmentValue)?.toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                      />
                    </Grid>
  
                    <Grid item xs={4}>
                      <div className="">
                        <div>
                          <span className="text-xl  font-bold">Total (₦)</span>{" "}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4} className="flex justify-center">
                      <span className=" text-xl rounded font-bold p-2 bg-dark ">
                        <CurrencyFormat
                          className="text-light "
                          value={parseFloat(
                            calculateGrandTotal(selectedSalesList))?.toFixed(2)}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"₦"}
                        />
                      </span>
                    </Grid>
                  </Grid>
                  </Grid>
              
                </div>
              </Grid>
  
              <Grid item xs={12}>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="">
                        <span className="text-sm ">Customer Notes</span>
                        <input
                          {...register("Customer_Notes", {})}
                          className="bg-filterInput pl-3 rounded  h-[15vh] w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="text"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="">
                        <span className="text-sm ">Terms and Conditions</span>
                        <div className="">
                          <input
                            readOnly={true}
                            type="readOnly"
                            className="bg-filterInput rounded h-[15vh] w-full pl-3 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                            placeholder="Enter the terms and conditions of your business to 
                              be displayed in your transaction"
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                <div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="">
                        <span className="text-sm ">Order Remark</span>
                        <input
                        placeholder="enter remark"
                          {...register("Order_Remark", {})}
                          className="bg-filterInput pl-3 rounded  h-[15vh] w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="text"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                    \
                    </Grid>
                  </Grid>

                </div>

              </Grid>
              <Grid item xs={12}>
                <div className="">
                  <span className="text-sm ">Attach File</span> 
                <GeneralFileUploadPreview defaultFileUrl={editableSalesOrder?.Sales_Order_Attachement_URL} 
                onFileSelected={onFileSelected} />
                  <i> maximum file size 2MB </i>
                </div>
           
  
                  
  
                      
                          <Grid container  > 
                          </Grid>
                         
                      
              </Grid>
              <Grid item xs={12}>
                <section>
                  <div className="gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
                    <div
                      type="cancel"
                      style={{ color: "#F17E7E", backgroundColor: "white" }}
                      className="text-sm  btn btn-danger rounded"
                      onClick={()=> navigate(-1)}
                    >
                      CANCEL
                    </div>
                 
                    <div>
                      <span
                        type="submit"
                        style={{ color: "#476EF8" }}
                        className="text-sm  px-3 rounded mr-10 py-2"
                      >
                        SAVE AS DRAFT
                      </span>
                      <button
                        type="submit"
                        style={{ backgroundColor: "#476EF8" }}
                        className="text-sm text-white px-3 rounded py-2"
                      >
                        SAVE
                      </button>
                    </div>
                  </div>
                </section>
              </Grid>
            </Grid>
          </section>
        </form>
      </>
    );
  }
  
  export default AddSalesOrderPage;
  