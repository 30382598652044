import { useEffect } from "react";

function ManualJournalFilter(props) {
  useEffect(() => {}, [props?.bulkActionData]);

  const createSelectItems = () => {
    let items = [];
    props?.bulkActionData?.forEach((item, i) => {
      items.push(
        <option key={i} value={item?.Credit_Note_Status_Id}>
          {item.Credit_Note_Status_Name}
        </option>
      );
    });

    return items;
  };

  const handleChange = (e) => {
    props.onFilterSelect(e.target.value);
  };

  return (
    <>
      <select
        className=" text-sm border-[1px] border-gray-200 rounded-lg outline-none focus:outline-none "
        onChange={handleChange}
      >
        {createSelectItems()}
      </select>
    </>
  );
}

export default ManualJournalFilter;
