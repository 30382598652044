import { Grid } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {accountService} from '../../services/accountService';
import { TableBody,TableCell, TableContainer, TableHead,TableRow} from "@mui/material";
  import DeleteIcon from "@mui/icons-material/Delete";
  import CreditScoreIcon from '@mui/icons-material/CreditScore';
  import { Table } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { purchaseService } from "../../services/purchase.service";
import LogicContext from "../../Context/LogicContext";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';








function PurchaseOrderReceiveTab(props) {
const myPromiseModal = createModal(GeneralModal);
  const { purchase_id } = useParams(); 
  const {tableData} = props;
  const [receiveDetails, setReceiveDetails] = useState({}); 
  const { pathname } = useLocation();
  const logicContext = useContext(LogicContext); 


  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const navigate = useNavigate();





  useEffect(()=> {
    let isMount = true;
    accountService.getPurchaseReceives(purchase_id).then(response => {
      if(response.data !== undefined && isMount){
        if(isMount){
          setReceiveDetails(response.data);
        }
       

      }
    });

    return ()=> {
      isMount = false;
    }
  },[purchase_id])


  const deletePurchaseReceive = async (receive) => {
    await myPromiseModal({
      title: "confirm your Delete!",
      body: `Do you want to delete ${receive?.Purchase_Receive_Number} ?`,showInput:false, placeHolder:''
    }).then((response) => {
      if (response.decision === true) {
        let temp = '';

           temp += `<a> <item_id> ${receive?.Item_Id} </item_id> </a>`;

        
        let option = 4;
        let xml_string_refno = `<root> ${temp} </root>`;
        purchaseService.submitPurchaseReceive(
          {Purchase_Receive_Ref_No:receive?.Purchase_Receive_Ref_No,
            Purchase_Order_Ref_No: receive?.Purchase_Order_Ref_No ,xml_string_refno, option}).then(res => {
          if(res?.data !== undefined){
            const {message_text} = res.data[0];
            logicContext.showToast(message_text, 'success');
            props.reloadPurchaseOrder();
          }
      }).catch((err)=> {
        logicContext.showToast('Error', 'error');
      });
      }
    });
  };


  const convertReceiveToBill = async (receive) => {
    await myPromiseModal({
      title: "Convert received item to BILL!",
      body: `Do you want to convert ${receive?.Purchase_Receive_Number}  to Bill?`,
      showInput:false, placeHolder:''}).then((response) => {
      if (response.decision === true) {
        let temp = '';

           temp += `<a> <item_id> ${receive?.Item_Id} </item_id> </a>`;

        
        let option = 9;
        let xml_string_refno = `<root> ${temp} </root>`;
        purchaseService.convertBillToReceive(
          {Purchase_Receive_Ref_No:receive?.Purchase_Receive_Ref_No,
            Purchase_Order_Ref_No: receive?.Purchase_Order_Ref_No ,xml_string_refno, option}).then(res => {
          if(res?.data !== undefined){
            const {message_text} = res.data[0];
            logicContext.showToast(message_text, 'success');
            props.reloadPurchaseOrder();
          }
      }).catch((err)=> {
        logicContext.showToast('Error', 'error');
      });
      }
    });
  };


  const viewItemDetails = (id) => {
    navigate(`/${currModuleId}/${currModuleName}/purchase-receives/details/${id}`)
  }



  return (
    <section className="   mt-2 w-full max-w-6xl text-filterTextColor">
      <Grid className="mt-2   " container spacing={2}>
        <Grid className=" border-2 ml-3" container spacing={2}>
          <Grid item xs={12}>
            {/* <ReceiveTable  tableData={receiveDetails?.Bill_Information} ></ReceiveTable> */}
            <div className="mt-2 ">
    <h6  className="text-center text-center font-bold" >Purchase Received List </h6>

  
  <TableContainer className="mt-3 w-full">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <colgroup>
                    <col style={{width:'25%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'25%'}}/>
                    <col style={{width:'5%'}}/>
                    <col style={{width:'5%'}}/>
                </colgroup>
              <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                <TableRow>
                  <TableCell className="subheading">Ref. NO</TableCell>
                 
                  <TableCell className="subheading">Receive NO</TableCell>
                  <TableCell className="subheading"> Status </TableCell>
                  <TableCell className="subheading"> Date </TableCell>
                  <TableCell className="subheading">  </TableCell>
                  <TableCell className="subheading">  </TableCell>
              
           
                </TableRow>
              </TableHead>
  
              <TableBody className=" mb-3">
                {tableData?.map((item, i) => {
                  return (
                    <TableRow className="border-1"
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell  className="subheading text-primary" onClick={()=> viewItemDetails(item.Purchase_Receive_Ref_No) }
                        align="left">{item.Purchase_Receive_Ref_No} </TableCell>
                      <TableCell  className="subheading text-primary"  align="left" onClick={()=> viewItemDetails(item.Purchase_Receive_Ref_No) }
                      >{item.Purchase_Receive_Number} </TableCell>
                      <TableCell  className="subheading" align="left">{item.Purchase_Receive_Status_Name} </TableCell>
                      <TableCell  className="subheading" align="left">{item.Created_Date_String}  </TableCell>
                      
                      <TableCell  className="subheading" align="left">
                        <Tooltip title="delete purchase receive">
                        <DeleteForeverIcon onClick={()=> deletePurchaseReceive(item)}
                        fontSize="large"  className="text-danger" />
                        </Tooltip>
                    
                      </TableCell> 
                      <TableCell  className="subheading" align="left">
                        {(item.Purchase_Receive_Status_Id !== 4) && 
                            <Tooltip title="convert purchase receive to bill">
                            <CreditScoreIcon onClick={()=> convertReceiveToBill(item)}
                              fontSize="large"  className="text-primary" />
                              </Tooltip>
                        }
                    
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
  </div>
          </Grid>    </Grid>
      </Grid>

   

    </section>
  );
}

export default PurchaseOrderReceiveTab;
