import "./App.css";


// pages
import HomePage from "./pages/HomePage";
import { Route, Routes } from "react-router-dom";
import ForgotPassword from "./pages/ForgotPasswordPage";
import NotFoundPage from "./pages/NotFoundPage";
import LoginPage from "./pages/LoginPage";

import SubscriptionRoutes from "./routes/SubscriptionRoutes";

import UsersRoutes from "./routes/UsersRoutes";
import CustomerTableNew2 from "./pages/CustomerTableNew2";

import InventoryRoutes from "./routes/InventoryRoutes";
import AccountRoutes from "./routes/AccountRoutes";
import NotificationRoutes from "./routes/NotificationRoutes";
import axios from "axios";
import { authenticationService } from "./services/authentication.service";
import { useContext, useEffect } from "react";
import LogicContext from "./Context/LogicContext";
import {spinnerService } from './services/spinner.service'
import TableOneBackup from "./components/TableOneBackUp";
import TableTwoBackup from "./components/TableTwoBackup";
import {ResetAssociateTableList, ResetItemToEdit}  from './store/itemAtom';
// import { Link, useLocation } from "react-router-dom";

import ModalContainer from 'react-modal-promise';
import TableBackup3 from "./components/TableBackup3";
import SettingsRoutes from "./routes/SettingsRoutes";
import socketModule from "./socketModule";

// const socket = io("localhost:8080",{ 
//   transports: ['websocket', 'polling'],
//   debug: true
// });
// const socket = io("/");










function App () {
  const logicContext = useContext(LogicContext);
  // const { pathname } = useLocation();
  const resetItemToEdit = ResetItemToEdit();
  const resetAssociateTableData = ResetAssociateTableList();
  useEffect(()=> {
    resetAssociateTableData();
    resetItemToEdit();
  },[resetAssociateTableData, resetItemToEdit])


  useEffect(()=> {
    socketModule.on('connect', (msg) => {
      console.log('Socket connect success ', msg);
    });


    socketModule.on("ivr-process_schedule_success", (data)=> {
      console.log('Success ', data);
      logicContext.showToast(` ${data?.msg}`, 'success');
    });


  
       // Cleanup on component unmount
       return () => {
        socketModule.off('message');
      };

  },[]);

  
 
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL_GET;
  axios.interceptors.request.use(function(config){
    spinnerService.showSpinner()
  return config;

  }, function(error){
    spinnerService.disableSpinner();
    return Promise.reject(error);
   
  });


  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    spinnerService.disableSpinner();
    return response;
  }, function (error) {
    console.log('ERRr ', error)
    spinnerService.disableSpinner();
    // Any status codes that falls outside the range of 2xx cause this function to trigger
      const {status} = error.response;
      const errorMessage =  error.response.data.msg;
   
    if(status === 403){
      logicContext.showToast('Authentication token expired!', 'error');
      // navigate('/login',  { replace: true });
       return authenticationService.Logout();

    }else if(status === 401) {
      return logicContext.showToast(`error : ${errorMessage ? errorMessage:
         'Something went wrong,please contact Admin'}`, 'error');

    }else if(status === 422) {
      return logicContext.showToast(`error : ${errorMessage ? errorMessage:
         'Something went wrong,please contact Admin'}`, 'info');

    }else if(status === 404) {
      return logicContext.showToast(`Error: ${errorMessage ? errorMessage:
        'Something went wrong,please contact Admin'}`, 'error');
   
    }else if(status === 400) {
      return logicContext.showToast(`Error: ${errorMessage ? errorMessage:
        'Something went wrong,please contact Admin'}`, 'error');
   
    }else if(status === 503) {
      return logicContext.showToast(`Requirement: ${errorMessage ? errorMessage:
        'Something went wrong,please contact Admin'}`, 'info');
   
    }else if(status === undefined) {
      return logicContext.showToast(`Error: ${errorMessage ? errorMessage:
        'Something went wrong,please contact Admin'}`, 'warning');
    }
  
   
    Promise.reject(error);
    spinnerService.disableSpinner();
    return  logicContext.showToast('Connection error', 'error');
  })

 
    return (
    <> 
    
      <ModalContainer />
      <Routes>
        
   
      
      <Route path="/backup-table3" element={<TableBackup3/>}></Route>
      <Route path="/table-one-backup" element={<TableOneBackup/>}></Route>
      <Route path="/table-two-backup" element={<TableTwoBackup/>}></Route>
      {/* <Route path="/tabletwo" element={<TableTwoBackup/>}></Route> */}
 
      
      <Route path="/tablenew2" element={<CustomerTableNew2/>}></Route>
      <Route path="/" element={<HomePage />}></Route>
      <Route path="/login" element={<LoginPage />}></Route>
   
      <Route path="/forget-password" element={<ForgotPassword />}></Route>

      {/* Module routes */}
      <Route path="/:id/Subscription/*" element={<SubscriptionRoutes />}></Route>
      <Route path="/:id/Inventory/*" element={<InventoryRoutes />}></Route>
      <Route path="/:id/Accounts/*" element={<AccountRoutes />}></Route>
      <Route path="/:id/Notification/*" element={<NotificationRoutes />}></Route>
      <Route path="/:id/Settings/*" element={<SettingsRoutes />}></Route>

      <Route path="/Users/*" element={<UsersRoutes />}></Route>
      <Route path="/*" element={<NotFoundPage />}></Route>
      </Routes>
      </>
    )
  }

 
export default App;
