import React, {useEffect,useState,useContext, useRef,useCallback} from "react";
import "./vendor.css";


import { useNavigate } from "react-router-dom";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import AddVendorGeneralInfoTab from "./AddVendorGeneralInfoTab";
import AddVendorAddressTab from "./AddVendorAddressTab";
import AddVendorOtherInfoTab from "./AddVendorOtherInfoTab";
import { customerService } from "../../services/customer.service";
import {SetEntryData, GetEntryData, GetVendorEdit, ResetVendorEdit} from '../../store/vendorAtom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}






function AddVendorPage() {
  const navigate = useNavigate();
  const resetVendorEdit = ResetVendorEdit();
  const setEnytryMasterData =  SetEntryData();
  const vendorToEdit =  GetVendorEdit();
  const [tabValue, setTabValue] = useState(0);


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

 
  useEffect(() => {
    let isMounted = true;
    if (isMounted){
      customerService.getMasterData().then((res) => { 
        if(res.data !== undefined){
          setEnytryMasterData(res.data)
        }
        // setMasterData(res?.data) 
      });
    }

    return ()=> {
      isMounted = false;
      resetVendorEdit()
    }
  
  }, [])





  const onDynamicTabSelect = (id)=> {
    if(id === 3){
      navigate(-1)
    }else{
      setTabValue(id);

    }
  }


  return (
    <>

<Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
          <Tab  className="btn " label="General Information" {...a11yProps(0)} />
          <Tab  className="btn "  disabled={vendorToEdit?.Vendor_Id ? false: true }  label="Address Information" {...a11yProps(1)} />
          <Tab  className="btn " disabled={vendorToEdit?.Vendor_Id ? false: true }  label="Other Information" {...a11yProps(1)} />
          {/* disabled={vendorToEdit?.Vendor_Id ? false: true }  */}
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
      <AddVendorGeneralInfoTab  onDynamicTabSelect={onDynamicTabSelect} />
      </TabPanel>
      {/* Image tab */}
      <TabPanel value={tabValue}  index={1}>
      <AddVendorAddressTab  onDynamicTabSelect={onDynamicTabSelect} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
      <AddVendorOtherInfoTab  onDynamicTabSelect={onDynamicTabSelect} />
      </TabPanel>
    
    </Box>

   
    </>
  );
}

export default AddVendorPage;
