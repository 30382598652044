import React, { useEffect, useState } from "react";

// import "../../../scrollBar.css";
import "../../components/AddProduct.css";
import Card from "@mui/material/Card";
import loan from "../../assets/loan.svg";
import {  useParams } from "react-router-dom";
import underscore from "underscore";
import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import { GetChartList, GetChartTableFilter } from "../../store/chartOfAccountAtom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid } from "@material-ui/core";




function ChartAccountScroll(props) {
  const getChartList = GetChartList();
  const [tableData, setTableData] = useState([]);
  const { chart_id } = useParams();
  const getchartFilter = GetChartTableFilter();

  const [bulkActionData, setBulkActionData] = useState(getchartFilter);
  // const label = { inputProps: { 'aria-label': 'Switch demo' } }


  useEffect(() => {
    if (getChartList?.length > 0) {
      processChartList(getChartList);
    }
  }, [getChartList]);


  const processChartList = (list)=> {
    let hashTable = {};
    list?.forEach((chart) => {
      hashTable[chart.Account_Id] = { ...chart, children: [],
        label: chart?.Account_Name, value: chart, checked: false };
    });
    const groupItems = underscore.groupBy(list, "Parent_Account_Id");

    for (const [key, valueList] of Object.entries(groupItems)) {
      if (hashTable[key]) {
        hashTable[key].children = valueList?.map((account) => {
          return hashTable[account.Account_Id];
        });
      }
    }

    for (const [key, valueList] of Object.entries(hashTable)) {
      hashTable[key]?.children?.map((account) => {
        const tempData = hashTable[account.Account_Id];
        delete hashTable[account.Account_Id];

        tempData.children.map((grandChild) => {
          delete hashTable[grandChild.Account_Id];
        });
      });
    }

    let temp = [];
    for (const [key, value] of Object.entries(hashTable)) {
      temp.push(value);
    }
 
    setTableData(temp);
  }




  function paginate(e) {
    let tempList = getChartList?.slice(0, Number(e.target.value));
    tempList = tempList?.map((item) => {
      return { ...item, checked: false };
    });
    setTableData([...tempList]);
  }



  const onSelectBulkAction = (action) => {
    const option = action.target.value;
    if (option === "") {
     return processChartList(getChartList)
    }
    var newList = getChartList.filter(
      (item) => item.Account_Status_Id === option
    );
    processChartList(newList)
  };



  const onCheckSelection = async (e, item) => {

    item.checked = e.target.checked;
    let newChildren = [];
    if(item?.children?.length > 0){
      newChildren = item?.children?.map((child)=> {
        if(child?.children?.length > 0){
        
         let newChildren = child?.children?.map((grandChild) => {
      return {...grandChild, checked:  e.target.checked}
          });

          return {...child, checked: e.target.checked, children:newChildren}
        }else{
          return {...child, checked: e.target.checked}
        }
       
      })
    }
    item.children = newChildren;
   
  
   let tempData = tableData.map((element)=> {
        if(element.Account_Id === item.Account_Id ){
           return item;
        }else{
          return element;
        }
    });
      props?.onSelection(tempData);
    setTableData([...tempData])

      
     };



  return (
    <>
      <section className="   w-35  h-[100vh]">
        <div className=" mt-1 flex  rounded ml-1 pr-4 ">
          <ActionPaneComponent
            bulkActionData={bulkActionData}
            onSelectBulkAction={onSelectBulkAction}
          />
        </div>
        
        {/* <ChartOfAccountExpandChild expandChildren={expandChildren} /> */}

        <section className=" scroll w-full  z-0 mt-3	main border h-[100vh]">
          <div className="  px-3 ">
            {tableData?.map((item, i) => {
              return (
                <Card key={i}
                style={{ width: '100%'}} 
                  className={ "row " + (chart_id == item?.Account_Id
                      ? "app_bg_color1 two-img  expand  mb-2 cursor mb-2 "
                      : "two-img w-full expand  mb-2 cursor mb-2 ")
                  }
                >
                  <div className="scroll_text grid  grid-col-3 grid-flow-col gap-4 ">
                    <div>
                      {" "}
                      <span className="">
                        {" "}
                        <input
                          type="checkbox"
                          onChange={(e) => onCheckSelection(e, item)}
                          checked={item?.checked ? item?.checked : ""}
                        />
                      </span>
                    </div>

                    <div>
                      {" "}
                      <span>
                        {" "}
                        <img className="pt-1" src={loan} alt="" />
                      </span>
                    </div>

                    <div>
                      {" "}
                      <span
                        onClick={() => props.viewItemDetails(item.Account_Id)}
                        style={{ color: "#4999F6" }}
                        className=""
                      >
                        {item.Account_Name?.slice(0, 25)}
                      </span>
                      <br></br>
                      <i>
                        {" "}
                        <span className="text-xs mr-2">
                          {item.Account_Type_Name}{" "}
                        </span>
                      </i>
                    </div>
                  </div>
                  <div className="ml-5 cleartext w-full ">
                    {item?.children?.length > 0 && (
                      <Accordion style={{ width: '100%'}} >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{item?.children?.length} </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {item?.children?.map((child, i) => {
                            return (
                              <div  key={i}  className="border-2 px-2 py-2">
                                <Grid container spacing={1}>
                                  <Grid  item xs={6} md={4}>
                                    <div>
                                      {" "}
                                      <span className="">
                                        {" "}
                                        <input
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                          }}
                                          type="checkbox"
                                          onChange={(e) => onCheckSelection(e, child)}
                                          checked={ child?.checked ? child?.checked : "" }/>
                                      </span>
                                    </div>
                                  </Grid>
                                  <Grid  item xs={6} md={8}>
                                    <div>
                                      <span
                                        onClick={() => props.viewItemDetails(child.Account_Id )}
                                        style={{ color: "#4999F6" }}
                                        className="text-sm" >
                                        {child.Account_Name?.slice(0, 25)}
                                      </span>
                                      <br></br>
                                      <i>
                                        <span className="text-xs mr-2">
                                          {child.Account_Type_Name}{" "}
                                        </span>
                                      </i>
                                    </div>
                                  </Grid>
                                </Grid>

                                {/* grand child */}
                                {child.children.length > 0 && (

<Accordion style={{ marginLeft:"10px"}} key={i} >
<AccordionSummary 
  expandIcon={<ExpandMoreIcon  />}
  aria-controls="panel1a-content"
  id="panel1a-header">

    {child.children.length}{" "}

</AccordionSummary>
<AccordionDetails className="border-2">
  <Typography>
    {child?.children?.map((grandChild, i) => {
      return (
        <div key={i} className=" ">
          <div  container className="row">
            <div className="col-4" >
              <div>
                {" "}
                <span className="">
                  {" "}
                  <input  style={{ width: "15px",height: "15px", }}
                    type="checkbox"
                    onChange={(e) => onCheckSelection(e, grandChild) }
                    checked={grandChild?.checked ? grandChild?.checked : "" }
                  />
                </span>
              </div>
            </div>

            <div item className="col-8">
              <div>
                <span
                  onClick={() =>props.viewItemDetails( grandChild.Account_Id) }
                  style={{ color: "#4999F6" }} className="text-sm" >
                  {grandChild.Account_Name?.slice(0,25)}
                </span>
                <br></br>
                <i>
                  <span className="text-xs mr-2">
                    {
                      grandChild.Account_Type_Name
                    }{" "}
                  </span>
                </i>
              </div>
            </div>
          </div>
        </div>
      );
    })}
  </Typography>
</AccordionDetails>
</Accordion>

                                ) }

                              

                                {/* end start end */}
                              </div>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </div>
                </Card>
              );
            })}
          </div>
        </section>

        <div className="clearfix mt-3 ">
          <div className="float-left">
            <div className="mr-3 pt-2">PAGINATE</div>
          </div>
          <div className="">
            <select style={{ backgroundColor: "#F1F1F1" }} onChange={paginate}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChartAccountScroll;
