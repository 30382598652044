import Select from 'react-select';
import { GetProductMasterData} from '../store/productAtom';

const customStyles = {
  control: base => ({
    ...base,
    height: 54,
    backgroundColor:'#F1F1F1',
    width:320,
  }),
  indicatorSeparator: styles => ({ ...styles, display : 'none' }),
  placeholder: styles => ({ ...styles, display : 'none' }),
  valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"40px", marginLeft:"13px"}),
  option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
}


function PlanSelect(props) {
  const masterData = GetProductMasterData();

  console.log('MASTer_comp ', masterData);
  const customOption = masterData[1]?.map((item)=> {
    return ({...item, label:`${item.Name}`})
  })




    const handleChange = (value) => {
      props.selectCompositeItems(value)
      };


    return ( 
        <>
   
         <Select  styles={customStyles}
          defaultValue={props?.defaultVal}
         onChange={handleChange} options={customOption} />


        </>
     );
}

export default PlanSelect;
