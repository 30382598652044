import { useEffect, useState } from "react";
import Select from 'react-select';
import { GetManualJournalAtom } from "../../store/manualJounalAtom";



function ManualJournalType(props) {
  const JournalData = GetManualJournalAtom();

  const customOption = JournalData.journal_type?.map((item)=> {
    return ({...item, label:`${item.journal_type_name}`})
  })

  
  const customStyles = {
    control: base => ({
      ...base,
      height: 45,
      backgroundColor: '#fff',
      width: 200,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#fff', color:"black"}),
  }

    const handleChange = (value) => {
      props.onTypeSelect(value)
      };


    return ( 
        <>
   
         <Select  placeholder={""}   styles={customStyles} onChange={handleChange} 
          defaultValue={props?.defaultVal}
          options={customOption}
         />


        </>
     );
}

export default ManualJournalType;
