import ItemGroupPrimaryImageUpload from "./ItemGroupPrimaryImageUpload";



const ItemGroupImageUpload = (props) => {
 

  

  return (

    <>
   
    <div className="flex flex-col items-center justify-center">
      {/* Top image */}
      <div className="mb-4 ">
      <ItemGroupPrimaryImageUpload defaultImage={props?.getListToEdit[0]?.Picture_URL} Item_Id={props?.Item_Id}  Picture_Id={props?.getListToEdit[0]?.Picture_Id} />
      </div>

      {/* Bottom four images */}
      <div className="grid grid-cols-4 gap-4">
      <ItemGroupPrimaryImageUpload defaultImage={props?.getListToEdit[1]?.Picture_URL}  Item_Id={props?.Item_Id} Picture_Id={props?.getListToEdit[1]?.Picture_Id} />
      <ItemGroupPrimaryImageUpload defaultImage={props?.getListToEdit[2]?.Picture_URL} Item_Id={props?.Item_Id} Picture_Id={props?.getListToEdit[2]?.Picture_Id} />
      <ItemGroupPrimaryImageUpload defaultImage={props?.getListToEdit[3]?.Picture_URL} Item_Id={props?.Item_Id}  Picture_Id={props?.getListToEdit[3]?.Picture_Id} />
      <ItemGroupPrimaryImageUpload defaultImage={props?.getListToEdit[4]?.Picture_URL} Item_Id={props?.Item_Id}  Picture_Id={props?.getListToEdit[4]?.Picture_Id} />
      </div>
      <small>NB - You can replace an image by clicking on it</small>
    </div>
    </>
   

  );
};
export default ItemGroupImageUpload;