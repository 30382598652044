import React, { useEffect, useState } from 'react';
import CurrencyFormat from "react-currency-format"; 
import moment from "moment";
import { formatCustomDate } from '../../helpers/formatCustomDate';
import { formatCustomNumber } from '../../helpers/formatCustomNumber';
import DocumentViewer from '../../helpers/DocumentViewer';

let totalDebt = 0;
let totalCredit = 0;

const ManualJournalDetailsContent =  React.forwardRef((props, ref) => {
    const {details, detailsList, id} = props;
    const [totalDebt, setTotalDebt] =  useState(0);
    const [totalCredit, setTotalCredit] =  useState(0);

console.log('details', details);
console.log('detailsList', detailsList);

  const manageStatus = (statusIs, statusName) => {
    let defaultClass = "bg-primary text-white";

    switch (statusIs) {
      case 1:
        defaultClass = "text-light bg-danger";
        break;
      case 2:
        defaultClass = "text-light bg-success";
        break;
      case 3:
        defaultClass = "text-light bg-warning";
        break;
      case 4:
        defaultClass = "text-light bg-info";
        break;

      default:
        break;
    }

    return (
      <h3
        className={`py-2.5 px-4 text-sm font-bold ${defaultClass} rounded`}
      >
        { statusName}
      </h3>
    );
  };


  useEffect(()=> {
    calculateDebtCredit(detailsList);

  },[detailsList]);

  const calculateDebtCredit = ((detailsList)=> {
  
    let tempDebt = 0;
    let tempCredit = 0;
    detailsList.forEach((table)=> {
      tempCredit += table.credit_amount  || 0;
      tempDebt += table.debit_amount  || 0;

    });
   
    setTotalDebt(tempDebt);
    setTotalCredit(tempCredit);
    // if(credit > debt){
    //   creditDiff = credit - debt;
    // }

    // if(debt > credit){
    //   debtDiff = debt - credit ;
    // }

  });

  return (
    <div   ref={ref} className="bg-white shadow-lg rounded-lg p-8  mx-auto">
   {/* content */}
   <div className="bg-white shadow-md rounded-md max-w-2xl mx-auto my-8 p-6">
      <header className="flex justify-between items-center border-b pb-4">
        <h1 className="text-xl font-bold uppercase">Journal</h1>
        <p className="text-sm text-gray-500">#{details?.reference_number} </p>
        <h3 className="text-xl font-bold uppercase m-2 p-2 ">{manageStatus(details?.status_id, details?.status_name)} </h3>

      </header>

      <div className="mt-4">
        <div className="flex justify-between">
          <p className="text-sm text-gray-600">
            <span className="font-medium">Date:</span> {formatCustomDate(details?.journal_date)} </p>
          <p className="text-sm text-gray-600">
            <span className="font-medium">Amount:</span> NGN {formatCustomNumber(totalCredit)}
          </p>
        </div>
        <p className="mt-2 text-sm text-gray-600">
          <span className="font-medium">Reference Number:</span> {details?.account_ref_no}
        </p>
        <p className="mt-2 text-sm text-gray-600">
          <span className="font-medium">Journal Type:</span> {details?.journal_type_name}
        </p> 
        <p className="mt-2 text-sm text-gray-600">
          <span className="font-medium">Transaction Type:</span> {details?.transation_type_name}
        </p>
        <p className="mt-2 text-sm text-gray-600">
          <span className="font-medium">Notes:</span> {details?.notes}
        </p>
      </div>

      <table className="w-full mt-6 border-collapse border border-gray-200">
        <thead className="bg-gray-100">
          <tr>
            <th className="border border-gray-200 px-4 py-2 text-left text-sm font-medium text-gray-700">
              Account
            </th>
            <th className="border border-gray-200 px-4 py-2 text-left text-sm font-medium text-gray-700">
              Contact
            </th>
            <th className="border border-gray-200 px-4 py-2 text-right text-sm font-medium text-gray-700">
              Debits
            </th>
            <th className="border border-gray-200 px-4 py-2 text-right text-sm font-medium text-gray-700">
              Credits
            </th>
          </tr>
        </thead>
        <tbody>
          {detailsList?.map((item)=> {

            return (
              <>
                 <tr>
            <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">
              {item?.account_name} 
            </td>
            <td className="border border-gray-200 px-4 py-2 text-sm text-gray-600">-</td>
            <td className="border border-gray-200 px-4 py-2 text-right text-sm text-gray-600">
              {formatCustomNumber(item?.debit_amount)}      </td>
            <td className="border border-gray-200 px-4 py-2 text-right text-sm text-gray-600">
              {formatCustomNumber(item?.credit_amount)}
            </td>
          </tr>
     
              
              </>
            )
          })}
       
        </tbody>
        <tfoot>
          {/* <tr className="bg-gray-100">
            <td className="border border-gray-200 px-4 py-2 text-sm font-medium text-gray-700">
              Sub Total
            </td>
            <td className="border border-gray-200 px-4 py-2">-</td>
            <td className="border border-gray-200 px-4 py-2 text-right text-sm font-medium text-gray-700">
              2,000.00
            </td>
            <td className="border border-gray-200 px-4 py-2 text-right text-sm font-medium text-gray-700">
              2,000.00
            </td>
          </tr> */}
          <tr>
            <td className="border border-gray-200 px-4 py-2 font-bold text-gray-800">
              Total
            </td>
            <td className="border border-gray-200 px-4 py-2">-</td>
            <td className="border border-gray-200 px-4 py-2 text-right font-bold text-red-600">
              NGN {formatCustomNumber(totalDebt)}
            </td>
            <td className="border border-gray-200 px-4 py-2 text-right font-bold text-green-600">
              NGN {formatCustomNumber(totalCredit)}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

   {/* content end */}
 
   {details?.attachement_url  &&
   <div class="flex justify-center">
   <a class="btn btn-primary" rel="noreferrer" href={details?.attachement_url} target="_blank">Open Attachment</a>
 </div>
   }

 
  



    </div>
  );
});

export default ManualJournalDetailsContent;
