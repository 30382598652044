import React, { Fragment, useState, useEffect, useContext } from "react";
import { useRecoilValue } from "recoil";
import { customerListAtom } from "../../store/customerAtom";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SetCustomerListAtom,
  GetCustomerListAtom,
  GetCustomerListBackUpAtom,
  SetCustomerListBackUpAtom,
} from "../../store/customerAtom";
import CustomerContext from "../../Context/CustomerContext";
import { customerService } from "../../services/customer.service";
import { debounce } from "lodash";
import LogicContext from "../../Context/LogicContext";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
// import MagnifyingGlass from "../assets/menu_icons/magnifyingGlass.svg";

function CustomerSearch() {
  const logicContext = useContext(LogicContext);
  const [searchResult, setSearchResult] = useState([]);
  const navigate = useNavigate();
  const customerListBackup = GetCustomerListBackUpAtom();
  const setCustomerListAtom = SetCustomerListAtom();
  const setCustomerAtomListBackUp = SetCustomerListBackUpAtom();
  const customerContext = useContext(CustomerContext);
  const [textInput, setTextInput] = useState("");

  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  useEffect(() => {
    if (customerListBackup.customers.length < 1) {
      getCustomerListData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerListBackup.customers.length]);

  const getCustomerListData = async () => {
    customerContext.getCustomerTableHeader();
    const response = await customerService.getCustomerListCache(0, 50);
    if (response.data?.customers?.length) {
      setCustomerListAtom(response?.data);
      setCustomerAtomListBackUp(response?.data);
    }
  };

  function selectedCustomerInSearchResult(customer: any) {
    const { Customer_Id } = customer;
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/customer-details/${Customer_Id}`
    );
  }

  const debouncedSearch = React.useRef(
    debounce(async (search: any) => {
      processSearch(search);
    }, 1500)
  ).current;

  function processSearch(search: string) {
    if (search?.length > 2) {
      customerService.getCustomerBySearch(search).then((res) => {
        if (res?.data?.length) {
           setSearchResult(res?.data);
           return;
        } else {
          logicContext.showToast(
            "No available record for your search query! ",
            ""
          );
          return setSearchResult([]);
        }
      });
    } else {
      // setSearchResult([]);
    }
  }

  return (
    <>
      <Combobox>
        <div className="relative">
          <div className="relative border-[1px] border-gray-200 rounded-md">
            <Combobox.Input
              className="w-full z-40 border-none py-2 pl-10 text-sm leading-5 text-gray-700 max-w-[350px] h-[40px] bg-white rounded-md overflow-hidden ring-0 focus:ring-1 focus:ring-[#1976D2]"
              // displayValue={(person) => person.name}
              placeholder="Search customer"
              onChange={(event) => debouncedSearch(event.target.value)}
              // onChange={(event) => setTextInput(event.target.value)}
            />
            <Combobox.Button className="absolute top-2 left-2.5 z-50">
              {/* <img
                src={MagnifyingGlass}
                alt="search_icon"
                className="h-6 w-6"
              /> */}
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setTextInput("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">

              {searchResult.length === 0 && textInput !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                searchResult.map((customer: any, i: number) => (
                  <Combobox.Option
                    key={i}
                    onClick={() => selectedCustomerInSearchResult(customer)}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                        active ? "bg-gray-100 text-white" : "text-gray-700"
                      }`
                    }
                    value={customer}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={` ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {customer.Name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-gray-700"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </>
  );
}

export default CustomerSearch;
