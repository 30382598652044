import React, { useEffect, useState, useContext } from "react";
// import "../pages/Plan.css";

import LogicContext from "../../Context/LogicContext";
import NotificationContext from "../../Context/NotificationContext";
import {
  SetChartEntryMasterData,
  GetChartEntryMasterData,
  GetChartEdit,
  ResetChartEdit,
} from "../../store/chartOfAccountAtom";
import { useForm } from "react-hook-form";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import EntrySelect from "./ChartOfAccountTypeSelect";
import { accountService } from "../../services/accountService";
import ChartOfAccountTypeSelect from "./ChartOfAccountTypeSelect";
import ChartOfAccountParentName from "./ChartOfAccountParentName";
import ChartOfAccountCurrency from "./ChartOfAccountCurrency";
import ChartMakeSubAccount from "./ChartmakeSubAccount";
import ChartAccountAddWatchList from "./ChartAccountAddWatchList";
import ChartAccountShowAsActive from "./ChartAccountShowAsActive";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function AddNewChartOfAccountPage() {
  const setMasterData = SetChartEntryMasterData();
  const getMasterData = GetChartEntryMasterData();
  const getChartEdit = GetChartEdit();
  const resetChartEdit = ResetChartEdit();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const logicContext = useContext(LogicContext);
  const notificationContext = useContext(NotificationContext);
  const myPromiseModal = createModal(GeneralModal);
  const navigate = useNavigate();
  const [showSubAccount, setShowSubAccount] = useState(false);
  const [trackAccountType, setTrackAccountType] = useState(0);
  const [filteredAccount, setFilteredAccount] = useState([]);


  useEffect(() => {
    setValue("Currency_Id", 1);
    if (getChartEdit?.Account_Id) {
      setValue("Account_Id", getChartEdit?.Account_Id);
      setTrackAccountType(getChartEdit?.Account_Type_Id);

      setValue("Account_Type_Id", getChartEdit?.Account_Type_Id);
      setValue("Account_Name", getChartEdit?.Account_Name);
      setValue("Account_Number", getChartEdit?.Account_Number);
      setValue("Account_Description", getChartEdit?.Account_Description);
      setValue("Currency_Id", getChartEdit?.Currency_Id);
      setValue("Currency_Id", getChartEdit?.Currency_Id);
      setValue("Show_WatchList_Id", getChartEdit?.Show_WatchList_Id);
      setValue("Account_Status_Id", getChartEdit?.Account_Status_Id);
      if (getChartEdit?.Parent_Account_Name !== null) {
        setShowSubAccount(true);
        setValue("Parent_Account_Id", getChartEdit?.Parent_Account_Id);
      }
    }



    return () => {
      resetChartEdit();
      setMasterData({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChartEdit?.Account_Id]);

  useEffect(()=> {
    if(!getMasterData['Account_Main']){
      accountService.getChartEntryMasterData().then((response) => {
        if (response?.data !== undefined) {
          setMasterData(response.data);
        }
      });
    }

  },[]);

  const filterSubAccountList = (type_id) => {
    const customOption = getMasterData["Parent_Account"]?.map((item) => {
      return { ...item, label: `${item.Account_Name}` };
    });
    const selections = customOption?.filter((acc) => {
      if (acc.Account_Type_Id === type_id) {
        return acc;
      }
    });
    setFilteredAccount(selections);
  };


  function onAccountTypeSelect(selection) {
    setValue("Account_Type_Id", selection.Account_Type_Id);
    setTrackAccountType(selection.Account_Type_Id);
    filterSubAccountList(selection.Account_Type_Id);
  }

  function subAccountSelect(decision) {
    setShowSubAccount(decision);
  }


  function onParentNameSelect(selection) {
    setValue("Parent_Account_Id", selection.Account_Id);
  }


  function onCurrencySelect(currency) {
    setValue("Currency_Id", currency?.Currency_Id);
  }


  function addToWatchList(selection) {
    setValue("Show_WatchList_Id", selection);
  }

  function addShowAsActive(selection) {
    setValue("Account_Status_Id", selection);

  }

  const onSubmit = async () => {
    let formData = getValues();
    let option = 1;
    if (getChartEdit?.Account_Id !== undefined) {
      option = 2;
    }
    const checkValue = getValues("Account_Type_Id");
    if (checkValue === undefined) {
      return logicContext.showToast("Account type is required", "error");
    }

    await myPromiseModal({
      title: "Confirmation", body: " Do you want to save this record?",
      showInput: false, placeHolder: ''
    }).then((res) => {

      if (res.decision === true) {
        accountService.saveNewChartAccount({ ...formData, option: option }).then((response) => {

          if (response?.data !== undefined) {
            const { message_text, message_id } = response.data[0];
            if (message_id === 1) {
              logicContext.showToast(message_text, "success");
              notificationContext.reloadChartList();
              sessionStorage.setItem('reloadPage', 'true');
              navigate(-1);
            } else {
              return logicContext.showToast(message_text, "error");
            }
          }
        })
          .catch((err) => {
            return logicContext.showToast("Error saving record!", "error");
          });
      }
    });
  };

  function onErrors(error) {
    console.error(errors);
    logicContext.showToast("One or more value is required", "error");
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <section className="   mt-3 ">

          {/* New Grid */}
          <div className="container mt-5 max-auto h-auto">
            <div className="row">
              <div className="col">

                <div>
                  {/* Account type select */}
                  <div className=" ">
                    <span className="text-sm text-danger">Account Type </span>
                    <div className="plain_input">
                      <ChartOfAccountTypeSelect
                        defaultVal={{ label: getChartEdit?.Account_Type_Name }}
                        onAccountTypeSelect={onAccountTypeSelect}
                      />
                    </div>
                  </div>

                  <div className=" mt-3  flex">
                    <div>
                      {" "}
                      <ChartMakeSubAccount
                        defaultVal={showSubAccount}
                        subAccountSelect={subAccountSelect}
                      />
                    </div>
                    <div className="mt-[0.3vh]">
                      {" "}
                      <span className="text-xs ml-2">
                        Make this a sub account
                      </span>
                    </div>
                  </div>
                </div>

                {showSubAccount && (

          <div>
            <span className="text-sm ">Parent Account</span>
            <div className="plain_input">
              <ChartOfAccountParentName
                subList={filteredAccount}
                defaultVal={{ label: getChartEdit?.Parent_Account_Name }}
                onParentNameSelect={onParentNameSelect}
              />
            </div>
          </div>

          )}

                {/* account Name */}
                <div className="">
                  <span className="text-sm text-danger">Account Name</span>
                  <div className=" name_input">
                    <div className="">
                      <label className="relative">
                        <span className="other_dimension_input plain_input">
                          <input
                            {...register("Account_Name", {
                              required: "Account Name is required!",
                            })}
                            className=" bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                            type="text"
                          />
                        </span>
                      </label>
                    </div>
                    <small className="text-danger">
                      {" "}
                      {errors?.Account_Name && errors.Account_Name.message}
                    </small>
                  </div>
                </div>

                
                   {/* parent account */}
               

             


              </div>



              {/* Second column */}
              <div className="col">
                {/* description */}
                <div className=" ">
                  <span className="text-sm text-center ">Description </span>
                  <div className="">
                    <textarea
                      {...register("Account_Description", {})}
                      className=" h-[20vh] w-[484px] bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="text"
                    />
                  </div>
                </div>

                {/* selections */}
                <div className="flex ">
                  <div className=" text-sm">
                    <div className="ml-2 flex">
                      <div>
                        {" "}
                        <ChartAccountAddWatchList defaultVal={getChartEdit?.Show_WatchList_Id}
                         addToWatchList={addToWatchList} />

                      </div>
                      <div className="mt-[0.3vh]">
                        {" "}
                        <span className="text-xs ml-2">
                          Add to watch list on my dashboard
                        </span>
                      </div>
                    </div>
                    <div className="ml-2 flex">
                      <div>
                        {" "}
                        <ChartAccountShowAsActive defaultVal={getChartEdit?.Account_Status_Id}
                          addShowAsActive={addShowAsActive} />
                      </div>
                      <div className="mt-[0.3vh]">
                        {" "}
                        <span className="text-xs ml-2">
                          Show as an active account,{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>


                {(trackAccountType === 1 || trackAccountType === 9) && (
                  <>
                  
                  {/*  currency */}
                  <div>
                    <span className="text-sm ">Currency</span>
                    <div className="plain_input">
                      <ChartOfAccountCurrency
                        defaultVal={{
                          label: getChartEdit?.Currency_Name_Short,
                        }}
                        onCurrencySelect={onCurrencySelect}
                      />
                    </div>
                  </div>


                  <div className="">
                  <span className="text-sm ">Account Number</span>
                  <div className=" name_input">
                      <label className="">
                        <span className="other_dimension_input plain_input">
                          <input
                            {...register("Account_Number", {
                            })}
                            className=" bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                            type="number"
                          />
                        </span>
                      </label>
                 
                  </div>
                </div>

                  </>

                  

                )}


              </div>


            </div>
          </div>


        </section>

        <section>
          <div className="row mx-2 mt-2">
            <div className="col">
              <div onClick={() => navigate(-1)}
                style={{ color: "#F17E7E", backgroundColor: "white" }}
                className="text-sm rounded float-left cursor"
              >
                CANCEL
              </div>

            </div>

            <div className="col">
              <div className="float-right ">
                <button
                  type="submit"
                  style={{ backgroundColor: "#476EF8" }}
                  className="text-sm  text-white px-3 rounded py-2 mr-[4.5vh]"
                >
                  SAVE
                </button>
              </div>
            </div>

          </div>
        </section>
      </form>

    </>
  );
}

export default AddNewChartOfAccountPage;
