import { atom , useSetRecoilState, useRecoilValue, selector} from "recoil";



 const manualAtom = atom({key:'manual_Journal_atom',default:{
    account_info:[], journal_type:[],transaction_type:[], chart_of_account:[], tax_info:[]}});
 const manualEditAtom = atom({key:'manual_Journal_edit_atom',default:[]});



export  const SetManualJournalAtom = () => useSetRecoilState(manualAtom);
export  const GetManualJournalAtom = () => useRecoilValue(manualAtom);


export  const SetManualJournalEditAtom = () => useSetRecoilState(manualEditAtom);
export  const GetManualJournalEditAtom = () => useRecoilValue(manualEditAtom);





