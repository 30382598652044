import React, { useContext, useRef } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import printer from "../../assets/Printer.png";
import pdf from "../../assets/pdf.png";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import trash from "../../assets/trash.png";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import {
  SetRecoilBillEdit,
  GetRecoilBillListData,
  SetRecoilBillListData,
} from "../../store/billsAtom";
import { purchaseService } from "../../services/purchase.service";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../Context/LogicContext";
import BillsDetailsScroll from "./BillsDetailsScroll";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid } from "@mui/material";
import BillHistory from "./BillHistory";
import { useReactToPrint } from "react-to-print";
import BillPaymentMade from "./BillPaymentMade";
import { BillWrapperComponent } from "./BillOverviewWrapper";
import { ImAttachment } from "react-icons/im";
import { FaFilePdf } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { IoPrintSharp } from "react-icons/io5";

const BillsDetailsPage = () => {
  const [billDetails, setBillDetails] = useState({});
  const [selectedList, setSelectedList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);
  const setBillToEdit = SetRecoilBillEdit();
  const billListData = GetRecoilBillListData();
  const setBillListData = SetRecoilBillListData();
  const logicContext = useContext(LogicContext);
  const [tabValue, setTabValue] = React.useState("1");
  const componentRef = useRef();

  const { bill_id } = useParams();
  const navigate = useNavigate();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    document.title = "Bill Details";
    let isMounted = true;
    purchaseService.getBillDetails(bill_id).then((res) => {
      if (isMounted && res.data !== undefined) {
        setBillDetails(res.data);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [bill_id]);

  function reloadBillDetails() {
    purchaseService.getBillDetails(bill_id).then((res) => {
      if (res.data !== undefined) {
        setBillDetails(res.data);
        setBillListData((prev) => {
          let newList = prev["Bill_Info"].map((item, index) => {
            if (item?.Bill_Ref_No === res.data.Bill_Ref_No) {
              return res.data;
            } else {
              return item;
            }
          });
          return { ...prev, Bill_Info: newList };
        });
      }
    });
  }


  async function bulkActions(action) {
    await myPromiseModal({
      title: "confirm your action!",
      body: `Do you want to ${action.Action_Name} ?`,
      showInput: false,
      placeHolder: "",
    }).then((result) => {
      if (result.decision === true) {
        let temp = "";
        const option = action.Procedure_Option;
        let currentExist = false;
        selectedList.map((item) => {
          if (item.Bill_Ref_No == bill_id) currentExist = true;
          temp += `<a> <refno> ${item.Bill_Ref_No} </refno> </a>`;
        });

        let xml_string_refno = `<root> ${temp} </root>`;
        purchaseService
          .setBillBulkAction({ xml_string_refno, option })
          .then((res) => {
            if (res?.data !== undefined) {
              const { message_text } = res.data[0];
              logicContext.showToast(message_text, "success");
              if (currentExist) {
                return reloadBillDetails();
              }

              // return getBillListApi();
            }
          });
      }
    });
  }

  const generalReloadBillList = async () => {
    purchaseService.getBillsList().then((response) => {
      if (response?.data !== undefined) {
        setBillListData(response.data);
      }
    });
  };

  const EditVendorDetails = async () => {
    const response = await myPromiseModal({
      title: "Modify Bill Record",
      body: " Do you want to modify this record?",
      showInput: false,
      placeHolder: "",
    });
    if (response.decision === true) {
      setBillToEdit(billDetails);
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
    }
  };

  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  function onSelection(list) {
    let tempList = list?.filter((item) => item.checked === true);
    setSelectedList(tempList);
  }

  function viewBillDetails(bill_id) {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/details/${bill_id}`
    );
  }

  function cloneBill() {
    let newClone = Object(billDetails);
    delete newClone["Bill_Ref_No"];
    delete newClone["Bill_Number"];
    setBillToEdit(newClone);
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
  }

  function newBill() {
    navigate(
      `/${currModuleId}/${currModuleName}/${"bills"}/add-new/?vendor_id=${
        billDetails?.Vendor_Id
      }&bill_number=${billDetails?.Bill_Number}`
    );
  }

  function viewVendor(vendor_id) {
    navigate(
      `/${currModuleId}/${currModuleName}/${"vendors"}/details/${vendor_id}`
    );
  }

  async function customActionsSingle(actionNumber) {
    var action = {};
    if (actionNumber === 1) {
      action.Action_Name = "Approved";
      action.Action_Id = 2;
      action.Procedure_Option = 8;
    }
    if (actionNumber === 2) {
      action.Action_Name = "Submit for Approval";
      action.Action_Id = 3;
      action.Procedure_Option = 8;
    }
    if (actionNumber === 3) {
      action.Action_Name = "Convert to Draft";
      action.Action_Id = 5;
      action.Procedure_Option = 11;
    }

    await myPromiseModal({
      title: "confirm your action!",
      body: `Do you want to ${action.Action_Name} ?`,
      showInput: false,
      placeHolder: "",
    }).then((result) => {
      if (result.decision === true) {
        const option = action.Procedure_Option;
        let xml_string_refno = `<root> <a> <refno> ${bill_id} </refno> </a> </root>`;
        purchaseService
          .setBillBulkAction({ xml_string_refno, option })
          .then((res) => {
            if (res?.data !== undefined) {
              const { message_text } = res.data[0];
              logicContext.showToast(message_text, "success");
              return reloadBillDetails();
            }
          });
      }
    });
  }

  const magicButtons = (status) => {
    switch (status) {
      case "Draft":
        return (
          <button
            onClick={() => customActionsSingle(2)}
            style={{ borderRadius: "5px" }}
            className=" px-3 bg-primary text-white h-[6vh] text-uppercase subheading  mr-3"
          >
            submit for approval
          </button>
        );
      case "Over Due":
        return (
          <button
            //  onClick={()=> createReceiveHandler(purchaseData?.Pending_Receive, 1)}
            style={{ borderRadius: "5px", backgroundColor: "#476EF8" }}
            className=" px-3 bg-primary text-white h-[6vh] text-uppercase  subheading mr-3"
          >
            Record Payment
          </button>
        );

      case "Received":
        return (
          <button
            //  onClick={()=> createReceiveHandler(purchaseData?.Pending_Receive, 1)}
            style={{ borderRadius: "5px", backgroundColor: "#476EF8" }}
            className=" px-3 app_blue_color text-white h-[6vh] text-uppercase  subheading  mr-3"
          >
            Record Payment
          </button>
        );

      case "Pending Approval":
        return (
          <button
            onClick={() => customActionsSingle(1)}
            style={{ borderRadius: "5px", backgroundColor: "#476EF8" }}
            className=" px-3 app_blue_color text-white h-[6vh] text-uppercase  subheading mr-3"
          >
            APPROVE
          </button>
        );

      case "Approved":
        return (
          <button
            onClick={() => customActionsSingle(3)}
            style={{ borderRadius: "5px" }}
            className=" px-3 app_blue_color text-white h-[6vh] text-uppercase  subheading mr-3"
          >
            Convert to Draft
          </button>
        );

      case "Received & Not Billed":
        return (
          <button
            onClick={() => customActionsSingle(3)}
            style={{ borderRadius: "5px" }}
            className=" px-3 app_blue_color text-white h-[6vh] text-uppercase  subheading  mr-3"
          >
            Convert to Draft
          </button>
        );

      default:
        return null;
    }
  };

  const deleteBillHandler = async () => {
    await myPromiseModal({
      title: "confirm your Delete!",
      body: `Do you want to delete ${billDetails?.Bill_Number} ?`,
      showInput: false,
      placeHolder: "",
    }).then((response) => {
      if (response.decision === true) {
        let option = 12;
        let temp = `<a> <refno> ${billDetails?.Bill_Ref_No} </refno> </a>`;
        let xml_string_refno = `<root> ${temp} </root>`;
        purchaseService
          .setBillBulkAction({ xml_string_refno, option })
          .then((res) => {
            if (res?.data !== undefined) {
              const { message_text } = res.data[0];
              logicContext.showToast(message_text, "success");
              navigate(-1);
              return generalReloadBillList();
            }
          })
          .catch((err) => {
            logicContext.showToast("Error", "error");
          });
      }
    });
  };

  async function bulkActionsSingle(action) {
    await myPromiseModal({
      title: "confirm your action!",
      body: `Do you want to ${action.Action_Name.replace("Bulk", "")} ?`,
      showInput: false,
      placeHolder: "",
    }).then((result) => {
      if (result.decision === true) {
        const option = action.Procedure_Option;
        let xml_string_refno = `<root> <a> <refno> ${bill_id} </refno> </a> </root>`;
        purchaseService
          .setBillBulkAction({ xml_string_refno, option })
          .then((res) => {
            if (res?.data !== undefined) {
              const { message_text } = res.data[0];
              logicContext.showToast(message_text, "success");
              return reloadBillDetails();
            }
          });
      }
    });
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <section className=" mt-2 ">
        <div>
          {selectedList.length > 0 && (
            <div className="">
              <Dropdown>
                <Dropdown.Toggle
                  className="bg-[#E5E5E5] hover:bg-gray-200 hover:text-gray-600 text-gray-600 text-sm"
                  id="dropdown-basic"
                >
                  Bulk Actions
                </Dropdown.Toggle>

                <Dropdown.Menu className="mt-1 rounded-lg bg-white border-[1px] border-gray-200 text-sm shadow-xl">
                  {billListData["Bulk_Action"]?.map((action, i) => {
                    return (
                      <Dropdown.Item
                        key={i}
                        onClick={() => bulkActions(action)}
                      >
                        {action.Action_Name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        <section className="mt-3">
          <div className="flex lg:justify-end">
            <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-4 ">
              <div className="flex">
                <span>{magicButtons(billDetails?.Bill_Status_Name)}</span>
                <Dropdown className=" " style={{ width: "" }}>
                  <Dropdown.Toggle
                    className="text-sm text-white bg-[#476EF8]"
                    id="dropdown-basic"
                  >
                    ACTION
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mt-1 shadow-xl border-[1px] border-gray-200 rounded-md bg-white">
                    {billListData["Bulk_Action"]?.map((option, i) => {
                      return (
                        <Dropdown.Item
                          className="border-1 active:bg-gray-200"
                          key={i}
                          onClick={() => bulkActionsSingle(option)}
                        >
                          <div className="">
                            {option.Action_Name?.replace("Bulk", "")}
                          </div>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="flex fle-row items-center space-x-1 ">
                <div className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer">
                  <ImAttachment className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
                </div>
                <div
                  onClick={() => handlePrint()}
                  className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
                >
                  <FaFilePdf className="h-[20px] w-[20px] flex-shrink-0 text-gray-600" />
                </div>
                <div
                  onClick={() => handlePrint()}
                  className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
                >
                  <IoPrintSharp className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
                </div>
                <div
                  onClick={() => EditVendorDetails()}
                  className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
                >
                  <MdEdit className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
                </div>

                <div
                  onClick={() => deleteBillHandler()}
                  className="p-1.5 rounded-md hover:bg-gray-200 active:bg-gray-100 cursor-pointer"
                >
                  <MdDelete className="h-[23px] w-[23px] flex-shrink-0 text-gray-600" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="mt-4 lg:flex space-x-1">
          <div className="lg:w-[210px]">
            <BillsDetailsScroll
              onSelection={onSelection}
              viewBillDetails={viewBillDetails}
            ></BillsDetailsScroll>
          </div>
          <div className="w-full overflow-x-hidden mt-4 lg:mt-0 lg:pl-4">
            <TabContext value={tabValue} className="w-full">
              <Box className="mt-2">
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label={`Details `}
                    value="1"
                    className="outline-non focus:outline-none"
                  />
                  {billDetails?.Count_Information?.length > 0 && (
                    <Tab
                      label={
                        `Payment Made (` +
                        billDetails?.Count_Information[0]?.Payment_Count +
                        ")"
                      }
                      value="2"
                      className="outline-non focus:outline-none"
                    />
                  )}

                  {billDetails?.Count_Information?.length > 0 && (
                    <Tab
                      label={
                        `Comments & History (` +
                        billDetails?.Count_Information[0]?.History_Count +
                        ")"
                      }
                      value="3"
                      className="outline-non focus:outline-none"
                    />
                  )}
                </TabList>
              </Box>

              <TabPanel value="1" className="w-full m-0 p-0">
                <BillWrapperComponent
                  billDetails={billDetails}
                  viewVendor={viewVendor}
                  ref={componentRef}
                />
              </TabPanel>

              <TabPanel value="2">
                <BillPaymentMade />
              </TabPanel>

              <TabPanel value="3">
                <BillHistory />
              </TabPanel>
            </TabContext>
          </div>
        </div>

        <section className="flex relative justify-between">
          <section className="w-full z-50 ">
            {/* overview and comment */}
            <section className=" w-95   text-filterTextColor sm: flex justify-between md:mt-5"></section>
          </section>
        </section>
      </section>
    </>
  );
};

export default BillsDetailsPage;
