import * as React from 'react';
import { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import RedeemContent from './RedeemContent';
import EarnContent from './EarnContent';
import FAQContent from './FAQcontent';
import { rewardService } from '../../services/Reward.service';
import LogicContext from "../../Context/LogicContext";
import { GetRewardCenterList, SetRewardCenterList } from '../../store/rewardCenterAtom';



function RewardCenterSetup() {
    const [value, setValue] = React.useState('1');
    const logicContext = useContext(LogicContext);
    const getRewardList = GetRewardCenterList();
    const setRewardList = SetRewardCenterList();



    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


    const submitReward = (formData)=> {
      rewardService.submitRedeemContent(formData).then((response)=> {
        const filteredData = logicContext.httpResponseManager(response?.data[0]);
        if(filteredData?.message_id == 1 || filteredData?.message_id == 1){
          logicContext.showToast(filteredData?.message_text || filteredData?.message_text, 'success');
          reloadMainData();
        }
      });
    }


    const submitEarnContent = (formData)=>  {
      rewardService.submitEarnContent(formData).then((res)=> {
        const filteredData = logicContext.httpResponseManager(res?.data[0]);
        if(filteredData?.message_id == 1 || filteredData?.message_id == 1){
          logicContext.showToast(filteredData?.message_text || filteredData?.message_text, 'success');
          reloadMainData();
        }
      });
    }


    const submitFAQContent = (formData)=>  {
      rewardService.submitFAQContent(formData).then((res)=> {
        const filteredData = logicContext.httpResponseManager(res?.data[0]);
        if(filteredData?.message_id == 1 || filteredData?.message_id == 1){
          logicContext.showToast(filteredData?.message_text || filteredData?.message_text, 'success');
          reloadMainData();
        }
      });
    
    }



  React.useEffect(()=> {
    if(!getRewardList.earn_info?.length){
      rewardService.getEarnContent().then((response)=> {
        if(response.data.earn_info?.length){
          setRewardList(response.data);
        }
      });
    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[getRewardList.earn_info?.length]);


  function reloadMainData(){
    rewardService.getEarnContent().then((response)=> {
      if(response.data.earn_info?.length){
        setRewardList(response.data);
      }
    });
  }

  
    return (
        <>
            <Box className="mt-14"  sx={{ width: '100%', typography: 'body1' }}>
      <TabContext  value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="Redeem page tab">
            <Tab label="Redeem" value="1" />
            <Tab label="Earn" value="2" />
            <Tab label="FAQ" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <RedeemContent   submitReward={submitReward} />
        </TabPanel>

        <TabPanel value="2">
          <EarnContent submitEarnContent={submitEarnContent} />
        </TabPanel>

        <TabPanel value="3">
          <FAQContent  submitFAQContent={submitFAQContent} />
        </TabPanel>
      </TabContext>
    </Box>
        </>
    );
}

export default RewardCenterSetup;