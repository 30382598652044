import { TableBody,TableCell, TableContainer, TableHead,TableRow} from "@mui/material";
  import DeleteIcon from "@mui/icons-material/Delete";
  import { Table } from "react-bootstrap";
  import CurrencyFormat from 'react-currency-format';
import { useNavigate, useLocation } from "react-router-dom";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";
// import {  SetPurChaseOrderEdit } from "../store/purchaseOrderAtom";



const PurchasePendingTab = (props) => {
  const { pathname } = useLocation();
  // const setPurchaseEdit = SetPurChaseOrderEdit();


  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const navigate = useNavigate();


 
  const viewItemDetails = (id) => {
    navigate(`/${currModuleId}/${currModuleName}/${'items'}/item-details/${id}`)
  }


  const markAsReceive  = (item, option)=> {
    props.markAsReceive([item], option);
  }

    return ( 

        <>
          <div className="mt-2 ">
    <h6  className="text-center text-center font-bold" >Pending Receive List </h6>

  
  <TableContainer className="mt-3 w-full">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <colgroup>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                    <col style={{width:'20%'}}/>
                </colgroup>
              <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                <TableRow>
                  <TableCell className="subheading">Items and Description</TableCell>
                 
                  <TableCell className="subheading">Order Quantity</TableCell>
                  <TableCell className="subheading"> Receive Quantity </TableCell>
                  <TableCell className="subheading"> Pending Quantity </TableCell>
                  <TableCell className="subheading"> </TableCell>
              
           
                </TableRow>
              </TableHead>
  
              <TableBody className=" mb-3">
                {props?.tableData?.map((item, i) => {
                  return (
                    <TableRow className="border-1"
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
                      <TableCell  className="subheading text-primary"  align="left" 
                      onClick={()=> viewItemDetails(item.Item_Id)} >{item.Item_Name} </TableCell>
                      <TableCell  className="subheading" align="left">{formatCustomNumber(item.Purcahse_Order_Quantity)}  </TableCell>
                      <TableCell  className="subheading" align="left">{formatCustomNumber(item.Purcahse_Receive_Quantity)}  </TableCell>
                      <TableCell  className="subheading" align="left">{formatCustomNumber(item.Remaining_Quantity)}  </TableCell>
                      <TableCell  className="subheading" align="left"> 
                      <button onClick={()=> markAsReceive(item, 2)} className="btn btn-primary" >receive</button> </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
  </div>
        </>
     );
}
 
export default PurchasePendingTab;