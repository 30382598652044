


import { DataGrid ,  gridPageCountSelector,
  gridPageSelector, GridToolbarContainer, GridToolbarExport ,
  useGridApiContext,
  useGridSelector} from "@mui/x-data-grid";
  import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useLocation, useNavigate } from "react-router-dom";
import {GetGroupItemList} from '../../store/itemGroupAtom';
import { useEffect } from "react";
// import '../pages/Table.css'
import { formatCustomNumber } from "../../helpers/formatCustomNumber";


function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columns = [
 
    {
      field: 'Item_Group_Id',
      headerName: ' ID',
      width: 180,
      headerClassName:'bg-gray-200',
     
    },
 
    {
      field: 'Item_Group_Name',
      headerName: ' Name',
      width: 180,
      headerClassName:'bg-gray-200',
     
    },
    {
      field: 'Item_Group_Brand_Name',
      headerName: 'SKU',
      headerClassName:'bg-gray-200',
      width: 180,
     
    },
    {
      field: 'Total_Items',
      headerName: 'Stock in Hand',
      headerClassName:'bg-gray-200',
      width: 180,
     
    },
    {
      field: 'Item_Group_Reorder_Point',
      headerName: 'Reorder Point',
      headerClassName:'bg-gray-200',
      width: 180,
     
    },
    {
      field: 'Item_Group_Status_Name',
      headerName: 'Status',
      headerClassName:'bg-gray-200',
      width: 180,
     
    },
  ];



function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}



function ItemGroupTable(props) {
const itemGroupList  =  GetGroupItemList();

    const navigate = useNavigate();
    const { pathname } = useLocation();  
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];

    const rows = itemGroupList?.map((item, i) => {
        return ({...item, id: i, Total_Items:formatCustomNumber(item?.Total_Items)})
    })


    function OnItemCellClick(event){
      const itemId = event.row['Item_Group_Id'];
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/item-group-details/${itemId}`)

  }




    function onSelectChange(selectedList){
        var selection = selectedList?.map((o) => {return itemGroupList[o]})
        props.selectedItems(selection);
        
    }



    return ( 
        <>
        
        <div className='table ' style={{ height: 500, width: '100%' }}>
      <DataGrid  className="table_css"
        rows={rows ? rows : []}
        columns={columns ? columns : []}
        pageSize={6}
        size={'small'}
        checkboxSelection={true}
        rowsPerPageOptions={[6]}
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPagination,
        }}
        onRowSelectionModelChange={onSelectChange}
        onCellClick={( event) => { event.defaultMuiPrevented = true}}

        onRowClick={(event)=> {  OnItemCellClick(event);
        }}

        sx={{
          // boxShadow: 2,
          // border: 2,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
            cursor: 'pointer',
          },
        }}
     
      />
    </div>
        </>
     );
}

export default ItemGroupTable;