import { FileUploader } from "react-drag-drop-files";
import React, { useContext, useEffect, useState } from "react";
import "./ImageUploader.css";
import Select from "react-select";
import LogicContext from "../../Context/LogicContext";
import { productService } from "../../services/product.service";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { toast } from "react-toastify";
import { GetProductList, SetProductList } from "../../store/productAtom";
import ProductslideScroll from "../product/ProductslideScroll";
import ProductPlanImage from "../ProductPlanImage";
import ProductPlans from "../../components/ProductPlans";
import ProductImagePlans from "./ProductImagePlan";
import { Col, Container, Row } from "react-bootstrap";

const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

const grocedyOptions = [
  { value: "1", label: "Grocedy plans" },
  { value: "2", label: "Grocedy products" },
  { value: "3", label: "Grocedy addons" },
  { value: "4", label: "Grocedy items" },
  { value: "5", label: "Grocedy items group" },
];

const MultipleImageUploadTab = () => {
  const logicContext = useContext(LogicContext);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState({});

  useEffect(() => {}, []);

  function viewItemDetails(product) {
    setSelectedProduct(product);
  }

  function onSelectPlan(plan) {
    setSelectedPlan(plan);
  }

  const submitItemImage = (
    imageFile,
    imageId,
    isDefault,
    extension,
    option
  ) => {
    let formData = new FormData();
    formData.set("file", imageFile);
    formData.set("plan_id", selectedPlan?.Plan_Id);
    formData.set("Image_Format", extension);
    formData.set("image_refno", selectedPlan?.Plan_Id + "_" + imageId);
    formData.set("isdefault_image", isDefault);
    // formData.set('product_id', product_id);
    formData.set("image_id", imageId);
    formData.set("option", option);
    productService.submitPlanImage(formData).then((response) => {
      const { message_text, message_id } = response.data[0];
      logicContext.showToast(message_text, "success");
    });
  };

  return (
    <>
      <div className="">
        {/* <Container>
          <Row className="">
            <Col md={4}>
              
            </Col>
            <Col md={4}></Col>
          </Row>
        </Container> */}

        <section className="flex">
          <Container className=" w-40">
            <Row className="">
              <Col md={12}>
                <div className="">
                  <ProductslideScroll
                    selectedProduct={selectedProduct}
                    viewItemDetails={viewItemDetails}
                  ></ProductslideScroll>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="">
            <Row >
              <Col md={5}>
                <div className="">
                  <ProductImagePlans
                    onSelectPlan={onSelectPlan}
                    Product_Id={selectedProduct?.Product_Id}
                  />
                </div>
              </Col>
              <Col md={7} className="">
                <div className=" flex justify-start w-full">
                  <ProductPlanImage
                    plan_id={selectedPlan?.Plan_Id}
                    getItemImages={productService.getPlanImageList}
                    submitItemImage={submitItemImage}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default MultipleImageUploadTab;

// <Container>
//         <Row className="">
//           <Col md={6}>
//             <ProductslideScroll
//               selectedProduct={selectedProduct}
//               viewItemDetails={viewItemDetails}
//             ></ProductslideScroll>
//           </Col>

//           <Col md={4}>
//           <ProductImagePlans
//               onSelectPlan={onSelectPlan}
//               Product_Id={selectedProduct?.Product_Id}
//             />
//             <ProductPlanImage
//               plan_id={selectedPlan?.Plan_Id}
//               getItemImages={productService.getPlanImageList}
//               submitItemImage={submitItemImage}
//             />
//           </Col>
//         </Row>
//       </Container>
