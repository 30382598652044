import { useEffect } from "react";
import Select from "react-select";





function ImageSlotSelection(props) {

      useEffect(() => {
       
    
      }, [props?.defaultVal]);
    
      const customOption = props?.slot_info?.map((item) => {
        return ({ ...item, label: `${item.Name}` });
      });
    
    

    return (
        <>
         <Select
                placeholder="Select product sharing slot "
                defaultValue={{label: props.defaultVal}}
                onChange={props.onSlotSelection}
                options={customOption}
                className="text-md" />
        </>
      );
}

export default ImageSlotSelection;