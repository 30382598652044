import { useLocation, useNavigate } from "react-router-dom";
// import CustomerTable from "./CustomerTable";


import { useCallback, useEffect, useState } from "react";

import RestartAlt from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { voucherService } from "../../services/voucherService";
import Tooltip from "@mui/material/Tooltip";
import { Table, Input } from 'antd';
import { formatCustomNumber } from "../../helpers/formatCustomNumber";
import { productSharingService } from "../../services/productSharing.service";
import { GetProductSharingAtom, SetProductSharingAtom } from "../../store/productSharingAtom";
import { formatCustomDate } from "../../helpers/formatCustomDate";

const { Search } = Input;



function ProductSharingPage() {

    const { pathname } = useLocation();
    const productSharingAtom = GetProductSharingAtom();
    const setProductSharingAtom = SetProductSharingAtom();
    const navigate = useNavigate();
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  
  
    useEffect(() => {
    //   document.title = 'Voucher Page'; product_sharing_group
      if (productSharingAtom?.product_sharing_info?.length < 1) {
          loadVoucherData();
      }else{
          setFilteredData(productSharingAtom?.product_sharing_info);
  
      }
    
    }, [productSharingAtom]);
  
  
    const reloadData = () => {
      loadVoucherData();
    }
  
  
    const loadVoucherData = () => {
      productSharingService.productSharingList().then(res => {
        if(res.data){
            setProductSharingAtom(res.data);
            if(res.data?.product_sharing_info?.length){
                setFilteredData(res.data?.product_sharing_info);
            }
        }
    
      });
    }
  
    function addNew() {
      navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)
    }
  
    const columns = [
      {
        title: 'Ref',
        key: 'Ref_No',
        dataIndex: 'Ref_No',
        className:"text-primary",
        sorter: (a, b) => a.Ref_No.localeCompare(b.Ref_No)
      //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
      },
   
      {
        title: 'Item Name',
        dataIndex: 'Item_Name',
        sorter: (a, b) => a.Item_Name.localeCompare(b.Item_Name)
      //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
      },

    
      {
        title: "Slot Price",
        dataIndex: "Slot_Price",
        sorter: (a, b) => a.Slot_Price.localeCompare(b.Slot_Price),
        render: (Slot_Price) => formatCustomNumber(Slot_Price),
      },
      {
        title: "Slot Number",
        dataIndex: "Slot_Number",
        sorter: (a, b) => a.Slot_Number.localeCompare(b.Slot_Number),
        render: (Slot_Number) => formatCustomNumber(Slot_Number),
      },
      {
        title: "Purchased Slot",
        dataIndex: "Total_Purchased_Slot",
        sorter: (a, b) => a.Total_Purchased_Slot.localeCompare(b.Total_Purchased_Slot),
        render: (Total_Purchased_Slot) => formatCustomNumber(Total_Purchased_Slot),
      },
      {
        title: "Remaining Slot",
        dataIndex: "Total_Remaining_Slot",
        sorter: (a, b) => a.Total_Remaining_Slot.localeCompare(b.Total_Remaining_Slot),
        render: (Total_Remaining_Slot) => formatCustomNumber(Total_Remaining_Slot),
      },
      {
        title: "Status",
        dataIndex: "Item_Status",
        sorter: (a, b) => a.Item_Status.localeCompare(b.Item_Status)
      },
       {
        title: " Created Date",
        dataIndex: "Created_Date",
        // sorter: (a, b) => a.Created_Date.localeCompare(b.Created_Date),
        render: (Created_Date) => formatCustomDate(Created_Date),
      },
      {
        title: " Expire Date",
        dataIndex: "Gropool_Date",
        // sorter: (a, b) => a.Created_Date.localeCompare(b.Created_Date),
        render: (Gropool_Date) => formatCustomDate(Gropool_Date),
      },
    ];
  
  
    const handleSearch = (value) => {
      setSearchText(value);
      const filtered = productSharingAtom.product_sharing_group.filter((record) =>
        Object.values(record).some((text) =>
          String(text).toLowerCase().includes(value.toLowerCase())
        )
      );
      setFilteredData(filtered);
    };
  
  
    const paginationOptions = {
      pageSize: 10,
      showSizeChanger: true,
      total: filteredData.length,
      // onChange: (page, pageSize) => {
    
      //   setTableList(newList);
      // },
    };
  



  const handleRowClick = (record) => {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${record?.Ref_No}`);
  };


  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };



    return (

        <>
          <Grid container >
            <Grid item xs={12}>
              {/* grid start */}
              <div className="row   mt-5 mb-2" >
                <div className="col" >
                  <Tooltip title="Reload">
                    <RestartAlt
                      className="cursor ml-2 mt-2 mr-2"
                      onClick={() => reloadData()}
                      color="primary"
                      fontSize="large"
                    />
                  </Tooltip>
                </div>
    
    
    
                <div className="col offset-3" >
    
                  <div className="flex  justify-end">
                    {/* <button
                      style={{ backgroundColor: "#476EF8" }}
                      onClick={() => addNew()}
                      className=" mr-3  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                    >
                      EXPORT
                    </button> */}
                    <button
                      style={{ backgroundColor: "#476EF8" }}
                      onClick={() => addNew()}
                      className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                    >
                      ADD NEW
                    </button>
    
                  </div>
                </div>
    
              </div>
              {/* End of Grid */}
    
    
            </Grid>
          </Grid>
    
    
          <section className="mt-5   w-full">
          <div className="">
    
            <Table key={filteredData?.length} columns={columns} 
            //  rowSelection={rowSelection}
            dataSource={filteredData?.map((item, i) => {
              return { ...item,Voucher_Amount: formatCustomNumber(item.Voucher_Amount)};
            })}
                          pagination={paginationOptions}
                          rowClassName="show-cursor"
                          style={{ background: '#f0f2f5', borderRadius: '8px' }}
                          onRow={(record) => {
                            return {
                              onClick: () => {
                                handleRowClick(record);
                              },
                            };
                          }}
                            />
    
            </div>
          </section>
        </>
    
      );
}

export default ProductSharingPage;