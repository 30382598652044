import React, { useEffect, useState, useContext, useRef } from "react";
import CustomerSearchSelect from '../credit_note/CustomerSearchSelect';

import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import LogicContext from "../../Context/LogicContext";
import { useForm } from "react-hook-form";
import { packageService } from "../../services/packageService";
import { GetPackageMasterData, SetPackageMasterData, GetPackageEditAtom, SetPackageEditAtom,
   GetPackageListAtom, SetPackageListAtom } from "../../store/packageStore";
import SalesOrderSelect from "./SalesOrderSelect";
import moment from "moment";
import PackageCustomerSelect from "./PackageCustomerSelect";
import PickAddressSelect from "./PickAddressSelect";
import CurrencyFormat from 'react-currency-format';


function NewPackage() {

  const navigate = useNavigate();
  const setPackageMasterData = SetPackageMasterData();
  const getPackageMasterData = GetPackageMasterData();
  const getPackageEditAtom = GetPackageEditAtom();
  const getPackageAtomList = GetPackageListAtom();
  const setPackageAtomList = SetPackageListAtom();

  const setPackageEditAtom = SetPackageEditAtom();
  const myPromiseModal = createModal(GeneralModal);
  const {register, setValue, getValues, handleSubmit,control,reset,formState: { errors }} = useForm();
  const logicContext = useContext(LogicContext);
  const [customer, setCustomer] = useState({});
  const [selectedSalesOrder, setSelectedSalesOrder] = useState([]);


  useEffect(()=> {
    // if(!getPackageMasterData.so_details_info?.length || !getPackageMasterData.so_info.length){
      packageService.packageMasterData().then((res)=> {
        setPackageMasterData(res.data);
    });

    // }

  },[getPackageMasterData.so_details_info?.length ,getPackageMasterData.so_info.length ]);



    useEffect(()=> {
      if(getPackageEditAtom?.length){
        setSelectedSalesOrder(getPackageEditAtom);
        const customer =  getPackageEditAtom[0];
        setCustomer(customer);
        setValue("sales_order_ref_no", getPackageEditAtom[0]?.Sales_Order_Ref_No);
        setValue("customer_id", getPackageEditAtom[0]?.Customer_Id);
        setValue("warehouse_id", getPackageEditAtom[0]?.Warehouse_Id);
        setValue("Package_Remark", getPackageEditAtom[0]?.Package_Remark);
        // setValue("pickup_date", getPackageEditAtom[0]?.Pickup_Date);
        // setValue("package_date", getPackageEditAtom[0]?.Package_Date);
      }

      return  ()=> {
        setPackageEditAtom([]);
      }

    },[getPackageEditAtom?.length]);

    const onCustomerSelected = (customer)=> {
        setCustomer(customer);
      const filteredList = getPackageMasterData.so_details_info.filter((sales)=> sales.Customer_Id === customer?.Customer_Id );
      const distinctArray = Array.from(new Set(filteredList.map(obj => obj.Sales_Order_No))).map(Sales_Order_No => filteredList.find(obj => obj.Sales_Order_No === Sales_Order_No));

      if(filteredList?.length){
        setSelectedSalesOrder(distinctArray);
        setCustomer(filteredList[0]);
      setValue("customer_id", filteredList[0]?.Customer_Id);

      }
      
      }

      console.log('getPackageEditAtom ', getPackageEditAtom);

    const onSalesOrderSelect = (option)=> {
      const filteredList = getPackageMasterData.so_details_info.filter((sales)=> sales.Customer_Id === customer?.Customer_Id );
      const distinctArray = Array.from(new Set(filteredList.map(obj => obj.Sales_Order_No))).map(Sales_Order_No => filteredList.find(obj => obj.Sales_Order_No === Sales_Order_No));
      console.log('')
      setSelectedSalesOrder(distinctArray);
        setValue("sales_order_ref_no", option?.Sales_Order_Ref_No);
        if(filteredList?.length){
          setCustomer(filteredList[0]);
        setValue("customer_id", filteredList[0]?.Customer_Id);

        }
      }



  function packageDate(){
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD")
    if(getPackageEditAtom[0]?.Package_Date !== undefined){
      defaultDate = moment(getPackageEditAtom[0]?.Package_Date).format("YYYY-MM-DD")
   }
   
    return (
    
     <input
              type="date"
              defaultValue={defaultDate}
              className="w-full border border-gray-300 rounded-md shadow-sm p-2"
              {...register("package_date", {
                required: "Date is required",
              })}
            /> 
    )
  }


  function pickUpDate(){
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD")
    if(getPackageEditAtom[0]?.Pickup_Date !== undefined){
       defaultDate = moment(getPackageEditAtom[0]?.Pickup_Date).format("YYYY-MM-DD")
    }
   
    return (
      <>
       <input
                type="date"
                defaultValue={defaultDate}
                className="w-full border border-gray-300 rounded-md shadow-sm p-2"
                {...register("pickup_date", {
                  required: "Pick up Date is required",
                })}
              /> 
        <small className="text-red-500">
                {Boolean(errors["pickup_date"]?.message) && String(errors["pickup_date"]?.message)}
              </small>
      </>
    )
  }



  const onSubmit = async () => {
    await myPromiseModal({ title: "Confirmation", body: " Do you want to save this record?",
    showInput:false, placeHolder:'',showInput:false, placeHolder:''}).then((res) => {
        if (res.decision === true) {
          const newData = getValues();
          let option = 13;
          if(getPackageEditAtom?.length){
            option = 14;
          }
          
          packageService.subOrUpdatePackage({...newData, option}).then((res) => {
              if (res?.data !== undefined) {
                const { message_text, message_id } = res.data[0];
                if (message_id === 1) {
                  logicContext.showToast(message_text, "success");
                  setPackageEditAtom([]); // Always set the edit to empty after save
                  navigate(-1);
                } else {
                  return logicContext.showToast(message_text, "error");
                }
              }
            });
        }
      })
      .catch((err) => {
        console.log('Error ', err);
        logicContext.showToast("error submitting record", "error");
      });
  };


  function onErrors(error) {
    logicContext.showToast("Form error", "error");
  }

  const pickUpAddressSelect = (option)=> {
    setValue("warehouse_id", option?.Pickup_Id);
  }


 
  const deletePackage = async () => {
   
    const response = await myPromiseModal({
      title: "Delete Package",
      body: `Package with ${getPackageEditAtom[0]?.Sales_Order_Ref_No} will be deleted.`,
      showInput: false,
      placeHolder: "",
    });

    if (response.decision === true) {
      packageService.deletePackage(getPackageEditAtom[0]?.Sales_Order_Ref_No).then(res => {
        const { message_text, message_id } =  res.data[0];

        const tempList =  getPackageAtomList.so_not_shipped;
        if(tempList?.length){
          const newList =  tempList.filter((lst)=> lst.Sales_Order_Ref_No !== getPackageEditAtom[0]?.Sales_Order_Ref_No);
          setPackageAtomList((prev)=> {
            return {...prev, so_not_shipped: newList}
          });
        }
        logicContext.showToast(message_text, "error");
        navigate(-1);
      }).catch((err)=> {
        console.log('del_error ', err);
      });
      // navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
    }
  };




  return (
    <div className="p-8">
       <form onSubmit={handleSubmit(onSubmit, onErrors)}>
      <h1 className="text-xl font-semibold mb-6 flex items-center">
        <span role="img" aria-label="package">📦</span> {getPackageEditAtom?.length ? ' Modify Package': 'New Package' } 
      </h1>
      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="grid grid-cols-2 gap-4">
          <div>
          <span className="text-sm  ">Customer Name</span> 
          <PackageCustomerSelect defaultVal={customer?.Customer_Email_Address} {...register("customer_id", { required: "Customer is required" })} 
           onOptionSelect={onCustomerSelected} list={getPackageMasterData.customer_info} />
             <small className="text-red-500">
                {Boolean(errors["customer_id"]?.message) && String(errors["customer_id"]?.message)}
              </small>

                {/* Customer display */}
          {customer?.Customer_Id && 
                 <div class="max-w-md mx-auto bg-white rounded-lg shadow-md  p-4">
                 <ul class="space-y-3">
                 <li class="flex items-center  rounded">
                     <span class="w-4 h-4 bg-blue-500 rounded-full mr-3"></span>
                     <span> Email: {customer?.Customer_Email_Address} </span>
                 </li>
                 <li class="flex items-center   rounded">
                     <span class="w-4 h-4 bg-blue-500 rounded-full mr-3"></span>
                     <span>Name: {customer?.Created_User_Full_Name} </span>
                 </li>
                 <li class="flex items-center  rounded">
                     <span class="w-4 h-4 bg-blue-500 rounded-full mr-3"></span>
                     <span>Mobile: {customer?.Customer_Mobile_No} </span>
                 </li>
                 </ul>
             </div>
               
               }
          </div>
        
          <div>
            <label className=" text-sm ">Sales Order#</label>
           <SalesOrderSelect 
          defaultVal={getPackageEditAtom[0]?.Sales_Order_No}
           list={selectedSalesOrder}
            onOptionSelect={onSalesOrderSelect} {...register("sales_order_ref_no", 
            { required: "Sale Order is required" })}  />
           <small className="text-red-500">
                {Boolean(errors["sales_order_ref_no"]?.message) && String(errors["sales_order_ref_no"]?.message)}
              </small>

          </div>
          <div>
            <label className=" text-sm ">Package Slip#</label>
            <input
              type="text"
              value={getPackageEditAtom[0]?.Package_Slip_Number}
              className="w-full border border-gray-300 rounded-md shadow-sm p-2"
              readOnly
            />
             <small className=""> Auto generated  </small>
          </div>
          <div>
            <label className=" text-sm">Package Date</label>
            {packageDate()}
          </div>

          <div>
            <label className=" text-sm">Pick up Date</label>
            {pickUpDate()}
          </div>

          <div className="col-span-2 mb-3">
            <label className=" text-sm ">Pick Up Address</label>
         
            <PickAddressSelect defaultVal={getPackageEditAtom[0]?.Pickup_Address} list={getPackageMasterData?.pickup_info} onOptionSelect={pickUpAddressSelect}
             {...register("warehouse_id", { required: "Pick up address is required" })}  />
              <small className="text-red-500">
                {Boolean(errors["warehouse_id"]?.message) && String(errors["warehouse_id"]?.message)}
              </small>
          </div>
        </div>


           {/* Table Section */}
      <table className="w-full bg-white border">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">#</th>
            <th className="py-2 px-4 border-b">Item & Description</th>
            <th className="py-2 px-4 border-b">Qty</th>
            <th className="py-2 px-4 border-b">Rate</th>
            <th className="py-2 px-4 border-b">Discount</th>
            <th className="py-2 px-4 border-b">Sub Total</th>
          </tr>
        </thead>
        <tbody>
        {selectedSalesOrder?.map((pkg, i)=> {
            return (
                <>
                <tr>
                <td className="py-2 px-4 border-b">{i+1} </td>
                <td className="py-2 px-4 border-b">{pkg?.Item_Name} </td>
                <td className="py-2 px-4 border-b">
                
                  <CurrencyFormat value={parseFloat(pkg?.Item_Sales_Quantity )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
                  </td>
                <td className="py-2 px-4 border-b">
                <CurrencyFormat value={parseFloat(pkg?.Item_Sales_Rate )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
                  </td>
                <td className="py-2 px-4 border-b">
                <CurrencyFormat value={parseFloat(pkg?.Discount_Amount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
                  
                   </td>
                <td className="py-2 px-4 border-b">
                <CurrencyFormat value={parseFloat((pkg?.Item_Sales_Rate * pkg.Item_Sales_Quantity ) - pkg?.Discount_Amount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
                 </td>
              </tr>
             
                </>
            )
          
                
            })}
          
        </tbody>
      </table>
        <div className="flex justify-between items-center mt-8">
        <p className="text-sm text-gray-500">!</p>
        <h3 className="font-semibold">
          Grand Total:  
          <CurrencyFormat value={parseFloat(selectedSalesOrder[0]?.Sales_Order_Amount )?.toFixed(2)}
                       displayType={'text'} thousandSeparator={true} />
          </h3>
      </div>

        <div className="mt-4">
          <label className=" text-sm ">Internal Notes</label>
          <textarea
          {...register("Package_Remark")} 
            rows="3"
            className="w-full border border-gray-300 rounded-md shadow-sm p-2"
          ></textarea>
        </div>

        <div className="flex justify-end mt-4">
          {(getPackageEditAtom?.length > 0) &&  
          <button onClick={()=> deletePackage()} type="button" className="bg-red-200 text-gray-700 py-2 px-4 rounded-md mr-2">
          Delete
        </button>
           }
        
          <button onClick={()=> navigate(-1)} type="button" className="bg-gray-200 text-gray-700 py-2 px-4 rounded-md mr-2">
            Cancel
          </button>
          <button className="bg-blue-600 text-white py-2 px-4 rounded-md">
             {getPackageEditAtom[0]?.Sales_Order_No ? 'Update': 'Save'}
          </button>
        </div>
      </div>
      </form>
    </div>
  );
}

export default NewPackage;