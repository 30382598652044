
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const customerService = {
    saveGeneralInformation,
    saveCustomerAddress,
    getMasterData,
    saveCustomerLocation,
    saveCustomerOtherInfo,
    getSingleCustomer,
    updateCustomer,
    getNotifications,
    getAllCustomers,
    submitCustomerImage,
    saveContactPerson,
    customerDetailsHistory,
    customerDetailBills,
    getCustomerListCache,
    getCustomerListNoCache,
    getCustomerListSearch,
    changeCustomerStatus,
    getRatingList,
    getRatingDetails,
    submitReplyToRating,
    getRewardHistory,
    getCustomerVouchers,
    getCustomerTransactions,
    customerCreditScore,
    customerDetailsGift,
    getCustomerCreditScore,
    getCustomerByDate,
    getCustomerBySearch
};




async function  submitCustomerImage(formdata) {
       return axios.post('/submit-customer-image', formdata, authHeader());
    }

async function  getAllCustomers() {
    var  userPref = {sql_ref_no : 6,  option : 2, c_code:1001}
       return axios.post('/all-customers', userPref, authHeader());
    }

async function  getRatingList() {
       return axios.get('/subscriptions/rating-list', authHeader());
    }


async function  getRatingDetails(id,min,max) {
       return axios.get(`/subscriptions/rating-details/${id}/${min}/${max}`, authHeader());
    }

    
async function  submitReplyToRating(data) {
       return axios.post(`/subscriptions/reply-to-rating`,data, authHeader());
    }

async function  getCustomerListCache(start = 0, end = 20) {
       return axios.get(`/customer-list-cache?start=${start}&end=${end}`, authHeader());
    }

async function  getCustomerListNoCache(start = 0, end = 50) {
       return axios.get(`/customer-list-no-cache?start=${start}&end=${end}`, authHeader());
    }

    async function  getCustomerByDate(body) {
        return  axios.put(`/customer-list-by-date`, body, authHeader());
    }
    

    async function  getCustomerBySearch(searchText) {
        return  axios.get(`/customer-by-search?search=${searchText}`, authHeader());
    }
    


   async function getCustomerListSearch(search){
        return axios.get(`/customer-list-search?search=${search}`, authHeader());
      } 

      async function  getRewardHistory(id) {
        return  axios.get(`/get-reward-history/${id}`, authHeader());
    }
    
      async function  getCustomerVouchers(id) {
        return  axios.get(`/get-customer-vouchers/${id}`, authHeader());
    }
    
    
      async function  getCustomerTransactions(id) {
        return  axios.get(`/get-customer-transaction/${id}`, authHeader());
    }
    



async function  saveGeneralInformation( body) {
   return axios.post(`/save-general-info`,body,  authHeader())
}


async function  saveContactPerson( body) {
   return axios.post("/save-contact-person",body,  authHeader())
}


async function  changeCustomerStatus(bodyData) {
   return axios.put(`/change-customer-status`, bodyData, authHeader())
}


async function  updateCustomer( body) {
    return axios.put(`/update-customer`,body,  authHeader())
}

async function  saveCustomerAddress( body) {
  return  axios.put('/save-address', body, authHeader());
}


async function  saveCustomerLocation( body) {
    return  axios.put('/save-location', body, authHeader());
}

async function  saveCustomerOtherInfo( body) {
    return  axios.put('/save-other-info', body, authHeader());
}

async function  getMasterData() {
    return  axios.get('/get-master-data', authHeader());
}




async function  getSingleCustomer(customer_id) {
    return  axios.get(`/get-single-customer/${customer_id}`, authHeader());
}


function getNotifications(customer_id){
    return  axios.get(`/customer-notification/${customer_id}`, authHeader());
}


function customerDetailsHistory(customer_id){
    return  axios.get(`/customer-history/${customer_id}`, authHeader());
}


function customerDetailsGift(customer_id){
    return  axios.get(`/customer-gift/${customer_id}`, authHeader());
}


function customerCreditScore(customer_id){
    return  axios.get(`/customer-credit-score/${customer_id}`, authHeader());
}

function customerDetailBills(customer_id){
    return  axios.get(`/customer-bills/${customer_id}`, authHeader());
}



function getCustomerCreditScore(zohoId){
    return  axios.get(`/customer-credit-score/${zohoId}`, authHeader());
}





