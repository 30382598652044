import React from 'react';
import CurrencyFormat from "react-currency-format"; 
import moment from "moment";
import { formatCustomDate } from '../../helpers/formatCustomDate';
import { formatCustomNumber } from '../../helpers/formatCustomNumber';


const CreditNoteDetailsContent =  React.forwardRef((props, ref) => {
    const {creditNoteDetails, id} = props;



  const manageInvoiceStatus = (status) => {
    let defaultClass = "bg-primary text-white";

    switch (status) {
      case "Draft":
        defaultClass = "text-light bg-danger";
        break;
      case "Approved":
        defaultClass = "text-light bg-success";
        break;
      case "Void":
        defaultClass = "text-light bg-warning";
        break;
      case "Pending Approval":
        defaultClass = "text-light bg-info";
        break;

      default:
        break;
    }

    return (
      <h3
        className={`py-2.5 px-4 text-sm font-bold ${defaultClass} rounded`}
      >
        {status}
      </h3>
    );
  };


  return (
    <div   ref={ref} className="bg-white shadow-lg rounded-lg p-8  mx-auto">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-8">
        <div className="text-left">
          <h2 className="text-2xl font-bold"> {manageInvoiceStatus(creditNoteDetails?.Status_Name)} </h2>
          <p className="text-gray-600">Nigeria</p>
        </div>
        <div className="text-right">
          <h2 className="text-4xl font-bold">CREDIT NOTE</h2>
          <p className="text-gray-600"># {creditNoteDetails?.Credit_Note_Number} </p>
        </div>
      </div>

      {/* Credit Info */}
      <div className="flex justify-between mb-6">
        <div className="text-left">
          <p onClick={()=> props?.viewCustomerDetails(creditNoteDetails?.Customer_Id)} className="font-bold text-primary show-cursor">Bill To {creditNoteDetails?.Customer_Full_Name} </p>
          <p onClick={()=> props?.viewCustomerDetails(creditNoteDetails?.Customer_Id)}  className="font-bold text-primary show-cursor">Email  {creditNoteDetails?.Customer_Email_Address} </p>
          <p onClick={()=> props?.viewCustomerDetails(creditNoteDetails?.Customer_Id)}  className="font-bold text-primary show-cursor">Mobile {creditNoteDetails?.Customer_Mobile_No}</p>
        </div>
     
        <div className="text-right">
          <p className="text-gray-600">Credit Date: {formatCustomDate(creditNoteDetails?.Transaction_Dat)} </p>
          <p className="text-gray-600">Ref #: {id} </p>
        </div>
      </div>

      {/* Item Description Table */}
      {/* <div className="border-t border-b mb-6">
        <table className="w-full text-left table-auto">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2">#</th>
              <th className="py-2">Item & Description</th>
              <th className="py-2">Qty</th>
              <th className="py-2">Rate</th>
              <th className="py-2">Amount</th>
            </tr>
          </thead>
          <tbody>
            
                {creditNoteDetails?.map((item, index)=> {

                    return (
                        <tr>
                         <td className="py-2">{index+1} </td>
                        <td className="py-2">
                            <strong>{item?.Item_Name} </strong>
                            <br />
                            <span className="text-gray-500 text-sm">Sample collection offlinePayment text</span>
                        </td>
                        <td className="py-2">1</td>
                        <td className="py-2"> {item?.Item_Rate_Market} </td>
                        <td className="py-2">{item?.Item_Rate_Zoho} </td>
                        </tr>

                    )
                })}
             
            
          </tbody>
        </table>
      </div> */}

      {/* Subtotal and Total */}
      <div className="text-right mb-6">
      
        <p className="text-xl font-bold">Amount: <span className="text-gray-600">
        
                           <CurrencyFormat
                              value={formatCustomNumber(creditNoteDetails?.Amount)}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"₦"}
                            />
          </span></p>
      </div>

      {/* Credits Remaining */}
      <p className='p-3' >Remark: {creditNoteDetails?.Remark}  </p>
     
    </div>
  );
});

export default CreditNoteDetailsContent;
