
import { Dropdown } from "react-bootstrap";
import trash from "../assets/trash.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { itemsSetupService } from "../services/itemSetup.service";
import LogicContext from "../Context/LogicContext";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { GetCompositeGroup, SetICompositeGroup, SetICompositeGroupBackup,
    GetCompositeGroupBackup} from "../store/itemAtom";
// import CsvDownload from "react-json-to-csv";
// import "../pages/ItemPage.css"
import CompositeItemGroupTable from "./item-page/AddItemFormProps/CompositeItemGroupTable";
import RestartAlt from '@mui/icons-material/RestartAlt';
import Tooltip from '@mui/material/Tooltip';
import { ResetCompositeGRoupEdit} from '../store/compositeGroup';
import {  ResetCompositeGroupAddList} from "../store/compositeGroup";


function CompositeItemGroupPage() {
    const logicContext = useContext(LogicContext);
    const compositeGroupList = GetCompositeGroup();
    const navigate = useNavigate();
    const setCompositeGroup = SetICompositeGroup();
    const  backupList = GetCompositeGroupBackup();
    const setBackUp = SetICompositeGroupBackup()
    const [itemSelectList, setItemSelect] = useState([]);
    const resetEdit = ResetCompositeGRoupEdit();
    const resetTableDataList = ResetCompositeGroupAddList();
         
  
  
    // Reset item to edit if not in edit page

    const { pathname } = useLocation();
    var url = pathname.split("/");
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];

  
  
    useEffect(() => {
      resetEdit();
      resetTableDataList()
     if(compositeGroupList.length < 1){
      itemsSetupService.getCompositesGroupItems().then((res) => {
        if(res?.data != undefined){ 
          setCompositeGroup(res?.data)
          setBackUp(res?.data);
        }
      });
     }
    }, [compositeGroupList]);
  
  
    
    function loadItemsFromServer() {
        itemsSetupService.getCompositesGroupItems().then((res) => {
          if(res?.data != undefined){ 
            setCompositeGroup(res?.data)
            setBackUp(res?.data);
          }
        });
    }

    
    function selectedItems(itemList) {
        setItemSelect(itemList);
      }


      function tableRowClick(item){
                navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${item.row.Composite_Group_Id}`)
                
      }
  
  

  
  
    function customFilterByStatus(status) {
        if(status === 'All') return setCompositeGroup(backupList);
      var customFilter = backupList.filter(
        (item) => item.Composite_Group_Status_Name === status
      );
      setCompositeGroup(customFilter);
    }
  

  

  
  
    function changeStatus(optionCode) {
      var xmlString = "";
      itemSelectList?.map((id) => {
        xmlString += `<a><refno>${id} </refno></a>`;
      });
  
      itemsSetupService.changeGroupStatus({xml_string: `<root>${xmlString} </root>`, option: optionCode}).then(res => {
        if(res.data !== undefined){
          logicContext.showToast(res.data[0]['message_text'], 'success');
          loadItemsFromServer();
        }
      });
    }
  
  
  
  
    function deleteItems() {
 
    }

    return ( 
        <>
        
        <div className="">
      <section className="">
        <section className=" py-4 px-2    md:flex justify-between ">
          <section className="flex">

          <div className=" mt-1  rounded bg-filterColor pr-4 -ml-2 ">
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  backgroundColor: "#E5E5E5",
                  border: "none",
                  color: "#45444B",
                  fontSize: "12px",
                  height: "35px",
                }}
                id="dropdown-basic"
              >
                FILTER BY
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item  onClick={() => customFilterByStatus("All")}>
                  All
                </Dropdown.Item>

                <Dropdown.Item onClick={() => customFilterByStatus("Active")}>
                  Active Items
                </Dropdown.Item>

                <Dropdown.Item onClick={() => customFilterByStatus("Inactive")}>
                  InActive Items
                </Dropdown.Item>

            
              </Dropdown.Menu>
            </Dropdown>
          </div>
          
          <Tooltip title="Reload">
            <RestartAlt className="cursor  ml-2 mt-1" onClick={()=> loadItemsFromServer()} color="primary" fontSize="large" />
          </Tooltip>
          </section>
     
          

          <div className="flex  ">

              {itemSelectList.length ? 
                <div className="flex ">
                <button
                  onClick={() => changeStatus(4)}
                  className=" bg-filterColor rounded text-black  text-xs py-[1.8vh] font-[Poppins]  mt-1 mr-1 px-4"
                >
                  ACTIVE
                </button>

                <button
                  onClick={() => changeStatus(5)}
                  className="bg-filterColor mx-2  rounded text-black text-xs py-[1.8vh] font-[Poppins] mt-1  px-4"
                >
                  INACTIVE
                </button>

                <img
                  onClick={() => deleteItems()}
                  className=" bg-filterColor mr-2   px-4 py-[1.8vh] mt-1   rounded text-black"
                  src={trash}
                  alt=""
                />


                <button className="bg-filterColor mx-2 rounded text-black text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3">
                  Export
                </button>
              </div>
            :
            
            null}
       
            
    

            <Link
              to={`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`}
            >
              <button
                style={{ backgroundColor: "#476EF8" }}
                className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
              >
                ADD NEW
              </button>
            </Link>
          </div>
        </section>
        <section className="mt-5">
        {/*  Table here... */}
        <CompositeItemGroupTable rowClick={tableRowClick} selectedItems={(list)=> selectedItems(list)}></CompositeItemGroupTable>
        </section>
      </section>
    </div>
        </>
     );
}

export default CompositeItemGroupPage;