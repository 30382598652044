import React, { useContext, useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { productService } from '../../services/product.service';
import Card from '@mui/material/Card';
import GeneralModal from "../../helpers/GeneralModal";
import { createModal, } from 'react-modal-promise';
import { SetAddonEdit } from '../../store/productAtom';
import { useNavigate, useLocation } from "react-router-dom";
import LogicContext from "../../Context/LogicContext";
import { Grid } from "@mui/material";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";



function ProductAddon(props) {
  const myPromiseModal = createModal(GeneralModal);
  const [tableData, setTableData] = useState([]);
  const setAddonEdit = SetAddonEdit();
  const navigate = useNavigate();
  const logicContext = useContext(LogicContext);
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];



  useEffect(() => {
    if (props?.Product_Id !== undefined) {
      productService.getProductAddon(props?.Product_Id).then(res => {
        if (res.data !== undefined) {
          setTableData(res.data);
        }
      });
    }
  }, [props?.Product_Id])


  function showInformation() {
    if (props?.Product_Id !== undefined) {
      productService.getProductAddon(props?.Product_Id).then(res => {
        if (res.data !== undefined) {
          setTableData(res.data);
        }
      });
    }
  }



  function openPanel() {
    if (tableData?.length < 1) {
      showInformation()
    }
  }


  async function editAddon(addon) {
    await myPromiseModal({
      title: 'Edit Addon?', body: ' Do you want to modify this record?',
      showInput: false, placeHolder: ''
    }).then(res => {
      if (res.decision === true) {
        setAddonEdit(addon);
        navigate(
          `/${currModuleId}/${currModuleName}/${currUserMenu}/edit-addon/${addon?.Addon_Id}/${addon.Product_Id}`
        );

      }
    })

  }




  const markAsInActive = async (item) => {
    await myPromiseModal({
      title: 'Mark as Inactive!', body: ' Do you want to mark this addon as Inactive?',
      showInput: false, placeHolder: ''
    }).then((res) => {
      if (res.decision === true) {
        //  send to server
        productService.submitProductAddon({ ...item, option: 4 }).then(res => {
          if (res.data !== undefined) {
            const { message_text, message_id } = res.data[0];
            if (message_id === 1) {
              logicContext.showToast(message_text, "success");
              const newList = tableData.map((addon) => {
                if (addon.Addon_Id === item.Addon_Id) {
                  return ({ ...addon, Addon_Status_Name: 'Inactive', Addon_Status_Id: 2 })
                }
                return addon;
              })
              setTableData(newList)
            } else {
              return logicContext.showToast(message_text, "error");
            }
          }
        })
      }
    })
  }


  const markAsActive = async (item) => {
    await myPromiseModal({
      title: 'Mark as Active!', body: ' Do you want to mark this coupon as Active?',
      showInput: false, placeHolder: ''
    }).then((res) => {
      if (res.decision === true) {
        //  send to server
        productService.submitProductAddon({ ...item, option: 5 }).then(res => {
          if (res.data !== undefined) {
            const { message_text, message_id } = res.data[0];
            if (message_id === 1) {
              logicContext.showToast(message_text, "success");
              const newList = tableData.map((addon) => {
                if (addon.Addon_Id === item.Addon_Id) {
                  return ({ ...addon, Addon_Status_Name: 'Active', Addon_Status_Id: 1 })
                }
                return addon;
              })
              setTableData(newList)
            } else {
              return logicContext.showToast(message_text, "error");
            }
          }
        })
      }
    })

  }




  return (
    <>

      <section className="my-2  ">
        <Accordion expanded={true}>
          <AccordionSummary onClick={openPanel} style={{ backgroundColor: "#E7ECFF" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="font-weight-bold" >  Addon List</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {tableData?.map((item, i) => {
              return (
         


                <Grid key={i} className="border-2 my-2 py-2 px-1" container spacing={2}>

                  <Grid item xs={4}>
                    <div className="">
                      <div>
                        <span className=" text-sm font-bold"
                          style={{ color: "#45444B" }}>{item?.Addon_Name} </span>
                        <div className="text-sm font-bold">Unit:  <span style={{ color: "#45444B" }}>{item?.Addon_Unit_Name}</span> </div>
                        <div className="text-sm font-bold">Description:  <span style={{ color: "#45444B" }}>{item.Addon_Description}</span> </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="">
                      {(item?.Addon_Status_Id === 1) ?
                        <span >Status:<span className="badge text-sm badge-success  font-weight-bold"> {item?.Addon_Status_Name} </span></span>
                        :
                        <span >Status: <span className="badge text-sm badge-danger  font-weight-bold">{item?.Addon_Status_Name}</span> </span>}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container className=" " spacing={2}>
                      <Grid item xs={12}>

                        <div className="flex justify-between">
                          <div className="">Interval :{item?.addon_interval_Name} </div>
                          <div className=" font-bold text-xl mb-2">₦ {formatCustomNumber(item?.Addon_Price)} </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} className="flex justify-between">

                        <button className=" py-0.5 px-1  rounded text-sm text-white bg-yellow-300" onClick={() => editAddon(item)} >Edit</button>

                        {(item?.Addon_Status_Id === 1) ?
                          <button className="py-0.5 px-1 mx-2 rounded text-sm text-white bg-orange-300 "  onClick={() => markAsInActive(item)} >Mark as Inactive</button>
                          : null}

                        {(item?.Addon_Status_Id === 2) ?
                          <button className="py-0.5 px-1  rounded text-sm text-white bg-green-500" onClick={() => markAsActive(item)} >Mark as Active</button>
                          : null}

                        {/* <button className="py-0.5 px-3  rounded text-sm text-white bg-red-900" style={{ color: "#F56132" }}>Delete</button> */}
 
                      </Grid>

                    </Grid>



                  </Grid>


                </Grid>


              )




            })}

          </AccordionDetails>
        </Accordion>

      </section>



      {/* {!showTable ?
    <button className="btn btn-sm btn-primary ml-4 mt-5" onClick={showInformation}> View Associate Items</button>
  : null  } */}




    </>
  );
}

export default ProductAddon;
