/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useContext, useRef } from "react";
import { purchaseService } from "../../services/purchase.service";
import { Dropdown } from "react-bootstrap";
import LogicContext from "../../Context/LogicContext";
import {
  GetRecoilBillListData,
  SetRecoilBillListData,
} from "../../store/billsAtom";
// import CurrencyFormat from 'react-currency-format';
import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";
import { useState } from "react";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { useLocation, useNavigate } from "react-router-dom";

import ActionPaneComponent from "../../helpers/ActionPaneComponent";
import BillPageTable from "./BillPageTable";

function BillPage() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const logicContext = useContext(LogicContext);
  const setBillListData = SetRecoilBillListData();
  const getBillListData = GetRecoilBillListData();
  const myPromiseModal = createModal(GeneralModal);

  const [tableData, setTableData] = useState([]);
  const [itemSelected, setItemSelected] = useState([]);
  const mountRef = useRef(false);

  useEffect(() => {
    document.title = "Bills";
    if (getBillListData["Bill_Info"]?.length > 0) {
      setTableData(getBillListData["Bill_Info"]);
      let newList = getBillListData["Bill_Info"].filter(
        (bill) => bill.Bill_Status_Id === 2
      );
    }
  }, [getBillListData]);

  const getPurchaseOrderListApi = async () => {
    purchaseService.getBillsList().then((response) => {
      if (response?.data !== undefined) {
        setBillListData(response.data);
      }
    });
  };

  const onActionFilterPerform = (action) => {
    const option = action.target.value;
    if (Number(option) === 0) {
      return setTableData(getBillListData["Bill_Info"]);
    }
    var newList = getBillListData["Bill_Info"].filter(
      (item) => item.Bill_Status_Id === Number(option)
    );
    if(newList?.length){
      setTableData(newList);
    }else{
      setTableData([]);
    }
  };

  function selectedTableItems(itemList) {
    setItemSelected(itemList);
  }

  function addNewInvoice() {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
  }

  async function bulkActions(action) {
  
    await myPromiseModal({
      title: "confirm your action!",
      body: `Do you want to ${action.Action_Name} ?`,
      showInput: false,
      placeHolder: "",
    }).then((result) => {
      if (result.decision === true) {
        let temp = "";
        const option = action.Procedure_Option;
        itemSelected.map((item) => {
          temp += `<a> <refno> ${item.Bill_Ref_No} </refno> </a>`;
        });

        let xml_string_refno = `<root> ${temp} </root>`;
        purchaseService
          .setBillBulkAction({ xml_string_refno, option })
          .then((res) => {
            if (res?.data !== undefined) {
              const { message_text } = res.data[0];
              logicContext.showToast(message_text, "success");
              return getPurchaseOrderListApi();
            }
          })
          .catch((err) => {
            logicContext.showToast("Error", "error");
          });
      }
    });
  }

  return (
    <>
      <section>
        <section className="bg-white  px-2    md:flex justify-between ">
          <div className="flex px-2 mt-1">
            {itemSelected.length > 0 ? (
              <div className="row">
                <div className="col col-md-4">
                  <Dropdown>
                    <Dropdown.Toggle
                      style={{
                        backgroundColor: "#E5E5E5",
                        border: "none",
                        color: "#45444B",
                        fontSize: "12px",
                        height: "45px",
                      }}
                      id="dropdown-basic"
                    >
                      Bulk Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {getBillListData["Bulk_Action"]?.map((action, i) => {
                        return (
                          <Dropdown.Item
                            key={i}
                            onClick={() => bulkActions(action)}
                          >
                            {action.Action_Name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ) : (
              <ActionPaneComponent
                bulkActionData={getBillListData["Filtering_Criteria"]}
                onSelectBulkAction={onActionFilterPerform}
              />
            )}

            <Tooltip title="Reload">
              <RestartAlt
                className="cursor ml-2 mt-1"
                onClick={() => getPurchaseOrderListApi()}
                color="primary"
                fontSize="large"
              />
            </Tooltip>
          </div>

          <div className="flex justify-between">
            <button className="  font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8 text-sm">
              IMPORT
            </button>
            <div className="btn-group">
              <button
                className=" font-[Poppins] font-bold py-2 mt-1 px-2 rounded md:ml-8   text-sm"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                EXPORT
              </button>
            </div>

            <button
              onClick={addNewInvoice}
              className="bg-buttonColor font-[Poppins] py-3 mt-1 mr-1 px-3 rounded md:ml-8  text-white text-xs"
            >
              ADD NEW
            </button>
          </div>
        </section>

        <section className="mt-5">
          <BillPageTable
            tableData={tableData}
            selectedTableItems={selectedTableItems}
          />
        </section>
      </section>
    </>
  );
}

export default BillPage;
