import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
// import "../pages/Plan.css";
import { purchaseService } from "../../services/purchase.service";
import iconImage from "../../assets/Icon.svg";
import CurrencyFormat from "react-currency-format";

import LogicContext from "../../Context/LogicContext";
import { imageService } from '../../services/image.service';

import {GetRecoilBillListData, SetRecoilBillListData, ResetRecoilBillEdit, GetRecoilBillEdit} from "../../store/billsAtom";

import { useForm } from "react-hook-form";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";


import { useSearchParams } from 'react-router-dom';
import BillWareHouseName from "./BillWareHouseName";
import BillPaymentTerm from "./BillPaymentTerm";
import BillRate from "./BillRate";
import BillTransDiscountType from "./BillTransDiscountType";
import AddBillTable from "./AddBillTable";
import BillVendorComponent from "./BillVendornameComponent";
import BillDiscountComponent from "./BillDiscountComponent";



function AddBillPage() {
  // const setEntryData = SetBillListData();
  const getBillListData = GetRecoilBillListData();
  const setBillListData = SetRecoilBillListData();
  const editableBill = GetRecoilBillEdit();
  const resetEdit = ResetRecoilBillEdit();

  const navigate = useNavigate();
  const myPromiseModal = createModal(GeneralModal);
  const { register, setValue, getValues, handleSubmit, control,reset, formState: { errors }} = useForm();

  const logicContext = useContext(LogicContext);

  const [purchaseList, setPurchaseList] = useState([]);
  const [imageUrlList, setImageUrlList] = useState([]);
  const [discountValue, setDiscountValue] = useState(0);

  const [adjustmentValue, setAdjustmentValue] = useState(0);
  const [disCountTypeID, setDiscountTypeID] = useState(1);

  const mountRef = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const customVendorId = searchParams.get('vendor_id');
  const customVendorName = searchParams.get('vendor_name');


  useEffect(() => {
    mountRef.current = true;
    if (getBillListData["Customer"] === undefined) {
      purchaseService.getBillEntryData().then((response) => {
        console.log("entry ", response.data);
        if (mountRef.current && response?.data !== undefined) {
          setBillListData(response.data);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBillListData]);


  useEffect(() => {
    if (customVendorId !== null) {
      setValue('Vendor_Id', customVendorId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customVendorId]);



  useEffect(() => {
    document.title = 'Add New | Bill';

    if (editableBill?.Bill_Ref_No) {
      document.title = 'Modify Bill record';
      setValue("Bill_Ref_No", editableBill?.Bill_Ref_No);
      setValue("Bill_Notes", editableBill?.Customer_Notes);
      setValue('Vendor_Id', editableBill?.Vendor_Id);
      
   
      setValue("Payment_Term_Id", editableBill?.Payment_Term_Id);
      setValue("Item_Rates_Type_Id", editableBill?.Item_Rates_Type_Id);
      setValue("Discount_Type_Id", editableBill?.Discount_Type_Id);
      setValue("discount_value", editableBill?.Discount_Value);
      setValue("adjustment_amount", editableBill?.Adjustment_Amount);
      // setValue("Attached_File_Format", editableBill?.Attached_File_Format);
      // setValue("Attached_File_Id", editableBill?.Attached_File_Id);
      setValue("Warehouse_Id", editableBill?.Warehouse_Id);
      setDiscountValue(editableBill?.Discount_Value);
      setDiscountTypeID(editableBill?.Discount_Type_Id);
      setValue("Discount_Type_Id", editableBill?.Discount_Type_Id);
    
      setAdjustmentValue(editableBill?.Adjustment_Amount);
      if (editableBill?.Attachments?.length > 0) {
        reMapImageUri(editableBill?.Attachments)
      }
    }

    return () => {
      mountRef.current = false;
      resetEdit();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableBill])



  // ADD REMOTE IMAGE LINK TO CURRENT
  const reMapImageUri = (ImageList) => {
    let newList = ImageList.map((file, i) => {
      return ({...file, preview: imageService.BILL_IMAGE_URL + file?.Bill_Ref_No
            + file?.Image_Reference_No + '.' + file?.Image_Format} ) });

    setImageUrlList(newList);
  }



  const onVendorSelect = (vendor) => {
    setValue("Vendor_Id", vendor.Vendor_Id);
  };



  function onPaymentTermsSelect(val) {
    setValue("Payment_Term_Id", val.Payment_Term_ID);
  }


  function onItemRateSelect(val) {
    setValue("Payment_Term_Id", val.Item_Rates_Type_Id);
  }


  function onWareHouseNameSelect(val) {
    setValue("Warehouse_Id", val.Warehouse_Id);
  }
  

  function onTransDiscountTypeSelect(val) {
    // setValue("Transaction_Discount_Type_Td", val.Purchase_Order_Discount_Type_Id);
  }




  const onSubmit = async (option) => {
    const purchaseForm = getValues();
 
    //  check if vendor is selected
    if (!purchaseForm?.Vendor_Id) {
      return logicContext.showToast("Please select a Vendor", "info");
    }
  //  check if vendor is selected
    if (purchaseList.length === 0) {
      return logicContext.showToast("at least one or more item must be selected!", "info");
    }


    var tempStringList = purchaseList?.map((item) => {
      let temp = ` <a> <item_id>${item.Item_Id}  </item_id>
          <item_quantity>${item?.Item_Quantity} </item_quantity>
          <item_rate>${item?.Item_Rate} </item_rate>
          <tax_id>${item?.Tax_Id ? item?.Tax_Id: 0 } </tax_id>
          <account_id>${item?.Account_Id ?  item?.Account_Id : 0} </account_id>
          <customer_id>${item?.Customer_Id} </customer_id>
            </a>`;
      return temp;
    });


    await myPromiseModal({title: "Confirmation", body: " Do you want to save this record?",
    showInput:false, placeHolder:''}).then((res) => {
      if (res.decision === true) {
        let option = 2;
        const newData = getValues();
        if (editableBill?.Bill_Ref_No) {
          option = 3;
        }
        // attachedFile
        const formData = new FormData();
        for (const keyData in newData) {
          formData.set(keyData, newData[keyData] ? newData[keyData] : 0);
        }
        formData.set("option", option);
        formData.set("xml_string_item", `<root> ${tempStringList} </root>`);
        formData.set("discount_value", discountValue);
        formData.set("adjustment_amount", adjustmentValue);

        if (imageUrlList?.length) {
          var tempImageStringList = '';
          let fileCount = imageUrlList.length;

          for (let i = 0; i < imageUrlList.length; i++) {
            let element = imageUrlList[i];
            let newFile = element.file;
            
            if(newFile?.name !== undefined){
              formData.append('files', newFile);
              
              var fileExtension = newFile.name.split(".").pop();
              tempImageStringList += `<a> <img_refno>${'_' + i}
                     </img_refno> <img_format>${fileExtension} </img_format> </a>`;

            }else{
              tempImageStringList += `<a> <img_refno>${element?.Image_Reference_No}</img_refno>
               <img_format>${element?.Image_Format} </img_format> </a>`;
            }
            fileCount =  fileCount + 1;
           
          }

     

          formData.set("xml_string_img", `<root> ${tempImageStringList} </root>`);
          formData.set("Attached_File_Id", 1);
        }

        purchaseService.saveNewBill(formData).then((res) => {
          if (res?.data !== undefined) {
            const { message_text, message_id } = res.data[0];
            if (message_id === 1) {
              logicContext.showToast(message_text, "success");
              navigate(-1);
            } else {
              return logicContext.showToast(message_text, "error");
            }
          }
        });
      }
    }).catch((err) => {
      console.log('form error ',err)
      logicContext.showToast("error submitting record", "error",);
    })
      .catch((err) => {
        logicContext.showToast(
          "error submitting form, please try again",
          "error"
        );
      });
  };

  function onErrors(error) {
    console.error("Errorrrrr ", error);
    logicContext.showToast("Form error", "error");
  }

  const selectFile = () => {
    document.getElementById("attached-file").click();
  };


  

  function attachmentHandler(e) {
    // setAttachedFile((prev) => {return [...prev, ...e.target.files]});
    setImageUrlList([])
    Array.from(e.target.files).map((file, i) => {
      // console.log('file ', file)
      var fileExt = file.name.split(".").pop();
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        setImageUrlList(prev => {
          return [...prev, 
            { Image_Format: fileExt, Image_Reference_No: i, preview: reader.result, file: file }]
        })

      });
      reader.readAsDataURL(file);

    });
  }

  const removeImage = (index) => {
    let tempList = [...imageUrlList]
  
    tempList.splice(Number(index), 1);
    setImageUrlList(tempList)
    // setAttachedFile(tempList)

  }


  function billDateHandler() {
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
    if (editableBill?.Expected_Delivery_Date_String !== undefined) {
      defaultDate = moment(editableBill?.Expected_Delivery_Date_String).format("YYYY-MM-DD");
    }

    return (
      <div className="">
        <input
          defaultValue={defaultDate}
          key={defaultDate}
          className="bg-filterInput  pl-3  rounded max-h-full w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
          type="date"
          {...register("Bill_Date", {
            required: "expected delivery date is required",
          })}
        />
      </div>
    );
  }

  function dueDateHandler() {
    let defaultDate = moment(Date.now()).format("YYYY-MM-DD");
    if (editableBill?.Created_Date_String !== undefined) {
      defaultDate = moment(editableBill?.Created_Date_String).format("YYYY-MM-DD");
    }

    return (
      <div className="">
        <input
          defaultValue={defaultDate}
          key={defaultDate}
          className="bg-filterInput  pl-3  rounded max-h-full w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
          type="date"
          {...register("Due_Date", {
            required: " date is required",
          })}
        />
      </div>
    );
  }

  const onSelectionList = (tableList) => {
    console.log("table list ", tableList);
    setPurchaseList(tableList);
  };


  const calculateSubTotal = (list) => {
    let result = 0;
    list?.forEach((element) => {
      result += element.Item_Quantity * element.Item_Rate;
    });
    return result;
  };


  const onDiscountTypeIDSelect = (val) => {
    setValue('Discount_Type_Id', val)
    setDiscountTypeID(Number(val));
  };


  const calculateGrandTotal = (list) => {
    let result = 0;
    list?.forEach((element) => {
      result += Number(element.Item_Quantity) * Number(element.Item_Rate);
    });

    if (Number(disCountTypeID) === 2) {
      console.log('Dis ', discountValue)
      let discount = (discountValue / 100) * result;
      console.log('dis2 ', discount)
      result = Number(result) - Number(discount);
      // result = discount
    }
    
    if (Number(disCountTypeID) === 1) {
      result = result - discountValue;
    }

    result = Number(result) + Number(adjustmentValue);
    return result;
  };



  return (
    <>

      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <section className="container  max-auto  ">
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Grid item xs={6}>
                <span className="heading text-filterTextColor ">Vendor Name</span>
                <span className="text-danger font-bold">*</span>

                <Grid container>
                  <Grid item xs={1.9}>
                    <button className="flex outline-none items-center justify-center px-4  ">
                      <svg
                        className="  outline-none border-transparent focus:border-transparent"
                        width="38"
                        height="44"
                        border="none"
                        viewBox="0 0 24 24"
                        fill="bg-inputColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.4996 10.875C16.4996 11.9875 16.1697 13.0751 15.5516 14.0001C14.9335 14.9251 14.055 15.6461 13.0272 16.0718C11.9994 16.4976 10.8684 16.609 9.77723 16.3919C8.68608 16.1749 7.6838 15.6391 6.89713 14.8525C6.11046 14.0658 5.57473 13.0635 5.35769 11.9724C5.14065 10.8812 5.25204 9.75024 5.67779 8.72241C6.10353 7.69457 6.8245 6.81607 7.74953 6.19798C8.67455 5.5799 9.76209 5.25 10.8746 5.25C12.3659 5.25169 13.7957 5.84487 14.8502 6.89939C15.9047 7.95392 16.4979 9.38368 16.4996 10.875ZM21.5294 21.5304C21.3888 21.6711 21.198 21.7501 20.9991 21.7501C20.8001 21.7501 20.6094 21.6711 20.4687 21.5304L16.4165 17.4783C14.7122 18.9092 12.5213 19.6269 10.3006 19.4817C8.08 19.3366 6.00111 18.3398 4.49749 16.6993C2.99387 15.0587 2.18161 12.9011 2.23008 10.6762C2.27855 8.45139 3.18402 6.33114 4.75766 4.75763C6.3313 3.18413 8.45163 2.27884 10.6765 2.23057C12.9013 2.18229 15.0589 2.99474 16.6993 4.4985C18.3398 6.00226 19.3363 8.08123 19.4813 10.3019C19.6262 12.5225 18.9083 14.7134 17.4773 16.4176L21.5294 20.4698C21.6701 20.6104 21.7491 20.8012 21.7491 21.0001C21.7491 21.199 21.6701 21.3898 21.5294 21.5304ZM10.8746 18C12.2838 18 13.6613 17.5821 14.833 16.7992C16.0047 16.0163 16.918 14.9035 17.4573 13.6016C17.9965 12.2997 18.1376 10.8671 17.8627 9.48498C17.5878 8.10287 16.9092 6.83331 15.9127 5.83686C14.9163 4.84042 13.6467 4.16182 12.2646 3.8869C10.8825 3.61199 9.44991 3.75308 8.14799 4.29236C6.84607 4.83163 5.73329 5.74486 4.95039 6.91656C4.16748 8.08826 3.74961 9.46581 3.74961 10.875C3.75175 12.764 4.5031 14.575 5.83883 15.9108C7.17457 17.2465 8.9856 17.9979 10.8746 18Z"
                          fill="#45444B"
                        />
                      </svg>
                    </button>
                  </Grid>
                  <Grid className="" item xs={10}>
                  <BillVendorComponent defaultVal={{label: editableBill?.Vendor_Name || customVendorName}}
                      onVendorSelect={onVendorSelect} />
                  </Grid>
                </Grid>
        
              </Grid>
            </Grid>

            <Grid item xs={6}>
                <span className="heading  text-filterTextColor ">Bill Number</span>
                  <input
                    type="text"
                    value={editableBill?.Bill_Number}
                    className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    placeholder="auto generated"
                    readOnly={true} />

            </Grid>
            <Grid item xs={6}>
                <span className="text-sm ">Warehouse Name</span>
                <span className="text-danger font-bold">*</span>
                <BillWareHouseName
                  defaultVal={{ label: editableBill?.Warehouse_Name }}
                  onWareHouseNameSelect={onWareHouseNameSelect}
                />
            </Grid>

            <Grid item xs={6}>
                <span className="heading text-filterTextColor ">Order Number</span>
                  <input
                    type="number"
                    className="bg-filterInput pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    placeholder=""
                    {...register("Order_Number", {
                    })} />
            </Grid>
            
            <Grid item xs={6}>
                <span className="heading text-filterTextColor ">Due Date</span>
                <span className="text-danger font-bold">*</span>
                {dueDateHandler()}
            </Grid>

            <Grid item xs={6}>
                <span className="heading text-filterTextColor ">Bill Date</span>
                <span className="text-danger font-bold">*</span>
                  {billDateHandler()}
            </Grid>

            <Grid item xs={6}>
              <span className="text-sm ">Payment Terms</span>
              {/* <span className="text-danger font-bold">*</span> */}
              <BillPaymentTerm
                defaultVal={{ label: editableBill?.Payment_Term_Name }}
                onPaymentTermsSelect={onPaymentTermsSelect}
              />
            </Grid>

            <Grid item xs={6}>
              <span className="text-sm ">Item Rate</span>
              <span className="text-danger font-bold">*</span>
              <BillRate
                defaultVal={{ label: editableBill?.Item_Rates_Type_Name }}
                onItemRateSelect={onItemRateSelect}
              />
            </Grid>

            <Grid item xs={6}>
              <span className="text-sm ">DiscountTransaction  Type</span>
              <span className="text-danger font-bold">*</span>
              <BillTransDiscountType
                defaultVal={{ label: editableBill?.Delivery_To_Type_Name }}
                onTransDiscountTypeSelect={onTransDiscountTypeSelect} />
            </Grid>

            <Grid item xs={12}>
              <AddBillTable
                defaultList={editableBill?.Bill_details}
                onSelectionList={onSelectionList} />
            </Grid>
            <Grid item xs={12}>
              <div className="flex justify-center ">
                <Grid className="my-2 border-2 px-2 py-2   " item xs={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <span className="#476ef8 text-md font-bold">Sub Total</span>
                    </Grid>
                    <Grid item xs={4}>
                      <span className="#476ef8"></span>
                    </Grid>
                    <Grid item xs={4} className="flex justify-center">
                      <span className="#476ef8 text-xl right-0">
                        <CurrencyFormat value={parseFloat(
                            calculateSubTotal(purchaseList))?.toFixed(2)} displayType={"text"}
                          thousandSeparator={true} prefix={"₦"} />
                      </span>
                    </Grid>

                    <Grid item xs={4}>
                      <span className="#476ef8 ">
                        <span className="text-md font-bold ">Discount</span>
                      </span>
                    </Grid>
                    <Grid item xs={4} className="flex justify-center">
                    <span className=" relative w-full">
                                  
                          
                      <input
                          className="bg-filterInput rounded pl-3 h-[7vh] w-full z-50 bottom-0 
                outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="number"
                          placeholder=""
                          name="discount_type"
                          key={discountValue}
                          autoFocus="autoFocus"
                          onChange={(e) => setDiscountValue(Number(e.target.value)) }
                          defaultValue={discountValue} />


              
                           
  
                  <span className=" border-2  absolute -top-0 right-0 ">
                  {/* key={disCountTypeID} */}
                    <BillDiscountComponent defaultVal={editableBill?.Discount_Type_Id}
                      onDiscountTypeSelect={(id)=>onDiscountTypeIDSelect(id)} />
                      </span>
                      </span>
                    </Grid>
                    <Grid item xs={4} className="flex justify-center">
                        {disCountTypeID === 1 && 
                        <CurrencyFormat className={ "text-xl " +(adjustmentValue < 0 ? "text-danger" : "text-success")}
                        value={discountValue?.toFixed(2)}
                        displayType={"text"} thousandSeparator={true}prefix={"₦"} />
                        
                        }
                      
                        {disCountTypeID === 2 && 
                        <CurrencyFormat className={ "text-xl " +(adjustmentValue < 0 ? "text-danger" : "text-success")}
                        value={discountValue?.toFixed(2)}
                        displayType={"text"} thousandSeparator={true}prefix={"%"} />
                        
                        }
                      

                    </Grid>

                    <Grid item xs={4}>
                        <span className="text-md font-bold">Adjustment</span>
                    </Grid>

                    <Grid item xs={4} className="flex justify-center">
                      <span className="w-full">  <input
                          className="bg-filterInput z-0  h-[7vh] w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="number" step="any" defaultValue={adjustmentValue}
                          autoFocus="autoFocus" onChange={(e) => setAdjustmentValue(Number(e.target.value)) }
                          key={adjustmentValue} name="adjustment_value"/></span>
                    </Grid>

                    <Grid item xs={4} className="flex justify-center">
                      <CurrencyFormat
                        className={ "text-xl " +(adjustmentValue < 0 ? "text-danger" : "text-success")}
                        value={parseFloat(adjustmentValue)?.toFixed(2)}
                        displayType={"text"} thousandSeparator={true}prefix={"₦"} />
                    </Grid>

                    <Grid item xs={4}>
                      <div className="">
                        <div>
                          <span className="text-xl  font-bold">Total (₦)</span>{" "}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4} className="flex justify-center">
                      <span className=" text-xl rounded font-bold p-2 bg-dark ">
                        <CurrencyFormat
                          className="text-light "
                          value={parseFloat( calculateGrandTotal(purchaseList))?.toFixed(2)}
                          displayType={"text"} thousandSeparator={true} prefix={"₦"} />
                      </span>
                    </Grid>
                  </Grid>
                </Grid>

              </div>
            </Grid>

            <Grid item xs={12}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <div className="">
                      <span className="text-sm ">Customer Notes</span>
                      <input  {...register("Bill_Notes", {})}
                        className="bg-filterInput pl-3 rounded  h-[15vh] w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="text" />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="">
                      <span className="text-sm ">Terms and Conditions</span>
                        <input readOnly={true} type="readOnly"
                          className="bg-filterInput rounded h-[15vh] w-full pl-3 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          placeholder="Enter the terms and conditions of your business to 
                              be displayed in your transaction" />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="">
                <span className="text-sm ">Attach File(s)</span>
                <div className="relative w-full">
                  <input id="attached-file"
                    className=" h-[6vh] w-50 bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                    onChange={attachmentHandler} multiple type="file" name="name"/>

                  <div
                    onClick={selectFile}
                    className=" btn btn-large btn-dark flex justify-between  absolute  top-0 left-0"
                  >Click here to add a attachments
                    <img className="ml-2 mt-3" src={iconImage} alt="" />

                    {/* <InvoiceImage></InvoiceImage> */}
                  </div>
                </div>
                <i> maximum file size 5MB </i>
              </div>


              <Grid container  >
                {
                  imageUrlList.map((file, i) => {
                    return (
                      <Grid xs={4}
                        key={i} className=" flex   justify-end">
                        <div className=" ">
                          <img className="" width="150" key={i} src={file.preview} alt={file.Image_Format}></img>
                          <div onClick={() => removeImage(i)} className="btn mt-1 btn-sm btn-danger" >X</div>
                        </div>
                      </Grid>
                    )
                  })
                }
              </Grid>
              <div className="">{imageUrlList.length} file(s) selected</div>


            </Grid>
            <Grid item xs={12}>
              <section>
                <div className="gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
                  <div type="cancel" style={{ color: "#F17E7E", backgroundColor: "white" }}
                    className="text-sm  btn btn-danger rounded"  onClick={() => navigate(-1)} >
                    CANCEL
                  </div>
                  <div>
                    <span    type="submit"   style={{ color: "#476EF8" }}
                      className="text-sm  px-3 rounded mr-10 py-2" >
                      SAVE AS DRAFT
                    </span>
                    <button  type="submit"  style={{ backgroundColor: "#476EF8" }}
                      className="text-sm text-white px-3 rounded py-2"  >
                      SAVE
                    </button>
                  </div>
                </div>
              </section>
            </Grid>
          </Grid>
        </section>
      </form>

    </>
  );
}

export default AddBillPage;
