
import axios from 'axios';
import { authHeader } from '../helpers/authHeader';


export const itemsService = {
    getItemsMasterData,
    getItemsList,
    getItemTableHeader,
    updateItemTableHeader,
    setItemActive,
    setIteminActive,
    saveNewItem,
    deleteItems,
    GetItemDetails,
    updateItem,
    submitItemImage,
    submitItemGroupImage,
    getItemBundleHistory,
    getItemTransaction,
    getItemHistory,
    getItemRateHistory,
    getItemImages,
    getItemStockInformation,
    submitRateHistory,
    getAssociateItems

};




async function  submitRateHistory(body) {
   return axios.post(`/submit-rate-history`,body,  authHeader())
}



function getAssociateItems(item_id){
  return axios.get(`/get-associate-items/${item_id}`, authHeader())
}


async function  getItemsMasterData() {
   return axios.get(`/get-item-master-data`,  authHeader())
}

async function  getItemStockInformation(id) {
   return axios.get(`/item-stock-information/${id}`,  authHeader())
}


async function  getItemsList() {
   return axios.get(`/get-item-list`,  authHeader())
}


async function  getItemTableHeader() {
   return axios.get(`/get-table-header`,  authHeader())
}

function updateItemTableHeader(xmlString){
   var body = {xml_string : xmlString}
   return axios.put('/update-item-table-header', body, authHeader())
        
 }

function setItemActive(xmlString){
   var body = {xml_string : xmlString}
  return axios.put('/set-item-active', body, authHeader())    
 }

function setIteminActive(xmlString){
   var body = {xml_string : xmlString}
  return axios.put('/set-item-inactive', body, authHeader())
       
 }


function deleteItems(xmlString){
   var body = {xml_string : xmlString}
  return axios.put('/delete-items', body, authHeader())
       
 }


function  saveNewItem(body){
  return axios.post('/save-new-item', body, authHeader())
       
 }


function  updateItem(body){
  return axios.put('/update-item', body, authHeader());
       
 }

function  GetItemDetails(item_id){
  return axios.get(`/get-item-details/${item_id}`, authHeader())
       
 }

 function submitItemImage(body){
   return axios.post(`/submit-item-image`, body, authHeader())
 }


 function submitItemGroupImage(body){
   return axios.post(`/submit-item-group-image`, body, authHeader())
 }




 function getItemBundleHistory(item_id){
   return axios.get(`/get-item-bundle-history/${item_id}`, authHeader())
 }


 function getItemTransaction(item_id){
   return axios.get(`/get-item-transactions/${item_id}`, authHeader())
 }



 function getItemHistory(item_id){
   return axios.get(`/get-item-history/${item_id}`, authHeader())
 }



 function getItemRateHistory(item_id){
   return axios.get(`/get-item-rate-history/${item_id}`, authHeader())
 }


 function getItemImages(item_id){
   return axios.get(`/get-item-images/${item_id}`, authHeader())
 }







