import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
// import "../../pages/Plan.css";
import { purchaseService } from "../../services/purchase.service";
import underscore from "underscore";
import LogicContext from "../../Context/LogicContext";



import { useForm, Controller } from "react-hook-form";
import moment from "moment";

import { useNavigate } from "react-router-dom";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";

import VendorCurrencyComponent from "./VendorCurrencyComponent";
import VendorTaxRateComponent from "./VendorTaxRateComponent";
import VendorPaymentTermsComponent from "./VendorPaymentTermsComponent";
import VendorPriceListComponent from "./VendorPriceListComponent";
import { GetEntryData, GetVendorEdit } from '../../store/vendorAtom';



function AddVendorOtherInfoTab(props) {
  const entryMasterData = GetEntryData();
  const vendorToEdit = GetVendorEdit();


  useEffect(() => {
    var masterDataGroup = underscore?.groupBy(entryMasterData, "Master_Type");
    let countryList = masterDataGroup[1];

    const tempData = countryList?.map((element) => {
      return ({ ...element, label: `${element?.Name}` })
    }
    );

  }, [entryMasterData])

  const navigate = useNavigate();
  const myPromiseModal = createModal(GeneralModal);
  const { register, setValue, getValues, handleSubmit, control, reset, formState: { errors } } = useForm();

  useEffect(() => {
    if (vendorToEdit.Vendor_Id) {
      setValue('Vendor_Id', vendorToEdit.Vendor_Id);
      setValue('Currency_Id', vendorToEdit.Currency_Id);
      setValue('Facebook_URL', vendorToEdit.Facebook_URL);
      // setValue('Tax_Id', vendorToEdit.Tax_Id);
      setValue('Payment_Term_Id', vendorToEdit.Payment_Term_Id);
      setValue('Twitter_URL', vendorToEdit.Twitter_URL);
      setValue('Website_URL', vendorToEdit.Website_URL);
      setValue('Vendor_Remark', vendorToEdit.Vendor_Remark);

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorToEdit])


  const logicContext = useContext(LogicContext);
  const mountRef = useRef(true);



  function onPaymentTermsSelect(val) {
    setValue("Payment_Term_Id", val.Code);
  }


  function onTaxRateSelect(val) {
    setValue("Tax_Id", val.Code);
  }



  const onSubmit = async (option) => {
    const formData = getValues();


    await myPromiseModal({
      title: "Confirmation", body: " Do you want to save this record?",
      showInput:false, placeHolder:''
    }).then((res) => {
      if (res.decision === true) {
        const newData = getValues();

        purchaseService.submitVendorOtherInfo(formData).then((res) => {
          if (res?.data !== undefined) {
            const { message_text, message_id } = res.data[0];
            if (message_id === 1) {
              logicContext.showToast(message_text, "success");
              props.onDynamicTabSelect(3)
            } else {
              return logicContext.showToast(message_text, "error");
            }
          }
        });
      }
    }).catch((err) => {
      logicContext.showToast("error submitting record", "error",);
    })
      .catch((err) => {
        logicContext.showToast(
          "error submitting form, please try again",
          "error"
        );
      });
  };

  function onErrors(error) {
    console.error("Errorrrrr ", error);
    logicContext.showToast("Form error", "error");
  }

  function onCurrencySelect(val) {
    setValue("Currency_Id", val.Code);

  }

  function onPriceListSelect(val) {
    // setValue("Price_List_Id", val.Code);

  }







  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <section className="container  max-auto  ">
          <Grid container spacing={2}>

            <Grid item xs={6}>
              <span className="text-sm ">Currency</span>
              <span className="text-danger font-bold">*</span>

              <VendorCurrencyComponent defaultVal={{ label: vendorToEdit?.Currency_Name }}
                onCurrencySelect={onCurrencySelect} />
            </Grid>
            <Grid item xs={6}>
              <div className="">
                <span className="text-sm ">Facebook</span>
                <div className="">
                  <input
                    className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    placeholder="enter url "
                    {...register("Facebook_URL", {
                    })}

                  />
                </div>
              </div>
            </Grid>

            <Grid item xs={6}>
              <span className="text-sm ">Tax Rate</span>
              <VendorTaxRateComponent
                defaultVal={{ label: vendorToEdit?.Tax_Name }}
                onTaxRateSelect={onTaxRateSelect}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="">
                <span className="text-sm ">Twitter</span>
                <div className="">
                  <input
                    // value={editableOrder?.Purchase_Order_Number}
                    className="bg-filterInput  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    placeholder="enter url "
                    {...register("Twitter_URL", {
                    })}

                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <span className="text-sm ">Payment Terms</span>
              <VendorPaymentTermsComponent
                defaultVal={{ label: vendorToEdit?.Payment_Term_Name }}
                onPaymentTermsSelect={onPaymentTermsSelect}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="">
                <span className="text-sm ">Remark <i>Internal use only </i></span>
                <div className="">
                  <input
                    // value={editableOrder?.Purchase_Order_Number}
                    className="bg-filterInput h-[10vh]  pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    placeholder="enter url "
                    {...register("Vendor_Remark", {
                    })}

                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <span className="text-sm ">Price List</span>
              <VendorPriceListComponent
                // defaultVal={{ label: editableOrder?.Payment_Term_Name }}
                onPriceListSelect={onPriceListSelect}
              />
            </Grid>
            <Grid item xs={6}>
              <div className="">
                <span className="text-sm ">Website </span>
                <div className="">
                  <input
                    // value={editableOrder?.Purchase_Order_Number}
                    className="bg-filterInput   pl-3 rounded max-h-full  w-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    placeholder="enter url "
                    {...register("Website_URL", {
                    })}

                  />
                </div>
              </div>
            </Grid>


            <Grid item xs={12}>
              <section>
                <div className="gird grid-cols-2 flex justify-between my-10 gap-4 w-full">
                  <div
                    type="cancel"
                    style={{ color: "#F17E7E", backgroundColor: "white" }}
                    className="text-sm  btn btn-danger rounded"
                    onClick={() => navigate(-1)}
                  >
                    CANCEL
                  </div>
                  <div>
                    <span
                      type="submit"
                      style={{ color: "#476EF8" }}
                      className="text-sm  px-3 rounded mr-10 py-2"
                    >
                      SAVE AS DRAFT
                    </span>
                    <button
                      type="submit"
                      style={{ backgroundColor: "#476EF8" }}
                      className="text-sm text-white px-3 rounded py-2"
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </section>
            </Grid>
          </Grid>
        </section>
      </form>
    </>


  );
}

export default AddVendorOtherInfoTab;
