import { useContext, useEffect, useState } from "react";
import LogicContext from "../../Context/LogicContext";
import { broadCastService } from "../../services/broadcast.service";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import CircularProgress from "@mui/material/CircularProgress";

 const templateMsgList = [
    {
        message: `Dear {{first name Last name}}

        We would like to keep informing you that you can still make payments towards your food baskets using the unique account number {{Bank Name}}, {{Account number}} provided to you. Rest assured, our delivery services are fully operational, delivering your food baskets to you as usual as we continue to save you money on your food purchases.
        
        Please continue to use the provided account number for seamless payment processing. Our team will always ensure that you receive your food baskets promptly and efficiently. You can get your account number by chatting with Mama on WhatsApp 08184223072 from your registered phone number with us. You can also download our mobile app on Android and iOS by visiting the Playstore or App Store respectively and search for Grocedy.
        
        If you have any questions or concerns, our customer support team is here to help. You can reach out to us via phone, email, or through our online chat feature on our website www.grocedy.com. Thank you for choosing Grocedy.`,
        template_name:'broadcast_message_1',
        dynamic:true
    },
    {
        message: `📲 Introducing Grocedy+: Buy now, Pay later! 🌽🍅🥦
        Exciting news, Grocedy customers! 🎉 Enjoy the convenience of purchasing food items now and paying later with Grocedy+! 🛒💳
        Order today and pay at your convenience within 1 month. 📅💵
        For more info, call us or chat on WhatsApp: 08097171288.
        Thank you for choosing Grocedy! 🤝😃`,
        template_name:'grocedy_plus',
        dynamic:false
    },
    {
        message: `*Good day, we have an update for you! Kindly reply to enable us continue the conversation.*`,
        template_name:'update_message'
    },
    {
        message: `Dear {{first name Last name}}
        This is for example 4 purpose.`,
        template_name:'broadcast_message_4'
    },
]

const broadCastUrl = process.env.REACT_APP_PUBLIC_SERVER_URL;




const BroadCastPage = () => {
    const logicContext = useContext(LogicContext);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const myPromiseModal = createModal(GeneralModal);


    useEffect(()=> {
        
        broadCastService.getCustomerList(broadCastUrl).then(res => {
        });
    },[]);

    const onSelectTemp = (temp)=> {
        setSelectedTemplate(temp);
    }


    const broadcastMessageAction = async()=> {
        const broadCastUrl = process.env.REACT_APP_PUBLIC_SERVER_URL;
        
        const response =  await myPromiseModal({title: "Send broadcast selected message", body: "The selected template message will be broadcasted. Do you want to continue?",
        showInput:false, placeHolder:''});
        if(response.decision === true){

        if(!selectedTemplate?.template_name){
            return logicContext.showToast("Please select a template!",'error');
        }
        setIsLoading(true);
        broadCastService.submitBroadCast(broadCastUrl,selectedTemplate).then((res => {
            setIsLoading(false);
            if(res?.msg){
                logicContext.showToast("Broadcast Message sent",'success');
                logicContext.showToast(res?.msg,'info');

            }
            
        })).catch(err => {
            console.log(err);
            setIsLoading(false);
            logicContext.showToast("something wen wrong!",'error');
        });
       
        }

    }

  
    return ( 
        <>

        <div className="jumbotron mt-5">
        <h4 className="display-4">Broadcast Page</h4>
        <p className="lead">Welcome to Notification Center</p>
        </div>


       

        {/*  container */}
        <div className="container">
            <div className="row">
                <div className="col-4 bg-light p-2 ">
                    <h6 className="font-weight-bold p-3">Template List</h6>
                {templateMsgList?.map((msg, i)=> {
                    return (
                    <>
                    <ul  className="list-group">
                <li key={i} onClick={()=> onSelectTemp(msg)} className={"list-group-item show-cursor bg-light" + `${msg?.template_name === selectedTemplate?.template_name ? "bg-light":"" }`}>
                    {msg?.template_name}</li>
                </ul>
                    </>)
                })}
                </div> 
                {/* End first column */}
               
                <div className="col-8 p-2 bg-light">
                <h2 className=" text-center mt-3 ">Template Name : <span className="text-primary font-weight-bold">{selectedTemplate?.template_name}</span> </h2>
                <hr className="my-4"/>
                <p className="p-1 mt-2 cursor ">
                {selectedTemplate?.message}
                </p>

               <div className="text-center">
               {!selectedTemplate?.template_name  && 
                     <span className="text-danger text-center text-center p-3">No template is selected. Please select a template!</span>
                    }
               </div>
            
               
                <div className="text-center p-4">
                {isLoading === true ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
                <button onClick={()=> broadcastMessageAction()} className="btn btn-success p-3">Broadcast Message</button>
              
            )}

                </div>
                {/* scroll */}
                
                {/* scroll end */}

                </div>
                {/* End second col */}
            </div>
            </div>
        
        </>
     );
}
 
export default BroadCastPage;