/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLocation, useNavigate } from "react-router-dom";
import { GetManualJournalAtom, SetManualJournalAtom } from "../../store/manualJounalAtom";

import { useCallback, useEffect, useState } from "react";

import RestartAlt from '@mui/icons-material/RestartAlt';
import Tooltip from "@mui/material/Tooltip";
import Grid from '@mui/material/Grid';
import { creditNoteService } from "../../services/creditNoteService";
import { Table, Input } from 'antd';
// import CreditNoteFilter from "./CreditNoteFilter";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";
import { formatCustomDate } from "../../helpers/formatCustomDate";
import { accountService } from "../../services/accountService";

const { Search } = Input;



function ManualJournalMainPage() {
  const { pathname } = useLocation();
  const setManualJournalAtom = SetManualJournalAtom();
  const getManualJournalAtom = GetManualJournalAtom();

  const navigate = useNavigate();
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');


  useEffect(() => {
    document.title = 'Manual Journal ';
    if (!getManualJournalAtom.account_info?.length) {
      loadTableList();
    } else {
      setFilteredData(getManualJournalAtom.account_info);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getManualJournalAtom.account_info]);


  const reloadData = () => {
    loadTableList();
  }


  const loadTableList = () => {
    accountService.manualJournal().then(res => {
      if (res.data) {
        setManualJournalAtom(res.data);
        setFilteredData(res.data?.account_info);
      }
    });
  }




  const onFilterSelect = (selection) => {
    if(selection == 0){
      setFilteredData(getManualJournalAtom?.account_info);
      return;
   }
    const newList = getManualJournalAtom?.account_info?.filter((item) => item?.Status_Id == selection);
    if (newList?.length) {
      setFilteredData(newList);
    } else {
      setFilteredData([]);
    }
  };

  function addNew() {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`)
  }

  const columns = [
    // {
    //   title: 'Date',
    //   key: 'postdate_string',
    //   sorter: (a, b) => a.postdate_string.localeCompare(b.postdate_string),
    //   dataIndex: "postdate_string",
    //   // sorter: (a, b) => a.Created_Date.localeCompare(b.Created_Date),
    //   render: (postdate_string) => formatCustomDate(postdate_string),
    
    // },
   
    {
      title: 'Acc Ref',
      key: 'account_ref_no',
      dataIndex: 'account_ref_no',
      className: "text-primary",
      sorter: (a, b) => a.account_ref_no.toString().localeCompare(b.account_ref_no.toString())
      //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
    {
      title: 'Date',
      key: 'journal_date',
      sorter: (a, b) => a.journal_date.localeCompare(b.journal_date),
      dataIndex: "Journal_Date",
      // sorter: (a, b) => a.Created_Date.localeCompare(b.Created_Date),
      render: (journal_date) => formatCustomDate(journal_date),
    
    },
    {
      title: 'Reference Id',
      dataIndex: 'reference_number',
      sorter: (a, b) => a.reference_number.toString().localeCompare(b.reference_number.tostring())
      //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
     {
      title: 'Account Number',
      dataIndex: 'account_number',
      sorter: (a, b) => a.account_number.localeCompare(b.account_number)
      //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
   
     {
      title: 'Status',
      dataIndex: 'account_status_name',
      // sorter: (a, b) => a.account_status_name.localeCompare(b.currency_name_short)
      //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
   
 
  ];


  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = getManualJournalAtom.account_info.filter((record) =>
      Object.values(record).some((text) =>
        String(text).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };


  const paginationOptions = {
    pageSize: 10,
    showSizeChanger: true,
    total: getManualJournalAtom?.account_info?.length,
    // total: filteredData.length,
    // onChange: (page, pageSize) => {
    //   console.log(page, pageSize);
    //   const newList = tableDataList.slice(0, pageSize);
    //   console.log('check ', newList);
    //   setTableList(newList);
    // },
  };



  const handleRowClick = (record) => {
    console.log('Row clicked...', record);
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${record?.account_ref_no}`);
  };




  return (

    <>
      <Grid container >
        <Grid item xs={12}>
          {/* grid start */}
          <div className="row   mt-5 mb-2" >
            <div className="col" >
              <Tooltip title="Reload">
                <RestartAlt
                  className="cursor ml-2 mt-2 mr-2"
                  onClick={() => reloadData()}
                  color="primary"
                  fontSize="large"
                />
              </Tooltip>
            </div>



            <div className="col offset-3" >

              <div className="flex  justify-end">
                {/* <button
                  style={{ backgroundColor: "#476EF8" }}
                  onClick={() => addNew()}
                  className=" mr-3  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                >
                  EXPORT
                </button> */}
                <button
                  style={{ backgroundColor: "#476EF8" }}
                  onClick={() => addNew()}
                  className="  rounded text-white text-sm font-[Poppins] mt-1 py-[1.8vh]  px-3"
                >
                  ADD NEW
                </button>

              </div>
            </div>

          </div>
          {/* End of Grid */}


        </Grid>
      </Grid>

      <div class="flex">
        <div class="w-1/2  p-1">
        {/* <CreditNoteFilter bulkActionData={getCreditNoteListAtom?.status_info}
            onFilterSelect={onFilterSelect}
             /> */}
             <span className=" ml-3 rounded-full badge p-3 text-white bg-blue-500 font-bold" >{filteredData?.length} </span>
        </div>
        <div class="w-1/2  p-1">
        {/* <Search
            placeholder="Search..."
            onSearch={handleSearch}
            onChange={(e) => handleSearch(e.target.value)}
            value={searchText}
            style={{ marginBottom: 10, width: 250 }}
          /> */}
         
        </div>
      </div>


      <section className="mt-5   w-full">
        <div className="">


          <Table key={filteredData?.length} columns={columns} 
          dataSource={filteredData?.map((item, i) => {
            return { ...item, Amount: formatCustomNumber(item.Amount),
               Transaction_Date: formatCustomDate(item.Transaction_Date)  };
          })}
            pagination={paginationOptions}
            rowClassName="show-cursor"
            style={{ background: '#f0f2f5', borderRadius: '8px' }}
            onRow={(record) => {
              return {
                onClick: () => {
                  handleRowClick(record);
                },
              };
            }}
          />

        </div>
      </section>
    </>

  );
}

export default ManualJournalMainPage;
