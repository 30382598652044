
import FAQEditModal from './FAQEditModal';
import { useEffect, useState } from 'react';
import {Table, Switch} from 'antd';
import { HolderOutlined, SyncOutlined} from '@ant-design/icons';

import ReactDragListView from 'react-drag-listview'; 
import 'antd/dist/antd.min.css';
import './CustomizeTable.css'; // import custom CSS
import { Tooltip } from '@mui/material';





function FAQTable(props) {
    const {tableDataList} = props;
    const [tableList, setTableList] = useState([]); // pagination


    useEffect(()=> {
  
      setTableList(tableDataList);
    },[tableDataList, tableDataList.length]);



    const columns = [
      {
        title: 'Drag',
        dataIndex: 'drag',
        key: 'drag',
        render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
      },
      {
        title: "Ref_No",
        dataIndex: "Ref_No",
        // sorter: (a, b) => a.Ref_No.localeCompare(b.Ref_No)
      },
    
      {
        title: "Question",
        dataIndex: "Question",
        sorter: (a, b) => a.Question.localeCompare(b.Question)
      },
      {
        title: "Answer",
        dataIndex: "Answer",
      },

      {
        title: "Sequence",
        dataIndex: "Display_Sequence",
        // sorter: (a, b) => a.Display_Sequence.localeCompare(b.Display_Sequence)
      },
    
      {
        title: "Status",
        dataIndex: "Status_Name",
        sorter: (a, b) => a.Status_Name.localeCompare(b.Status_Name)
      },

      {
        title: 'Toggle',
        dataIndex: 'toggle',
        key: 'toggle',
        render: (toggle, record) => (
          <Switch
            checked={toggle || record?.Status_Id === 1}
            onChange={(checked) => props.changeFaqStatus(checked, record)}
          />
        ),
      },
      {
        title: 'Action',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, record) => (
          <div>
             <FAQEditModal faq={record} />
          </div>
        ),
      },
    
    
    ];



    const paginationOptions = {
      pageSize: 10,
      showSizeChanger: true,
      total: tableDataList?.length,
      // onChange: (page, pageSize) => {
      //   const newList = tableDataList.slice(0, pageSize);
      //   setTableList(newList);
      // },
    };



  //   const handlePaginationChange = (event, pageValue) => {
  //     let end = 8 * pageValue;
  //     let start = end - 8;
  //     setPage(pageValue);
  //     let currentList = tableDataList.slice(start, end);
  //     setTableList(currentList);
  // };




const handleDragEnd = (fromIndex, toIndex) => {
  let newData = [...tableList];
    let toItem = newData[toIndex];
    let fromItem = newData[fromIndex];
    // let fromSeq = fromItem?.Display_Sequence;
    // let toSeq = toItem?.Display_Sequence;
    // fromItem['Display_Sequence'] = toSeq;
    // toItem['Display_Sequence'] = fromSeq;
    newData[fromIndex] =  toItem;
    newData[toIndex] =  fromItem;
  setTableList(newData);
};

const dragProps = {
  onDragEnd: handleDragEnd,
  handleSelector: ".ant-table-row",
};




    return ( 
        <>
   
         <ReactDragListView {...dragProps}>
        
         <Tooltip title="Sync table sequence" className='show-cursor'>
         <SyncOutlined onClick={()=> props.syncTableSequence(tableList)} style={{ fontSize: '20px', color: 'blue'}} />
         </Tooltip>
        <Table  columns={columns} dataSource={tableList}
        pagination={paginationOptions}
         style={{ background: '#f0f2f5', borderRadius: '8px' }} />
      </ReactDragListView>
        </>
     );
}

export default FAQTable;