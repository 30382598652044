import { useEffect, useRef, useState } from "react";
// import {GetPurchaseOrderMasterData} from '../store/purchaseOrderAtom';
import { Grid } from "@mui/material";
import {  GetVoucherListAtom } from "../../store/voucherAtom";
import moment from "moment";
import { useLocation,useParams,  useNavigate } from "react-router-dom";
import { SetGeneralVoucherListAtom, GetGeneralVoucherListAtom} from "../../store/voucherAtom";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";
import { formatCustomDate } from "../../helpers/formatCustomDate";






function VoucherScroll(props) {
    const mountRef = useRef(true)
    const getGeneralVoucherList = GetGeneralVoucherListAtom();
    const { id } = useParams();

    const navigate = useNavigate();
    const { pathname } = useLocation();  
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];



    useEffect(() => {
        mountRef.current = true;

        return () => {
            mountRef.current = false;
        }
    }, [getGeneralVoucherList]);


 

    const onItemSelect = (id)=> {
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${id}`);
    }




    const managePurchaseStatus = ( Voucher_Status_Id, statusName) => {
        let defaultClass = "color1";

        switch (Voucher_Status_Id) {
            case 1:
                defaultClass = "color2";
                break;
            case 2:
                defaultClass = "color3";
                break;
            case 3:
                defaultClass = "color4";
                break;
            case 4:
                defaultClass = "color5";
                break;
            case 5:
                defaultClass = "color6";
                break;
            case 6:
                defaultClass = "color1";
                break;
            case 7:
                defaultClass = "color8";
                break;

            default:
                break;
        }

        return (
            <span className={`text-sm font-bold md:text-sm ${defaultClass} `}>
                {statusName}
            </span>
        );
    };



    return (
        <>

            <section className=" w-full  z-0  main	 border-2 b">
           

                {getGeneralVoucherList?.map((ele, i)=> {
                    return (
                        <>
                          <Grid onClick={()=> onItemSelect(ele?.Ref_No)} key={i} container spacing={2}
                           className={" hover:bg-filterColor border-2 mb-2 show-cursor " 
                           +(Number(id) === ele?.Ref_No ? "app_bg_color1" :  "")}
                           >
                    <Grid item xs={12} className="">
                      <span className="ml-2" >
                      {managePurchaseStatus(ele?.Voucher_Status_Id, ele?.Voucher_Status_Name)}
                      </span>
                      <br/>
                     <span className="ml-2" > {formatCustomDate(ele?.Transaction_Date)}</span>
                    </Grid> 
                    
                    <Grid item xs={6}>
                                <span className="text-md ml-2 text-primary">
                                    {ele?.Voucher_Number}
                                </span>
                    </Grid>
                     <Grid item xs={6}>
                    <div className=" text-md  text-primary" >
                                ₦{ formatCustomNumber(ele?.Voucher_Amount)}
                            </div>
                    </Grid>
                   

                </Grid>
                        
                        </>

                    )
                })}
              



            </section>
        </>
    );
}

export default VoucherScroll;
