import { useState, Fragment } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {GetGroupItemList} from "../store/itemGroupAtom";



function ItemGroupSearch() {
  const itemGroupList = GetGroupItemList();
    const [searchResult, setSearchResult] = useState([]);
    const navigate = useNavigate();
    const [textInput, setTextInput] = useState('');

    const { pathname } = useLocation(); 
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];


    function itemSearchChange (search: string) {

      setTextInput(search);
      const filtered = itemGroupList.filter((record) =>
        Object.values(record).some((text) =>
          String(text).toLowerCase().includes(search.toLowerCase())
        )
      );
      setSearchResult(filtered);
    }


    function selectedCustomerInSearchResult(customer: any){
        const {Item_Group_Id} = customer;
        setSearchResult([]);
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/item-group-details/${Item_Group_Id}`);
    }


    return (  

        <>
         <Combobox  >
        <div className="relative">
          <div className="relative border-[1px] border-gray-200 rounded-md">
            <Combobox.Input
              className="w-full z-40 border-none py-2 pl-10 text-sm leading-5 text-gray-700 max-w-[350px] h-[40px] bg-white rounded-md overflow-hidden ring-0 focus:ring-1 focus:ring-[#1976D2]"
              // displayValue={(person) => person.name}
              placeholder="Search in item group"
              onChange={(event) => itemSearchChange(event.target.value)}
              // onChange={(event) => setTextInput(event.target.value)}
            />
            <Combobox.Button className="absolute top-2 left-2.5 z-50">
              {/* <img
                src={MagnifyingGlass}
                alt="search_icon"
                className="h-6 w-6"
              /> */}
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setTextInput("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {searchResult.length === 0 && textInput !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                searchResult.map((item: any,  i: number) => (
                  <Combobox.Option
                    key={i}
                    onClick={()=> selectedCustomerInSearchResult(item)}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                        active ? "bg-gray-100 text-white" : "text-gray-700"
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span  className={` ${ selected ? "font-medium" : "font-normal" }`} >
                          {item.Item_Group_Name} | {item.Item_Group_Category_Name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-gray-700"
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
        </>
    );
}

export default ItemGroupSearch;