/* eslint-disable jsx-a11y/anchor-is-valid */

import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useForm, useFormState } from "react-hook-form";
import underscore from "underscore";
import { useEffect } from 'react';
import { customerService } from "../../services/customer.service";
import LogicContext from "../../Context/LogicContext";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';



const style = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  height:'80%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}



function VendorContactPersonModal(props) {
  const [open, setOpen] = React.useState(false);
  const { register, setValue, getValues,handleSubmit, control,reset,formState: { errors }} = useForm();
  const [checked, setChecked] = React.useState(false); 
  const [saluteList, setSaluteList] = React.useState({});
  const logicContext = React.useContext(LogicContext);




  useEffect(()=> {
    if( props?.customerData?.Customer_Id){
      setValue("Customer_Id", props?.customerData?.Customer_Id );
      setValue("option", 1 );

    }
    customerService.getMasterData().then((res) => { 
      if(res?.data !== undefined){
        var masterDataGroup = underscore.groupBy(res.data, "Master_Type");
        setSaluteList(masterDataGroup[12])
      }
     });
  },[open]);

  
  const  createSelectItems = ()=> {
    let items = [];         
    saluteList?.forEach((item, i)=> {
      items.push(<option key={i} value={i}>{item.User_Full_Name}</option>)
    });
  }





  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };




  const onSubmit = ()=> {
    const data = getValues();
    customerService.saveContactPerson(data).then( response => {
      const {message_text, message_id} = response?.data[0];
      if(message_id === 1){
       
        logicContext.showToast(message_text,'success');
        handleClose();
        reset({});
      }
    }).catch((err)=> {
      logicContext.showToast("Submission Error!", "error");
    })
  }

  function onErrors(error) {
    console.error(errors);
    logicContext.showToast("One or more value is required!", "error");
  }


  const handleChange = (e) => {
    if(e.target.checked === true){
      setChecked(true);
      // setValue("User_Ref_No", val.User_Id);
    }else{
      setChecked(false);
      props?.onEmailSendSelect(2)
    }
  };

  

 
    return ( 

      <>
       <div>
    <span 
    style={{ color: "#476EF8" }} className="text-xs">
                   
                      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography> Contact Person(s) <AddCircleOutlineIcon className='ml-5' onClick={handleOpen} color="primary" /></Typography>
        </AccordionSummary>
        <AccordionDetails>
          {props?.customerData?.Person?.map((p, i)=> {
            return (
              <Typography key={i} className='mb-2'>
         
              <div> Email<span className='font-weight-bold'> {p?.Person_Email_Address} </span>  </div>
              <div> First Name<span  className='font-weight-bold'> {p?.Person_First_Name} </span>  </div>
              <div> Last Name<span  className='font-weight-bold'> {p?.Person_Last_Name} </span>  </div>
              <div> Mobile<span  className='font-weight-bold'> {p?.Person_Mobile_Phone} </span>  </div>
              <div> Office<span  className='font-weight-bold'> {p?.Person_Work_Phone} </span>  </div>
             </Typography>

            )
          })}
        
        </AccordionDetails>
      </Accordion>
  
           </span>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, width: 600  }}>

      <div className="container app_bg_color2">
        <div className='p-5 text-center'>
            Add Contact Person
        </div>
        <form   onSubmit={handleSubmit(onSubmit, onErrors)}>

  <div className="row">
    <div className="col">
      
        <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="inputGroup-sizing-default">Name</span>
        </div>
        <input type="text" className="form-control"  placeholder='first Name'  {...register("Person_First_Name", {})}/>


      </div>

    </div>
    {/* <div class="col">

    <div class="input-group mb-3">
        <input type="text" class="form-control"  placeholder='First Name'
         {...register("Person_First_Name", {})}/>
      </div>

    </div> */}
    <div className="col">
      
    <div className="input-group mb-3">
        <input type="text" className="form-control"  {...register("Person_Last_Name", {})}
         placeholder='Last Name'/>
      </div>

    </div>
  </div>

  <div className="row">
    <div className="col">
    <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text text-danger" id="inputGroup-sizing-default">Email</span>
        </div>
        <input type="email" className="form-control"  placeholder='email'
        {...register("Person_Email_Address", {
          required: "Email is required",
        })}/>
      </div>
      <small className="text-danger">
                      {errors?.Person_Email_Address &&
                        errors.Person_Email_Address.message}
                    </small>
    </div>
 
  </div>

  <div className="row">
  <div className="col">
  <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text " id="inputGroup-sizing-default">contact</span>
        </div>
        <input type="text" className="form-control"  placeholder='Work Phone'
        {...register("Person_Mobile_Phone", {})}/>
      </div>

  </div>
  <div  className="col">
  <div className="input-group mb-3">
        <input type="text" className="form-control" {...register("Person_Work_Phone", {})}
          placeholder='Mobile'/>
      </div>
  </div>
  </div>
  {/* 4th */}
  <div className='row'>
    <div className='col'>
    <div className="input-group mb-3">
        <input type="text" className="form-control" {...register("Person_Designation", {})}
          placeholder='Designation'/>
      </div>

    </div>
    <div className='col'>
    <div className="input-group mb-3">
        <input type="text" className="form-control"  {...register("Person_Department", {})}
          placeholder='Department'/>
      </div>
    </div>

  </div>

  <div className='row'>
    <div className='col'>
        <input type="checkbox" className="form-control" checked={checked}  onChange={handleChange}
          placeholder=''/>
        <span className='ml-1 text-sm'>Enable portal access</span> <br />
        <small className='text-muted'>This customer will be able to see all their transactions with your organization by 
          logging in to the portal using their email address.</small>
    </div>

  </div>

  <div className='clearfix'>
  <button type='submit' className='float-right mt-1 btn bg-primary text-light ' >
            Add Contact Person
        </button>

        <a   onClick={handleClose} className=' float-right btn mr-3  bg-light mt-1' >
            Cancel
        </a>
        
     
   </div>

   </form>

  </div>

  
      </Box>
     
    </Modal>
  </div>
      </>

     );
}

export default VendorContactPersonModal;