
import { useEffect } from "react";
import { GetChartEntryMasterData, SetChartEntryMasterData} from "../../store/chartOfAccountAtom";
import {accountService}  from '../../services/accountService';
import Select from 'react-select';

const customStyles = {
  control: base => ({
    ...base,
    height: 54,
    backgroundColor:'#F1F1F1',
    width:320,
  }),
  indicatorSeparator: styles => ({ ...styles, display : 'none' }),
  placeholder: styles => ({ ...styles, display : 'none' }),
  valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"40px", marginLeft:"13px"}),
  option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color:"black"}),
}



function ChartOfAccountCurrency(props) {
  const masterData = GetChartEntryMasterData();
  const getMasterData = GetChartEntryMasterData();
  const setMasterData = SetChartEntryMasterData();


  const customOption = masterData?.Currency?.map((item)=> {
    return ({...item, label:`${item.Currency_Name_Short}`})
  })

  useEffect(()=> {
    if(getMasterData['Account_Main'] ===  undefined){
        accountService.getChartEntryMasterData().then(response => {
          if(response?.data !== undefined){
            setMasterData(response.data);
          }
        });
      }

  },[getMasterData, setMasterData])


     return (
      <>
   
      <Select  styles={customStyles}
       defaultValue={props?.defaultVal}
      onChange={props?.onCurrencySelect} options={customOption} />


     </>
    );
}

export default ChartOfAccountCurrency;
