import * as React from "react";

import { useState } from "react";
import InvoiceContent from "../../components/CustomerInventoryItems/InvoiceContent";
import PaymentContent from "../../components/CustomerInventoryItems/PaymentContent";
import BillContent from "./BillContent";
import CreditNoteTab from "./CreditNoteTab";
import "../../pages//ScrollB.css";
import "./CustomerIventroyTransaction.css";
import SalesOrderContent from "./SalesOrderContent";
import RewardPointTab from "./RewardPointTab";
import { Tabs } from "antd";
import VoucherTab from "./VoucherTab";
import CustomerTransactionTab from "./CustomerTransactionTab";

function CustomerTransaction(props) {
  const onChange = (key) => {
    console.log(key);
  };

  const items = [ 
    {
      key: "1",
      label: "Invoice",
      children: (
        <InvoiceContent customer_id={props.customer_id}></InvoiceContent>
      ),
    },
    {
      key: "2",
      label: "Payment",
      children: (
        <PaymentContent customer_id={props.customer_id}></PaymentContent>
      ),
    },
  
    {
      key: "4",
      label: "Sales order",
      children: (
        <SalesOrderContent customer_id={props.customer_id}></SalesOrderContent>
      ),
    },
   
    {
      key: "6",
      label: "Bills",
      children: <BillContent customer_id={props.customer_id}></BillContent>,
    },
    {
      key: "7",
      label: "Credit note",
      children: <CreditNoteTab customer_id={props.customer_id}></CreditNoteTab>,
    },
    {
      key: "8",
      label: "Reward point",
      children: (
        <RewardPointTab customer_id={props.customer_id}></RewardPointTab>
      ),
    },
    {
      key: "9",
      label: "Vouchers",
      children: <VoucherTab customer_id={props.customer_id}></VoucherTab>,
    },
    {
      key: "10",
      label: "Transactions",
      children: (
        <CustomerTransactionTab
          customer_id={props.customer_id}
        ></CustomerTransactionTab>
      ),
    },
  ];

  return (
    <>
      <Tabs
        className="w-full"
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />
    </>
  );
}

export default CustomerTransaction;
