import React, { useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Select from 'react-select';
import { GetPriceDataStore } from "../../store/priceListAtom";
import { priceService } from "../../services/priceService";
import LogicContext from "../../Context/LogicContext";
import LinearProgress from '@mui/material/LinearProgress';





const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ];


  const customStyles = {
    control: base => ({
      ...base,
      height: 42,
      zIndex: 0,
      width: 280,
    }),

    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '', height: 20, paddingBottom: "45px", marginLeft: "13px" }),
    option: styles => ({ ...styles, color: "black", backgroundColor: 'white' }),
    menuPortal: styles => ({ ...styles, zIndex: 0 })
  }





export default function CopyPriceModal(props) {
    const [open, setOpen] = React.useState(false);
    const logicContext = useContext(LogicContext);
    const [optionList, setOptionList ] = React.useState([]);
    const [combineMainData, setCombineMainData ] = React.useState([]);
    const [corTableData, setCorTableData ] = React.useState([]);
    const [corporateId, setCorporateId ] = React.useState(null);
    const [showProgress, setShowProgress ] = React.useState(false);
    const priceStoreData = GetPriceDataStore();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
    



    useEffect(() => {

      }, []);

    const handleClickOpen = () => {
        setOpen(true);
        let newList = priceStoreData.coporate_info.map((item) => {
          return {...item, label: item?.Corporate_Name}
      });
      setOptionList(newList);


      setCombineMainData(props.tableDataList);
    };


    const handleClose = () => {
      setCombineMainData([]);
      setCorporateId(null);
     
        setOpen(false);
    };




    const onCorporateSelect = (data) => {
      const id =  data?.Corporate_Id;
      setCorporateId(id);
        setCorTableData([]);
        setShowProgress( (prev)=>  {return !prev} );
        priceService.getPriceDetails(id, props.productCatId).then(res => {
          setShowProgress( (prev)=>  {return !prev} );
            if (res?.data?.length) {
              setCorTableData(res.data);
              let corListToUpdate = res.data.map((item, index) => {
                const mainItem =  combineMainData[index];
                return {...mainItem, Item_Rate_Market2: item.Item_Rate_Market, 
                  Item_Rate_Zoho2: item.Item_Rate_Zoho}
            });
            setCombineMainData(corListToUpdate);
            } else {
              
               
              setCorTableData([]);
            }
  
        }).catch((err)=> {
          setShowProgress( (prev)=>  {return !prev} );
        });
    }



  

    const completeCopyPriceHandler = async () => {
     
          let temp =``;

          if(corporateId){
              // Update all for search List.
              let example =  corTableData?.map((product, index) => {
                const newPriceData =  combineMainData[index];
                  temp  +=`<a><sku>${product.Item_Id}</sku><market_rate>${newPriceData?.Item_Rate_Market || 0}</market_rate><grocedy_rate>${newPriceData?.Item_Rate_Zoho || 0}</grocedy_rate></a>`;
                  return temp;
              });
             
              priceService.updateSingleItem({corporate_id: corporateId ,product_id: props.productCatId, xml_string_item: `<root>${temp}</root>` }).then(res => {
                  if(res.data?.length){
                      if(res.data[0]?.message_id === 1){
                         logicContext.showToast(res.data[0]?.message_text,'success');
                         return  handleClose()
                      }
                  }
              }).catch((err)=> {
                logicContext.showToast("error submitting record", "error",);
              });

          }else{
            logicContext.showToast("Please select the Corporate you want to update", "error");
              // update all for general list
              // let example =  tableData?.map((product) => {
              //     temp  +=`<a><sku>${product.Item_Id}</sku><market_rate>${product?.Item_Rate_Market}</market_rate><grocedy_rate>${product?.Item_Rate_Zoho}</grocedy_rate></a>`;
              //     return temp;
              // });
  
          }

  }




    return (
        <React.Fragment>
             <Tooltip title="copy Current price to another corporate" className='show-cursor' >
            <Button className='ml-4' onClick={handleClickOpen} style={{
                width: '11em',
                height: '3em' // call it humonguous
            }} component="" variant="contained"
                startIcon={<ContentCopyIcon className="text-white" />}>
                Copy Price
            </Button>
         
         
            </Tooltip>
            <Dialog
                fullScreen={fullScreen}
                open={open}
               maxWidth={'md'}
                
                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title">

<TableContainer component={Paper}>


<Grid container className='p-2' spacing={2}>

  <Grid item xs={3}>
<div className='font-weight-bold pt-2 ml-5' >Copy Price to</div>
  </Grid >


  {/* % end */}
  <Grid item xs={6}>
          <Select 
        onChange={(e) => onCorporateSelect(e)} placeholder={"Select corporate"}
         options={optionList} styles={customStyles} />
  </Grid>

  <Grid item xs={3}>
    <button  onClick={()=>  handleClose()} className='ml-3 btn  btn-sm  btn-dark nt-2' >X</button>
  </Grid>
</Grid>

{ showProgress  &&  <Box sx={{ width: '100%' }}> <LinearProgress className='mb-1' /> </Box>  }



<Table sx={{ minWidth: 700 }} aria-label="customized table">
  <TableHead>
    <TableRow>
      <StyledTableCell> Name</StyledTableCell>
      <StyledTableCell align="right">Market Price Current (₦)</StyledTableCell>
      <StyledTableCell align="right">Market Price New (₦)</StyledTableCell>
      <StyledTableCell align="right">Grocedy Price Current(₦)</StyledTableCell>
      <StyledTableCell align="right">Grocedy Price New(₦)</StyledTableCell>
   
    </TableRow>
  </TableHead>
  <TableBody>
    {combineMainData?.length && combineMainData.map((product, i) => (
      <StyledTableRow key={i}>
        <StyledTableCell component="th" scope="row">
          <span className='font-weight-bold' >{product.Item_Name}</span>
        </StyledTableCell>
        <StyledTableCell align="right">
            <del>{product?.Item_Rate_Market2}</del> 
        </StyledTableCell>
        <StyledTableCell align="right">
        <span className='font-weight-bold' >{product?.Item_Rate_Market}</span>
        </StyledTableCell>
        <StyledTableCell align="right">
        <del>{product?.Item_Rate_Zoho2}</del>
        </StyledTableCell>
        <StyledTableCell align="right">
        <span className='font-weight-bold' >{product?.Item_Rate_Zoho}</span>
        </StyledTableCell>
     </StyledTableRow>
    ))}
  </TableBody>

</Table>
{/* <div class="d-flex align-items-end flex-column mt-3 mb-3 " >
  <button onClick={() => props.sendAllProductItems(tableDataList)} className='btn btn-primary btn-sm mr-4' >SAVE ALL</button>
</div> */}
</TableContainer>

<div className='text-center p-4' >
  <button onClick={()=> completeCopyPriceHandler()} className='btn btn-lg btn-primary' >Finalize Price Copy</button>

</div>

            </Dialog>
        </React.Fragment>
    );
}