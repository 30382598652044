import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Table } from "react-bootstrap";
import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CurrencyFormat from "react-currency-format";
import { NumericFormat } from 'react-number-format';
import SubscriptionAddonSelect from "./SubscriptionAddonNameSelect";
import { GetSubEntryMasterdata } from "../../store/subscriptionAtom";
import Select from 'react-select';
import DeleteIcon from '@mui/icons-material/Delete';





function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};





function AddSubscriptionTable(props) {
    const getEntryMasterdata = GetSubEntryMasterdata();

    const customOption = getEntryMasterdata?.Addon?.map((item) => {
        return ({ ...item, label: `${item.Item_Name} ${item?.Item_Id}` });
    });

    const customTaxOption = getEntryMasterdata?.Tax?.map((item)=> {
        return ({...item, label:`${item.Tax_Name}`})
      })

    const customStyles = {
        control: base => ({
            ...base,
            height: 44,
            backgroundColor: '#F1F1F1',
            width: 300,
        }),
        indicatorSeparator: styles => ({ ...styles, display: 'none' }),
        // placeholder: styles => ({ ...styles, display : 'none' }),
        valueContainer: styles => ({ ...styles, backgroundColor: '', height: 20, paddingBottom: "45px", marginLeft: "13px" }),
        option: styles => ({ ...styles, backgroundColor: '#F1F1F1', color: "black" }),



    }

    const handleChange = (value) => {
        props.onSelection(value)
    };


    return (
        <>
          

                <TableContainer className="mt-3 w-full bg-light">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <colgroup>
                            <col style={{ width: '30%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '15%' }} />
                        </colgroup>
                        <TableHead style={{ backgroundColor: "#F1F1F1" }}>
                            <TableRow>
                                <TableCell className="subheading">Name</TableCell>
                                <TableCell className="subheading">Minimum Payment</TableCell>
                                <TableCell className="subheading"> Qty </TableCell>
                                <TableCell className="subheading"> Unit Price </TableCell>
                                <TableCell className="subheading"> tax </TableCell>
                                <TableCell className="subheading"> Amount </TableCell>
                                <TableCell className="subheading">  </TableCell>

                            </TableRow>
                        </TableHead>

                        <TableBody className=" mb-3">
                            {props?.addonList?.map((addon, i) => {
                                return (
                                    <TableRow className="border-1"
                                        key={i}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                        <TableCell className="subheading" align="left">
                                            {addon.label}
                                            {/* <SubscriptionAddonSelect key={i}  planNameList={props?.addonNameList}
                                defaultVal={addon}
                                onSelection={(val)=> props?.onAddonNameSelect(val, i)}/> */}

                                            <Select styles={customStyles} onChange={(val) => props?.onAddonNameSelect(val, i)}
                                                defaultValue={{label: addon.label}}
                                                key={addon.label}
                                                options={customOption}
                                                placeholder="Choose" />
                                        </TableCell>

                                        <TableCell className="subheading" align="left">

                                            <CurrencyFormat value={(addon.Addon_Minimim_Payment)?.toFixed(2)}
                                                displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                                        </TableCell>
                                        <TableCell className="subheading" align="left">
                                            <NumericFormat
                                                value={addon?.Quantity}
                                                style={{ width: "90px" }}
                                                thousandSeparator=","
                                                decimalSeparator="."
                                                allowedDecimalSeparators={['%']}
                                                onValueChange={(values, sourceInfo) => {
                                                    props?.onAddonQuantityChange(parseFloat(Number(values.value)), i)
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell className="subheading" align="left">
                                            <CurrencyFormat value={(addon.Item_Sales_Rate)?.toFixed(2)}
                                                displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                                        </TableCell>
                                        <TableCell className="subheading" align="left">

                                        <Select   styles={customStyles} onChange={(val) => props?.onAddonTaxSelect(val, i)}
                                        defaultValue={props?.defaultVal}
                                        options={customTaxOption}  />

                                        </TableCell>
                                        <TableCell className="subheading" align="left">
                                            <CurrencyFormat value={(addon.Item_Sales_Rate * addon.Quantity)?.toFixed(2)}
                                                displayType={'text'} thousandSeparator={true} prefix={'₦'} />
                                        </TableCell>
                                        <TableCell className="subheading" align="left">
                                        <DeleteIcon onClick={()=> props?.removeOldAddon(i)}  />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
          

        </>
    );
}

export default AddSubscriptionTable;