import { useEffect, useState } from 'react';
import { GetRecoilBillListData} from '../../store/billsAtom';


function BillDiscountComponent(props) {
  const masterData = GetRecoilBillListData();
  const [customOption, setCustomOption] = useState([]);


    useEffect(()=> {
      let topElement = {};
      const tempData =  masterData['Discount']?.map((element, index)=> {
        if(props?.defaultVal === element?.Discount_Type_Id){
          topElement = {...element, label:`${element?.Discount_Type_Name}`};
        }
     
         return ({...element, label:`${element?.Discount_Type_Name}`})
       }
       );
       if(topElement){
        // tempData.unshift(topElement)
      }
       setCustomOption(tempData)
   
     },[masterData, props]);


    const  createSelectItems = ()=> {
      let items = [];         
      customOption?.forEach((discount, i)=> {
        items.push(<option key={i} value={discount.Discount_Type_Id}>{discount.Discount_Type_Name}</option>)
      });

        return items;
  } 





    return ( 
        <>

<select  onChange={(e)=> props.onDiscountTypeSelect(e.target.value)}>{createSelectItems()} </select>

        </>
     );
}

export default BillDiscountComponent;