import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DateAdapter from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import "../../components/Notificatiom.css";
import Button from "@mui/material/Button";
import { customerService } from "../../services/customer.service";
import { DatePicker } from "@mui/lab";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useRecoilValue } from "recoil";
import underscore from "underscore";
import { transactionmasterDataAtom } from "../../store/transAtom";
import { makeStyles } from "@material-ui/core";
import { twMerge } from "tailwind-merge";
import { IoSearchOutline } from "react-icons/io5";
import { formatCustomDate } from "../../helpers/formatCustomDate";

function CustomerNotification() {
  const [value, setValue] = React.useState(null);
  const { customer_id } = useParams();
  const [notificationList, setNotificationList] = useState([]);
  const [notificationListTemp, setNotificationListTemp] = useState([]);
  const masterData = useRecoilValue(transactionmasterDataAtom);
  var masterDataGroup = underscore.groupBy(masterData, "Master_Type");
  const notificationType = masterDataGroup[7];
  const notificationCategory = masterDataGroup[8];

  function handleCategoryChange(event) {
    const selectedItem = event.target.value;
    if (selectedItem === "All") {
      setNotificationListTemp(notificationList);
    }

    var filteredItems = notificationList.filter(
      (item) => item.Notification_Type_Name === selectedItem
    );
    setNotificationListTemp(filteredItems);
  }

  useEffect(() => {
    customerService.getNotifications(customer_id).then((res) => {
      if (res?.data?.notification?.length) {
        setNotificationList(res?.data?.notification);
        setNotificationListTemp(res?.data?.notification);
      }
    });
  }, [customer_id]);

  return (
    <section className="w-full @container">
      <section className="@xl:flex @xl:flex-row @xl:justify-between  mt-2 w-full">
        <div className="flex flex-row items-center space-x-4">
          <section className="">
            <div>
              <label className="pt-2" for="start">
                From:
              </label>{" "}
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField sx={{ width: 140 }} size="small" {...params} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </section>

          <section className="">
            <div>
              <label className="pt-2" for="start">
                To:
              </label>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="datepicker"
                      sx={{ width: 140 }}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </section>
        </div>
        <div className="mx-[2px] flex flex-row items-center space-x-1">
          <div className="notification">
            <div>
              <label className="pt-2 " for="start">
                Type:
              </label>
            </div>
            <div>
              <select
                onChange={(ev) => handleCategoryChange(ev)}
                className="rounded-md w-[100px] xl:w-[130px]"
              >
                <optgroup className="bg-white rounded-md">
                  {notificationType?.reverse().map((type, i) => {
                    return <option value={type.Name}>{type.Name}</option>;
                  })}
                </optgroup>
              </select>
            </div>
          </div>

          <div className="notification">
            <div>
              <label className="pt-2" for="start">
                Category:
              </label>
            </div>

            <select
              onChange={(ev) => handleCategoryChange(ev)}
              className="rounded-md w-[100px] @xl:w-[130px]"
            >
              <optgroup className="bg-white rounded-md">
                {notificationCategory?.reverse().map((type, i) => {
                  return (
                    <option key={i} value={type.Name}>
                      {type.Name}{" "}
                    </option>
                  );
                })}
              </optgroup>
            </select>
          </div>
        
        </div>
      </section>
      {/* SEARCH BAR */}

      <div className="mt-4 relative w-full max-w-[250px]">
        <input
          placeholder="Search Retainer"
          className={twMerge(
            "w-full pl-11 h-[40px] bg-white text-gray-700 placeholder:text-gray-400 placeholder:text-sm outline-none focus:outline-none border-[1px] border-gray-200 rounded-md ring-0 focus:ring-1 focus:ring-blue-500"
          )}
        />
        <div className="absolute top-2.5 left-1.5 pl-2">
          <IoSearchOutline className="h-[20px] w-[20px] text-gray-600" />
        </div>
      </div>
      <div className="mt-4">
        {notificationListTemp?.map((message, i) => {
          return (
            <section
              key={i}
              className="mt-3 bg-white px-6 sm:px-8 py-6 rounded-xl"
            >
              <div className="flex flex-row items-center justify-end">
                <div className="w-fit px-6 py-1 rounded-full font-bold text-[#476EF8] bg-[#476EF8]/30">
                  {message?.Notification_Type_Name}
                </div>
              </div>
              <div className="mt-2">
                <div>
                  <span className="text-sm font-normal text-gray-500">
                    {message?.Notification_Address} -{" "}
                  </span>
                  <span className="text-sm font-normal text-gray-500">
                    {formatCustomDate(message?.Notification_Date)}
                  </span>{" "}
                </div>
                <div>
                  <span className="text-sm  font-semibold text-gray-600">
                    {message?.Notification_Subject} <br></br>
                    {message?.Notification_Message}
                  </span>
                </div>
              </div>
              <div className="mt-2 flex flex-row items-center justify-end">
                <div className="">
                  <span className="font-semibold text-sm text-[#70CC40]">
                    Status:
                  </span>{" "}
                  <span className="font-semibold text-sm text-[#70CC40]">
                    Success
                  </span>
                </div>
              </div>
            </section>
          );
        })}
      </div>
    </section>
  );
}

export default CustomerNotification;
