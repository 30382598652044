import { useEffect } from "react";
import { Link } from "react-router-dom";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";
import PriceListMainTable from "./PriceListMainTable";
import { priceService } from "../../services/priceService";
import { SetPriceDataSore, GetPriceDataStore } from "../../store/priceListAtom";




function PriceListPage() {
  const getPriceDataStore = GetPriceDataStore();
  const setPriceDataStore = SetPriceDataSore();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split('/')
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];


  useEffect(()=> {

  },[]);


  const newFeatureSetup = () => {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
  }


  const fetchDataFromServer = () => {
    priceService.getPriceList().then(res => {
      if (res.data?.coporate_info?.length) {
        setPriceDataStore(res.data);
      }
    });
  }




  return (
    <>
      <div className=" b">
        <section className="">




          <div className="flex justify-between  w-full  "  >
            <Tooltip title="Reload">
              <RestartAlt
                className="cursor ml-2 mt-1"
                onClick={() => fetchDataFromServer()}
                color="primary"
                fontSize="large"
              />
            </Tooltip>
         
          </div>



    
          <PriceListMainTable list={getPriceDataStore?.coporate_info} />
        </section>
      </div>

    </>

  );
}

export default PriceListPage;
