 import React, { useContext } from "react";
import paper from "../../assets/paper.png";
import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
// import "./CustomerDetails.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link} from "react-router-dom";
import { SetVendorEdit } from "../../store/vendorAtom";
// import "./CustomerDetails.css";
import { purchaseService } from "../../services/purchase.service";
import GeneralModal from "../../helpers/GeneralModal";
import VendorDetailsScroll from "./VendorDetailsScroll";
import VendorOverview from "./VendorOverview";
import VendorsComment from "./VendorsComment";
import VendorTransaction from "./VendorTransaction";
import VendorNotification from "./VendorNotification";
import VendorStatement from "./VendorStatement";
import { createModal } from "react-modal-promise";
import VendorToggle from "./VendorToggle";
import LogicContext from "../../Context/LogicContext";




const TAB_SECTION = {
  OVERVIEW: "OVERVIEW",
  COMMENT: "COMMENT",
  TRANSACTIONS: "TRANSACTIONS",
  NOTIFICATIONS: "NOTIFICATIONS",
  STATEMENTS: "STATEMENTS",
};

const VendorDetailsPage = () => {
  const [selectedTab, setSelectedTab] = useState(TAB_SECTION.OVERVIEW);
  const [vendorDetails, setVendorDetails] = useState({});
  const [selectedList, setSelectedList] = useState([]);
  const myPromiseModal = createModal(GeneralModal);
  const setVendorEdit = SetVendorEdit();
  const logicContext = useContext(LogicContext); 



  const { vendor_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Vendor details'
    let isMounted = true;
      purchaseService.getVendorDetails(vendor_id).then(res => {
        if(isMounted && res.data !== undefined){
          setVendorDetails(res.data['Vendor_Info']);
        }
      })
    return ()=> {
      isMounted = false;
    }
  }, [vendor_id]);





const EditVendorDetails = async () => {
  const response = await myPromiseModal({
    title: "Modify Vendor record",body: " Do you want to modify this record?", showInput:false, placeHolder:''});
  if (response.decision === true) {
    setVendorEdit(vendorDetails)
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
  }
};

  const selectTab = (section) => {
    setSelectedTab(section);
  };

  
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];




  function onSelection(list) {
    let tempList = list?.filter((item) => item.checked === true);
    setSelectedList(tempList);
  }

  function viewVendorDetails(vendor_id) {
    navigate( `/${currModuleId}/${currModuleName}/${currUserMenu}/details/${vendor_id}`);
  }

  function newPaymentMade() {
    navigate(`/${currModuleId}/${currModuleName}/${'payment-mades'}/add-new/?vendor_id=${vendorDetails?.Vendor_Id}&vendor_name=${vendorDetails?.Vendor_Full_Name}`);
  }

  function newBill() {
    navigate(`/${currModuleId}/${currModuleName}/${'bills'}/add-new/?vendor_id=${vendorDetails?.Vendor_Id}&vendor_name=${vendorDetails?.Vendor_Full_Name}`);
  }

  function newPurchaseOrder() {
    navigate(`/${currModuleId}/${currModuleName}/${'purchase-order'}/add-new/?vendor_id=${vendorDetails?.Vendor_Id}&vendor_name=${vendorDetails?.Vendor_Full_Name}`);
  }


  function newVendorCredit() {
    navigate(`/${currModuleId}/${currModuleName}/${'vendor-credits'}/add-new/?vendor_id=${vendorDetails?.Vendor_Id}&vendor_name=${vendorDetails?.Vendor_Full_Name}`);
  }

  const toogleItemStatus = (val) => {
    if (val === true) {


      // productService.saveNewProduct(selectedProduct).then((res) => { 
      //     logicContext.showToast(res.data[0]["message_text"], "success");
      //   })
      purchaseService.changeVendorStatus({sql_ref_no:68,option:7, vendor_id: vendorDetails?.Vendor_Id}).then(res => {
        logicContext.showToast(res.data[0]["message_text"], "success");
        setVendorDetails({...vendorDetails, Vendor_Status_Name:'Active', Vendor_Status_Id:'A'});
      });
    }

    if (val === false) {
      purchaseService.changeVendorStatus({sql_ref_no:8,option:8, vendor_id: vendorDetails?.Vendor_Id}).then(res => {
        logicContext.showToast(res.data[0]["message_text"], "success");
        setVendorDetails({...vendorDetails, Vendor_Status_Name:'InActive', Vendor_Status_Id:'I'});
      });
    }
  };


  return (
  <>
     <section className=" mt-2 ">
      <section className="  ">
        <div className="">
       
          <div className="flex  w-full justify-end">
            

        <div className="" >

        </div>
            <p className="font-bold" >{vendorDetails?.Vendor_Status_Name}</p>
          <VendorToggle toogleItemStatus={toogleItemStatus}
                   val={vendorDetails?.Vendor_Status_Id} />
            
            <Dropdown style={{ width: "150px", marginRight:"30px", marginTop: "2px" }}>
              <Dropdown.Toggle
                className="pr-10"
                id="dropdown-basic"
                style={{
                  backgroundColor: "#476EF8",
                  fontSize: "10px",
                  height: "43px",
                
                
                }}
              >
                 NEW TRANSACTION
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={()=> newBill()} className="border" style={{ fontSize: "15px" }}>
                  Create Bill
                </Dropdown.Item>

                <Dropdown.Item onClick={()=> newPaymentMade()} className="border" style={{ fontSize: "15px" }}>
                  Payment made
                </Dropdown.Item>

                <Dropdown.Item onClick={()=> newPurchaseOrder()} className="border" style={{ fontSize: "15px" }}>
                  Purchase Order
                </Dropdown.Item>

                <Dropdown.Item onClick={()=> newVendorCredit()} className="border" style={{ fontSize: "15px" }}>
                  Vendor Credits
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
       
          

            <div className="flex ">
              <button
                style={{ borderRadius: "5px" }}
                className=" px-3  h-[6vh] text-filterTextColor bg-filterColor  mr-3" >
                <img src={paper} alt="" className="" />
              </button>
              <button
                style={{ borderRadius: "5px" }}
                onClick={EditVendorDetails}
                className=" px-3  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-3"
              >
                <img src={pencil} alt="" />
              </button>
              <button
                style={{ borderRadius: "5px" }}
                className="  px-3 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 mr-2"
              >
                <img src={trash} alt="" />
              </button>
              {/* <Link
                to=
                {`/${currModuleId}/${currModuleName}/${currUserMenu}/add_customer`}>
              
                <button
                  style={{ backgroundColor: "#476EF8" }}
                  className=" h-[6vh] ml-1  rounded text-white text-xs py-1 mr-2 px-4"
                >
                  ADD
                </button>
              </Link> */}
            </div>
          </div>
        </div>
      </section>

      <section className="flex relative justify-between">
        <VendorDetailsScroll onSelection={onSelection}
          viewVendorDetails={viewVendorDetails} 
        ></VendorDetailsScroll>

        <section className="w-full z-50 ">
          {/* overview and comment */}
          <section className=" w-95 ml-[2vw]   border-b-2 text-filterTextColor sm: flex justify-between md:mt-5">
            <span
              onClick={() => selectTab(TAB_SECTION.OVERVIEW)}
              className={
                "text-md text-filterTextColor E5E5E5 " +
                (selectedTab === TAB_SECTION.OVERVIEW
                  ? "font-weight-bold text-primary "
                  : null)
              }
            >
              Overview
            </span>
            <span
              onClick={() => selectTab(TAB_SECTION.COMMENT)}
              className={
                "text-md text-filterTextColor  " +
                (selectedTab === TAB_SECTION.COMMENT
                  ? "font-weight-bold text-primary "
                  : null)
              }
            >
              Comments
            </span>
            <span
              onClick={() => selectTab(TAB_SECTION.TRANSACTIONS)}
              className={
                "text-md text-filterTextColor  " +
                (selectedTab === TAB_SECTION.TRANSACTIONS
                  ? "font-weight-bold text-primary "
                  : null)
              }
            >
              Transactions
            </span>
            <span
              onClick={() => selectTab(TAB_SECTION.NOTIFICATIONS)}
              className={
                "text-md text-filterTextColor  " +
                (selectedTab === TAB_SECTION.NOTIFICATIONS
                  ? "font-weight-bold text-primary "
                  : null)
              }
            >
              Notifications
            </span>
            <span
              onClick={() => selectTab(TAB_SECTION.STATEMENTS)}
              className={
                "text-md text-filterTextColor  " +
                (selectedTab === TAB_SECTION.STATEMENTS
                  ? "font-weight-bold text-primary "
                  : null)
              }
            >
              Statement
            </span>
            
          
          </section>
          {/* <section className=" w-95 ml-[2vw]   border-b-2   text-filterTextColor sm: flex justify-between md:mt-5">
              <span className="text-xs sm:text-lg text-filterTextColor active:bg-red-600  hover:bg-filterColor px-4 ">Overview</span>
              <span className="text-xs  sm:text-lg text-filterTextColor hover:bg-filterColor px-4">Comments</span>
              <span className="text-xs  sm:text-lg text-filterTextColor hover:bg-filterColor px-4">Transactions</span>

            </section> */}

          <section className=" ml-[2vw] mt-2 max-auto max-w-6xl text-filterTextColor">
            {/* 
    DETAILS SECTION */}
            <section className=" ">
              {/* CUSTOMER DETAILS INFORMATION */}
              {selectedTab === TAB_SECTION.OVERVIEW ? (
                <VendorOverview EditVendorDetails={EditVendorDetails}
                  vendorDetails={vendorDetails}
                />
              ) : null}
              {selectedTab === TAB_SECTION.COMMENT ? (
                <VendorsComment />
              ) : null}
              {selectedTab === TAB_SECTION.TRANSACTIONS ? (
                <VendorTransaction customer_id={vendor_id} />
              ) : null}
              {selectedTab === TAB_SECTION.NOTIFICATIONS ? (
                <VendorNotification customer_id={vendor_id} />
              ) : null}
              {selectedTab === TAB_SECTION.STATEMENTS ? (
                <VendorStatement customer_id={vendor_id} />
              ) : null}
            </section>
          </section>
        </section>
      </section>
    </section>
  </>

  );
};

export default VendorDetailsPage;
