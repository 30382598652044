import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useForm } from "react-hook-form";
import { useContext, useEffect } from 'react';
import LogicContext from "../../Context/LogicContext";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { GetRewardCenterList, SetRewardCenterList } from '../../store/rewardCenterAtom';
import { rewardService } from '../../services/Reward.service';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  



export default function EarnContentEditModal(props) {
    const {item}= props;
    const getRewardList = GetRewardCenterList();
    const setRewardList = SetRewardCenterList();
  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {register, setValue, getValues, handleSubmit,control,reset,formState: { errors }} = useForm();
  const myPromiseModal = createModal(GeneralModal);
  const logicContext = useContext(LogicContext);



  useEffect(()=> {

        if(item?.Ref_No){
            setValue('title', item.Earn_Title);
            setValue('description', item.Earn_Description);
            setValue('display_sequence', item.Display_Sequence);
            // setValue('activation_date', item.Offer_Activation_Date);
            setValue('activation_date', moment(item.Offer_Activation_Date).format("YYYY-MM-DD"));
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[item]);





  const updateEarnItem = async () => {
    const formData =  getValues();
    const newUpdate = {ref_no: item.Ref_No,title: formData.title ,description: formData?.description,
        activation_date: formData?.activation_date, status_id: item?.Earn_Status_Id, 
        Display_Sequence: formData.display_sequence};

    await myPromiseModal({title: `Confirmation!`, body: `The following record will be updated! `
    ,showInput: false, placeHolder: ''}).then((res) => {
        if(res.decision === true){
          
              rewardService.updateEarnContent(newUpdate).then((res)=> {
                const filteredData = logicContext.httpResponseManager(res?.data[0]);
                if(filteredData?.message_id == 1 || filteredData?.message_id == 1){
                  logicContext.showToast(filteredData?.message_text || filteredData?.message_text, 'success');
                  handleClose();
                  reloadMainData();
                }
              });

        }
});
}


  function reloadMainData(){
    rewardService.getEarnContent().then((response)=> {
      if(response.data.earn_info?.length){
        setRewardList(response.data);
      }
    });
  }


  

  return (
    <div>
      <EditIcon  onClick={handleOpen} fontSize="large" className='show-cursor' color="primary" />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title " variant="h6" component="h2">
            <div className='text-center font-weight-bold' >Update Earn Record</div>
          </Typography>
             <div onClick={handleClose} class="d-flex flex-row-reverse bd-highlight mb-4">
                  <button className='btn btn-danger text-light' >X</button>
             </div>


          <form onSubmit={handleSubmit(updateEarnItem)} >
        <Grid container spacing={1}
        justifyContent="center"
        alignItems="flex-start"
        >
                <Grid item textAlign="left" xs={3}>
                    
                    <div className=" mt-3">
                        Title 
                    </div>
                </Grid>
                <Grid textAlign="left" item xs={9}>
                    
                    <div className="">
                 
                  <div className="">
                  {/* w-full */}
                    <input
                      className="bg-filterInput h-[8vh] w-full   pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="text"
                      placeholder='Enter title.'
                      {...register("title", {
                        required: "title is required",
                      })}
                    />  <br/>
                      <small className="text-red-500">
                            {Boolean(errors["title"]?.message) &&
                                String(errors["title"]?.message)}
                        </small>
                  </div>
                </div>
                  
                </Grid>
                
                <Grid textAlign="left" item xs={3}>
                   
                    <div className=" mt-3">
                         Description
                    </div>
                    
                </Grid>
                <Grid textAlign="left" item xs={9}>
                  
                  <div className="">
                  {/* w-full */}
                  <textarea rows={8}
                    className="bg-filterInput w-full   pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    type="text"
                    placeholder='Enter description.'
                    {...register("description", {
                      required: "description is required",
                    })}
                    />
                     <br/>
                     <small className="text-red-500">
                            {Boolean(errors["description"]?.message) &&
                                String(errors["description"]?.message)}
                        </small>
                  </div>
                   
                </Grid>

                <Grid item textAlign="left" xs={3}>
                   
                    <div className=" mt-3">
                       Display Sequence 
                    </div>
                   
                </Grid>
                <Grid item textAlign="left" xs={9}>
                   
                    <div className="">
                 
                  <div className="">
                  {/* w-full */}
                    <input
                      className="bg-filterInput w-full   pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="number"
                      placeholder='Enter Sequence.'
                      {...register("display_sequence", {
                        required: "Sequence is required",
                      })}
                    />  <br/>
                      <small className="text-red-500">
                            {Boolean(errors["display_sequence"]?.message) &&
                                String(errors["display_sequence"]?.message)}
                        </small>
                  </div>
                </div>
                 
                </Grid>

                <Grid textAlign="left" item xs={3}>
                    
                    <div className=" mt-3">
                         Date
                    </div>
                </Grid>
                <Grid  textAlign="left" item xs={6}>
                   
                    <div className="">
                 
                  <div className="">
                  {/* w-full */}
                  <input
                    className="bg-filterInput h-[8vh]  pl-3  rounded max-h-full bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                    type="date"
                    placeholder='Select date.'
                    {...register("activation_date", {
                      required: "activation data is required",
                    })}
                    />
                    <br/>
                       <small className="text-red-500">
                            {Boolean(errors["activation_date"]?.message) &&
                                String(errors["activation_date"]?.message)}
                        </small>
                  </div>
                </div>
                   
                </Grid>

                <Grid justifyContent="start" item  xs={3} >
                <div className='text-center mt-4  mr-10'>
                    <button className='btn  btn-primary' >UPDATE </button>
                </div>
                </Grid>
        </Grid>
        
        </form>

         
        </Box>
      </Modal>
    </div>
  );
}