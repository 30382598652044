/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext } from "react";
import "./ProductItem.css";


import "./productionEntryTab.css";
import { useState } from "react";
import { useEffect } from "react";
import LogicContext from "../../Context/LogicContext";

import { itemsSetupService } from "../../services/itemSetup.service";

import ProductionItemTable2 from "../ProductionItemTable2";
import WarehouseItemSelect from "../WarehouseItemSelect";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { GetWareHouse} from "../../store/finishItemAtom";

import {useParams } from "react-router-dom";
import  moment from 'moment';
import '../../pages/scrollBar.css';
import  {GetProductionItems} from '../../store/finishItemAtom';
import { GetProductionEntryEdit} from '../../store/productionAtom';
import GeneralModal from "../../helpers/GeneralModal";
import { createModal , } from 'react-modal-promise';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}




function ProductionEntryTab(props) {
    // const setWareHouse = SetWareHouse();
    const wareHouseList = GetWareHouse();
   
    const { register,setValue,  getValues, handleSubmit,getFieldState, reset, formState: { errors },} = useForm();
    const [selectedFinishItem, setSelectedFinishItemm] = useState({});
    const myPromiseModal = createModal(GeneralModal);
    const [selectedTableData, setSelectedTableData] = useState([]);
    const logicContext = useContext(LogicContext);
    
    const [editMode, setEditMode] = useState(true);
    const { batch_id } = useParams();
  
    var itemList = GetProductionItems();
    const newEditable = GetProductionEntryEdit();

  
    useEffect(()=> {
      if(newEditable?.Ref_No){
        setValue("batch_no", newEditable?.Ref_No);
        setValue("Production_Quantity", newEditable?.Production_Quantity);
      }
    },[newEditable]);



    const getItemCreateNewCallback = useCallback((batch_no)=> {
      itemsSetupService.modifyProduction(batch_no).then((res) => {
        if (res.data !== undefined) {
          setSelectedTableData(res.data);
          // setValue("remark", res.data[0]["Production_Remark"]);
          // setValue("Warehouse_Id", res.data[0]["Warehouse_Id"]);
          // setValue("Production_Quantity", res.data[0]["Production_Quantity"]);
          setEditMode(true);
        }
      });

    },[])



     
  useEffect(()=> {
    if (batch_id !== undefined){
      let routeProps = batch_id?.split('_');
      if(routeProps[1] == 0){
        let newBatchId = routeProps[0];
        if(newBatchId !== undefined){
          getItemCreateNewCallback(newBatchId);
          setValue("batch_no", newBatchId);
        }
      }
    }
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[batch_id]);






  const batchNumberCallback = useCallback((batch_no)=> {
    itemsSetupService.modifyProduction(batch_no).then((res) => {
      if (res.data !== undefined) {
        setSelectedTableData(res.data);
        setValue("remark", res.data[0]["Production_Remark"]);
        setValue("Warehouse_Id", res.data[0]["Warehouse_Id"]);
        setValue("Production_Quantity", res.data[0]["Production_Quantity"]);
        setEditMode(true);
      }
    });
  },[]);

     
  useEffect(()=> {
    if (batch_id !== undefined){
      let routeProps = batch_id?.split('_');
      if(routeProps[1] == 1){
        let newBatchId = routeProps[0];
        if(newBatchId !== undefined){
          batchNumberCallback(newBatchId);
          setValue("batch_no", newBatchId);
        }
      }
    }
     
    },[batch_id]);
  
    
  
    useEffect(()=> {
     
      if(batch_id !== undefined){
        let routeProps = batch_id?.split('_');
        if(routeProps[1] == 2){
          let newItemId = routeProps[0];
          if(newItemId !== undefined){
            const selectedRouteItem =  itemList.filter((item)=>  item.Item_Id == newItemId);
            setSelectedFinishItemm(selectedRouteItem[0]);

            itemsSetupService.getProductionItemprops(newItemId).then((res) => {
              if (res.data !== undefined) {
                setSelectedTableData(res?.data);
              }
            }).catch(err => {
              logicContext.showToast('Items not found!', "info");
              setSelectedTableData([]);
            });
          }
        }
      }
    },[batch_id, itemList]);
  
  
  
  
  
    useEffect(()=> {
      if(batch_id !== undefined){
        let routeProps = batch_id?.split('_');
        if(routeProps[1] == 3){
          let newItemId = routeProps[0];
          const selectedRouteItem =  itemList.filter((item)=>  item.Item_Id == newItemId);
          setSelectedFinishItemm(selectedRouteItem[0]);
        
          itemsSetupService.getProductionItemprops(newItemId).then((res) => {
            if (res.data !== undefined) {
              setSelectedTableData(res?.data);
            }
          }).catch(err => {
            logicContext.showToast('Items not found!', "info");
            setSelectedTableData([]);
          });
        }
      }
    },[batch_id, itemList]);


 


    function batchNumberValueChange(e) {
        if (e.key === "Enter") {
          itemsSetupService.modifyProduction(e.target.value).then((res) => {
            if (res.data !== undefined) {
              setSelectedTableData(res.data);
              setValue("remark", res.data[0]['Production_Remark']);
              setValue("Production_Date", moment(res.data[0]['Production_Date']).format("YYYY-MM-DD"));
              setValue("Production_Date", res.data[0]['Production_Date']);
              setValue("Production_Quantity", res.data[0]['Production_Quantity']);
            }
          }).catch(err => {
            logicContext.showToast('No record found!', "info");
            setSelectedTableData([]);
          })
      
        }
      }


      function selectedWareHousePick(item) {
        setValue("Warehouse_Id", item.Code);
      }


      function productionQty(qty) {
        setValue("Production_Quantity", qty);
        const modifiedData = selectedTableData?.map((item) => {
          return { ...item, Required_Quantity: item.Item_quantity_per_Unit * qty };
        });
        setSelectedTableData(modifiedData);
      }



      function modifyExtraQuantity(newQuatity, itemModify) {
        const modifiedList = selectedTableData.map((item) => {
          return { ...item, Extra_Quantity: newQuatity };
        });
        setSelectedTableData(modifiedList);
      }


    async  function submitItemListToServer() {
      await myPromiseModal({title:'Confirmation', body:' Do you want to save this record?',
      showInput:false, placeHolder:''}).then(response => {
        if(response.decision === true){
          let checkWareHouse =  getValues['Warehouse_Id'];
          let temp2 = getValues();
      
          if(!temp2?.Warehouse_Id){
            return logicContext.showToast('Please select a warehouse', 'error');
          }
          if (response.decision === true) {
            let xml_string = "";
            let temp = "";
            selectedTableData.map((item, i) => {
              temp += `<a><item_id>${item.Item_Id} </item_id>  
              <item_quantity>${item.Required_Quantity} </item_quantity> 
               <extra_quantity>${item.Extra_Quantity} </extra_quantity> 
               </a>
              `;
            });
            xml_string = `<root> ${temp} </root>`;
            
           
            let customOption = 1;
      
            if(temp2.batch_no){
              customOption = 2;
            }
      
          
            itemsSetupService.submitProduction({ xml_string, ...temp2, option: customOption ,
            Item_Id: selectedFinishItem?.Item_Id})
              .then((res) => {
                logicContext.showToast(res.data[0]["message_text"], "success");
                setSelectedTableData([]);
                // reloadFinishedItems();
              });
          } else {
          }
       
        }
      });
      }







    return ( 
        <>
           <section className="container">
                  <section className=" mt-2 text-filterTextColor">
                    <section className="profile_section w-[56vw] ">
                      <div className=" w-full  ">
                        <div className="flex justify-between w-full">
                          {" "}
                          
                          <div className="required-q">
                            { getValues('batch_no') ? 
                            
                          <>
                            <span className="text-xs">Batch Number</span>{" "}
                            <br></br>
                            <input
                                    {...register("batch_no")}
                                    onKeyDown={batchNumberValueChange}
                                    disabled={editMode}
                                    className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                    type="number"
                                  />
                          </>
                          
                            :  
                             <small className="text-primary">Batch NO. <small className="text-danger" > <br/> Auto Generated</small> </small>}
                          
                          </div>
                          <div className="required-q">
                            <span className="text-xs">Production Date</span>{" "}
                            <br></br>
                            <input
                                    // type="date" defaultValue={getValues('Production_Date')}
                                    {...register("Production_Date")}
                                    type="date" defaultValue={moment(getValues('Production_Date')).format("YYYY-MM-DD")}
                                    className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                  />
                          </div>
                          <WarehouseItemSelect
                            list={wareHouseList}
                            defaultValue={wareHouseList}
                            userSelection={selectedWareHousePick}
                          />
                        </div>
                      </div>
                      <div className="  ">
                        <div className="flex w-full">
                          {" "}
                          <div className="required-q">
                            <span className="text-xs">Production Quantity</span>{" "}
                            <br></br>
                            <input
                              {...register("Production_Quantity")}
                              onChange={(e) => productionQty(e.target.value)}
                              className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                              type="number"
                            />
                          </div>
                          <div className="required-q-2 ml-2">
                            <span className="text-xs">Remark</span> <br></br>
                            <input
                              {...register("remark")}
                              className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      {/* end */}

                    </section>
                
                  </section>
                      
                  <section className="mt-3">
                        <ProductionItemTable2
                          modifyExtraQuantity={modifyExtraQuantity}
                          tableData={selectedTableData}
                        ></ProductionItemTable2>

                  <section className="mt-[5vh] ">
                                <div className="flex justify-end  ">
                                  <button
                                    style={{ backgroundColor: "#C6C6C6", color: "#151EFA" }}
                                    className=" h-[6vh] rounded  text-xs py-1 mr-2 px-4"
                                  >
                                    DELETE
                                  </button>
                                  <button
                                    onClick={()=> submitItemListToServer()}
                                    style={{ backgroundColor: "#476EF8" }}
                                    className=" h-[6vh]  rounded text-white text-xs py-1 mr-2 px-4"
                                  >
                                    SAVE
                                  </button>
                                </div>
                              </section>

                      </section>
                </section>
        </>
     );
}

export default ProductionEntryTab;