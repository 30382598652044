/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
// import GrocedySettingTable  from './GrocedySettingTable';
import { GetFeatureListAtom, SetFeatureListAtom } from "../../store/actionUrlAtom";
import { Link } from "react-router-dom";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import RestartAlt from "@mui/icons-material/RestartAlt";
import { settingsService } from "../../services/settingsService";
import FeatureActionMainTable from "./FeatureActionUrlTable";


const FeatureActionUrlMainPage = () => {
    const getFeatureList = GetFeatureListAtom();
    const setFeatureList = SetFeatureListAtom();
    const navigate = useNavigate();
    const { pathname } = useLocation();  
    var url = pathname.split('/')
    const currModuleId = url[1];
    const currModuleName = url[2];
    const currUserMenu = url[3];


  useEffect(() => {
    if (getFeatureList.length < 1) {
      loadDataFromServer();
    }
  }, [getFeatureList.length]);



  const loadDataFromServer = () => {
    settingsService.getHomePageFeature().then(res => {
      if (res?.data?.length) {
        setFeatureList(res.data);
      }
    });
  }



  const addNewMenu = () => {
    navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/add-new`);
  }


  return (
    <>
      <div className=" b">
        <section className="">




          <div className="flex justify-between w-full  "  >
            <Tooltip title="Reload">
              <RestartAlt
                className="cursor ml-2 mt-1"
                onClick={() => loadDataFromServer()}
                color="primary"
                fontSize="large"
              />
            </Tooltip>
            <button
              onClick={() => addNewMenu()}
              style={{ backgroundColor: "#476EF8" }}
              className="  rounded text-white text-sm font-[Poppins] mt-1 py-2  px-3"
            >
              Add New
            </button>
          </div>



          <FeatureActionMainTable list={getFeatureList} />
        </section>
      </div>


    </>
  );
}
 
export default FeatureActionUrlMainPage;
