import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { itemsService } from '../../services/items.service';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { productService } from '../../services/product.service';
import Card from '@mui/material/Card';
import GeneralModal from "../../helpers/GeneralModal";
import { createModal, } from 'react-modal-promise';
import { SetCouponEdit } from '../../store/productAtom';
import { useNavigate, useLocation } from "react-router-dom";
import LogicContext from "../../Context/LogicContext";
import { Grid } from "@mui/material";
import { formatCustomNumber } from "../../helpers/formatCustomNumber";






function ProductCoupon(props) {
  const myPromiseModal = createModal(GeneralModal);
  const [tableData, setTableData] = useState([]);
  const logicContext = useContext(LogicContext);
  const setCouponEdit = SetCouponEdit();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];



  useEffect(() => {
    if (props?.Product_Id !== undefined) {
      productService.getProductCoupon(props?.Product_Id).then(res => {
        if (res.data !== undefined) {
          setTableData(res.data);
        }
      });
    }
  }, [props?.Product_Id])




  function showInformation() {
    if (props?.Product_Id !== undefined) {
      productService.getProductCoupon(props?.Product_Id).then(res => {
        if (res.data !== undefined) {
          setTableData(res.data);
        }
      });
    }
  }



  function openPanel() {
    if (tableData?.length < 1) {
      showInformation()
    }
  }


  async function editCoupon(coupon) {
    await myPromiseModal({
      title: 'Edit Coupon?', body: ' Do you want to modify this record?',
      showInput: false, placeHolder: ''
    }).then(res => {
      if (res.decision === true) {
        setCouponEdit(coupon)
        navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/edit-coupon/${coupon?.Coupon_Id}/${coupon.Product_Id}`
        );

      }
    })

  }

  const markAsInActive = async (item) => {
    await myPromiseModal({ title: 'Mark as Inactive!', body: ' Do you want to mark this coupon as Inactive?' }).
      then((res) => {
        if (res?.decision === true) {
          //  send to server
   

          const coupon = {Redemption_Type_Id: item?.Redemption_Type_Id, Product_Id: item?.Product_Id, Coupon_Id: item?.Coupon_Id,
            Discount_Type_Id: item?.Discount_Type_Id, Coupon_Name: item?.Coupon_Name, Coupon_Discount: item?.Coupon_Discount,
            Coupon_Code: item?.Coupon_Code, Coupon_Valid_UpTo: item?.Coupon_Valid_UpTo, Maximum_Redemption: item?.Maximum_Redemption
            }
          productService.submitProductCoupon({ ...coupon, option: 4 }).then(res => {
            if (res.data !== undefined) {
              const { message_text, message_id } = res.data[0];
              if (message_id === 1) {
                logicContext.showToast(message_text, "success");
                const newList = tableData.map((coupon) => {
                  if (coupon.Coupon_Id === item.Coupon_Id) {
                    return ({ ...coupon, Coupon_Status_Name: 'Inactive', Coupon_Status_Id: 2 })
                  }
                  return coupon;
                })
                setTableData(newList)
              } else {
                return logicContext.showToast(message_text, "error");
              }
            }
          })
        }
      })
  }


  const markAsActive = async (item) => {
    await myPromiseModal({
      title: 'Mark as Active!', body: ' Do you want to mark this coupon as Active?',
      showInput: false, placeHolder: ''
    }).then((res) => {
      if (res.decision === true) {
        
        
        const coupon = {Redemption_Type_Id: item?.Redemption_Type_Id, Product_Id: item?.Product_Id, Coupon_Id: item?.Coupon_Id,
          Discount_Type_Id: item?.Discount_Type_Id, Coupon_Name: item?.Coupon_Name, Coupon_Discount: item?.Coupon_Discount,
          Coupon_Code: item?.Coupon_Code, Coupon_Valid_UpTo: item?.Coupon_Valid_UpTo, Maximum_Redemption: item?.Maximum_Redemption
          }
        //  send to server
        productService.submitProductCoupon({ ...coupon, option: 5 }).then(res => {
          if (res.data !== undefined) {
            const {  message_text, message_id } = res.data[0];
            if (message_id === 1) {
              logicContext.showToast(message_text, "success");
              const newList = tableData.map((coupon) => {
                if (coupon.Coupon_Id === item.Coupon_Id) {
                  return ({ ...coupon, Coupon_Status_Name: 'Active', Coupon_Status_Id: 1 })
                }
                return coupon;
              })
              setTableData(newList)
            } else {
              return logicContext.showToast(message_text, "error");
            }
          }
        })
      }
    })

  }



  const deleteActive = async (item) => {
    await myPromiseModal({
      title: 'Delete Record ?',
      body: ' Record will be delete and cannot be recovered!', showInput: false, placeHolder: ''
    }).
      then((res) => {
        if (res.decision === true) {
          //  send to server
          productService.submitProductCoupon({ ...item, option: 3 }).then(res => {
            // if(res.data !== undefined){
            //   const { message_text, message_id } = res.data[0];
            //   if(message_id === 1){
            //     logicContext.showToast(message_text, "success");
            //     const newList = tableData.map((coupon)=> {
            //       if(coupon.Coupon_Id === item.Coupon_Id){
            //         return ({...coupon, Coupon_Status_Name:'Active', Coupon_Status_Id:1})
            //       }
            //       return coupon;
            //     })
            //     setTableData(newList)
            //   }else{
            //    return logicContext.showToast(message_text, "error");
            //   }
            // }
          })
        }
      })

  }



  return (
    <>

      <section className="my-2  ">
        <Accordion expanded={true}>
          <AccordionSummary onClick={openPanel} style={{ backgroundColor: "#E7ECFF" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="font-weight-bold" >  Coupon List</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {tableData?.map((item, i) => {
              return (


                <Grid container className=" border-2 my-2 py-2 px-1" spacing={2}>
                  <Grid item xs={4}>
                    <div className="">
                      <div >
                        <span  className="text-sm" style={{ color: "#45444B" }}>Name: <span className="font-bold text-sm">{item?.Coupon_Name}</span> </span> 
                      </div>

                      <div>
                      <span style={{ color: "#45444B" }} className=" text-sm  ">Code : <span className="font-bold text-sm">{item?.Coupon_Code}</span>  </span>
                      </div>
                     


                      <div>
                        <span style={{ color: "#45444B" }} className="text-sm">Discount type:  <span className="font-bold text-sm"> {item?.Discount_Type_Name} </span>  </span>
                      </div>
                      <div>
                        <span style={{ color: "#45444B" }} className="text-sm">Discount Rate:  <span className="font-bold text-sm"> {formatCustomNumber(item?.Coupon_Discount)} </span>  </span>
                      </div>
                    </div>

                  </Grid>
                  <Grid item xs={2}>
                    <div className="">
                      {(item?.Coupon_Status_Id === 1) ?
                        <span className="text-sm" >Status:<span className="badge text-sm badge-success  font-weight-bold"> {item?.Coupon_Status_Name} </span></span>
                        :
                        <span className="text-sm" >Status: <span className="badge text-sm badge-danger  font-weight-bold">{item?.Coupon_Status_Name}</span> </span>}
                    </div>

                  </Grid>

                  <Grid item xs={6}>
                    <Grid container className=" " spacing={2}>
                      <Grid item xs={12}>
                        <div className="flex">
                       <div>
                       <span className="font-bold text-sm"> {item?.Redemption_Type_Name} Redemption:</span><br></br>
                       </div>
                          <div className="ml-2 ">
                          <span className="font-bold text-sm"> Redemption Type :  {item?.Redemption_Type_Name} </span><br></br>
                          <span style={{ color: "#45444B" }} className="text-sm"> {item?.Maximum_Redemption} Max Usage /
                            {item?.Coupon_Uses_Left} left </span>
                          </div>
                        </div>

                      </Grid>
                      <Grid item xs={12} className="flex justify-between">
                        <button className="py-0.5 px-1  rounded text-sm text-white bg-yellow-300" onClick={() => editCoupon(item)} >Edit</button>
                        {(item?.Coupon_Status_Id === 1) ?
                          <button className="py-0.5 px-1 mx-2 rounded text-sm text-white bg-orange-300 " onClick={() => markAsInActive(item)} >Mark as Inactive</button> : null}
                        {(item?.Coupon_Status_Id === 2) ?
                          <button className="py-0.5 px-1  rounded text-sm text-white bg-green-500" onClick={() => markAsActive(item)} >Mark as active</button> : null}
                        {/* <button className="py-0.5 px-3  rounded text-sm text-white bg-red-900" onClick={() => deleteActive(item)} style={{ color: "#F56132" }}>Delete</button> */}
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>



              )

            })}

          </AccordionDetails>
        </Accordion>
        {/* <Card key={i} className="mb-3 ">

          <div className="row">
            <div className="col-4 ml-3">
              <div >
                <span className="font-bold text-md" style={{ color: "#45444B" }}>{item?.Coupon_Name} </span>
              </div>
              <div >
                <span className="font-bold text-md badge p-2 badge-light">Code - {item?.Coupon_Code} </span>
              </div>

              <div>
                <span style={{ color: "#45444B" }} className="text-md">Discount type:  <span className="font-bold"> {item?.Discount_Type_Name} </span>  </span>
              </div>
              <div>
                <span style={{ color: "#45444B" }} className="text-md">Discount Rate:  <span className="font-bold"> {item?.Coupon_Discount} </span>  </span>
              </div>
            </div>

            <div className="col-1">
              {(item?.Coupon_Status_Id === 1) ?
                <span >Status:<span className="badge badge-success p-1 font-weight-bold"> {item?.Coupon_Status_Name} </span></span>
                :
                <span >Status: <span className="badge badge-light p-1 font-weight-bold">{item?.Coupon_Status_Name}</span> </span>}
            </div>

            <div className="col-6">

              <div className="clearfix">
                <div className="float-right">
                  <div className="text-right">
                    <span className="font-bold text-md"> {item?.Redemption_Type_Name} Redemption</span><br></br>
                    <span className="font-bold text-sm"> Redemption Type :  {item?.Redemption_Type_Name} </span><br></br>
                    <span style={{ color: "#45444B" }} className="text-sm"> {item?.Maximum_Redemption} Max Usage /
                      {item?.Coupon_Uses_Left} left </span>
                  </div>

                  <button className="btn m-2" onClick={() => editCoupon(item)} >Edit</button>
                  {(item?.Coupon_Status_Id === 1) ?
                    <button className="btn m-2" onClick={() => markAsInActive(item)} >Mark as Inactive</button> : null}
                  {(item?.Coupon_Status_Id === 2) ?
                    <button className="btn m-2" onClick={() => markAsActive(item)} >Mark as active</button> : null}
                  <button className="btn m-2" onClick={() => deleteActive(item)} style={{ color: "#F56132" }}>Delete</button>

                </div>
              </div>
            </div>
          </div>

        </Card> */}

      </section>








    </>
  );
}

export default ProductCoupon;
