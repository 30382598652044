import Box from "@mui/material/Box";
import { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Modal from "@mui/material/Modal";
import { useForm } from "react-hook-form";
import LogicContext from "../../Context/LogicContext";
import { productService } from "../../services/product.service";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ProductAddModal = (props) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [open, setOpen] = useState(false);
  const addNewProduct = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const logicContext = useContext(LogicContext);

  // props.submitNewProduct()

  const onSubmit = async () => {
    const newData = getValues();
    const option = 1;
    productService.saveNewProduct({ ...newData, option: option })
      .then((res) => {
        if (res?.data !== undefined) {
          const { message_text } = res.data[0];
          logicContext.showToast(message_text, "success");
          handleClose();
          reset({});
          props.reloadProductList();
        }
      });
  };

  function onErrors(error) {
    console.error(errors);
    logicContext.showToast("Form error", "error");
  }

  return (
    <>
      <div className="">
        <button
          onClick={addNewProduct}
          className="bg-blue-600 px-3 text-white rounded btn-sm hover:bg-blue-500"
        >
          Add Product
        </button>
        <div>
          {/* <Button >Open modal</Button> */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="modal-body">
              <DialogActions>
                <button
                  className="btn-danger btn-sm"
                  autoFocus
                  onClick={handleClose}
                >
                  X
                </button>
              </DialogActions>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <form onSubmit={handleSubmit(onSubmit, onErrors)}>
                  <section>
                    <div>
                      <span>Name</span>
                      <div className="name">
                        <input
                          className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="text"
                          placeholder="enter name"
                          {...register("Product_Name", {
                            required: "Product name is required",
                          })}
                        />
                      </div>
                      <small className="text-danger">
                        {errors?.Product_Name && errors.Product_Name.message}
                      </small>
                    </div>

                    <div className="description mt-2">
                      <span>
                        Description <i>Option</i>{" "}
                      </span>
                      <div className="name">
                        <input
                          className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="text"
                          placeholder="write description"
                          {...register("Product_Description", {})}
                        />
                      </div>
                    </div>

                    <div className="name mt-4">
                      <span>Notification Mail Address</span>
                      <p className="text-sm">
                        This is the email address where alerts about this
                        product should be sent. (To separate several email
                        addresses, use commas.)
                      </p>
                      <div className="name">
                        <input
                          className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="text"
                          placeholder="write notification address"
                          {...register("Mail_Address", {})}
                        />
                      </div>
                    </div>

                    <div className="name mt-4">
                      <span>Redirect URL</span>
                      <p className="text-sm">
                        This is the URL to which your clients should be
                        redirected to after completing the process on the
                        payment page.
                      </p>
                      <div className="name">
                        <input
                          className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                          type="text"
                          placeholder="write redirect url"
                          {...register("Notification_Url", {})}
                        />
                      </div>
                      <p>
                        <i>
                          If the field is left empty, your customers will be
                          redirected to the default thank you page.
                        </i>
                      </p>
                    </div>

                    <div className="my-2">
                      <div>
                        <input className="h-[1vw] w-[1vw]" type="checkbox" />
                        <span className="text-xs ml-2">
                          Auto-generate Product Numbers
                        </span>
                      </div>
                    </div>

                    <div className="prefix mt-4 w-full ">
                      <div className="flex justify-between w-full">
                        <div className="name">
                          <span className="text-sm">Prefix (Alphanumeric)</span>
                          <div className="name">
                            <input
                              className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="  ">
                          <div className="name  ">
                            <span className="text-sm">
                              Next Numbers (Numeric)
                            </span>

                            <div className="name">
                              <input
                                className="bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                                type="text"
                                placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="gird grid-cols-2 flex justify-between mt-4 gap-4 w-full">
                      <button
                        style={{ color: "#F17E7E" }}
                        className="text-sm rounded"
                        onClick={handleClose}
                      >
                        CANCEL
                      </button>
                      <button
                        type="submit"
                        style={{ backgroundColor: "#476EF8" }}
                        className="text-sm text-white px-3 rounded py-2"
                      >
                        SAVE
                      </button>
                    </div>
                  </section>
                </form>
              </Typography>
            </Box>
          </Modal>
        </div>
        {/* end Add product */}
      </div>
    </>
  );
};

export default ProductAddModal;
