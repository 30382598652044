import React, { useContext } from "react";

import pencil from "../../assets/pencil.png";
import trash from "../../assets/trash.png";
import dollar from "../../assets/dollar.svg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

import "./ItemDetailsPage.css";
import ToggleSwitch from "../../components/ToggleSwitch";
import ItemBundleHistory from "../../components/ItemBundleHistory";
import RateHistory from "../../components/RateHistory";
import History from "../../components/History";
import ItemOverviewPage from "../../components/ItemOverviePage";
import ItemDetailsSideScroll from "./ItemDetailsSideScroll";
import ItemTransaction from "../../components/ItemTransaction";
import { itemsService } from "../../services/items.service";
import { SetItemToEdit, SetItemImageToEdit } from "../../store/itemAtom";
import LogicContext from "../../Context/LogicContext";
import { Grid } from "@mui/material";
import GeneralModal from "../../helpers/GeneralModal";
import { createModal } from "react-modal-promise";

const TAB_SECTION = {
  OVERVIEW: "OVERVIEW",
  BUNDLEHISTORY: "BUNDLEHISTORY",
  PRODUCTION_ENTRY: "PRODUCTION_ENTRY",
  TRANSACTIONS: "TRANSACTIONS",
  RATEHISTORY: "RATEHISTORY",
  HISTORY: "HISTORY",
};

function ItemDetailsPage() {
  const [selectedTab, setSelectedTab] = useState(TAB_SECTION.OVERVIEW);
  const setItemToEdit = SetItemToEdit();
  const myPromiseModal = createModal(GeneralModal);

  const setItemImageToEdit = SetItemImageToEdit();
  const [itemRecord, setItemRecord] = useState({});
  const [imageList, setImageList] = useState([]);
  const logicContext = useContext(LogicContext);

  const { item_id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  useEffect(() => {
    if(item_id != undefined){
      getItemDetailFromServer(item_id);
    }
   
  }, [item_id]);

  function getItemDetailFromServer(id) {
    if(!id){
      return ;
    }
    itemsService.GetItemDetails(id).then((res) => {
      if(res?.data?.item_info){
        setItemRecord(res?.data.item_info[0]);
      }else{
        setItemRecord({});
      }
      if(res?.data?.item_picture_info?.length){
        setImageList(res?.data.item_picture_info);
      }else{
        setImageList([]);
      }
    })
      .catch((err) => {
      });
  }

  function viewItemDetails(id) {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/item-details/${id}`
    );
    getItemDetailFromServer(id);
  }

  const selectTab = (section) => {
    setSelectedTab(section);
  };

  const editCurrentItem = async() => {
    const response = await myPromiseModal({
      title: "Modify Item Group",
      body: " Do you want to modify this record?",
      showInput: false,
      placeHolder: "",
    });

    if (response.decision === true) {
      setItemToEdit(itemRecord);
      setItemImageToEdit(imageList);
      navigate(
        `/${currModuleId}/${currModuleName}/${currUserMenu}/add-item/${1}`
      );

    }
    
   
  };

  const addNewItemHandler = () => {
    navigate(
      `/${currModuleId}/${currModuleName}/${currUserMenu}/add-item/${1}`
    );
  };

  // This method is for updating item status base on toggle response TRUE/FALSE
  const toogleItemStatus = (val) => {
    if (val === true) {
      setItemRecord({
        ...itemRecord,
        Item_Status_Id: 1,
        Item_Status_Name: "Active",
      });

      let xmlString = `<a><refno>${itemRecord?.Item_Id} </refno></a>`;
      itemsService
        .setItemActive(`<root> ${xmlString} </root>`)
        .then((res) => {
          logicContext.showToast(res.data[0]["message_text"], "success");
        })
        .catch((err) => {
          logicContext.showToast("Connection error", "error");
          setItemRecord({
            ...itemRecord,
            Item_Status_Id: 2,
            Item_Status_Name: "Inactive",
          });
        });
    }

    if (val === false) {
      setItemRecord({
        ...itemRecord,
        Item_Status_Id: 2,
        Item_Status_Name: "Inactive",
      });
      let xmlString = `<a><refno>${itemRecord?.Item_Id} </refno></a>`;

      itemsService
        .setIteminActive(`<root> ${xmlString} </root>`)
        .then((res) => {
          logicContext.showToast(res.data[0]["message_text"], "success");
        })
        .catch((err) => {
          logicContext.showToast("Connection error", "error");
          setItemRecord({
            ...itemRecord,
            Item_Status_Id: 1,
            Item_Status_Name: "Active",
          });
        });
    }
  };

  return (
    <section className="   mt-2">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <section className=" flex justify-end ">
            <div className="flex  ">
              <div>
                <button className=" md:mr-3"> </button>
              </div>
              <div className="flex">
                {itemRecord.Item_Status_Id === 1 ? (
                  <span className="text-sm mt-[1.9vh] text-success">ACTIVE</span>
                ) : (
                  <span className="text-sm mt-[1.9vh]">INACTIVE</span>
                )}
                <ToggleSwitch
                  toogleItemStatus={toogleItemStatus}
                  Item_Status_Id={itemRecord?.Item_Status_Id}
                ></ToggleSwitch>
              </div>

              {/* ADD NEW TRANSACTION SECTION */}
              <div className="flex">
                {/* paper, edit and pencil section */}

                <div className="flex ">
                  <button
                    style={{ borderRadius: "5px" }}
                    className="   px-4 h-[6vh] text-filterTextColor bg-filterColor lg:pt-1  "
                  >
                    <img src={trash} alt="" />
                  </button>

                  <button
                    style={{ borderRadius: "5px" }}
                    onClick={editCurrentItem}
                    className=" mx-2 px-4  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1  "
                  >
                    <img src={pencil} alt="" />
                  </button>

                  <button
                    style={{ borderRadius: "5px" }}
                    className=" mr-2 px-4  h-[6vh] text-filterTextColor bg-filterColor lg:pt-1 "
                  >
                    <img src={dollar} alt="" />
                  </button>

                  <button
                    onClick={addNewItemHandler}
                    style={{ backgroundColor: "#476EF8" }}
                    className=" h-[6vh]  rounded text-white text-xs py-1 mr-1 px-4">
                    ADD NEW
                  </button>
                </div>
              </div>
            </div>
          </section>
        </Grid>


        <Grid item xs={12}>
          <section className="">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <ItemDetailsSideScroll
                  viewItemDetails={viewItemDetails}
                />
              </Grid>
              <Grid item className="" xs={9}>
                <section className=" ">
                  {/* overview and comment */}
                  <section className="  border-b-2 text-filterTextColor sm: flex justify-between md:mt-5">
                    <span
                      onClick={() => selectTab(TAB_SECTION.OVERVIEW)}
                      className={
                        "text-sm text-filterTextColor E5E5E5 show-cursor " +
                        (selectedTab === TAB_SECTION.OVERVIEW
                          ? "font-weight-bold text-primary "
                          : null)
                      }
                    >
                      Overview
                    </span>

                    <span
                      onClick={() => selectTab(TAB_SECTION.TRANSACTIONS)}
                      className={
                        "text-sm text-filterTextColor show-cursor  " +
                        (selectedTab === TAB_SECTION.TRANSACTIONS
                          ? "font-weight-bold text-primary "
                          : null)
                      }
                    >
                      Transactions
                    </span>
                    {/* commented out for temprarrary purpose , we want item type to display first */}

                    <span
                      onClick={() => selectTab(TAB_SECTION.BUNDLEHISTORY)}
                      className={
                        "text-sm text-filterTextColor show-cursor " +
                        (selectedTab === TAB_SECTION.BUNDLEHISTORY
                          ? "font-weight-bold text-primary "
                          : null)
                      }
                    >
                      Production History
                    </span>

                    <span
                      onClick={() => selectTab(TAB_SECTION.RATEHISTORY)}
                      className={
                        "text-sm text-filterTextColor show-cursor  " +
                        (selectedTab === TAB_SECTION.RATEHISTORY
                          ? "font-weight-bold text-primary "
                          : null)
                      }
                    >
                      Rate History
                    </span>

                    <span
                      onClick={() => selectTab(TAB_SECTION.HISTORY)}
                      className={
                        "text-sm text-filterTextColor show-cursor  " +
                        (selectedTab === TAB_SECTION.HISTORY
                          ? "font-weight-bold text-primary "
                          : null)
                      }
                    >
                      History
                    </span>
                  </section>

                  <section className=" mt-2 max-auto max-w-6xl text-filterTextColor">
                    {/* 
    DETAILS SECTION */}
                    <section className=" ">
                      {/* CUSTOMER DETAILS INFORMATION */}
                      {selectedTab === TAB_SECTION.OVERVIEW ? (
                        <ItemOverviewPage
                          viewCustomer={viewItemDetails}
                          imageList={imageList}
                          itemDetails={itemRecord}
                        />
                      ) : null}

                      {selectedTab === TAB_SECTION.BUNDLEHISTORY ? (
                        <ItemBundleHistory item_id={item_id} />
                      ) : null}

                      {selectedTab === TAB_SECTION.TRANSACTIONS ? (
                        <ItemTransaction item_id={item_id} />
                      ) : null}

                      {selectedTab === TAB_SECTION.RATEHISTORY ? (
                        <RateHistory item_id={item_id} />
                      ) : null}

                      {selectedTab === TAB_SECTION.HISTORY ? (
                        <History item_id={item_id} />
                      ) : null}
                    </section>
                  </section>
                </section>
              </Grid>
            </Grid>
          </section>
        </Grid>
      </Grid>
    </section>
  );
}

export default ItemDetailsPage;
