import styles from "./Comment.module.css";
import chat from "../../assets/chat.png";
import { customerService } from "../../services/customer.service";
import { useEffect, useState } from "react";
import { useNavigate,  useLocation } from "react-router-dom";
import { Table, Input } from 'antd';

const { Search } = Input;



function CustomerDetailsHistory(props) {
  const { customer_id } = props;
  const [history, setHistory] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  var url = pathname.split("/");
  const currModuleId = url[1];
  const currModuleName = url[2];
  const currUserMenu = url[3];

  useEffect(() => {
    customerService.customerDetailsHistory(customer_id).then((res) => {
      if (res?.data) {
        const  customizeData =  res.data.map((record)=> {
          return {...record, History_Created_By: record.History_Created_By?.toString()}
        })
        setHistory(customizeData);
        setFilteredData(customizeData);

      }
    });
  }, [customer_id]);




  const handleRowClick = (record) => {
    // navigate(`/${currModuleId}/${currModuleName}/${currUserMenu}/details/${record?.IVR_Id}`);
  };


  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = history.filter((record) =>
      Object.values(record).some((text) =>
        String(text).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };


  const paginationOptions = {
    pageSize: 10,
    showSizeChanger: true,
    total: filteredData.length,
    // onChange: (page, pageSize) => {

    //   setTableList(newList);
    // },
  };



const columns = [
    {
      title: 'Ref Number',
      key: 'id',
      dataIndex: 'History_Ref_No',
      sorter: (a, b) => a.History_Ref_No.localeCompare(b.History_Ref_No)
    //   render: () => <HolderOutlined style={{ fontSize: '20px', color:'blue' }} />,
    },
 
    {
      title: "History Date",
      dataIndex: "History_Date",
      sorter: (a, b) => a.History_Date.localeCompare(b.History_Date)
    },
  
    {
      title: "Type",
      dataIndex: "History_Type",
      sorter: (a, b) => a.History_Type.localeCompare(b.History_Type)
    },
    {
      title: "Description",
      dataIndex: "History_Description",
      sorter: (a, b) => a.History_Description.localeCompare(b.History_Description)
    },
    {
      title: "Created By",
      dataIndex: "History_Created_By",
      sorter: (a, b) => a.History_Created_By.localeCompare(b.History_Created_By)
    },
  ];



  return (
    <>
      <div className="mt-4 p-4 w-full bg-white @container">
        <div className="">
        <Search
        placeholder="Search..."
        onSearch={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        value={searchText}
        style={{ marginBottom: 10, width: 250 }}
      />

        <Table key={filteredData?.length} columns={columns} dataSource={filteredData}
                      pagination={paginationOptions}
                      style={{ background: '#f0f2f5', borderRadius: '8px' }}
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            handleRowClick(record);
                          },
                        };
                      }}
                        />

        </div>
      </div>
    </>
  );
}

export default CustomerDetailsHistory;
