import { useEffect } from "react";
import Select from 'react-select';



function CooperateSelect(props) {

  useEffect(()=> {

  },[props?.list]);

  const notificationOptions = props?.list?.map((item)=> {
    return ({...item, label:`${item?.Customer_Name}`})
  });

  
  const customStyles = {
    control: base => ({
      ...base,
      height: 50,
      backgroundColor: '#ffffff',
      width: 300,
    }),
    indicatorSeparator: styles => ({ ...styles, display : 'none' }),
    // placeholder: styles => ({ ...styles, display : 'none' }),
    valueContainer: styles => ({ ...styles, backgroundColor: '',height: 20, paddingBottom:"45px", marginLeft:"13px"}),
    option: styles => ({ ...styles, backgroundColor: '#ffffff', color:"black"}),

  
    
  }

    const handleChange = (value) => {
      props.onCooperateSelect(value)
      };


    return ( 
        <>
   
         <Select   styles={customStyles} onChange={handleChange} 
          options={notificationOptions}
         />


        </>
     );
}

export default CooperateSelect;
