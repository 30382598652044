import Tooltip from "@mui/material/Tooltip";
import { useContext, useEffect, useState } from "react";
import AddCompositTable from "../../AddCompositTable";
import CompositSelect from "../../CompositSelect";
import { itemsSetupService } from "../../../services/itemSetup.service";
import { useForm } from "react-hook-form";
import LogicContext from "../../../Context/LogicContext";
import { createModal } from "react-modal-promise";
import GeneralModal from "../../../helpers/GeneralModal";
import { useNavigate } from "react-router-dom";
import { GetCompositeGroupAddList, SetCompositeGroupAddList, ResetCompositeGroupAddList,
} from "../../../store/compositeGroup";
import {GetCompositeGRoupEdit, SetCompositeGroupEdit} from '../../../store/compositeGroup';



function CompositeGroupForm(props) {
  const { register, setValue, getValues,  handleSubmit, reset, formState: { errors }} = useForm();
  const [currSelection, setCurrSelection] = useState({});
  const tableData = GetCompositeGroupAddList();
  const setTableDataList = SetCompositeGroupAddList();
  const resetTableDataList = ResetCompositeGroupAddList();
  const logicContext = useContext(LogicContext);
  const myPromiseModal = createModal(GeneralModal);
  const editableGroup = GetCompositeGRoupEdit();
  const navigate = useNavigate();
     
  setValue("Composite_Group_Quantity", 1);

  useEffect(() => {
    if(editableGroup?.Composite_Group_Id){
      setValue("Composite_Group_Quantity", 1);
      setValue("Maximum_Amount",  editableGroup.Maximum_Amount);
      setValue("Minimum_Amount",  editableGroup.Minimum_Amount);
      setValue("Composite_Group_Id",  editableGroup.Composite_Group_Id);
      setValue("Composite_Group_Name",  editableGroup.Composite_Group_Name);
      setValue("Composite_Group_Description",  editableGroup.Composite_Group_Description);
      getItemDetailsFromServer(editableGroup.Composite_Group_Id);
    }
  }, []);



  function getItemDetailsFromServer(id) {
    itemsSetupService.getCompositeInformation(id).then((res) => {
      if (res?.data !== undefined) {
        setTableDataList(res.data);
   
      }
    });
  }


  function userSelection(item) {
    const qty = getValues('Composite_Group_Quantity');
    setValue("Total_Amount", item.Sales_Rate * qty);
    setValue("Price", item.Sales_Rate);
    setCurrSelection({...item, Item_Quantity: parseInt(qty)});
  }


  function deleteItemFromTable(remove) {
    const newList = tableData.filter((item) => item.Item_Id !== remove.Item_Id);
    setTableDataList(newList);
  }


  function modifyQuatity(newValue, itemToUpdate) {
    if (newValue < 1) return;

    const maxAmount = getValues('Maximum_Amount');
    const minAmount = getValues('Minimum_Amount');
    let grandTotal = itemToUpdate.Sales_Rate * newValue;
    if(grandTotal > maxAmount){
      return logicContext.showToast('Amount must not be more than MAXIMUM amount, please reduce the quantity');
    }
    if(grandTotal < minAmount){
      return logicContext.showToast('Amount must be more than MINIMUM amount, please increase the quantity');
    }


    const modifiedList = tableData.map((item) => {
      if (item.Item_Id === itemToUpdate.Item_Id) {
        return { ...item, Item_Quantity: parseInt(newValue) };
      } else {
        return item;
      }
    });
    setTableDataList(modifiedList);
  }


  function addSelectionToTable() {
    const maxAmount = getValues('Maximum_Amount');
    const minAmount = getValues('Minimum_Amount');

    let grandTotal = currSelection.Sales_Rate * currSelection.Item_Quantity;
    if(grandTotal > maxAmount){
      return logicContext.showToast('Amount must not be more than MAXIMUM amount, please reduce the quantity');
    }
    if(grandTotal < minAmount){
      return logicContext.showToast('Amount must be more than MINIMUM amount, please increase the quantity');
    }

    if (!currSelection.Item_Id) {
      return logicContext.showToast("Please select an item", "info");
    }

    const exist = tableData?.find((element) => element.Item_Id === currSelection.Item_Id);
    if (exist)
      return logicContext.showToast( "Item already selected, please select another item","error");
    setTableDataList((prev) => {
      return [...prev, { ...currSelection }];
    });
  }


  function quantityChange(value){
    if(value < 1){
      return logicContext.showToast('minimum quantity must not be lesser than 1', 'error');
    }

    setCurrSelection(prev => {
      return({...prev, Item_Quantity: parseInt(value)})
    });

  }



  async function prompforPreviousPage() {
    await myPromiseModal({ title: "Composite Group has been saved!", body: " Do you want to add image?",
    showInput:false, placeHolder:''}).then((result) => {
      if (result.decision) {
        reset({})
        navigate(-1)
      }
    });
  }

  async function saveCompositeGroup() {
    const nameExist = getValues("Composite_Group_Name");
    if (!nameExist)
      return logicContext.showToast("Group name is required!", "error");

    await myPromiseModal({ title: "Confirmation", body: " Do you want to save this record?", 
    showInput:false, placeHolder:''}).then((response) => {
      if (response.decision === true) {
        let xml_string = "";
        let temp = "";
        tableData.map((item, i) => {
          temp += `<a><item_id>${item.Item_Id} </item_id>  
                  <item_quantity>${item.Item_Quantity} </item_quantity> 
                   <extra_quantity>${item.Item_Quantity} </extra_quantity> 
                   </a>
                  `;
        });
        xml_string = `<root> ${temp} </root>`;
        const formData = getValues();
        let option = 1;
        if (formData?.Composite_Group_Id) {
          // update item
          option = 2;
        }
        itemsSetupService
          .submitCompositeGroup({ xml_string, ...formData, option: option })
          .then((res) => {
            if (res?.data != undefined) {
              const { message_text, Message_Ref_No } = res?.data[0];
              logicContext.showToast(message_text, "success");
              setValue("Composite_Group_Id", Message_Ref_No);
              props.group_id(Message_Ref_No);
              props.changeTab(1)
              setTimeout(() => {
             
                if(formData?.Composite_Group_Id){
                  navigate(-1);
                }else{
                  // prompforPreviousPage();
                  resetTableDataList();
                }
              }, 2000);
            }
          });
      }
    });
  }

  function cancelTableList() {
    resetTableDataList();
  }

  return (
    <>
      <section className="mx-2  mt-2 max-auto max-w-4xl text-filterTextColor">
        <section className=" ">
          <section className=" mt-1 right-1   md:  ">
            <section className="">
              <small>Required <span className="text-danger font-weight-bold">*</span></small>
              <section className="">
                <span className="text-sm font-bold">Group Information</span>
                <div className="flex profile_section_one   w-full">
                  {" "}
                  <div className="">
                    <span style={{ color: "red" }} className="text-xs">
                      Group Name  <span className="text-danger font-weight-bold">*</span>
                    </span>{" "}
                    <br></br>
                    <input
                      className=" rounded bg-filterInput  bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="text"
                      placeholder="Group Name"
                      {...register("Composite_Group_Name", {
                        required: "Group name is required",
                      })}
                    />
                    <small className="text-danger">
                      {errors?.Composite_Group_Name &&
                        errors.Composite_Group_Name.message}
                    </small>


                    <div className="flex  ">
                           {/*  Max and min */}
                   <div className="required-description-two w-full ">
                
                <span className="text-xs">Minimum Amount </span> <span className="text-danger font-weight-bold">*</span> <br></br>
                <input
                  className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                  type="number"
                  placeholder=""
                  {...register("Minimum_Amount", {})}
                />
              </div>
             {/*  Max and min */}
             <div className="required-description-two w-full ml-3  ">
          
                <span className="text-xs">Maximum Amount </span> <span className="text-danger font-weight-bold">*</span> <br></br>
                <input
                  className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                  type="number"
                  placeholder=""
                  {...register("Maximum_Amount", {})}
                />
              </div>

          {/* end */}
                    </div>
                  </div>

                   

                  <div className="ml-3 description">
                    <span className="text-xs ">
                      Description <i>Optional</i>{" "}
                    </span>{" "}
                    <br></br>
                    <input
                      className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                      type="text"
                      placeholder="Write description"
                      {...register("composite_group_description", {})}
                    />
                  </div>
                </div>

              
              </section>
            </section>
          </section>
        </section>
      </section>

      <section className="mx-2  mt-4 max-auto max-w-4xl text-filterTextColor">
        <section className=" ">
          <section className=" mt-1 right-1   md:  ">
            <section className="">
              <section className="profile_section ">
                <div className="  ">
                  <span className="text-sm font-bold">Add Items</span>
                  <div className="flex justify-between profile_section_two  w-full">
                    {" "}
                    <CompositSelect
                      userSelection={userSelection}
                    ></CompositSelect>

                    <div className="flex justify-between w-full ml-3">
                    <div className="required-quantity-two w-full  ">
                      <span className="text-xs">Item quantity </span> <br></br>
                      <input
                        className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="number"
                        onChange={(e)=> quantityChange(e.target.value)}
                        placeholder=""
                        defaultValue={ getValues('Composite_Group_Quantity')}
                      />
                    </div>
                    <div className="required-quantity-two  w-full  ">
                      <span className="text-xs">Price </span> <br></br>
                      <input
                        className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="number"
                        placeholder=""
                        readOnly={true}
                        {...register("Price", {})}
                      />
                    </div>
                    <div className="required-quantity-two  w-full ">
                      <span className="text-xs">Total Amt </span> <br></br>
                      <input
                        className=" rounded bg-filterInput bottom-0 outline-none border-transparent focus:border-transparent focus:ring-0"
                        type="number"
                        placeholder=""
                        readOnly={true}
                        value={currSelection.Sales_Rate * currSelection.Item_Quantity}
                      />
                    </div>
                    <div className=" mt-4 w-full ">
                      <Tooltip title="add">
                        <button
                          onClick={addSelectionToTable}
                          style={{ backgroundColor: "#476EF8" }}
                          className="  rounded text-white text-sm   py-[1.6vh]   px-4"
                        >
                          ADD
                        </button>
                      </Tooltip>
                    </div>
                    </div>
                  
                  </div>
                </div>
                {/* end */}
              </section>
            </section>
          </section>
        </section>
      </section>

      <section className="mt-4 ml-[0.5vw] mr-[3vw]">
        <AddCompositTable
          deleteItemFromTable={deleteItemFromTable}
          modifyQuatity={modifyQuatity}
          tableData={tableData}
        ></AddCompositTable>
      </section>

      <div className="row">
        <div className="col offset-6"></div>
        <div className="col">
          <button
            onClick={cancelTableList}
            className="btn btn-light text-danger mr-3"
          >
            {" "}
            CANCEL
          </button>

          <button
            onClick={saveCompositeGroup}
            style={{ backgroundColor: "#476EF8" }}
            className="  rounded text-white text-sm font-[Poppins]  py-[1.8vh]   px-3"
          >
            SUBMIT
          </button>
        </div>
      </div>
    </>
  );
}

export default CompositeGroupForm;
